import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_branch = `${BASE_URL}:yyd3i3Ww/branch/:id`;
const get_staff_branch = `${BASE_URL}:yyd3i3Ww/getStaff`;
const get_sibling_branch = `${BASE_URL}:yyd3i3Ww/siblingBranches`;
const get_partner_branch = `${BASE_URL}:yyd3i3Ww/allowedChildBranches`;
const update_branch_head = `${BASE_URL}:yyd3i3Ww/update/branch_head`;
const set_managing_branch = `${BASE_URL}:yyd3i3Ww/managing_branch/update`;
const update_child_branches = `${BASE_URL}:yyd3i3Ww/update/allowedChildBranches`;

// Get Staff Branch
export type GetStaffBranchRequestType = Partial<{
    branch_id: string | number;
    headers: { Authorization: string };
}>;
export type staffdetailstype = Partial<{
    id: string;
    name: string;
    branch_type: string;
    phone: string;
    email: string;
    type: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    pincode: string;
    property_type: string;
    address_proof: string;
    gst_number: string;
    relationshil_manager: string;
    status: string;
    managed_by: string|number;
    branch_head: string | number;
    _branch_head:{
        id: string | number;
        fname: string;
    }
    _managed:{
        parent_channel:string|number;
    }
    view_branches: number[];
  }>;

export type GetStaffBranchResponseType = Partial<{
    id: string;
    name: string;
    phone: string;
    email: string;
    type: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    pincode: string;
    property_type: string;
    address_proof: string;
    gst_number: string;
    relationshil_manager: string;
}>;

export const getStaffBranch = (
    payload: AxiosRequestConfig<GetStaffBranchRequestType>
): AxiosPromise<GetStaffBranchResponseType> => {
    const branchId = payload.data?.branch_id;

    if (branchId === undefined) {
        throw new Error("branch_id is not provided in the payload.");
    }

    const url = staff_branch.replace(":id", branchId.toString());
    return apis.get(url, payload);
};

// Get Staff  of a particular Branch
export type GetStaffParticularBranchRequestType = Partial<{
    branch_id: string | number;
    headers: { Authorization: string };
}>;


export type GetStaffParticularBranchResponseType = Partial<{

    staff:{
        id:string|number;
        fname:string;
    }[];

    
}>;

export const getStaffParticularBranch = (
    payload: AxiosRequestConfig<GetStaffBranchRequestType>
): AxiosPromise<GetStaffParticularBranchResponseType> => {
   
    return apis.get(get_staff_branch, payload);
};

// Get Sibling Branch
export type GetSiblingBranchRequestType = Partial<{
    branch_id: string | number;
    headers: { Authorization: string };
}>;


export type GetSiblingBranchResponseType = Partial<{

    branch:{
        id:string|number;
        name:string;
    }[];
    
}>;

export const getSiblingBranch = (
    payload: AxiosRequestConfig<GetSiblingBranchRequestType>
): AxiosPromise<GetSiblingBranchResponseType> => {
   
    return apis.get(get_sibling_branch, payload);
};

// Get Partner Branches
export type GetPartnerBranchesRequestType = Partial<{
    headers: { Authorization: string };
}>;


export type GetPartnerBranchesResponseType = Partial<{

    branches:{
        id:string|number;
        name:string;
        email:string;
        level:string|number;
    }[];

    
}>;

export const getPartnerBranch = (
    payload: AxiosRequestConfig<GetPartnerBranchesRequestType>
): AxiosPromise<GetPartnerBranchesResponseType> => {
   
    return apis.get(get_partner_branch, payload);
};

// Update Branch Head
export type UpdateBranchHeadRequestType = Partial<{
    branch_id: string | number;
    staff_id: string | number;
    headers: { Authorization: string };
}>;


export type UpdateBranchHeadResponseType = Partial<{

    staff:{
        id:string|number;
        fname:string;
    }[];

    
}>;

export const updateBranchHead = (
    payload: AxiosRequestConfig<UpdateBranchHeadRequestType>
): AxiosPromise<UpdateBranchHeadResponseType> => {
   
    return apis.put(update_branch_head, payload);
};

// Set Managing Branch
export type UpdateManagingBranchRequestType = Partial<{
    branch_id: string | number;
    managing_branch: string | number;
    status: string;
    headers: { Authorization: string };
}>;


export type UpdateManagingBranchResponseType = Partial<{
 
}>;

export const updateManagingBranch = (
    payload: AxiosRequestConfig<UpdateManagingBranchRequestType>
): AxiosPromise<UpdateManagingBranchResponseType> => {
   
    return apis.put(set_managing_branch, payload);
};


// Update Allowed ChildBranch Request Type
export type UpdateAllowedChildBranchRequestType = Partial<{
    branch_id: string | number;
    childBranches: (string|number)[];
    headers: { Authorization: string };
}>;


export type UpdateAllowedChildBranchResponseType = Partial<{

    
}>;

export const updateChildBranch = (
    payload: AxiosRequestConfig<UpdateAllowedChildBranchRequestType>
): AxiosPromise<UpdateAllowedChildBranchResponseType> => {
   
    return apis.post(update_child_branches, payload);
};
