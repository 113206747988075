import React from "react";

import { Text } from "components";

type ChannelPartnerRegFiveHeaderProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "primarytexttwo" | "primarytexttwo1" | "primarytexttwo2"
> &
  Partial<{
    primarytexttwo: string;
    primarytexttwo1: string;
    primarytexttwo2: string;
    isUpdate: boolean;
  }>;

const ChannelPartnerRegFiveHeader: React.FC<
  ChannelPartnerRegFiveHeaderProps
> = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col h-12  items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              {!!props?.primarytexttwo ? (
                <Text
                  className="text-blue_gray-900_01 text-sm w-full"
                  size="txtRubikRomanSemiBold14"
                >
                  {props?.primarytexttwo}
                </Text>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex flex-col h-12  items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              {!!props?.primarytexttwo1 ? (
                <Text
                  className="text-blue_gray-900_01 text-sm w-full"
                  size="txtRubikRomanSemiBold14"
                >
                  {props?.primarytexttwo1}
                </Text>
              ) : null}
            </div>
          </div>
        </div>
        {props.isUpdate && (
          <div className="flex flex-col h-12  items-start justify-start w-full">
            <div className="flex flex-col h-full items-center justify-center px-3 w-full">
              <div className="flex flex-col items-start justify-start w-full">
                {!!props?.primarytexttwo2 ? (
                  <Text
                    className="text-blue_gray-900_01 text-sm w-full"
                    size="txtRubikRomanSemiBold14"
                  >
                    {props?.primarytexttwo2}
                  </Text>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

ChannelPartnerRegFiveHeader.defaultProps = {};

export default ChannelPartnerRegFiveHeader;
