import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { DealType, OrderType } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import {
  deal_status_options,
  order_status_options,
  payment_status_options,
} from "common/filters";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { Eye, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getDeal,
  GetDealRequestType,
  getOrder,
  GetOrderRequestType,
} from "service/api";
import { Capitalize } from "utils";

export const columns: ColumnDef<OrderType>[] = [
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Order-ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    filterFn: (row, id, value) => {
      const rowValue = String(row.getValue(id));
      const searchValue = String(value);
      return rowValue.includes(searchValue);
    },
  },
  {
    accessorKey: "customerName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Customer Name" />
    ),
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
  },
  {
    accessorKey: "source",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Source" />
    ),
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
  },
  {
    accessorKey: "orderStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Order Status" />
    ),
    cell: ({ row }) => {
      const status = order_status_options.find(
        (status) => status.value === row.getValue("orderStatus"),
      );

      if (!status) {
        return null;
      }

      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
        >
          {status.label}
        </p>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount" />
    ),
    cell: ({ row }) => {
      const amount = row.getValue("amount") as number;
      const paymentStatus = row.original.orderStatus;
      const status = order_status_options.find(
        (status) => status.value === paymentStatus,
      );

      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex gap-2 items-center">
                {amount}
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.19987 13.6673C10.8819 13.6673 13.8665 10.6827 13.8665 7.00065C13.8665 3.31865 10.8819 0.333984 7.19987 0.333984C3.51787 0.333984 0.533203 3.31865 0.533203 7.00065C0.533203 10.6827 3.51787 13.6673 7.19987 13.6673ZM8.19987 4.33398C8.41854 4.62465 8.5752 4.96465 8.64987 5.33398H9.86654V6.33398H8.64987C8.5346 6.89867 8.22778 7.40618 7.78132 7.77064C7.33486 8.13509 6.7762 8.3341 6.19987 8.33398H6.0732L8.5532 10.814L7.84654 11.5207L4.5332 8.20732V7.33398H6.19987C6.51014 7.33408 6.8128 7.23796 7.06616 7.05886C7.31952 6.87976 7.51111 6.62651 7.61454 6.33398H4.5332V5.33398H7.61454C7.51111 5.04146 7.31952 4.7882 7.06616 4.60911C6.8128 4.43001 6.51014 4.33389 6.19987 4.33398H4.5332V3.33398H9.86654V4.33398H8.19987Z"
                    fill={status?.textColor}
                  />
                </svg>
              </div>
            </TooltipTrigger>
            <TooltipContent className="bg-black-900 flex gap-2 items-center border-none rounded-lg">
              <p className="text-white-A700 text-xs font-medium">
                Payment Status
              </p>
              {status ? (
                <p
                  style={{
                    backgroundColor: status.bgColor,
                    color: status.textColor,
                  }}
                  className="py-[3px] text-sm leading-4 font-medium px-2 text-center rounded-md"
                >
                  {status.label}
                </p>
              ) : (
                "Status not available"
              )}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    },
  },
  {
    accessorKey: "serviceProvider",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Service Provider" />
    ),
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
  },
  // {
  //   accessorKey: "Action",
  //   cell: ({ row }) => {
  //     return (
  //       <Link
  //         className="text-center w-full"
  //         to={`/remit/orderdetails/${(row.getValue("id") as string)?.split("-")[1]}`}
  //       >
  //         <Eye className="h-4 w-4 hover:text-blue-700" />
  //       </Link>
  //     );
  //   },
  // },
];

export default function OrderPage() {
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleAction = (rowData: any) => {
    // Your action logic here, e.g.:
    navigate(`/remit/orderdetails/${(rowData.id as string)?.split("-")[1]}`);
  };

  const fetchResults = () // query: string,
  // page: string | number,
  // perPage: string | number,
  : void => {
    setIsLoading(true);
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetOrderRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      //   params: {
      //     page: page,
      //     per_page: perPage,
      //     service_partner_id: partner,
      //     start: startDate == null ? "" : startDate,
      //     end: endDate == null ? "" : endDate,
      //     payment_status: paymentstatus,
      //     status: orderstatus,
      //     q: query.length == 0 ? "" : `${query}:*`,
      //   },
    };

    getOrder(payload)
      .then((res) => {
        if (res?.data?.items && Array.isArray(res.data.items)) {
          const channel_id = localStorage.getItem("channel_id");
          console.log(res.data.items);
          setOrders(
            res?.data?.items?.map(
              (
                result: Partial<{
                  id: string | number;
                  _order_customers: {
                    full_name: string;
                    phone_number: string;
                  };
                  status: string;
                  amount_inr: number;
                  payable_amount: number;
                  _order_channels: {
                    id: string | number;
                    name: string;
                    status: string;
                  };
                  _order_service_partner: {
                    display_name: string;
                  };
                  payment_status: string;
                  partner_status: string;
                }>,
              ) => {
                return {
                  id: `ORD-${result.id}`,
                  customerName: result?._order_customers?.full_name,
                  phone_number: result?._order_customers?.phone_number,
                  orderStatus: result?.status,
                  amount: result?.payable_amount?.toFixed(2),
                  source:
                    result?._order_channels?.id == channel_id
                      ? "Walkin Order"
                      : result?._order_channels?.name &&
                        result?._order_channels?.name
                          ?.slice(0, 1)
                          ?.toUpperCase() +
                          result?._order_channels?.name?.slice(1).toLowerCase(),
                  serviceProvider: result?._order_service_partner?.display_name,
                  paymentStatus:
                    result?.payment_status === "recieved"
                      ? "received"
                      : result?.payment_status === "partially recieved"
                        ? "partially received"
                        : result?.payment_status,
                  partner_status: result?._order_channels?.status,
                };
              },
            ),
          );
        }
        // if (currentPage > res?.data?.pageTotal) {
        //   setCurrentPage(res?.data?.pageTotal);
        // } else if (currentPage === 0 && res?.data?.pageTotal > 0) {
        //   setCurrentPage(1);
        // }
        // setTotalPages(res?.data.pageTotal);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchResults();
  }, []);
  return (
    <div className="flex h-full gap-5 py-5 px-6 w-full flex-col overflow-hidden">
      <div className=" flex w-full justify-between items-center">
        <div className=" flex flex-col gap-2">
          <h3 className=" font-semibold leading-4 text-lg text-black-label">
            Remit Orders
          </h3>
          <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
            List of all remittance order
          </p>
        </div>
        <div className=" flex gap-3">
          <Link to={"/createRemit"}>
            <button className="rounded-lg py-[11px] px-4 border border-blue-700 text-blue-700 font-semibold text-sm">
              Create Gift & Maint. Order
            </button>
          </Link>
          <Link to={"/createorder"}>
            <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
              Create Edu. Related Order
            </button>
          </Link>
        </div>
      </div>
      <div className="flex-1 relative overflow-auto">
        {isLoading && <Spinner />}
        <DataTable 
          onRowClick={handleAction} 
          data={orders} 
          columns={columns}  
          searchData={{
            placeholder: "Search by ID, customer name",
            columns: ["id", "customerName"],
          }}
        />
      </div>
    </div>
  );
}
