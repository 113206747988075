import Stepper from "components/Stepper/newstepper";
import { useState } from "react";
import { createContactSteps } from "utils";
import ContactDetails from "./step1";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import OpportunityDetails from "./step2";
import InteractionDetails from "./step3";
import { step1Schema } from "./schema";
import {  z } from "zod";
import { AxiosRequestConfig } from "axios";
import { CreateContactReqType, postCreateContact } from "service/apis/Opportunities";
import { toast, Toaster } from "sonner";
import { Spinner } from "components";
import { resetState } from "redux/features/CreateOpportunity";
import { useNavigate } from "react-router-dom";

export default function CreateContact() {
    const [currentStep, setCurrentStep] = useState(1);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [loading, setLoading] = useState(false);
    const { product_list, medium_of_contact, pipelineType, refferal_campaign,refferal_source,partner_location, partner_id, partner_reffered,employee_reffered, entityEmail,entityName,entityNumber,entityType, customer_contact_address,customer_contact_email,customer_contact_name,customer_contact_phone, company_address, company_email, company_name, contact_person_name, contact_person_phone, contact_person_type } = useAppSelector((state) => state.CreateOpportunity);


    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handlePrevious = () => {
        if (currentStep === 1) return;
        setCurrentStep((prev) => prev - 1);
        setErrors({});
    }

    const validateStep = (step: number) => {
        let isValid = false;
        const  newErrors: Record<string, string> = {};

        switch (step) {
            case 1:
                try {
                    if(pipelineType === 'corporate'){
                        step1Schema.parse({
                            company_name,
                            company_email,
                            contact_person_name,
                            contact_person_phone,
                            contact_person_type,
                        });
                        isValid = true;
                    }
                    else if (pipelineType === 'retail'){
                        // step1Schema.pick({ company_email: true }).parse({
                        //     company_email: customer_contact_email,                            
                        // })
                        isValid = true
                    } 
                    else if (pipelineType === 'partner'){
                        step1Schema.pick({ company_email: true , contact_person_phone: true}).parse({
                            company_email: entityEmail,  
                            contact_person_phone: entityNumber,                          
                        })
                        isValid = true;
                    }                                           
                } catch (error) {
                    if (error instanceof z.ZodError) {
                        error.errors.forEach((err) => {
                            newErrors[err.path[0]] = err.message;
                        });
                    }
                }
                break;
            // Add cases for other steps if needed
            // case 2:
            //     // Validate step 2
            //     break;
            // case 3:
            //     // Validate step 3
            //     break;
            default:
                isValid = true; // Default to true for steps without validation
        }

        setErrors(newErrors);
        return isValid;
    }

    const buttonState = () => {
        switch (currentStep) {
            case 1:
                if ((pipelineType === 'corporate') && (!company_name || !company_email || !contact_person_name || !contact_person_phone || !contact_person_type)) {
                    return false;
                }
                if ((pipelineType === 'retail') && (!customer_contact_name || !customer_contact_phone)) {
                    return false;
                }
                if ((pipelineType === 'partner') && (!entityName || !entityEmail || !entityNumber || !entityType)) {
                    return false;
                }
                return true;
            case 2:
                if (!product_list.length)
                    return false;
                return true;
            case 3:
                if (!medium_of_contact)
                    return false;
                return true;
            default:
                return false;
        }
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <ContactDetails errors={errors} />;
            case 2:
                return <OpportunityDetails errors={errors} />;
            case 3:
                return <InteractionDetails errors={errors} />;
            default:
                return null
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        const auth = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);

        const formData = new FormData();

        switch (pipelineType) {
            case "retail":
                formData.append('contactEmail', customer_contact_email);
                formData.append("contactName", customer_contact_name);
                formData.append("contactNumber", customer_contact_phone);
                formData.append("contactAddress", customer_contact_address); 
                formData.append("typeOfRefferal", refferal_source);  
                formData.append("campaign", refferal_campaign);               
                formData.append("refferedBy_staff", employee_reffered);               
                if(refferal_source === 'partner'){
                    formData.append("partnerLocation", partner_location);
                    formData.append("refferedBy_partner", partner_reffered);               
                }
                formData.append('pipelineType', 'retail');
                break;
            case "corporate":
                formData.append("company_name", company_name);
                formData.append("company_email", company_email);
                formData.append("contactName", contact_person_name);
                formData.append("contactNumber", contact_person_phone);
                formData.append("typeOfContact", contact_person_type);
                formData.append("contactEmail", "bdbbh@gmail.com");
                formData.append("contactAddress", company_address);
                formData.append('pipelineType', 'coorporate');
                break;
            case "partner":
                formData.append("contactName", entityName );
                formData.append("contactEmail", entityEmail);
                formData.append("contactNumber", entityNumber);
                formData.append("typeOfPartner", entityType);
                formData.append("typeOfContact", "partner");
                formData.append('pipelineType', 'partner');
                break;
            default:
                break
        }
        formData.append("source", medium_of_contact);
        product_list.forEach((product, ) => {
            formData.append('items[]', JSON.stringify(product));
        });

        const payload: AxiosRequestConfig<CreateContactReqType> = {
            headers: { Authorization: `Bearer ${authToken}` },
            data: formData as any,
        };

        try {
            const res = await postCreateContact(payload);
            if(res.status === 200){
                toast.success('Opportunity Created Successfully');
                setLoading(false);
            }
            dispatch(resetState());
            setTimeout(() => {
                navigate('/opportunities');
            }
            , 1000);
        } catch (error) {
            console.log(error)
            setLoading(false);
            toast.error('Failed to create opportunity');
        }
    }

    const handleNext = () => {
        if (currentStep === createContactSteps.length) {
            handleSubmit();
            return;
        }
        // console.log(errors)
        if (validateStep(currentStep)) {
            setCurrentStep((prev) => prev + 1);
            setErrors({}); // Clear errors when moving to next step
        }
    }

    return (
        <>
            {loading && <Spinner />}
            <div className="p-4 flex flex-col font-inter items-start justify-start overflow-hidden mx-auto w-full">
                <div className="gap-8 w-full flex h-full flex-col">
                    <div className=" flex flex-col border-b gap-2 px-5 pb-2.5">
                        <h3 className=" font-semibold leading-4 text-lg text-black-label">
                            <span className="capitalize">{pipelineType}</span> opportunities
                        </h3>
                        <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
                            Create new {pipelineType} opportunities.
                        </p>
                    </div>
                    <div className="rounded-xl flex flex-col justify-between h-full gap-5 w-full px-5 py-2.5 overflow-auto">
                        <Stepper
                            steps={createContactSteps.map((val) => val.name)}
                            currentStep={currentStep - 1}
                        />
                        <div className="flex relative flex-col gap-5 w-full overflow-y-auto flex-grow">
                            {renderStep()}
                        </div>
                        <div className="flex gap-3">
                            {currentStep !== 1 && (
                                <button
                                    type="button"
                                    onClick={handlePrevious}
                                    className={`px-4 py-[11px] w-fit rounded-lg border border-blue-700 text-blue-700 ${true ? "" : "opacity-40"}`}
                                >
                                    Previous
                                </button>
                            )}
                            <button
                                type="button"
                                disabled={!buttonState()}
                                onClick={handleNext}
                                className="px-4 py-[11px] w-fit rounded-lg bg-blue-700 text-white-A700 disabled:opacity-40"
                            >
                                {currentStep === createContactSteps.length
                                    ? "Submit"
                                    : "Next"}
                            </button>
                        </div>
                    </div>
                </div>
                <Toaster theme="dark" position='top-right' />
            </div>
        </>
    )
}