import Navbar from "components/Navbar/Index";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Outlet, useNavigate } from "react-router-dom";
import { Img } from "components";
import { useCallback } from "react";
import { useAppDispatch } from "redux/hooks";
import { resetRedirectState } from "redux/features/Redirect Portal";

export default function RedirectPortal() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const handleCancel = useCallback(() => {
        dispatch(resetRedirectState())
        navigate("/dashboard")
    }, [])
    return (
        <div className="flex p-4 flex-col h-screen gap-2.5 font-inter">
            <Navbar className="flex justify-start sm:hidden">
                <div className=" w-full flex justify-between">
                    <div className=" flex gap-3 items-center">
                        <Img
                            src="images/sidebar_img/remit.svg"
                            className="h-5"
                            alt=""
                        />
                        <div className="gap-2 flex flex-col">
                            LOGO
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <div className="gap-2.5 flex items-center">
                            <Img src="images/overview/wrench.svg" alt="" />
                            <span className="text-sm font-semibold text-blue-700">
                                Help
                            </span>
                        </div>
                        <AlertDialog>
                            <AlertDialogTrigger>
                                <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                                    Cancel Order
                                </button>
                            </AlertDialogTrigger>
                            <AlertDialogPortal>
                                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                                    <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                                            Are you absolutely sure?
                                        </AlertDialogTitle>
                                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                                            This action cannot be undone. This will permanently
                                            delete your progress and remove the actions preformed.
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                                        <AlertDialogCancel
                                            className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                                            asChild
                                        >
                                            <button>Close</button>
                                        </AlertDialogCancel>
                                        <AlertDialogAction
                                            className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                                            asChild
                                        >
                                            <button className="" onClick={handleCancel}>
                                                Continue
                                            </button>
                                        </AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialogPortal>
                        </AlertDialog>
                    </div>
                </div>
            </Navbar>
            <div className="flex-1 h-full flex-grow  overflow-hidden">
                <Outlet />
            </div>
        </div>
    )
}
