import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CreateOrderStatetype = {
  purpose_id: string;
  showerror: boolean;
  customer_name: string;
  customer_email: string;
  customer_address: string;
  customer_phone: string;
  country: string;
  amount: string;
  currency: string;
  type: string;
  customers_id: string;
  Foreign_Bank_Charges: string;
  customer_rate: string;
  PAN: string;
  beneficiary_name: string;
  beneficiary_addr_line_1: string;
  beneficiary_addr_line_2: string;
  beneficiary_city: string;
  beneficiary_state: string;
  beneficiary_country: string;
  beneficiary_zip: string;
  beneficiary_account_number: string;
  beneficiary_bank_name: string;
  beneficiary_bank_addr: string;
  beneficiary_bank_country: string;
  beneficiary_swift_code: string;
  beneficiary_routing_number: string;
  beneficiary_transit_code: string;
  beneficiary_bsbcode: string;
  beneficiary_short_code: string;
  beneficiary_national_id: string;
  beneficiary_iban_no: string;
  beneficiary_correspondant_bank_name: string;
  beneficiary_correspondant_bank_no: string;
  created_by: string;
  discount: string;
  add_on_rates: number;
  docs: object;
  cal_total_charges: string | number;
  cal_gst: string | number;
  cal_tt_charges: string | number;
  cal_total_payable: string;
  cal_foreign_bank_charges: string | number;
  cal_amount_inr: string;
  cal_amount_currency: string;
  cal_your_rate: string | number;
  cal_customer_rate: string;
  cal_profit: string;
  discloser_amount: string | number;
  tcs: string;
  walkin_order: boolean;
  deal_id: string | number;
  foreign_bank_rates: string | number;
  tt_rates: string | number;
  service_partner_id: string | number;
  settlement_rate: string | number;
  required_docs: string | number;
  fx_charges: string;
  l1_commission: string | number;
  l2_commission: string | number;
  l3_commission: string | number;
};

export const initialState: CreateOrderStatetype = {
  purpose_id: "",
  country: "",
  showerror: false,
  customer_name: "",
  customer_email: "",
  customer_address: "",
  customer_phone: "",
  amount: "",
  customer_rate: "",
  type: "beneficiary_receives",
  currency: "",
  customers_id: "",
  Foreign_Bank_Charges: "BEN",
  PAN: "",
  beneficiary_name: "",
  beneficiary_addr_line_1: "",
  beneficiary_addr_line_2: "",
  beneficiary_city: "",
  beneficiary_state: "",
  beneficiary_country: "",
  beneficiary_zip: "",
  beneficiary_account_number: "",
  beneficiary_bank_name: "",
  beneficiary_bank_addr: "",
  beneficiary_bank_country: "",
  beneficiary_swift_code: "",
  beneficiary_routing_number: "",
  beneficiary_transit_code: "",
  beneficiary_bsbcode: "",
  beneficiary_short_code: "",
  beneficiary_national_id: "",
  beneficiary_iban_no: "",
  beneficiary_correspondant_bank_name: "",
  beneficiary_correspondant_bank_no: "",
  created_by: "",
  discount: "",
  add_on_rates: 0,
  docs: {},
  cal_total_charges: "",
  cal_gst: "",
  cal_tt_charges: "",
  cal_total_payable: "",
  cal_foreign_bank_charges: "",
  cal_amount_inr: "",
  cal_amount_currency: "",
  cal_your_rate: "",
  cal_profit: "",
  cal_customer_rate: "",
  discloser_amount: "",
  tcs: "",
  walkin_order: false,
  deal_id: "",
  foreign_bank_rates: "",
  tt_rates: "",
  service_partner_id: "",
  settlement_rate: "",
  required_docs: "",
  fx_charges: "",
  l1_commission: "",
  l2_commission: "",
  l3_commission: "",
};

export const CreateOrderSlice = createSlice({
  name: "CreateOrder",
  initialState,
  reducers: {
    setPurposeId: (state, action: PayloadAction<string>) => {
      state.purpose_id = action.payload;
    },
    setshowerror: (state, action: PayloadAction<boolean>) => {
      state.showerror = action.payload;
    },
    setCustomerPhone: (state, action: PayloadAction<string>) => {
      state.customer_phone = action.payload;
    },
    setCustomerAddress: (state, action: PayloadAction<string>) => {
      state.customer_address = action.payload;
    },
    setcustomername: (state, action: PayloadAction<string>) => {
      state.customer_name = action.payload;
    },
    setcustomeremail: (state, action: PayloadAction<string>) => {
      state.customer_email = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setCustomer_rate: (state, action: PayloadAction<string>) => {
      state.customer_rate = action.payload;
    },
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setCustomers_id: (state, action: PayloadAction<string>) => {
      state.customers_id = action.payload;
    },
    setForeign_Bank_Charges: (state, action: PayloadAction<string>) => {
      state.Foreign_Bank_Charges = action.payload;
    },
    setPAN: (state, action: PayloadAction<string>) => {
      state.PAN = action.payload;
    },
    setBeneficiaryName: (state, action: PayloadAction<string>) => {
      state.beneficiary_name = action.payload;
    },
    setBeneficiaryAddrLine1: (state, action: PayloadAction<string>) => {
      state.beneficiary_addr_line_1 = action.payload;
    },
    setBeneficiaryAddrLine2: (state, action: PayloadAction<string>) => {
      state.beneficiary_addr_line_2 = action.payload;
    },
    setBeneficiaryCity: (state, action: PayloadAction<string>) => {
      state.beneficiary_city = action.payload;
    },
    setBeneficiaryState: (state, action: PayloadAction<string>) => {
      state.beneficiary_state = action.payload;
    },
    setBeneficiaryCountry: (state, action: PayloadAction<string>) => {
      state.beneficiary_country = action.payload;
    },
    setBeneficiaryZip: (state, action: PayloadAction<string>) => {
      state.beneficiary_zip = action.payload;
    },
    setBeneficiaryAccountNumber: (state, action: PayloadAction<string>) => {
      state.beneficiary_account_number = action.payload;
    },
    setBeneficiaryBankName: (state, action: PayloadAction<string>) => {
      state.beneficiary_bank_name = action.payload;
    },
    setBeneficiaryBankAddr: (state, action: PayloadAction<string>) => {
      state.beneficiary_bank_addr = action.payload;
    },
    setBeneficiaryBankCountry: (state, action: PayloadAction<string>) => {
      state.beneficiary_bank_country = action.payload;
    },
    setBeneficiarySwiftCode: (state, action: PayloadAction<string>) => {
      state.beneficiary_swift_code = action.payload;
    },
    setBeneficiaryRoutingNumber: (state, action: PayloadAction<string>) => {
      state.beneficiary_routing_number = action.payload;
    },
    setBeneficiaryTransitCode: (state, action: PayloadAction<string>) => {
      state.beneficiary_transit_code = action.payload;
    },
    setBeneficiaryBsbcode: (state, action: PayloadAction<string>) => {
      state.beneficiary_bsbcode = action.payload;
    },
    setBeneficiaryShortCode: (state, action: PayloadAction<string>) => {
      state.beneficiary_short_code = action.payload;
    },
    setBeneficiaryNationalId: (state, action: PayloadAction<string>) => {
      state.beneficiary_national_id = action.payload;
    },
    setBeneficiaryIbanNo: (state, action: PayloadAction<string>) => {
      state.beneficiary_iban_no = action.payload;
    },
    setBeneficiaryCorrespondantBankName: (
      state,
      action: PayloadAction<string>
    ) => {
      state.beneficiary_correspondant_bank_name = action.payload;
    },
    setBeneficiaryCorrespondantBankNo: (
      state,
      action: PayloadAction<string>
    ) => {
      state.beneficiary_correspondant_bank_no = action.payload;
    },
    setCreatedBy: (state, action: PayloadAction<string>) => {
      state.created_by = action.payload;
    },
    setDiscount: (state, action: PayloadAction<string>) => {
      state.discount = action.payload;
    },
    setAddOnRates: (state, action: PayloadAction<number>) => {
      state.add_on_rates = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    setDocs: (state, action: PayloadAction<Object>) => {
      if (action.payload === null) {
        setDocs({});
      } else {
        const { filename, file } = action.payload;

        if (filename == null) {
          state.docs = {};
        } else {
          state.docs = { ...state.docs, [filename]: file };
        }
      }
    },
    setcal_total_charges: (state, action: PayloadAction<string | number>) => {
      state.cal_total_charges = action.payload;
    },
    setcal_gst: (state, action: PayloadAction<string | number>) => {
      state.cal_gst = action.payload;
    },
    setcal_tt_charges: (state, action: PayloadAction<string | number>) => {
      state.cal_tt_charges = action.payload;
    },
    setcal_total_payable: (state, action: PayloadAction<string>) => {
      state.cal_total_payable = action.payload;
    },
    setcal_foreign_bank_charges: (
      state,
      action: PayloadAction<string | number>
    ) => {
      state.cal_foreign_bank_charges = action.payload;
    },
    setcal_amount_inr: (state, action: PayloadAction<string>) => {
      state.cal_amount_inr = action.payload;
    },
    setcal_amount_currency: (state, action: PayloadAction<string>) => {
      state.cal_amount_currency = action.payload;
    },
    setcal_your_rate: (state, action: PayloadAction<string | number>) => {
      state.cal_your_rate = action.payload;
    },
    setcal_profit: (state, action: PayloadAction<string>) => {
      state.cal_profit = action.payload;
    },
    setcal_customer_rate: (state, action: PayloadAction<string>) => {
      state.cal_customer_rate = action.payload;
    },
    setDiscloserAmount: (state, action: PayloadAction<string>) => {
      state.discloser_amount = action.payload;
    },
    setTcs: (state, action: PayloadAction<string>) => {
      state.tcs = action.payload;
    },
    setWalkinOrder: (state, action: PayloadAction<boolean>) => {
      state.walkin_order = action.payload;
    },
    setDealID: (state, action: PayloadAction<string | number>) => {
      state.deal_id = action.payload;
    },
    setForeign_Bank_Rates: (state, action: PayloadAction<string | number>) => {
      state.foreign_bank_rates = action.payload;
    },
    setTTRates: (state, action: PayloadAction<string | number>) => {
      state.tt_rates = action.payload;
    },
    setServicePartnerID: (state, action: PayloadAction<string | number>) => {
      state.service_partner_id = action.payload;
    },
    setSettlementRate: (state, action: PayloadAction<string | number>) => {
      state.settlement_rate = action.payload;
    },
    setRequiredDocs: (state, action: PayloadAction<string | number>) => {
      state.required_docs = action.payload;
    },
    setFXCharges: (state, action: PayloadAction<string>) => {
      state.fx_charges = action.payload;
    },
    setL1CommissionRate: (state, action: PayloadAction<string | number>) => {
      state.l1_commission = action.payload;
    },
    setL2CommissionRate: (state, action: PayloadAction<string | number>) => {
      state.l2_commission = action.payload;
    },
    setL3CommissionRate: (state, action: PayloadAction<string | number>) => {
      state.l3_commission = action.payload;
    },
  },
});

export const {
  setPurposeId,
  setshowerror,
  setcustomername,
  setcustomeremail,
  setCustomerPhone,
  setCustomerAddress,
  setCountry,
  setAmount,
  setType,
  setCustomers_id,
  setForeign_Bank_Charges,
  setPAN,
  setBeneficiaryName,
  setBeneficiaryAddrLine1,
  setBeneficiaryAddrLine2,
  setBeneficiaryCity,
  setBeneficiaryState,
  setBeneficiaryCountry,
  setBeneficiaryZip,
  setBeneficiaryAccountNumber,
  setBeneficiaryBankName,
  setBeneficiaryBankAddr,
  setBeneficiaryBankCountry,
  setBeneficiarySwiftCode,
  setBeneficiaryRoutingNumber,
  setBeneficiaryTransitCode,
  setBeneficiaryBsbcode,
  setBeneficiaryShortCode,
  setBeneficiaryNationalId,
  setBeneficiaryIbanNo,
  setBeneficiaryCorrespondantBankName,
  setBeneficiaryCorrespondantBankNo,
  setCreatedBy,
  setDiscount,
  setAddOnRates,
  setCurrency,
  setDocs,
  setcal_total_charges,
  setcal_gst,
  setcal_tt_charges,
  setcal_total_payable,
  setcal_amount_currency,
  setcal_profit,
  setcal_foreign_bank_charges,
  setcal_amount_inr,
  setcal_your_rate,
  setcal_customer_rate,
  setDiscloserAmount,
  setTcs,
  setCustomer_rate,
  setWalkinOrder,
  setDealID,
  setForeign_Bank_Rates,
  setTTRates,
  setServicePartnerID,
  setSettlementRate,
  setRequiredDocs,
  setFXCharges,
  setL1CommissionRate,
  setL2CommissionRate,
  setL3CommissionRate
} = CreateOrderSlice.actions;

export default CreateOrderSlice.reducer;
