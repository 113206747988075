import ChannelPartnerRegFiveHeader from "components/ChannelPartnerRegFiveHeader";
import { Button, Img, Input, Spinner, Text } from "components";
import check from "assets/images/gic_tick.svg";
import ChannelPartnerRegFiveRowprimarytext from "components/ChannelPartnerRegFiveRowprimarytext";
import React from "react";
import { getCommisionRatesStep5 } from "service/apis/PartnerRegistrationapi";
import { activateProduct } from "service/apis/PartnerDetails";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

type Props = {
  defaultSubmit: boolean;
  partner_id: string;
  product_id: string;
  approvalNeeded: string;
  setApprovalNeeded: (value: string) => void;
  PartnerDetails: () => void | null;
};

const ProductActivationStep2: React.FC<Props> = ({
  defaultSubmit,
  partner_id,
  product_id,
  approvalNeeded,
  setApprovalNeeded,
  PartnerDetails,
}) => {
  const [commisionrate, setCommisionrate] = React.useState<any>([]);
  const [UpdateCommisionRate, setUpdateCommisionRate] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    fetchCommisionRatesStep5();
  }, []);

  React.useEffect(() => {
    if (defaultSubmit) {
      handleSubmit();
    }
  }, [defaultSubmit]);

  function fetchCommisionRatesStep5(): void | null {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        product_id: product_id,
      },
    };

    getCommisionRatesStep5(req)
      .then((res) => {
        setCommisionrate(res.data);
      })
      .catch(() => {});
  }

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleSubmit = () => {
    const updatedOldCommision = commisionrate.map((obj1: any) => {
      const correspondingObj2 = UpdateCommisionRate.find(
        (obj2: any) => obj2.purpose_id === obj1.purpose_id
      );

      // If commission exists and has a lower charge, update the charge in oldCommission
      if (correspondingObj2 && correspondingObj2.charges <= obj1.charges) {
        return { ...obj1, charges: correspondingObj2.charges };
      }

      return obj1; // No update needed
    });

    const updatedNewCommision = UpdateCommisionRate.filter(
      (obj1: any) =>
        !updatedOldCommision.some(
          (obj2: any) =>
            obj1.purpose_id === obj2.purpose_id && obj1.charges === obj2.charges
        )
    );

    // const approvalNeeded=updatedOldCommision.filter((obj1:any) => updatedNewCommision.some((obj2:any) => obj1.id === obj2.id));
    const finalApprovalNeeded = updatedOldCommision.map((obj1: any) => {
      const correspondingObj2 = updatedNewCommision.find(
        (obj2: any) => obj2.purpose_id === obj1.purpose_id
      );

      if (correspondingObj2) {
        return { ...obj1, charges: correspondingObj2.charges };
      }

      return obj1; // No update needed
    });

    const finalApprovalNeededObj = finalApprovalNeeded.map((obj1: any) => {
      return {
        id: obj1.id,
        purpose_id: obj1.purpose_id,
        charges: obj1.charges,
      };
    });

    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req = {
      data: {
        child_channel: partner_id,
        unapproved_commision_rates:
          updatedNewCommision.length > 0 ? finalApprovalNeededObj : [],
        approved_commision_rates:
          updatedNewCommision.length > 0 ? [] : finalApprovalNeededObj,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);

    activateProduct(req)
      .then((res) => {
        setLoading(false);
        setApprovalNeeded(res.data === "pending" ? "required" : "not_required");

        if (res.data === "pending") {
          toast.success("Commissions Rates sent for Approval");
        } else {
          toast.success("Commissions Rates Approved Successfully");
        }
        PartnerDetails();
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message || "Something went wrong");
      });
    PartnerDetails();
  };

  const [edit, setEdit] = React.useState<boolean>(false);

  return (
    <>
      {loading && <Spinner />}
      <div className="flex flex-col gap-[40px]">
        {approvalNeeded === "" && (
          <div className="flex w-full justify-end gap-[10px]">
            <Button
              className={`${
                edit ? "bg-[#E8E8E8]" : ""
              } cursor-pointer font-inter min-w-[100px] h-[40px] py-2 rounded-md border-[2px] text-[16px] border-[#EAEAEA] text-base text-center text-[#393A45] font-semibold inline`}
              onClick={() => {
                handleEdit();
              }}
            >
              <div className="flex flex-row w-full h-full justify-center items-center gap-[5px]">
                <Text>Edit</Text>

                <Img
                  className="w-[19px] h-[19px]"
                  src="images/img_edit1.svg"
                  alt="cell"
                />
              </div>
            </Button>
          </div>
        )}
        <div
          className={`flex flex-col gap-[20px] ${
            approvalNeeded === "" ? "py-[40px]" : "py-[10px]"
          } relative w-full pl-0 border border-indigo-50 border-solid`}
        >
          {approvalNeeded === "" && (
            <Text className="font-rubik text-md md:text-[22px] sm:text-xl w-full pl-[12px] pb-[10px] text-start font-semibold">
              Modify the Proposed Margin
            </Text>
          )}

          <ChannelPartnerRegFiveHeader
            className="relative bg-white-A700_99 pt-[5px] hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-5 h-[61px] md:h-auto items-center justify-start hover:my-0 my-0 hover:shadow-bs shadow-bs w-full"
            primarytexttwo="Purpose"
            isUpdate={edit}
            primarytexttwo1="Default Margin"
            primarytexttwo2="Proposed Margin"
          />
          {commisionrate.map((item: any) => {
            return (
              <ChannelPartnerRegFiveRowprimarytext
                key={item.id}
                id={product_id}
                updateCommisionRate={UpdateCommisionRate}
                setUpdateCommisionRate={setUpdateCommisionRate}
                purpose_id={item.purpose_id}
                isUpdate={edit}
                primarytexttwo={item._purpose_mapper.name}
                primarytexttwo1={item.charges}
                className="relative bg-white-A700_99 pl-[10px] hover:cursor-pointer flex flex-1 sm:flex-col flex-row font-inter sm:gap-5 h-16 md:h-auto items-start justify-start hover:my-0 my-0 hover:shadow-bs w-full"
              />
            );
          })}
          {approvalNeeded === "" && edit && (
            <Button
              className={`bg-blue-700 ml-[12px] cursor-pointer font-inter w-[100px] h-[40px] py-2 rounded-md border-[2px] border-[#EAEAEA] text-base text-center text-white-A700`}
              onClick={() => {
                handleSubmit();
              }}
            >
              Update
            </Button>
          )}
        </div>

        {approvalNeeded === "required" && (
          <div className="flex flex-col gap-[12px] py-[20px] relative w-full pl-[12px] border border-indigo-50 border-solid text-[#7E7E7E]">
            <Text className="font-rubik text-md md:text-[22px] sm:text-xl w-full text-start font-medium">
              Note{" "}
            </Text>

            <Text className="font-rubik text-md md:text-[22px] sm:text-xl w-full text-start text-[#7E7E7E] font-medium">
              <div className="flex flex-row gap-[5px] w-full items-center w-full justify-start">
                <Img
                  className="w[16px] h-[16px] w-[16px] text-[#7E7E7E] fill-current "
                  src="images/img_info.svg"
                  alt="cell"
                />

                <Text>
                  For Approval - Email will be sent to partner on completing the
                  product activation
                </Text>
              </div>
            </Text>
          </div>
        )}

        {approvalNeeded === "not_required" && (
          <Text className="font-rubik text-md md:text-[22px] sm:text-xl w-full pl-[12px] text-start text-[#3FB73D] font-medium">
            <div className="flex flex-row gap-[5px] w-full items-center w-full justify-start">
              <Img
                src={check}
                className="w[16px] h-[16px] w-[16px] text-[#7E7E7E] rounded-full object-cover"
                alt="check"
              />

              <Text>GIC Account is Approved Successfully</Text>
            </div>
          </Text>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default ProductActivationStep2;
