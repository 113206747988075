import { AxiosRequestConfig } from "axios";
import { Img } from "components";
import { useState, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  getDocumentMapping,
  GetDocumentMappingRequestType,
  GetDocumentMappingResponseType,
} from "service/apis/CreateOrderapi";

interface FileData {
  access: string;
  path: string;
  name: number;
  type: string;
  size: number;
  mime: string;
  meta: Record<string, unknown>;
}

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export default function Documentation() {
  const { register, getValues, setValue } = useFormContext();
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [docMap, setDocMap] = useState<GetDocumentMappingResponseType[]>([]);


  const handleFileChange = useCallback(
    (fieldName: string, id: number) =>
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
          setFiles((prev) => ({ ...prev, [fieldName]: file }));
          const base64String = await convertFileToBase64(file);

          // Create the file data object
          const fileData: FileData = {
            access: "public",
            path: base64String,
            name: id,
            type: file.type,
            size: file.size,
            mime: file.type,
            meta: {},
          };

          // Update the form value
          setValue(fieldName, JSON.stringify(fileData));

          // Update the array of file data
          const currentFiles = getValues("documents_SUPPORT") || [];
          setValue("docs",files);
          setValue("documents_SUPPORT", [...currentFiles, fileData]);
        }
      },
    [setValue, getValues],
  );

  const fetchDocMap = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetDocumentMappingRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: Number(getValues().purpose_id),
        service_provider: String(getValues().service_partner_id),
      },
    };
    try {
      const res = await getDocumentMapping(payload);
      setValue("requiredDocs", res.data.length);
      setDocMap(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDocMap();
  }, []);

  return (
    <div className="flex gap-8 flex-col pb-8">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          <div className="">
            <Img src="/images/remit/documentation.svg" alt="" />
          </div>
          <div className="text-blue-700">Upload Documents</div>
        </div>
        <small className="">{Object.keys(files).length}/{getValues('requiredDocs')} uploaded</small>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        {docMap.map(({ id, doc_name }: GetDocumentMappingResponseType) => (
          <div key={id} className="flex gap-2 flex-col w-full">
            <h3>{doc_name}</h3>
            <div
              className={`py-1.5 px-3.5 justify-between border rounded-md flex ${files[doc_name] && "bg-[#F2F7FF]"}`}
            >
              <div className="flex gap-3 items-center overflow-hidden">
                <Img src="/images/remit/file.svg" alt="" />
                <p className="truncate text-[#999999] text-sm font-normal">
                  {files[doc_name] ? files[doc_name].name : "No file choosen"}
                </p>
              </div>
              <label
                htmlFor={doc_name}
                className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
              >
                {files[doc_name] ? "Re-upload" : "Upload"}
              </label>
              <input
                {...register(doc_name)}
                type="file"
                id={doc_name}
                className="hidden"
                onChange={handleFileChange(doc_name, id)}
                accept="image/*"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
