import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_customer = `${BASE_URL}:NcXLAC78/customers/:id`;
const customer_status = `${BASE_URL}:NcXLAC78/customers/status/:id`;

// Get Staff Customer
export type GetStaffCustomerRequestType = Partial<{
  customers_id: string | number;
  headers: { Authorization: string };
}>;

export type GetStaffCustomerResponseType = {
  id: number;
  full_name: string;
  phone_number: string;
  email: string;
  last_kyc: string;
  status: string;
  pan_no: string;
  pan_soft_copy: {
    url: string;
  };
  aadhaar_soft_copy_front: {
    url: string;
  };
  aadhaar_no: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pincode: string;
  bank_account_number: string;
  bank_name: string;
  ifsc_code: string;
};

export const getStaffCustomer = (
  payload: AxiosRequestConfig<GetStaffCustomerRequestType>
): AxiosPromise<GetStaffCustomerResponseType> => {
  const customersId = payload.data?.customers_id;

  if (customersId === undefined) {
    throw new Error("customers_id is not provided in the payload.");
  }

  const url = staff_customer.replace(":id", customersId.toString());
  return apis.get(url, payload);
};

// put Customer Status
export type PutCustomerStatusRequestType = Partial<{
  customers_id: string | number;
  status: string;
  headers: { Authorization: string };
}>;

export type PutCustomerStatusResponseType = Partial<{}>;

export const putCustomerStatus = (
  payload: AxiosRequestConfig<PutCustomerStatusRequestType>
): AxiosPromise<PutCustomerStatusResponseType> => {
  const customersId = payload.data?.customers_id;

  if (customersId === undefined) {
    throw new Error("customers_id is not provided in the payload.");
  }

  const url = customer_status.replace(":id", customersId.toString());
  return apis.put(url, payload);
};