import React, { useRef } from "react";
import { Img, Spinner } from "../../components";
import { Button, Text } from "components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useAppSelector } from "redux/hooks";
import Cancel from "models/Cancel";

const Step4: React.FC<any> = ({ handleCancel,downloadPDFfromUrl }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [confirmCancel, setConfirmCancel] = React.useState<boolean>(false);

  const childRef = useRef(null);

  const { pdf_url } = useAppSelector((state) => state.CreateForexQuotation);

  React.useEffect(() => {
    const child = childRef.current;
    child.id = "print";
  }, []);

  const downloadPdf = async () => {
    if (childRef.current) {
      html2canvas(childRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true) as any;
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 5;
        pdf.addImage(
          imgData,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save("forex_quotation.pdf");
      });
    }
  };

  const downloadImage = () => {
    if (childRef.current) {
      html2canvas(childRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "forex_quotation.png";
        link.click();
      });
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title="Are You Sure You Want To Close This Quotation?"
            setConfirmCancel={setConfirmCancel}
            handleCancel={handleCancel}
          />{" "}
        </div>
      )}
      <div className=" relative flex flex-1 flex-col gap-5 md:h-auto items-start justify-start overflow-y-scroll md:px-5 md:w-full">
        <div className="sm:hidden border-b-2 border-indigo-50 border-solid flex flex-row w-full justify-between">
          <div className="flex flex-row sm:flex-col gap-5 items-center sm:items-end sm:p-2 justify-end">
            {/* <Button
              className="common-pointer bg-[#FFFFFF] cursor-pointer py-2.5 rounded-md shadow-bs2 text-sm text-center w-auto px-5"
              onClick={downloadPdf}
            >
              <div className="flex flex-row gap-[10px] items-center">
                <Img
                  src={pdfsvg}
                  width={24}
                  height={24}
                  alt="Preview"
                  className="h-full object-cover"
                />
                Download PDF
              </div>
            </Button>
            <Button
              className="common-pointer bg-[#FFFFFF] cursor-pointer py-2.5 rounded-md shadow-bs2 text-sm text-center w-auto px-5"
              onClick={downloadImage}
            >
              <div className="flex flex-row gap-[10px] items-center">
                <Img
                  src={imgsvg}
                  width={24}
                  height={24}
                  alt="Preview"
                  className="h-full object-cover"
                />
                Download Image
              </div>
            </Button>
            <Button className="common-pointer bg-[#FFFFFF] cursor-pointer py-2.5 rounded-md shadow-bs2 text-sm text-center w-auto px-5">
              <div className="flex flex-row gap-[10px] items-center">
                <Img
                  src={mailsvg}
                  width={24}
                  height={24}
                  alt="Preview"
                  className="h-full object-cover"
                />
                Send on Email
              </div>
            </Button> */}
            {/* <Text
              className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
              size="txtInterRegular16Black9007f"
              onClick={() => setConfirmCancel(true)}
            >
              Close
            </Text> */}
          </div>
        </div>
        <div className="flex w-full justify-between gap-x-10 md:flex-col md:gap-4">
          <div className="flex py-2 px-5 flex-1 items-center bg-black-label gap-3 rounded-lg">
            <Img
              className=" text-white-A700 "
              src="/images/img_info.svg"
              alt="cell"
            />
            <Text
              className="text-[12px] text-white-A700 tracking-[0.15px] w-[100%] sm:w-full"
            >
              Share and Download the Generated PDF
            </Text>
          </div>
          <div className=" flex gap-3">
            <Button
              className="common-pointer flex gap-3 items-center bg-blue-700 cursor-pointer py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-4"
            >
              Share
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1113_47838)">
                  <path d="M0.341719 8.40159C0.341344 9.7459 0.695344 11.0585 1.36847 12.2155L0.277344 16.1685L4.35434 15.1078C5.48199 15.7169 6.74543 16.036 8.02934 16.0361H8.03272C12.2712 16.0361 15.7213 12.6139 15.7232 8.40761C15.724 6.36934 14.9247 4.45269 13.4726 3.01071C12.0207 1.56885 10.0898 0.774368 8.03241 0.773438C3.79347 0.773438 0.343531 4.19545 0.341781 8.40159" fill="url(#paint0_linear_1113_47838)" />
                  <path d="M0.066875 8.40177C0.0664375 9.79445 0.433125 11.154 1.13025 12.3524L0 16.4471L4.22319 15.3484C5.38681 15.9779 6.69694 16.3098 8.03006 16.3103H8.0335C12.424 16.3103 15.9981 12.765 16 8.40809C16.0007 6.29659 15.1727 4.31104 13.6687 2.8174C12.1646 1.32394 10.1646 0.500868 8.0335 0.5C3.64225 0.5 0.068625 4.04481 0.066875 8.40177ZM2.58194 12.146L2.42425 11.8976C1.76137 10.8518 1.4115 9.64326 1.412 8.40226C1.41337 4.78087 4.38363 1.83457 8.036 1.83457C9.80475 1.83532 11.467 2.51947 12.7172 3.76078C13.9674 5.0022 14.6554 6.65243 14.6549 8.4076C14.6533 12.029 11.683 14.9757 8.0335 14.9757H8.03088C6.84256 14.975 5.67712 14.6584 4.66075 14.06L4.41888 13.9177L1.91275 14.5696L2.58194 12.146Z" fill="url(#paint1_linear_1113_47838)" />
                  <path d="M6.0432 5.10368C5.89408 4.77481 5.73714 4.76818 5.59533 4.76241C5.4792 4.75745 5.34645 4.75782 5.21383 4.75782C5.08108 4.75782 4.86539 4.80737 4.68308 5.00489C4.50058 5.20259 3.98633 5.68036 3.98633 6.65208C3.98633 7.6238 4.69964 8.56297 4.79908 8.69487C4.89864 8.82653 6.17614 10.8845 8.19939 11.6761C9.88089 12.3341 10.2231 12.2032 10.588 12.1702C10.953 12.1374 11.7658 11.6926 11.9316 11.2314C12.0975 10.7702 12.0975 10.3749 12.0478 10.2923C11.998 10.21 11.8653 10.1606 11.6662 10.0619C11.4671 9.96315 10.4885 9.48526 10.306 9.41934C10.1235 9.35348 9.99083 9.32061 9.85808 9.51838C9.72533 9.71583 9.34414 10.1606 9.22795 10.2923C9.11189 10.4244 8.9957 10.4408 8.7967 10.342C8.59751 10.2429 7.95645 10.0346 7.19589 9.36179C6.60414 8.83825 6.20464 8.19174 6.08852 7.99397C5.97239 7.79652 6.07608 7.68948 6.17589 7.59106C6.26533 7.50256 6.37502 7.36042 6.47464 7.24514C6.57389 7.12979 6.60702 7.04749 6.67339 6.91577C6.73983 6.78393 6.70658 6.66858 6.65689 6.56979C6.60702 6.471 6.2202 5.49419 6.0432 5.10368Z" fill="white" />
                </g>
                <defs>
                  <linearGradient id="paint0_linear_1113_47838" x1="772.568" y1="1540.28" x2="772.568" y2="0.773438" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1FAF38" />
                    <stop offset="1" stopColor="#60D669" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_1113_47838" x1="800" y1="1595.21" x2="800" y2="0.5" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                  </linearGradient>
                  <clipPath id="clip0_1113_47838">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

            </Button>
            <Button
              className="common-pointer flex gap-3 items-center bg-blue-700 cursor-pointer py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-4"
              onClick={downloadPDFfromUrl}
            >
              Download PDF
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.95898 6.1224C3.95898 4.28145 5.45137 2.78906 7.29232 2.78906H11.9212C12.1574 2.78906 12.3826 2.88933 12.5406 3.06493L15.8284 6.71804C15.9662 6.87106 16.0423 7.06964 16.0423 7.27551V14.8724C16.0423 16.7133 14.5499 18.2057 12.709 18.2057H7.29232C5.45137 18.2057 3.95898 16.7133 3.95898 14.8724V6.1224Z" fill="white" />
                <path d="M16.041 7.78906V7.27551C16.041 7.06964 15.9648 6.87106 15.8271 6.71804L12.5393 3.06493C12.3813 2.88933 12.1561 2.78906 11.9198 2.78906H11.041V4.45573C11.041 6.29668 12.5334 7.78906 14.3743 7.78906H16.041Z" fill="#98BDFF" fillOpacity="0.5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.924 10.7048C13.1776 10.939 13.1933 11.3344 12.9592 11.5881L9.728 15.0881C9.61342 15.2121 9.45342 15.2846 9.28458 15.2889C9.11567 15.2931 8.95225 15.2289 8.83158 15.1107L7.06282 13.3795C6.81614 13.138 6.8119 12.7423 7.05335 12.4956C7.2948 12.249 7.6905 12.2447 7.93718 12.4861L9.24608 13.7673L12.0407 10.7401C12.2749 10.4865 12.6703 10.4707 12.924 10.7048Z" fill="#165FE3" />
              </svg>
            </Button>
          </div>
        </div>

        <div className="flex flex-row w-[100%] h-auto p-[10px] md:p-0" ref={childRef}>
            {pdf_url !== "" && (
              <iframe
                className="w-full h-[580px] sm:h-[200px]"
                title="Embedded Content"
                src={pdf_url}
                frameBorder="0"
                allowFullScreen
              />
            )}
        </div>
        <Button
          className="common-pointer bg-blue-700 cursor-pointer py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-4"
          onClick={()=>setConfirmCancel(true)}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default Step4;
