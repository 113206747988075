import React from "react";

import { Button, Img, Input, Radio, SelectBox, Text } from "components";
import { AxiosRequestConfig } from "axios";
import { GetChannelRequestType, getChannel } from "service/apis/CreateOrderapi";

import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setCreatedBy,
  setWalkinOrder,
  setDealID,
} from "redux/features/CreateOrder";

import {
  GetAllDealRequestType,
  getAllDeals,
} from "service/apis/CreateOrderapi";

import { selectOptionType } from "components/SelectBox";
import ReactSelect from "react-select";

const Step1: React.FC = () => {
  const [deal, setDeal] = React.useState<selectOptionType[]>([]);
  const [partneroption, setPartneroption] = React.useState<
    { label: string; value: number }[]
  >([]);

  const created_by = useAppSelector((state) => state.CreateOrder.created_by);
  const showerror = useAppSelector((state) => state.CreateOrder.showerror);
  const deal_id = useAppSelector((state) => state.CreateOrder.deal_id);

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    fetchDeals();
  }, []);

  const channel_id = localStorage.getItem("channel_id");

  const fetchDeals = () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetAllDealRequestType> = {
      headers: {
        Authorization: "Bearer " + authToken,
      },
    };
    getAllDeals(payload)
      .then((res) => {
        let deals = res.data.filter(
          (item) =>
            item.status === "confirmed" &&
            item.utilization_status === "unutilized",
        );
        setDeal(
          deals.map((item) => {
            return {
              label: item.id,
              value: item.id,
            };
          }),
        );
      })
      .catch((err) => {});
  };

  return (
    <>
      {/* <div className="bg-gray-100 flex flex-col font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full"> */}

      <div className="bg-white-A700 rounded-lg flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start md:px-5 w-full md:w-full">
        <div className="flex flex-col gap-[15px] items-start justify-start w-[377px] sm:w-full">
          <div className="flex flex-col relative font-inter gap-2.5 items-start justify-center w-full">
            <SelectBox
              className="bg-white-A700 pl-10 border border-black-900_4c border-solid h-[52px] pr-[13px] py-4 text-[15px]  text-left tracking-[0.50px] max-w-md w-full rounded-[30px]"
              placeholderClassName="text-black-900_60"
              indicator={
                <Img
                  className="h-4 w-4"
                  src="images/img_arrowdown.svg"
                  alt="arrow_down"
                />
              }
              errors={deal_id == "" && showerror ? ["Deal is required"] : [""]}
              isMulti={false}
              name="inputbox"
              options={deal}
              value={deal_id}
              onChange={(e) => {
                dispatch(setDealID(e));
              }}
              isSearchable
              placeholder="Search and Select Deal"
            />
            <svg
              className="absolute left-5 top-[22px]"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6875 12.5689L9.38349 9.2649C10.1775 8.31172 10.5734 7.08914 10.4889 5.85148C10.4044 4.61382 9.84604 3.45638 8.92991 2.61993C8.01378 1.78349 6.81044 1.33244 5.57022 1.36062C4.33 1.3888 3.14839 1.89404 2.2712 2.77123C1.39401 3.64842 0.88877 4.83003 0.86059 6.07025C0.83241 7.31047 1.28346 8.51381 2.1199 9.42994C2.95635 10.3461 4.11379 10.9045 5.35145 10.9889C6.58911 11.0734 7.81169 10.6775 8.76486 9.88352L12.0689 13.1875L12.6875 12.5689ZM1.74999 6.18752C1.74999 5.40876 1.98092 4.64748 2.41358 3.99996C2.84624 3.35244 3.46119 2.84776 4.18067 2.54974C4.90016 2.25172 5.69186 2.17375 6.45566 2.32568C7.21946 2.47761 7.92105 2.85262 8.47172 3.40329C9.02239 3.95396 9.3974 4.65555 9.54933 5.41935C9.70126 6.18315 9.62329 6.97485 9.32527 7.69434C9.02725 8.41382 8.52257 9.02877 7.87505 9.46143C7.22753 9.89409 6.46625 10.125 5.68749 10.125C4.64355 10.1239 3.64271 9.70865 2.90454 8.97047C2.16636 8.2323 1.75115 7.23145 1.74999 6.18752Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* </div>
      </div> */}
    </>
  );
};

export default Step1;
