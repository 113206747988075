import { Form, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { AxiosRequestConfig } from "axios";
import { CURRENCY, NUMBER, RADIO, SELELCT, TEXT } from "common/constants";
import { Button, Img, Spinner, Text } from "components";
import { IFormInput, Option } from "components/Form/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  CurrencyInput,
  IField,
  RadioInput,
  SelectInput,
} from "components/Input/Inputs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import Navbar from "components/Navbar/Index";
import Stepper from "components/Stepper/newstepper";
import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAppSelector } from "redux/hooks";
import {
  getChannel,
  GetChannelRequestType,
  getCountry,
  GetCountryRequestType,
  getPurpose,
  GetPurposeRequestType,
  getServicePartners,
  GetServicePartnersRequestType,
  postCreateDeal,
  PostCreateDealRequestType,
  postDealCalc,
  PostDealCalcRequestType,
} from "service/apis/CreateDealapi";
import { useDispatch } from "react-redux";
import {
  setRemitterName,
  setRemitterPAN,
  setRemitterDOB,
  setPurposeId,
  setCountry,
  setAmount,
  setCreatedBy,
  setCurrency,
  setCustomer_rate,
  setcal_total_charges,
  setcal_gst,
  setcal_tt_charges,
  setcal_total_payable,
  setcal_foreign_bank_charges,
  setcal_amount_inr,
  setcal_your_rate,
  setcal_profit,
  setcal_customer_rate,
  setServicePartnerMode,
  setServicePartnerId,
  setSettlementRates,
  setForeignBankRates,
  setTTRates,
  setPartnerStatus,
  setPartnerMargin,
  setTcs,
  setFXCharges,
  setPartnerDeal,
  setStudentName,
  setAddOnRates,
  resetDealState,
} from "../../redux/features/CreateDeal";
import { formatIndianNumber } from "utils";
import { toast, Toaster } from "sonner";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SelectBox, selectOptionType } from "components/SelectBox";
import * as Switch from "@radix-ui/react-switch";

const countries = [
  "United States",
  "Canada",
  "Australia",
  "United Kingdom",
  "France",
  "New Zealand",
  "Singapore",
];

type countrydatatype = {
  country: string;
  currency: string;
};

export function useDebounce(
  callback: () => void,
  delay: number,
  dependencies: any[],
) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Clear the previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout
    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay);

    // Cleanup function to clear the timeout if the component unmounts or dependencies change
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, dependencies);
}

interface IFormInputMap {
  customSettlementRate: string;
  purpose: string;
  country: string;
  amount: number;
  charges: string;
  rate?: number;
  name?: string;
  servicePartner?: string;
  remitter_name?: string;
  pan?: string;
  dob?: string;
}

const CreateNewDeal = () => {
  const navigate = useNavigate();
  const form = useForm<IFormInputMap>();
  const [disableSteps, setDisableSteps] = useState<boolean>(false);
  const [activeField, setActiveField] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [purpose, setPurpose] = useState<Option[]>([]);
  const [countryoption, setCountryoption] = useState<Option[]>([]);
  const [currencydata, setCurrencydata] = useState<countrydatatype[]>([]);
  const [servicePartners, setServicePartners] = useState<selectOptionType[]>(
    [],
  );
  const [deal_type, setDealType] = useState<string>("Self");
  const [servicePartner, setServicePartner] = useState<string | number>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [rate, setRate] = useState<number>(0);
  const [showOptionalFields, setShowOptionalFields] = useState<boolean>(false);
  const [isCalcLoading, setIsCalcLoading] = useState(false);
  const [showSettlementRate, setShowSettlementRate] = useState(false);
  const [partneroption, setPartneroption] = useState<
    { label: string; value: number }[]
  >([]);

  const dispatch = useDispatch();

  const level = localStorage.getItem("level");
  const channel_id = localStorage.getItem("channel_id");

  const handleonWalkinOrder = (): void => {
    // get channel id from localstorage
    if (channel_id === null) return;
    dispatch(setPartnerDeal(false));
    dispatch(setCreatedBy(channel_id));
    dispatch(setPartnerStatus(""));
    dispatch(setPartnerMargin(""));
  };

  const {
    remitterPAN,
    remitterDOB,
    remitter_name,
    discount,
    customer_email,
    customer_phone,
    service_partner_mode,
    foreign_bank_rates,
    tt_rates,
    settlement_rates,
    amount,
    country,
    created_by,
    currency,
    customer_rate,
    add_on_rates,
    purpose_id,
    type,
    cal_customer_rate,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    service_partner_id,
    partner_status,
    partner_walkin_margin,
    partner_margin,
    fx_charges,
    partnerDeal,
  } = useAppSelector((state) => state.CreateDeal);

  const formConfig = [
    [
      {
        label: "What is your purpose for sending the money ?",
        name: "purpose",
        type: "select",
        placeholder: "Select the Gift",
        options: purpose,
      },
      {
        label: "Where do you want to send the money ?",
        name: "country",
        type: SELELCT,
        placeholder: "Specify the country",
        options: countryoption,
      },
      {
        label: "Enter the amount you want to send.",
        name: "amount",
        type: CURRENCY,
        currency,
      },
      {
        label: "Select the Foreign Charges",
        name: "charges",
        type: RADIO,
        optional: true,
        alwaysShow: true,
        options: [
          {
            value: "BEN",
            label: "BEN",
          },
          {
            value: "OUR",
            label: "OUR",
          },
        ],
      },
      {
        label: "Enter the customer rate (optional)",
        name: "rate",
        type: CURRENCY,
        currency: "USD",
        optional: true,
      },
    ],
    [
      {
        label: "Custom Settlement Rate",
        name: "customSettlementRate",
        type: "settlement-rate-input",
        currency: "INR",
        optional: true,
        alwaysShow: true,
      },
      {
        label: "What is the name of student? (optional)",
        name: "name",
        type: TEXT,
        optional: true,
        placeholder: "John Doe",
        alwaysShow: true,
      },
    ],
  ];

  const [formData, setFormData] = useState(formConfig[currentStep - 1]);

  const watchAllFields = form.watch();
  const steps = ["Basic Details", "Other details"];

  const fetchPurpose = () => {
    const payload: AxiosRequestConfig<GetPurposeRequestType> = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        product_id: 1,
      },
    };
    getPurpose(payload)
      .then((res) => {
        setPurpose(
          res.data.map((item) => {
            return {
              label: item.name as string,
              value: String(item.id),
            };
          }),
        );
      })
      .catch();
  };

  const fetchPartner = (): void | null => {
    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 1000,
      },
    };

    getChannel(payload)
      .then((res) => {
        if (res.data && res.data.items) {
          let partner = res.data.items.filter(
            (item) =>
              (item.status === "active" || item.status === "pending") &&
              item.approval_status === "approved",
          );

          setPartneroption(
            partner.map((item) => {
              return {
                label: item.name,
                value: item.id,
                status: item.status,
                style: {
                  color: "red",
                },
              };
            }),
          );
        }
      })
      .catch((err) => { });
  };

  const fetchCalc = (): void => {
    if (
      fx_charges === "" ||
      amount === "" ||
      currency === "" ||
      purpose_id === ""
    ) {
      return;
    }

    setIsCalcLoading(true); // Show loading spinner

    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostDealCalcRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        purpose_id: purpose_id === "" ? 1 : parseInt(purpose_id),
        currency: currency === "" ? "USD" : currency,
        amount: parseInt(amount) ? parseInt(amount) : 0,
        type,
        created_by,
        tcs_type: 1,
        discount: 0,
        addon_rates: add_on_rates,
        country,
        service_partner_id: servicePartner,
        partner_margin:
          partner_status === "pending"
            ? partner_margin === ""
              ? "0"
              : String(partner_margin)
            : "",
        partner_walkin_margin:
          partner_status === "pending"
            ? partner_walkin_margin === ""
              ? "0"
              : String(partner_walkin_margin)
            : "",
        fx_charges,
      },
    };

    if (
      currency &&
      purpose_id &&
      created_by !== "-1" &&
      (partner_status !== "pending" ||
        (partner_status === "pending" &&
          partner_margin &&
          partner_walkin_margin))
    ) {
      postDealCalc(payload)
        .then((res) => {
          dispatch(setTcs(res?.data.tcs));
          dispatch(setServicePartnerId(res.data.service_partner));
          dispatch(setForeignBankRates(res.data.foreign_bank_charges));
          dispatch(setTTRates(res.data.tt_charges));
          dispatch(setServicePartnerMode(res.data.service_partner_mode));
          if (res.data.service_partner_mode === "online") {
            dispatch(setSettlementRates(res.data.settlement_rates));
          } else {
            dispatch(setSettlementRates(""));
          }
          dispatch(
            setcal_total_charges(
              parseFloat(res.data.payable_amount) -
              parseFloat(res.data.base_amount),
            ),
          );
          if (level === "1") {
            dispatch(setcal_profit(res.data.profit.l1));
          }
          if (level === "2") {
            dispatch(setcal_profit(res.data.profit.l2));
          }
          if (level === "3") {
            dispatch(setcal_profit(res.data.profit.l3));
          }
          dispatch(setcal_amount_inr(res.data.base_amount));
          dispatch(setcal_gst(res.data.gst));
          dispatch(setcal_tt_charges(res.data.tt_charges_with_gst));
          dispatch(setcal_total_payable(res.data.payable_amount));
          dispatch(
            setcal_foreign_bank_charges(res.data.foreign_bank_charges_with_gst),
          );
          dispatch(
            setcal_your_rate(
              (
                parseFloat(res?.data?.settlement_rates) +
                (level === "2" ? res?.data?.cumulative_commission.l1 : 0) +
                (level === "3" ? res?.data?.cumulative_commission.l2 : 0)
              )?.toFixed(4),
            ),
          );

          setRate(
            parseFloat(res?.data?.settlement_rates) +
            (res?.data?.cumulative_commission_without_addon.l3 > 0
              ? res?.data?.cumulative_commission_without_addon.l3
              : res?.data?.cumulative_commission_without_addon.l2 > 0
                ? res?.data?.cumulative_commission_without_addon.l2
                : res?.data?.cumulative_commission_without_addon.l1),
          );

          localStorage.setItem(
            "service_partner_id",
            JSON.stringify(res.data.service_partner),
          );

          dispatch(
            setCustomer_rate(Number(res.data.customer_rate)?.toFixed(4)),
          );
          dispatch(
            setcal_customer_rate(
              parseFloat(res.data.customer_rate)?.toFixed(4)?.toString(),
            ),
          );
        })
        .catch((err) => {
          toast.warning(err.response.data.message);
        })
        .finally(() => {
          setIsCalcLoading(false); // Hide loading spinner
        });
    } else {
      setIsCalcLoading(false); // Hide loading spinner
    }
  };

  const fetchCountry = () => {
    const payload: AxiosRequestConfig<GetCountryRequestType> = {};
    getCountry(payload)
      .then((res) => {
        setCurrencydata(res.data);
        const countryOrder = countries.reduce(
          (acc: any, country: string, index: number) => {
            acc[country] = index + 1; // Rank based on index in countries array
            return acc;
          },
          {},
        );

        console.log("countryOrder", countryOrder);

        const sortedData = res.data.sort((a, b) => {
          const aOrder = countryOrder[a.country] || Infinity; // Use Infinity for items not in countries array
          const bOrder = countryOrder[b.country] || Infinity;
          return aOrder - bOrder;
        });

        console.log("sortedData", sortedData);

        setCountryoption(
          sortedData.map((item) => {
            return {
              label: item.country,
              value: item.country,
            };
          }),
        );
      })
      .catch();
  };

  useEffect(() => {
    fetchPartner()
    fetchPurpose();
    fetchCountry();
    if (created_by === "") {
      handleonWalkinOrder();
    }
  }, []);

  const updateCurrencyForCountry = (selectedCountry: string) => {
    const countryData = currencydata.find(
      (item) => item.country === selectedCountry,
    );
    if (countryData) {
      dispatch(setCurrency(countryData.currency));
      // Update the currency for the amount field in formData
      setFormData((prevFormData: any) =>
        prevFormData.map((field: any) =>
          field.name === "amount"
            ? { ...field, currency: countryData.currency }
            : field,
        ),
      );
    }
  };

  useDebounce(
    () => {
      if (!isCalcLoading) {
        fetchCalc();
        if (isNaN(add_on_rates)) {
          dispatch(setAddOnRates(0));
        }
      }
    },
    1000, // 1 second delay
    [amount, fx_charges, customer_rate, currency, purpose_id, servicePartner],
  );

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name !== undefined && value[name] !== undefined) {
        // Update Redux store based on the changed field
        switch (name) {
          case "purpose":
            dispatch(setPurposeId(value[name]));
            break;
          case "country":
            dispatch(setCountry(value[name]));
            updateCurrencyForCountry(value[name]);
            fetchCalc();
            break;
          case "amount":
            dispatch(setAmount(String(value[name])));
            break;
          case "charges":
            dispatch(setFXCharges(value[name]));
            break;
          case "name":
            dispatch(setStudentName(value[name]));
            break;
          case "rate":
            dispatch(setCustomer_rate(String(value[name])));
            dispatch(
              setAddOnRates(
                parseFloat(String(value[name])) - parseFloat(rate.toFixed(4)),
              ),
            );
            break;
          case "remitter_name":
            dispatch(setRemitterName(value[name]));
            break;
          case "pan":
            dispatch(setRemitterPAN(value[name]));
            break;
          case "dob":
            dispatch(setRemitterDOB(value[name]));
            break;
          default:
          // Add more cases as needed for other fields
        }
      }
      if (name !== undefined) {
        // Update the form data for the changed field
        const filterFieldNames = ["dob", "pan", "remitter_name"];
        if (service_partner_id !== 17) {
          console.log("this is getting triggered inside the 17");
          setFormData((prevFormData: any) =>
            prevFormData.filter(
              (field: any) => !filterFieldNames.includes(field.name),
            ),
          );
        } else
          setFormData((prevFormData: any) =>
            prevFormData.map((field: any) =>
              field.name === name ? { ...field, value: value[name] } : field,
            ),
          );
        // Update activeField if necessary
        const currentFieldIndex = formData.findIndex(
          (field) => field.name === name,
        );
        if (currentFieldIndex === activeField && value[name]) {
          setActiveField((prev) => Math.min(prev + 1, formData.length - 1));
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch, activeField, formData]);

  const isFieldDisabled = (index: number) =>
    disableSteps ? index > 1 : index > activeField;

  const isFormComplete = formData.every(
    (field) =>
      field.optional ||
      Boolean(watchAllFields[field.name as keyof IFormInputMap]),
  );

  useEffect(() => {
    setFormData(formConfig[currentStep - 1]);
  }, [currentStep, form]);

  const fetchServicePartners = (): void => {
    if (purpose_id === "" || currency === "") {
      dispatch(setServicePartnerId(""));
      return;
    }

    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetServicePartnersRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: parseInt(purpose_id),
        currency,
      },
    };

    getServicePartners(payload)
      .then((res) => {
        if (res.data.length > 0) {
          dispatch(setServicePartnerId(res.data[0].id));
          setServicePartner(res.data[0].id);
          fetchCalc();
        } else {
          dispatch(setServicePartnerId(""));
          setServicePartner("");
        }
        setServicePartners(
          res.data.map((item) => {
            return {
              label: item.display_name,
              value: item.id,
            };
          }),
        );
        if (res.data.length < 1) {
          toast.warning("Service Partners not available");
          setActiveField(1); // Set active field to country step
          setDisableSteps(true);
        } else {
          setDisableSteps(false); // Enable steps if service partners are available
        }
      })
      .catch((err) => {
        dispatch(setServicePartnerId(""));
        setServicePartner("");
        toast.warning(err.response.data.message);
        setActiveField(1); // Set active field to country step
        setDisableSteps(true);
      });
  };

  useEffect(() => {
    fetchServicePartners();
  }, [purpose_id, currency]);

  useEffect(() => {
    setFormData(formConfig[currentStep - 1]);
  }, [currentStep, purpose, currency]);

  const handleonSubmitCreateDeal = (): void => {
    setLoading(true);

    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const formData = form.getValues();
    const data = new FormData();
    data.append("purpose_id", purpose_id);
    data.append("currency", currency);
    data.append("amount", amount);
    data.append("type", type);
    data.append("country", country);
    data.append("created_by", created_by);
    data.append("tcs_type", "1");
    data.append("discount", discount);
    data.append("addon_rates", add_on_rates?.toLocaleString());
    data.append("country", country);

    data.append("remitter_name", remitter_name);
    data.append("customer_email", customer_email);
    data.append("customer_phone", customer_phone);
    data.append("remitter_PAN", remitterPAN);
    data.append("remitter_DOB", remitterDOB);

    if (service_partner_mode === "offline" && settlement_rates !== "") {
      data.append("service_partner_id", service_partner_id?.toLocaleString());
      data.append("settlement_rates", settlement_rates?.toLocaleString());
      data.append("foreign_bank_charges", foreign_bank_rates.toString());
      data.append("tt_charges", tt_rates?.toLocaleString());
    } else if (service_partner_mode === "online" && settlement_rates !== "") {
      data.append("service_partner_id", service_partner_id?.toLocaleString());
      data.append("settlement_rates", settlement_rates?.toLocaleString());
      data.append("foreign_bank_charges", foreign_bank_rates.toString());
      data.append("tt_charges", tt_rates?.toLocaleString());
    } else if (service_partner_mode === "online" && settlement_rates === "") {
      data.append("service_partner_id", service_partner_id?.toLocaleString());
    } else if (service_partner_mode === "offline" && settlement_rates === "") {
      data.append("service_partner_id", service_partner_id?.toLocaleString());
      data.append("foreign_bank_charges", foreign_bank_rates.toString());
      data.append("tt_charges", tt_rates?.toLocaleString());
    }

    data.append("partner_margin", partner_margin?.toLocaleString());
    data.append(
      "partner_walkin_margin",
      partner_walkin_margin?.toLocaleString(),
    );
    data.append("fx_charges", fx_charges);

    if (level === "1" && formData.customSettlementRate) {
      data.append("settlement_rates", formData.customSettlementRate);
    } else if (service_partner_mode === "offline" && settlement_rates !== "") {
      data.append("settlement_rates", settlement_rates?.toLocaleString());
    }

    const payload: AxiosRequestConfig<PostCreateDealRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: data as any,
    };

    postCreateDeal(payload)
      .then((res) => {
        localStorage.setItem("deal_id", JSON.stringify(res.data.deals.id));
        dispatch(setcal_total_payable(res.data.payment));
        setLoading(false);
        navigate("/remit/dealsuccess", {
          state: { deal_id: res.data.deals.id, payment: res.data.payment },
          replace: true,
        });
        dispatch(resetDealState());
      })
      .catch((err) => {
        setLoading(false);
        toast.warning(err.response.data.message);
      });
  };

  const handleCancel = () => {
    localStorage.removeItem("deal_id");
    dispatch(resetDealState());
    navigate("/remit");
  };

  const ToggleOptionalFields = () => (
    <button
      type="button"
      onClick={() => setShowOptionalFields(!showOptionalFields)}
      className="text-blue-700 relative -top-10 underline text-left text-sm mt-4"
    >
      {showOptionalFields ? "Cancel" : "Set Customer Rate"}
    </button>
  );

  const renderFormField = (
    data: IFormInput,
    field: IField<IFormInputMap>["field"],
    isDisabled: boolean,
  ) => {
    if (isDisabled) return null;
    if (data.optional && !data.alwaysShow && !showOptionalFields) return null;

    if (
      ["dob", "pan", "remitter_name"].includes(data.name) &&
      service_partner_id !== "17"
    ) {
      return null;
    }

    switch (data.type) {
      case SELELCT:
        return (
          <SelectInput<IFormInputMap>
            name={data.name}
            isDisabled={isDisabled}
            field={field}
            options={data?.options ?? []}
            placeholder={data.placeholder}
          />
        );

      case RADIO:
        return (
          <RadioInput
            defaultValue="BEN"
            options={data.options ?? []}
            field={field}
          />
        );

      case CURRENCY:
        if (data.name === "settlementRate" && !showSettlementRate) return null;
        return (
          <CurrencyInput
            currencyType={data.currency as string}
            currencySymbol={data.currencySymbol ?? ""}
            field={field}
            isLoading={isCalcLoading}
          />
        );

      case NUMBER:
        return (
          <Input
            type="number"
            {...field}
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          />
        );
      case "settlement-rate-input":
        return level === "1" ? <SettlementRateInput field={field} /> : null;
      case "date":
        return (
          <Input
            type="date"
            {...field}
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          />
        );
      default:
        return (
          <Input
            type="text"
            {...field}
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          />
        );
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    form.handleSubmit(() => {
      if (currentStep < formConfig.length) {
        setCurrentStep((prev) => prev + 1);
      } else {
        // Handle final submission
        handleonSubmitCreateDeal();
      }
    })();
  };

  const remitterFields = ()=>{
    if(servicePartner === 17 && (!remitterDOB || !remitterPAN || !remitter_name))
      return true
    return false
  }

  useEffect(() => { }, [service_partner_id, currentStep]);

  return (
    // skipcq: JS-0415
    <>
      {loading && <Spinner />}
      {
        <div className="p-4 sm:p-2 h-screen font-inter flex gap-2.5 flex-col">
          <Navbar className="lg:flex-shrink-0">
            <div className=" w-full flex justify-between">
              <div className=" flex gap-3 items-center">
                <Img
                  src="images/sidebar_img/remit.svg"
                  className="h-5"
                  alt=""
                />
                <div className="gap-2 flex flex-col">
                  <span className="text-sm font-semibold">Create Deals</span>
                  <span className="text-xs font-normal text-[#999999]">
                    Comprehensive details of all deals
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="gap-2.5 py-2 px-4 flex items-center">
                  <Img src="images/overview/wrench.svg" alt="" />
                  <span className="text-sm font-semibold text-blue-700">
                    Help
                  </span>
                </div>
                <AlertDialog>
                  <AlertDialogTrigger>
                    <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                      Cancel
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                      <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                          This action cannot be undone. This will permanently
                          delete your progress and remove the actions preformed.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                          className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                          asChild
                        >
                          <button>Cancel</button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                          className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                          asChild
                        >
                          <button onClick={handleCancel} className="">
                            Continue
                          </button>
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogPortal>
                </AlertDialog>
              </div>
            </div>
          </Navbar>
          <div className="w-full h-full gap-2.5 flex sm:flex-col lg:flex-grow lg:overflow-hidden">
            {/* Details */}
            <section className="w-[72%] sm:w-full bg-white-A700 p-5 flex gap-8 flex-col rounded-xl">
              <div className=" relative flex">
                <div className="w-full">
                  <Stepper steps={steps} currentStep={currentStep - 1} />
                </div>
                {level === "1" && (
                  <div className="flex absolute -top-4 right-0 flex-row items-center justify-center h-full">
                    <Controller
                      name="servicePartner"
                      control={form.control}
                      defaultValue=""
                      // skipcq: JS-0417
                      render={({ field }) => (
                        // skipcq: JS-0417
                        <Select
                          // skipcq: JS-0417
                          onValueChange={(value) => {
                            field.onChange(value);
                            setServicePartner(value);
                            dispatch(setServicePartnerId(value));
                          }}
                          value={servicePartner as string}
                        >
                          <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Service Partner" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>Select Partner</SelectLabel>
                              {servicePartners.map((item) => (
                                <SelectItem
                                  className="hover:bg-blue_gray-lite-bg cursor-pointer"
                                  key={item.value}
                                  value={item.value as string}
                                >
                                  {item.label}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                )}
              </div>
              <Form {...form}>
                <form
                  // skipcq: JS-0417
                  onSubmit={handleSubmit}
                  className="pb-8 relative overflow-auto flex flex-col gap-10 pl-10 pt-1"
                >
                  {
                    currentStep === 1 && level === "1" &&
                    <>
                      <div className="flex flex-col gap-3">
                        <p>Do you want to create deal for</p>
                        <RadioGroup
                          className="flex"
                          defaultValue="Self"
                          onValueChange={(e) => {
                            if (e === "Self") {
                              setDealType("Self");
                              handleonWalkinOrder();
                              dispatch(setPartnerDeal(false));
                            }
                            if (e === "Partner") {

                              setDealType("Partner");

                              if (!partnerDeal) {
                                dispatch(setCreatedBy("-1"));
                                dispatch(setPartnerStatus(""));
                                dispatch(setPartnerMargin(""));
                              }
                              dispatch(setPartnerDeal(true));
                            }

                          }
                          }
                        >
                          <div
                            className={`${deal_type === "Self" ? " border-blue-700" : ""} flex items-center rounded-lg border p-3 space-x-2`}
                          >
                            <RadioGroupItem
                              value="Self"
                              id="Self"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                            />
                            <label htmlFor="Self">Self</label>
                          </div>
                          <div
                            className={`${deal_type === "Partner" ? "border-blue-700" : ""} flex items-center rounded-lg border p-3 space-x-2`}
                          >
                            <RadioGroupItem value="Partner" id="Partner" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2" />
                            <label htmlFor="Partner">Partner</label>
                          </div>
                        </RadioGroup>
                      </div>
                      {partnerDeal && (
                        <div className="flex flex-col gap-2.5 mb-[5px] h-auto items-start justify-start w-[377px]">
                          <SelectBox
                            className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                            placeholderClassName="text-black-900_60"
                            indicator={
                              <Img
                                className="h-4 w-4"
                                src="images/img_arrowdown.svg"
                                alt="arrow_down"
                              />
                            }
                            value={created_by}
                            onChange={(e) => {
                              dispatch(setCreatedBy(e));
                              dispatch(
                                setPartnerStatus(
                                  partneroption?.find((item) => item.value === e)?.status,
                                ),
                              );
                              dispatch(setPartnerMargin(""));
                            }}
                            isMulti={false}
                            name="inputbox"
                            options={partneroption}
                            isSearchable={true}
                            placeholder="Search and Select a partner"
                          />
                        </div>
                      )}
                    </>
                    }
                  {formData.map((data, index) => {

                    return (
                      <div
                        key={data.name}
                        className="flex justify-between max-w-[340px]"
                      >
                        <div
                          className={`flex items-center flex-col after:right-10 ${index === formData.length - 1 && "after:border-none"}  ${isFieldDisabled(index) ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                          <img
                            className="relative -left-10"
                            src={`${isFieldDisabled(index) ? "images/overview/ellipse-gray.svg" : "images/overview/ellipse.svg"}`}
                            alt=""
                          />
                        </div>
                        <Controller
                          name={data.name as keyof IFormInputMap}
                          control={form.control}
                          defaultValue=""
                          // skipcq: JS-0417
                          render={({ field }) => (
                            <FormItem
                              className={`space-y-0 w-full flex ${false ? "flex-row items-start" : "flex-col"} gap-3`}
                            >
                              <FormLabel
                                className={`${isFieldDisabled(index) ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
                              >
                                {data.label}
                              </FormLabel>
                              {renderFormField(
                                data,
                                field,
                                isFieldDisabled(index),
                              )}
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    );
                  })}
                  {
                    currentStep === 2 && servicePartner === 17 &&
                    <Accordion type="single" collapsible className="w-full relative right-10">
                      <AccordionItem className="border-none " value="item-1">
                        <AccordionTrigger className=" bg-[#F9F9F9] rounded-xl border-none px-5">
                          <div className="flex justify-between">
                            <div className="flex gap-2 items-center">
                              <div className="">
                                <Img src="/images/remit/beni.svg" alt="" />
                              </div>
                              <div className="">Remitter Details</div>
                            </div>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent className="flex flex-col mt-5 pl-10 gap-10 w-1/3">
                          <div className="flex flex-col gap-5">
                            {service_partner_id == 17 && (
                              <FormItem
                                className={`space-y-0 relative w-full flex ${false ? "flex-row items-start" : "flex-col"} gap-3`}
                              >
                                <div
                                  className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                                >
                                  <img
                                    className="relative -left-10 "
                                    src={`images/overview/ellipse.svg`}
                                    alt=""
                                  />
                                </div>
                                <FormLabel
                                  className={`${false ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
                                >
                                  What is the name of remitter
                                </FormLabel>
                                <Input
                                  value={remitter_name}
                                  onChange={(e) => dispatch(setRemitterName(e.target.value))}
                                  name="price"
                                  placeholder="Enter Remitter Name"
                                  className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
                                ></Input>
                              </FormItem>)}
                            {service_partner_id == 17 && (
                              <FormItem
                                className={`space-y-0 relative w-full flex ${false ? "flex-row items-start" : "flex-col"} gap-3`}
                              >
                                <div
                                  className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                                >
                                  <img
                                    className="relative -left-10 "
                                    src={`images/overview/ellipse.svg`}
                                    alt=""
                                  />
                                </div>
                                <FormLabel
                                  className={`${false ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
                                >
                                  What is the PAN of remitter
                                </FormLabel>
                                <Input
                                  value={remitterPAN}
                                  onChange={(e) => dispatch(setRemitterPAN(e.target.value))}
                                  name="price"
                                  placeholder="Enter Remitter Name"
                                  className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
                                ></Input>
                              </FormItem>)}

                            {service_partner_id == 17 && (
                              <FormItem
                                className={`space-y-0 relative w-full flex ${false ? "flex-row items-start" : "flex-col"} gap-3`}
                              >
                                <div
                                  className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                                >
                                  <img
                                    className="relative -left-10 "
                                    src={`images/overview/ellipse.svg`}
                                    alt=""
                                  />
                                </div>
                                <FormLabel

                                  className={`${false ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
                                >
                                  What is the DOB of remitter
                                </FormLabel>
                                <Input
                                  value={remitterDOB}
                                  onChange={(e) => dispatch(setRemitterDOB(e.target.value))}
                                  onFocus={(e) => (e.target.type = "date")}
                                  onBlur={(e) => (e.target.type = "text")}
                                  name="price"
                                  placeholder="Enter Remitter Name"
                                  className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
                                ></Input>
                              </FormItem>)}
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  }
                  {formData.some(
                    (data: IFormInput) => data.optional && !data.alwaysShow,
                  ) && <ToggleOptionalFields />}
                  <button
                    type="submit"
                    disabled={!isFormComplete || disableSteps || remitterFields()}
                    className={`px-4 py-[11px] w-fit rounded-lg bg-blue-700 text-white-A700 disabled:opacity-40 ${isFormComplete && !disableSteps ? "" : "opacity-40"}`}
                  >
                    {currentStep === formConfig.length ? "Submit" : "Next"}
                  </button>
                </form>
              </Form>
            </section>

            {/* calculations */}
            <section className="flex-1 bg-white-A700 px-3 py-5 rounded-xl gap-4 flex flex-col">
              <p className="text-lg leading-4 font-semibold">Calculations</p>
              <div className="pt-5 pb-10 px-5 border-b-8 rounded-xl bg-custom-calc-gradient border-blue-700 gap-10">
                <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex justify-between w-full">
                      <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                        Customer Rate
                      </span>
                      {isCalcLoading ? (
                        <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                      ) : (
                        <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                          {cal_customer_rate === ""
                            ? "0.00"
                            : `₹ ${cal_customer_rate}`}
                        </span>
                      )}
                    </div>
                    <div className="flex justify-between w-full">
                      <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                        Recipient gets
                      </span>
                      {isCalcLoading ? (
                        <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                      ) : (
                        <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                          {cal_amount_inr == ""
                            ? "0.00"
                            : (currency === "" || currency === undefined
                              ? "USD "
                              : `${currency} `) +
                            formatIndianNumber(
                              parseFloat(amount)?.toFixed(4),
                            ).toString()}
                        </span>
                      )}
                    </div>
                    <div className="w-full border-[.3px]" />
                    <div className="flex flex-col w-full gap-4">
                      <div className="flex justify-between w-full">
                        <span className="text-sm leading-[17px] indent-3.5 font-normal">
                          Total Charges
                        </span>
                        {isCalcLoading ? (
                          <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                        ) : (
                          <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                            {cal_total_charges === "" || amount === ""
                              ? "0.00"
                              : `INR ${parseFloat(Number(cal_total_charges)?.toFixed(2))}`}
                          </span>
                        )}
                      </div>
                      <div className="ml-4 border-l border-gray-300 flex flex-col gap-4 mt-2">
                        <div className="flex justify-between w-full mb-2">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            GST on FX conversion
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_gst === "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_gst)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                        <div className="flex justify-between w-full mb-2">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            Foreign Bank charges{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_foreign_bank_charges == "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_foreign_bank_charges)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                        <div className="flex justify-between w-full">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            TT charges{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_tt_charges == "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_tt_charges)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                    <span className="text-xs leading-[14.5px] indent-3.5 font-semibold">
                      Total estimation
                    </span>
                    {isCalcLoading ? (
                      <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                    ) : (
                      <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                        {cal_total_payable == "" || amount === ""
                          ? "0.00"
                          : `INR ${formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2))}`}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Toaster theme="dark" position="top-right" />
        </div>
      }
    </>
  );
};

export default CreateNewDeal;

const SettlementRateInput = ({ field }: IField<IFormInputMap>) => {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className="flex relative  flex-col z-50">
      <div className="flex absolute left-48 -top-8 items-center ">
        {/* <label htmlFor="settlement">Enable Custom Settlement Rate</label> */}
        <Switch.Root
          className="w-[45px] h-[25px] bg-blue_gray-lite-bg rounded-full  border-2 border-blue-700 outline-none cursor-default"
          id="airplane-mode"
          style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
          checked={enabled}
          onCheckedChange={(checked) => {
            setEnabled(checked);
            if (!checked) field.onChange("");
          }}
        >
          <Switch.Thumb
            className={`flex p-1 w-[20px] h-[20px] bg-blue-700_01 rounded-full shadow-black-900 transition-transform duration-100 ${enabled && "translate-x-5"} will-change-transform`}
          />
        </Switch.Root>
      </div>
      {enabled && (
        <CurrencyInput currencyType="INR" field={field} isLoading={!enabled} />
      )}
    </div>
  );
};
