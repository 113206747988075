import Stepper from "components/Stepper/newstepper";
import { useState } from "react";
import { createExistingContactSteps as CECS } from "utils";
import ExistingContactDetail from "./step1";
import ExistingOpportunityDetail from "./step2";
import ExistingInteractionDetail from "./step3";
import { step1SchemaExisting } from "pages/Create Opportunities/schema";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { AxiosRequestConfig } from "axios";
import { postExistingOpportunity, PostExistingOpportunityReqType } from "service/apis/Opportunities";
import { toast, Toaster } from "sonner";
import { resetState } from "redux/features/CreateOpportunity";
import { Spinner } from "components";

interface OpportunitiesSheetProps {
    company_id: string;
    handleSheetOpen: (open: boolean) => void;
    isOpen: boolean;
}

export default function OpportunitiesSheet({ company_id, handleSheetOpen, isOpen }: OpportunitiesSheetProps) {
    const [currentStep, setCurrentStep] = useState(1);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [loading, setLoading] = useState(false);
    const { product_list, medium_of_contact, pipelineType, contact_id, refferal_campaign,refferal_source,partner_reffered, employee_reffered, company_address, company_email, company_name, company_phone, contact_person_name, contact_person_phone, contact_person_type } = useAppSelector((state) => state.CreateOpportunity);
    const dispatch = useAppDispatch();

    const handlePrevious = () => {
        if (currentStep === 1) return;
        setCurrentStep((prev) => prev - 1);
        setErrors({});
    }

    const validateStep = (step: number) => {
        let isValid = false;
        const newErrors: Record<string, string> = {};

        if (pipelineType === 'retail' || pipelineType === 'partner') {
            isValid = true;
            return isValid;
        }

        switch (step) {
            case 1:
                try {
                    step1SchemaExisting.parse({
                        contact_person_name,
                        contact_person_phone,
                        contact_person_type
                    });
                    isValid = true;
                } catch (error) {
                    if (error instanceof z.ZodError) {
                        error.errors.forEach((err) => {
                            newErrors[err.path[0]] = err.message;
                        });
                    }
                }
                break;
            // Add cases for other steps if needed
            // case 2:
            //     // Validate step 2
            //     break;
            // case 3:
            //     // Validate step 3
            //     break;
            default:
                isValid = true; // Default to true for steps without validation
        }

        setErrors(newErrors);
        return isValid;
    }

    const buttonState = () => {
        if (pipelineType === 'retail' || pipelineType === 'partner') {
            switch (currentStep) {
                case 1:
                    if (!product_list.length)
                        return false;
                    return true;
                case 2:
                    if (!medium_of_contact)
                        return false;
                    return true;
                default:
                    return false;
            }
        }
        switch (currentStep) {
            case 1:
                if (!contact_person_name || !contact_person_phone || !contact_person_type) {
                    return false;
                }
                return true;
            case 2:
                if (!product_list.length)
                    return false;
                return true;
            case 3:
                if (!medium_of_contact)
                    return false;
                return true;
            default:
                return false;
        }
    }

    const renderStep = () => {
        if (pipelineType === 'retail' || pipelineType === 'partner') {
            switch (currentStep) {
                case 1:
                    return <ExistingOpportunityDetail errors={errors} />;
                case 2:
                    return <ExistingInteractionDetail errors={errors} />;
                default:
                    return null
            }
        }
        switch (currentStep) {
            case 1:
                return <ExistingContactDetail errors={errors} />;
            case 2:
                return <ExistingOpportunityDetail errors={errors} />;
            case 3:
                return <ExistingInteractionDetail errors={errors} />;
            default:
                return null
        }
    }

    const retailExistingSteps = [
        {
            name: "Opportunity Details",
            step: 1,
        },
        {
            name: "Interaction Details",
            step: 2,
        },
    ];

    const createExistingContactSteps =( pipelineType === 'retail' || pipelineType === 'partner' ) ? retailExistingSteps : CECS;

    const handleSubmit = async () => {
        setLoading(true);
        const auth = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);

        const contact_id = company_id;
        const partner_id = company_id;

        const formData = new FormData();
        switch (pipelineType) {
            case "retail":
                formData.append('contact_id', contact_id); 
                formData.append('typeOfRefferal', refferal_source);
                formData.append('campaign', refferal_campaign);
                formData.append('refferedBy_staff', employee_reffered);
                formData.append('refferedBy_partner', partner_reffered);                      
                formData.append('pipelineType', 'retail');
                break;
            case "corporate":
                formData.append("contactName", contact_person_name);
                formData.append("contactNumber", contact_person_phone);
                formData.append("contactPersonType", contact_person_type);                
                formData.append("coorporate_company_id", company_id);
                formData.append('pipelineType', 'coorporate');
                break;
            case "partner":
                formData.append("contact_id", partner_id);
                formData.append('pipelineType', 'partner');

                break;
            default:
                break
        }
            
        formData.append("source", medium_of_contact);
        product_list.forEach((product,) => {
            formData.append('items[]', JSON.stringify(product));
        });

        const payload: AxiosRequestConfig<PostExistingOpportunityReqType> = {
            headers: { Authorization: `Bearer ${authToken}` },
            data: formData as any
        };

        try {
            const res = await postExistingOpportunity(payload);
            if (res.status === 200) {
                toast.success('Opportunity Created Successfully');
                setLoading(false);
            }
            dispatch(resetState());
            handleSheetOpen(!isOpen);
        } catch (error) {
            console.log(error)
            setLoading(false);
            toast.error('Failed to create opportunity');
        }
    }

    const handleNext = () => {
        if (currentStep === createExistingContactSteps.length) {
            handleSubmit();
            return;
        }
        // console.log(errors)
        if (validateStep(currentStep)) {
            setCurrentStep((prev) => prev + 1);
            setErrors({}); // Clear errors when moving to next step
        }
    }
    return (
        <>
            {loading && <Spinner />}
            <div className="p-4 flex flex-col font-inter items-start justify-start mx-auto w-full">
                <div className="gap-8 w-full flex h-full flex-col">
                    <div className=" flex flex-col border-b gap-2 px-5 pb-2.5">
                        <h3 className=" font-semibold capitalize leading-4 text-lg text-black-label">
                            {pipelineType} Opportunities
                        </h3>
                        <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
                            Create a new {pipelineType} opportunity.
                        </p>
                    </div>
                    <div className="rounded-xl flex flex-col justify-between gap-5 w-full px-5 py-2.5">
                        <Stepper
                            steps={createExistingContactSteps.map((val) => val.name)}
                            currentStep={currentStep - 1}
                        />
                        <div className="flex relative flex-col gap-5 w-full">
                            {renderStep()}
                        </div>
                        <div className="flex gap-3">
                            {currentStep !== 1 && (
                                <button
                                    type="button"
                                    onClick={handlePrevious}
                                    className={`px-4 py-[11px] w-fit rounded-lg border border-blue-700 text-blue-700 ${true ? "" : "opacity-40"}`}
                                >
                                    Previous
                                </button>
                            )}
                            <button
                                type="button"
                                disabled={!buttonState()}
                                onClick={handleNext}
                                className="px-4 py-[11px] w-fit rounded-lg bg-blue-700 text-white-A700 disabled:opacity-40"
                            >
                                {currentStep === createExistingContactSteps.length
                                    ? "Submit"
                                    : "Next"}
                            </button>
                        </div>
                    </div>
                </div>
                <Toaster position="top-right" theme="dark" />
            </div>
        </>
    )
}