import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { AxiosRequestConfig } from "axios";
import { Img, Input, SelectBox, Text, Button, Spinner } from "components";
import CustomerDetails from "models/CreateRemittanceQuotation/CustomerDetails";
import { formatIndianNumber } from "../../utils";
import { selectOptionType } from "components/SelectBox";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  GetPurposeRequestType,
  getPurpose,
  GetCountryRequestType,
  getCountry,
  GetChannelRequestType,
  getChannel,
  PostDealCalcRequestType,
  postDealCalc,
  GetServicePartnersRequestType,
  getServicePartners,
} from "service/apis/CreateDealapi";
import {
  setPurposeId,
  setCountry,
  setshowerror,
  setAmount,
  setCreatedBy,
  setCurrency,
  setAddOnRates,
  setCustomer_rate,
  setcal_total_charges,
  setcal_gst,
  setcal_tt_charges,
  setcal_total_payable,
  setcal_foreign_bank_charges,
  setcal_amount_inr,
  setcal_your_rate,
  setcal_profit,
  setcal_customer_rate,
  setServicePartnerMode,
  setServicePartnerId,
  setSettlementRates,
  setForeignBankRates,
  setTTRates,
  setPartnerStatus,
  setPartnerMargin,
  setTcs,
  setPartnerWalkinMargin,
  setFXCharges,
  setPartnerDeal,
  setType,
  setCustomers_id,
  setDiscount,
  setForeign_Bank_Charges,
  setRemitterPAN,
  setRemitterDOB,
  setDocs,
  setStudentName
} from "../../redux/features/CreateDeal";
import { TailSpin } from "react-loader-spinner";
import Cancel from "models/Cancel";
import Navbar from "components/Navbar/Index";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Toaster, toast } from "sonner";
import { Skeleton } from "@/components/ui/skeleton";

type countrydatatype = {
  country: string;
  currency: string;
};

const CreateRemittanceQuotation: React.FC = () => {
  const [isCalcLoading, setIsCalcLoading] = useState(false);
  const [purpose, setPurpose] = useState<selectOptionType[]>([]);
  const [countryoption, setCountryoption] = useState<selectOptionType[]>(
    []
  );
  const [servicePartners, setServicePartners] = useState<selectOptionType[]>(
    []
  );
  const [customerDetailsPopup, setCustomerDetailsPopup] = useState<boolean>(false);
  const [servicePartner, setServicePartner] = useState<string | number>("");
  const [currencydata, setCurrencydata] = useState<countrydatatype[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [showProfit, setShowProfit] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [rate, setRate] = useState<number>(0);
  const [editCustomerRate, setEditCustomerRate] = useState<boolean>(false);
  const navigate = useNavigate();
  const [partneroption, setPartneroption] = useState<
    { label: string; value: number }[]
  >([]);

  const dispatch = useAppDispatch();

  const {
    amount,
    country,
    created_by,
    currency,
    customer_rate,
    add_on_rates,
    purpose_id,
    showerror,
    type,
    cal_profit,
    cal_customer_rate,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    service_partner_id,
    partner_status,
    partner_walkin_margin,
    partner_margin,
    tcs,
    fx_charges,
    partnerDeal
  } = useAppSelector((state) => state.CreateDeal);

  const level = localStorage.getItem("level");

  React.useEffect(() => {
    fetchPartner();
    fetchPurpose();
    fetchCountry();
    fetchCalc();
    if (created_by === "") {
      handleonWalkinOrder();
    }
  }, []);

  React.useEffect(() => {
    dispatch(setcal_total_charges(""));
    dispatch(setcal_amount_inr(""));
    dispatch(setcal_gst(""));
    dispatch(setcal_tt_charges(""));
    dispatch(setcal_total_payable(""));
    dispatch(setcal_foreign_bank_charges(""));
    dispatch(setcal_profit(""));
  }, [created_by]);

  React.useEffect(() => {
    const coun = currencydata.find((item) => item.country === country);
    if (type === "remitter_sends") {
      dispatch(setCurrency("INR"));
    } else {
      if (coun?.currency) {
        dispatch(setCurrency(coun?.currency));
      }
    }
  }, [country, type]);


  React.useEffect(() => {

    let timeoutId: any = null;
    if (amount && currency != "") {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to call fetchCalc after 1 second
      timeoutId = setTimeout(() => {
        fetchCalc();
      }, 1000);
    } else {
      dispatch(setcal_total_charges(""));
      dispatch(setcal_amount_inr(""));
      dispatch(setcal_gst(""));
      dispatch(setcal_tt_charges(""));
      dispatch(setcal_total_payable(""));
      dispatch(setcal_foreign_bank_charges(""));
      dispatch(setcal_profit(""));
    }
    return () => {
      // Cleanup the timeout on component unmount or when amount changes
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [purpose_id, amount, type, created_by, fx_charges, currency, add_on_rates, servicePartner, partner_margin, partner_walkin_margin]);

  React.useEffect(() => {
    fetchServicePartners();
  }, [purpose_id, currency])

  const channel_id = localStorage.getItem("channel_id");


  const fetchServicePartners = (): void | null => {
    if (purpose_id == "" || currency == "") {
      dispatch(setServicePartnerId(""));
      return null;
    }

    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetServicePartnersRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: parseInt(purpose_id),
        currency: currency,
      },
    };

    getServicePartners(payload)
      .then((res) => {
        if (res.data.length > 0) {
          dispatch(setServicePartnerId(res.data[0].id));
          setServicePartner(res.data[0].id);
          fetchCalc();
        }
        else {
          dispatch(setServicePartnerId(""));
          setServicePartner("");
        }
        setServicePartners(
          res.data.map((item) => {
            return {
              label: item.display_name,
              value: item.id,
            };
          }));
        if (res.data.length < 1) {
          toast.error("Service Partners not available");
        }
      })
      .catch((err) => {
        dispatch(setServicePartnerId(""));
        setServicePartner("");
        toast.error(err.response.data.message);
      });
  }

  const fetchCalc = (): void | null => {
    // get auth token from localstorage

    if (fx_charges === "") {
      return;
    }
    setIsCalcLoading(true);
    setDisabled(true);
    setShowSpinner(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostDealCalcRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        purpose_id: purpose_id === "" ? 1 : parseInt(purpose_id),
        currency: currency === "" ? "USD" : currency,
        amount: parseInt(amount) ? parseInt(amount) : 0,
        type: type,
        created_by: created_by,
        tcs_type: 1,
        discount: 0,
        addon_rates: add_on_rates,
        country: country,
        service_partner_id: servicePartner,
        partner_margin: partner_status === "pending" ? (partner_margin === "" ? "0" : partner_margin) as string : "",
        partner_walkin_margin: partner_status === "pending" ? (partner_walkin_margin === "" ? "0" : partner_walkin_margin) as string : "",
        fx_charges: fx_charges
      },
    };

    if (currency && purpose_id && created_by !== "-1" && (partner_status !== "pending" || (partner_status === "pending" && partner_margin && partner_walkin_margin))) {
      postDealCalc(payload)
        .then((res) => {
          dispatch(setTcs(res?.data.tcs))
          dispatch(setServicePartnerId(res.data.service_partner));
          dispatch(setForeignBankRates(res.data.foreign_bank_charges));
          dispatch(setTTRates(res.data.tt_charges));
          dispatch(setServicePartnerMode(res.data.service_partner_mode));
          if (res.data.service_partner_mode === "online") {
            dispatch(setSettlementRates(res.data.settlement_rates));
          }
          else {
            dispatch(setSettlementRates(""));
          }
          dispatch(
            setcal_total_charges(
              parseFloat(res.data.payable_amount) -
              parseFloat(res.data.base_amount)
            )
          );
          if (level === "1") {
            dispatch(setcal_profit(res.data.profit.l1));
          }
          if (level === "2") {
            dispatch(setcal_profit(res.data.profit.l2));
          }
          if (level === "3") {
            dispatch(setcal_profit(res.data.profit.l3));
          }
          dispatch(setcal_amount_inr(res.data.base_amount));
          dispatch(setcal_gst(res.data.gst));
          dispatch(setcal_tt_charges(res.data.tt_charges_with_gst));
          dispatch(setcal_total_payable(res.data.payable_amount));
          dispatch(
            setcal_foreign_bank_charges(res.data.foreign_bank_charges_with_gst)
          );
          dispatch(
            setcal_your_rate(
              (
                parseFloat(res?.data?.settlement_rates) +
                (level === "2" ? res?.data?.cumulative_commission.l1 : 0) +
                (level === "3" ? res?.data?.cumulative_commission.l2 : 0)
              )?.toFixed(4)
            )
          );

          setRate(
            parseFloat(res?.data?.settlement_rates) +
            (res?.data?.cumulative_commission_without_addon.l3 > 0
              ? res?.data?.cumulative_commission_without_addon.l3
              : res?.data?.cumulative_commission_without_addon.l2 > 0
                ? res?.data?.cumulative_commission_without_addon.l2
                : res?.data?.cumulative_commission_without_addon.l1)
          );

          localStorage.setItem(
            "service_partner_id",
            JSON.stringify(res.data.service_partner)
          );

          dispatch(
            setCustomer_rate(Number(res.data.customer_rate)?.toFixed(4))
          );
          dispatch(
            setcal_customer_rate(
              parseFloat(res.data.customer_rate)?.toFixed(4)?.toLocaleString()
            )
          );
          setDisabled(false);
          setShowSpinner(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setDisabled(false);
          setShowSpinner(false);
        }).finally(() => {
          setIsCalcLoading(false);
        })
    } else {
      setIsCalcLoading(false);
      setDisabled(false);
      setShowSpinner(false);
    }
  };
  const handleonWalkinOrder = (): void | null => {
    // get channel id from localstorage
    if (channel_id === null) return null;
    dispatch(setPartnerDeal(false));
    dispatch(setCreatedBy(channel_id));
    dispatch(setPartnerStatus(""));
    dispatch(setPartnerMargin(""));
  };

  const fetchPartner = (): void | null => {
    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 1000,
      },
    };

    getChannel(payload)
      .then((res) => {
        if (res.data && res.data.items) {
          let partner = res.data.items.filter(
            (item) =>
              (item.status === "active" || item.status === "pending") && item.approval_status === "approved"
          );

          setPartneroption(
            partner.map((item) => {
              return {
                label: item.name,
                value: item.id,
                status: item.status,
                style: {
                  "color": "red"
                }
              };
            })
          );
        }
      })
      .catch((err) => {
      });
  };

  const fetchCountry = () => {
    const payload: AxiosRequestConfig<GetCountryRequestType> = {};
    getCountry(payload)
      .then((res) => {
        setCurrencydata(res.data);
        setCountryoption(
          res.data.map((item) => {
            return {
              label: item.country,
              value: item.country,
            };
          })
        );
      })
      .catch((err) => {
      });
  };

  const fetchPurpose = () => {
    const payload: AxiosRequestConfig<GetPurposeRequestType> = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        product_id: 1
      }
    };
    getPurpose(payload)
      .then((res) => {
        setPurpose(
          res.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
      })
      .catch((err) => {
      });
  };

  const handleCancel = () => {
    dispatch(setPurposeId(""));
    dispatch(setCountry(""));
    dispatch(setshowerror(false));
    dispatch(setAmount(""));
    dispatch(setCreatedBy(""));
    dispatch(setCurrency(""));
    dispatch(setStudentName(""));
    dispatch(setCustomer_rate(("")));
    dispatch(setAddOnRates(0));
    dispatch(setcal_your_rate(""));
    dispatch(setcal_customer_rate(""));
    dispatch(setcal_total_charges(""));
    dispatch(setcal_amount_inr(""));
    dispatch(setcal_gst(""));
    dispatch(setcal_tt_charges(""));
    dispatch(setcal_total_payable(""));
    dispatch(setcal_foreign_bank_charges(""));
    dispatch(setcal_profit(""));
    dispatch(setType("beneficiary_receives"));
    dispatch(setCustomers_id(""));
    dispatch(setForeign_Bank_Charges(""));
    dispatch(setDiscount(""));
    dispatch(setRemitterPAN(""));
    dispatch(setRemitterDOB(""));
    dispatch(setDocs([]));
    dispatch(setPartnerMargin(""));
    dispatch(setFXCharges("BEN"));
    navigate("/deals");
    navigate("/");
  };

  const handleCreateDeal = () => {
    if (purpose_id == '' || country == '' || amount == '') {
      toast.error('Please fill all the details')
    }
    else {
      navigate("/createdeal");
    }
  }

  return (
    <>{loading && <Spinner />}

      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title="Are You Sure You Want To Cancel This Remittance Quotation?"
            setConfirmCancel={setConfirmCancel}
            handleCancel={handleCancel}
          />{" "}
        </div>
      )}

      {customerDetailsPopup && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <CustomerDetails
            setConfirmCancel={setCustomerDetailsPopup}
          />{" "}
        </div>
      )}


      <div className="p-4 bg-gray-100 flex flex-col h-screen font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="flex md:flex-col flex-col gap-4 h-full md:gap-5 items-start justify-start w-full">
          <Navbar>
            <div className=" w-full flex justify-between">
              <div className=" flex gap-3 items-center">
                <Img
                  src="images/sidebar_img/remit.svg"
                  className="h-5"
                  alt=""
                />
                <div className="gap-2 flex flex-col">
                  <span className="text-sm font-semibold">
                    Remit Quotation
                  </span>
                  <span className="text-xs font-normal text-[#999999]">
                    Create the remittance quotation
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="gap-2.5 flex items-center">
                  <Img src="images/overview/wrench.svg" alt="" />
                  <span className="text-sm font-semibold text-blue-700">
                    Help
                  </span>
                </div>
                <AlertDialog>
                  <AlertDialogTrigger>
                    <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                      Cancel
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                      <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                          This action cannot be undone. This will permanently
                          delete your progress and remove the actions preformed.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                          className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                          asChild
                        >
                          <button>Close</button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                          className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                          asChild
                        >
                          <button className="" onClick={handleCancel}>
                            Continue
                          </button>
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogPortal>
                </AlertDialog>
              </div>
            </div>
          </Navbar>
          <div className="gap-5 w-full flex h-full flex-1 flex-grow">
            <div className="flex bg-white-A700 md:flex-1 rounded-xl flex-col gap-8 p-5 md:h-auto items-start justify-start sm:min-h-[auto] md:px-5 w-8/12 md:w-full">
              <div className=" flex flex-row gap-2.5 items-start justify-between pb-2.5 w-full">
                {level === "1" && <div className="hidden flex-row items-center justify-center h-full w-[30%]">
                  <SelectBox
                    className="bg-white-A700 border w-full border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                    placeholderClassName="text-black-900_60"
                    indicator={
                      <Img
                        className="h-4 w-4"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    value={service_partner_id}
                    onChange={(e) => {
                      setServicePartner(e);
                      if (e == "7") {
                        dispatch(setFXCharges(fx_charges == "SHA" ? "BEN" : fx_charges))
                      }

                      if (e == "17") {
                        dispatch(setFXCharges(fx_charges != "OUR" ? "OUR" : fx_charges))
                      }
                    }}
                    isMulti={false}
                    name="inputbox"
                    errors={created_by == "" && showerror ? ["Select anyone"] : []}
                    options={servicePartners}
                    isSearchable={true}
                    placeholder="Service Partner"
                  />
                </div>}
              </div>
              <div className="flex pl-10 flex-col gap-[15px] items-start justify-start w-2/4 sm:w-full">
                <div className="flex relative flex-col gap-2.5 h-auto md:h-auto items-start justify-start w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text
                    className="text-sm font-medium leading-[17px]"
                  >
                    1. Select Purpose
                  </Text>
                  <SelectBox
                    className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                    placeholderClassName="text-black-900_60"
                    indicator={
                      <Img
                        className="h-4 w-4"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    errors={
                      purpose_id == "" && showerror ? ["Purpose is required"] : [""]
                    }
                    isMulti={false}
                    name="inputbox"
                    options={purpose}
                    value={purpose_id}
                    onChange={(e) => {
                      dispatch(setPurposeId(e));
                    }}
                    isSearchable={false}
                    placeholder="Select Purpose"
                  />
                </div>
                <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text
                    className="text-sm font-medium leading-[17px]"
                  >
                    2. Select Country
                  </Text>
                  <SelectBox
                    className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                    placeholderClassName="text-black-900_60"
                    indicator={
                      <Img
                        className="h-4 w-4"
                        src="images/img_arrowdown.svg"
                        alt="arrow_down"
                      />
                    }
                    isMulti={false}
                    name="inputboxone"
                    errors={
                      country == "" && showerror ? ["Country is required"] : [""]
                    }
                    options={countryoption}
                    value={country}
                    onChange={(e) => dispatch(setCountry(e))}
                    isSearchable={true}
                    placeholder="Type and Search Country"
                  />
                </div>
                {partner_status === "pending" && (
                  <div className="flex flex-col relative gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text
                      className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                      size="txtInterBold15Black900dd"
                    >
                      3. Partner Margin{" "}
                    </Text>
                    <div className="flex flex-row w-full items-center justify-between">
                      <div className="flex flex-row gap-1 w-full">
                        <div className="flex flex-col w-full">
                          <Input
                            name="input"
                            value={partner_margin}
                            onChange={(e: string) => dispatch(setPartnerMargin(e))}
                            errors={
                              amount == "" && showerror
                                ? ["Partner Margin is required"]
                                : [""]
                            }
                            placeholder="Enter Partner Margin"
                            className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left ${currency == "" ? "w-full" : "w-[180px]"
                              }`}
                            wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                            type="text"
                            disabled={disabled}
                          ></Input>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {partner_status === "pending" && (
                  <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-32 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text
                      className="text-sm font-medium leading-[17px]"
                    >
                      4. Partner Walkin Margin{" "}
                    </Text>
                    <div className="flex flex-row w-full items-center justify-between">
                      <div className="flex flex-row gap-1 w-full">
                        <div className="flex flex-col w-full">
                          <Input
                            name="input"
                            value={partner_walkin_margin}
                            onChange={(e: string) => dispatch(setPartnerWalkinMargin(e))}
                            errors={
                              amount == "" && showerror
                                ? ["Partner Walkin Margin is required"]
                                : [""]
                            }
                            placeholder="Enter Partner Walkin Margin"
                            className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left  "w-full"
                                `}
                            wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                            type="text"
                            disabled={disabled}
                          ></Input>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {purpose_id && country && (
                  <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-32 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text
                      className="text-sm font-medium leading-[17px]"
                    >
                      {partner_status === "pending" ? "5." : "3."} Enter amount you want to send{" "}
                    </Text>
                    <div className="flex flex-row w-full items-center justify-between">
                      <div className="flex flex-row gap-1">
                        <div className="flex flex-col w-[15%]">
                          <Input
                            name="input"
                            value={currency}
                            placeholder="Currency"
                            className={`w-full p-0 text-blue-700_01 flex flex-col placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left`}
                            wrapClassName="w-full bg-white-A700 text-blue-700_01 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[11px] py-4 rounded-lg"
                            type="text"
                            disabled={true}
                          ></Input>
                        </div>
                        <div className="flex flex-col w-[85%]">
                          <Input
                            name="input"
                            value={amount}
                            type="number"
                            onChange={(e: string) => dispatch(setAmount(e))}
                            errors={
                              amount == "" && showerror
                                ? ["Amount is required"]
                                : [""]
                            }
                            placeholder="Enter Amount"
                            className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left ${currency == "" ? "w-full" : "w-[180px]"
                              }`}
                            wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                            disabled={disabled}
                            suffix={
                              <div>
                                {showSpinner && (
                                  <TailSpin
                                    height="20"
                                    color="#3892F3"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperClass=""
                                    visible={showSpinner}
                                  />
                                )}
                              </div>
                            }
                          ></Input>
                        </div>
                      </div>
                    </div>
                    {showProfit && <Text
                      className="text-black-900_8c text-sm w-auto"
                      size="txtInterRegular12Black9008c"
                    >
                      My profit (approx.) :{" "}
                      <span
                        className={`${parseFloat(cal_profit) >= 0
                          ? "text-[#4fa94d]"
                          : "text-[#FF0000]"
                          }`}
                      >
                        INR{" "}
                        {cal_profit === ""
                          ? "0.00"
                          : formatIndianNumber(
                            parseFloat(cal_profit)?.toFixed(4)
                          )}{" "}
                      </span>
                    </Text>}
                  </div>
                )}

                {purpose_id && country && <div className="hidden flex flex-row w-full justify-end">
                  <Text
                    className="cursor-pointer text-blue-700_01 text-sm w-auto"
                    size="txtInterRegular12"
                    onClick={() => setEditCustomerRate(!editCustomerRate)}>
                    {!editCustomerRate ? "Add Customer Rate" : "Cancel"}
                  </Text>
                </div>}

                {purpose_id && country && editCustomerRate && (
                  <div className="flex flex-col  items-start justify-start w-full">
                    <div className="flex flex-row justify-between w-full">
                      <Text
                        className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto pb-[10px]"
                        size="txtInterBold15Black900dd"
                      >
                        {!editCustomerRate ? (partner_status === "pending" ? "5. " : "3.") : (partner_status === "pending" ? "6. " : "4.")} Enter customer rate
                      </Text>
                    </div>
                    <div className="flex flex-row gap-1">
                      <div className="flex flex-col w-[15%]">
                        <Input
                          name="input"
                          value={"INR"}
                          placeholder="Currency"
                          className={`w-full p-0 text-blue-700_01 flex flex-col placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left`}
                          wrapClassName="w-full bg-white-A700 text-blue-700_01 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[11px] py-4 rounded-lg"
                          type="text"
                          disabled={true}
                        ></Input>
                      </div>
                      <div className="flex flex-col w-[85%]">
                        <Input
                          name="input"
                          value={customer_rate}
                          onChange={(e: string) => {
                            dispatch(setCustomer_rate(e));
                            dispatch(
                              setAddOnRates(parseFloat(e) - parseFloat(rate.toString()))
                            );
                          }}
                          errors={
                            amount == "" && showerror
                              ? ["Amount is required"]
                              : [""]
                          }
                          placeholder="Enter Amount"
                          className={`w-full p-0 flex flex-col placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left`}
                          wrapClassName="w-full bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg"
                          type="number"
                          disabled={!((partner_status !== "pending" || (partner_status === "pending" && partner_margin && partner_walkin_margin)) && amount)}
                          suffix={
                            <div>
                              {showSpinner && (
                                <TailSpin
                                  height="20"
                                  color="#3892F3"
                                  ariaLabel="tail-spin-loading"
                                  radius="1"
                                  wrapperClass=""
                                  visible={showSpinner}
                                />
                              )}
                            </div>
                          }
                        ></Input>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                  <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text
                      className="text-sm font-medium leading-[17px]"
                    >
                      {!editCustomerRate ? (partner_status === "pending" ? (purpose && country) ? "7." : "5." : (purpose && country) ? "4." : "3.") : (partner_status === "pending" ? (purpose && country) ? "7." : "5." : (purpose && country) ? "5." : "3.")} FX Charges
                    </Text>
                    <div className="flex flex-row w-full items-center">
                      <div className="flex flex-row gap-2.5 h-[46px] md:h-auto items-start justify-start sm:w-full">
                        <div className="flex flex-row gap-2 items-center justify-start w-[33%]">
                          <RadioGroup
                            className="flex"
                            onValueChange={(e) => dispatch(setFXCharges(e))}
                            defaultValue="BEN"
                          >
                            <div
                              className={` ${fx_charges === 'BEN' ? " border-blue-700" : " "} flex items-center rounded-lg border p-3 space-x-2`}
                            >
                              <RadioGroupItem
                                disabled={servicePartner == "17"}
                                value="BEN"
                                id="BEN"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"                                
                              />
                              <label htmlFor="BEN">BEN</label>
                            </div>
                            <div
                              className={` ${fx_charges === 'SHA' ? "border-blue-700" : ""} flex items-center rounded-lg border p-3 space-x-2`}
                            >
                              <RadioGroupItem value="SHA" id="SHA" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2" />
                              <label htmlFor="SHA">SHA</label>
                            </div>
                            <div className={` ${fx_charges === 'OUR' ? "border-blue-700" : ""} flex items-center rounded-lg border p-3 space-x-2`}>
                              <RadioGroupItem value="OUR" id="OUR" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2" />
                              <label htmlFor="OUR">OUR</label>
                            </div>
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className=" common-pointer bg-blue-700  cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                onClick={() => { setCustomerDetailsPopup(true) }}
              >
                <div className="flex flex-row gap-[10px] items-center">
                  <Img
                    className="w[16px] h-[16px] w-[16px] text-[#7E7E7E] fill-current "
                    src="images/download.svg"
                    alt="cell"
                  />
                  Download PDF
                </div>
              </Button>
            </div>
            <section className="flex-1 bg-white-A700 px-3 py-5 rounded-xl gap-4 flex flex-col">
              <p className="text-lg leading-4 font-semibold">Calculations</p>
              <div className="pt-5 pb-10 px-5 border-b-8 rounded-xl bg-custom-calc-gradient border-blue-700 gap-10">
                <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex justify-between w-full">
                      <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                        Customer Rate
                      </span>
                      {isCalcLoading ? (
                        <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                      ) : (
                        <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                          {cal_customer_rate === ""
                            ? "0.00"
                            : `₹ ${cal_customer_rate}`}
                        </span>
                      )}
                    </div>
                    <div className="flex justify-between w-full">
                      <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                        Recipient gets
                      </span>
                      {isCalcLoading ? (
                        <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                      ) : (
                        <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                          {cal_amount_inr == ""
                            ? "0.00"
                            : (currency === "" || currency === undefined
                                ? "USD "
                                : `${currency} `) +
                              formatIndianNumber(
                                parseFloat(amount)?.toFixed(4),
                              ).toString()}
                        </span>
                      )}
                    </div>
                    <div className="w-full border-[.3px]" />
                    <div className="flex flex-col w-full gap-4">
                      <div className="flex justify-between w-full">
                        <span className="text-sm leading-[17px] indent-3.5 font-normal">
                          Total Charges
                        </span>
                        {isCalcLoading ? (
                          <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                        ) : (
                          <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                            {cal_total_charges === "" || amount === ""
                              ? "0.00"
                              : `INR ${parseFloat(Number(cal_total_charges)?.toFixed(2))}`}
                          </span>
                        )}
                      </div>
                      <div className="ml-4 border-l border-gray-300 flex flex-col gap-4 mt-2">
                        <div className="flex justify-between w-full mb-2">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            GST on FX conversion
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_gst === "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_gst)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                        <div className="flex justify-between w-full mb-2">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            Foreign Bank charges{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_foreign_bank_charges == "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_foreign_bank_charges)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                        <div className="flex justify-between w-full">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            TT charges{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_tt_charges == "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_tt_charges)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                    <span className="text-xs leading-[14.5px] indent-3.5 font-semibold">
                      Total estimation
                    </span>
                    {isCalcLoading ? (
                      <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                    ) : (
                      <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                        {cal_total_payable == "" || amount === ""
                          ? "0.00"
                          : `INR ${formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2))}`}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </section>
            {/* <div className="flex bg-white-A700 rounded-xl sm:flex-1 flex-col gap-2.5 md:h-auto items-start justify-start sm:min-h-[] md:px-5 px-9 py-[61px] w-[34.5%] sm:w-full">
              <div className="flex flex-row justify-between items-center w-full">
                <div className="flex flex-row">
                  <Text
                    className="text-black-900_01 font-[800] text-xl w-[125px]"
                    size="txtRubikRomanRegular20"
                  >
                    Calculations
                  </Text>
                  <button className="pr-2">
                    {showProfit ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        onClick={() => setShowProfit(!showProfit)}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        onClick={() => setShowProfit(!showProfit)}
                      />
                    )}
                  </button>
                </div>
                <div>
                  <Text
                    className="text-[15px] text-black-900_8c tracking-[0.15px] w-auto"
                    size="txtInterBold15Black900dd"
                  >
                    {cal_customer_rate == "" ? "0.00" : "₹ " + cal_customer_rate}
                  </Text>
                </div>
              </div>
              <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                <Text
                  className="text-base text-black-900_8c w-[180px]"
                  size="txtInterBold16Black9008c"
                >
                  Received Amount
                </Text>
                <div>
                  <Text
                    className="text-base text-black-900_8c text-right w-[146px]"
                    size="txtInterBold16Black9008c"
                  >
                    {cal_amount_inr == ""
                      ? "0.00"
                      : (currency === "" || currency === undefined
                        ? "USD"
                        : currency) +
                      " " +
                      formatIndianNumber(parseFloat(amount)?.toFixed(4))}
                  </Text>
                </div>
              </div>
              <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                <Text
                  className="text-base text-black-900_8c w-auto"
                  size="txtInterBold16Black9008c"
                >
                  Total Charges
                </Text>
                <Text
                  className="text-base text-black-900_8c text-right w-[146px]"
                  size="txtInterBold16Black9008c"
                >
                  {cal_total_charges === "" || amount === ""
                    ? "0.00"
                    : "₹ " + parseFloat(Number(cal_total_charges)?.toFixed(2))}
                </Text>
              </div>
              <div className="border-y-2 border-[#] flex flex-col font-inter py-[12px] gap-[4px] items-start justify-start w-full">
                <Text
                  className="text-base text-black-900_8c w-[62px]"
                  size="txtInterRegular16Black9008c"
                >
                  Breakup
                </Text>
                <div className="flex flex-col w-full gap-[4px]">
                  <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
                    <Text
                      className="text-black-900_8c text-xs w-auto"
                      size="txtInterRegular12Black9008c"
                    >
                      GST on FX conversion
                    </Text>
                    <Text
                      className="text-base text-black-900_8c text-right w-[146px]"
                      size="txtInterRegular16Black9008c"
                    >
                      {cal_gst == "" || amount === ""
                        ? "0.00"
                        : "₹ " + Number(cal_gst)?.toFixed(2)}
                    </Text>
                  </div>
                  <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
                    <Text
                      className="text-black-900_8c text-xs w-full"
                      size="txtInterRegular12Black9008c"
                    >
                      Foreign Bank Charges (incl. GST)
                    </Text>
                    <Text
                      className="text-base text-black-900_8c text-right w-[146px]"
                      size="txtInterRegular16Black9008c"
                    >
                      {cal_foreign_bank_charges == "" || amount === ""
                        ? "0.00"
                        : "₹ " + Number(cal_foreign_bank_charges)?.toFixed(2)}
                    </Text>
                  </div>
                  <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
                    <Text
                      className="text-black-900_8c text-xs w-auto"
                      size="txtInterRegular12Black9008c"
                    >
                      TT Charges (incl. GST)
                    </Text>
                    <Text
                      className="text-base text-black-900_8c text-right w-[146px]"
                      size="txtInterRegular16Black9008c"
                    >
                      {cal_tt_charges == "" || amount === ""
                        ? "0.00"
                        : "₹ " + Number(cal_tt_charges)?.toFixed(2)}
                    </Text>
                  </div>
                  <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
                    <Text
                      className="text-black-900_8c text-xs w-auto"
                      size="txtInterRegular12Black9008c"
                    >
                      TCS
                    </Text>
                    <Text
                      className="text-base text-black-900_8c text-right w-[146px]"
                      size="txtInterRegular16Black9008c"
                    >
                      {tcs == "" || amount === ""
                        ? "0.00"
                        : "₹ " + Number(tcs)?.toFixed(2)}
                    </Text>
                  </div>
                </div>
              </div>

              <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
                <Text
                  className="text-base text-black-900_01 w-auto"
                  size="txtInterBold16Black90001"
                >
                  Estimated Total
                </Text>
                <Text
                  className="text-base text-blue-700_01 text-right w-[142px]"
                  size="txtInterBold16Blue70001"
                >
                  {cal_total_payable == "" || amount === ""
                    ? "0.00"
                    : "₹ " +
                    formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2))}
                </Text>
              </div>
              <div className="hidden flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                <Text
                  className="text-base text-black-900_01 w-auto"
                  size="txtInterBold16Black90001"
                >
                  Customer Rate
                </Text>
                <Text
                  className="text-base text-blue-700_01 text-right w-[142px]"
                  size="txtInterBold16Blue70001"
                >
                  {cal_total_payable == "" || amount === ""
                    ? "0.00"
                    : "₹ " +
                    formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(4))}
                </Text>
              </div>
              <Text
                className="leading-[28.00px] max-w-[342px] md:max-w-full text-base text-black-900_8c"
              // size="txtInterBold16Black9008c"
              >
                <span className="text-black-900_8c font-inter text-left font-bold inline">
                  Important:{" "}
                  <Text className="text-black-900_8c font-inter text-sm font-semibold inline">
                    The above amount is an estimate and may change based on real time
                    fx rates and remitter's current FY transactions.
                  </Text>
                  <Text className='text-black-900_8c font-inter text-sm font-semibold inline'>
                    Decimal are rounded off.
                  </Text>
                </span>
              </Text>
              <div className="flex flex-row w-full">

                <Button
                  onClick={handleCreateDeal}
                  className="text-center common-pointer bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-full px-5">
                  Create Deals
                </Button>
              </div>
            </div> */}
          </div>
        </div>
        <div className=" hidden bg-white-A700 bottom-[0] flex gap-[33px] h-[73px] md:h-auto items-center justify-end md:px-5 px-[83px] py-[27px] shadow-bs1 sticky w-full">
          <div className="flex flex-row flex-wrap gap-5 items-center justify-center sm:items-end">
            {/* <Text
            className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
            size="txtInterRegular16Black9007f"
            onClick={() => { setConfirmCancel(true) }}
          >
            Cancel
          </Text> */}
            {/* <Button
            disabled={purpose_id == '' || country == '' || amount == ''}
            className="common-pointer bg-white-A700 border border-1.5 border-[#165FE3] border-solid cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-[#165FE3] w-auto px-5"
          // onClick={()=>{setCustomerDetailsPopup(true)}}
          >
            <div className="flex flex-row gap-[10px] items-center "
            >

              <Img
                className="w[16px] h-[16px] w-[16px] text-[#7E7E7E] fill-current "
                src="images/share.svg"
                alt="cell"
              />
              Share
            </div>
          </Button>           */}
          </div>
        </div>
        <Toaster theme="dark" position='top-right' />
      </div>
    </>
  );
};

export default CreateRemittanceQuotation;
