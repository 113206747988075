import { boolean } from "yup";
import { apis } from "../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";
const staff_channel = `${BASE_URL}:MhhkaM6y/channels`;
const staff_purpose = `${BASE_URL}:W9rFQOtq/purpose`;
const get_country = `${BASE_URL}:S0YxmlVR/currency`;
const staff_deal = `${BASE_URL}:7K7mNQ3j/deal`;
const staff_deal_calc = `${BASE_URL}:7K7mNQ3j/deal-calc`;
const staff_deal_calc_new = `${BASE_URL}:7K7mNQ3j/deal-calc-v3`;
const service_partners = `${BASE_URL}:6JkkZBcl/service_partners`;

// get channel/ partner
export type GetChannelRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetChannelResponseType = Partial<{
  items: {
    approval_status: string;
    status: string;
    id: number;
    name: string;
  }[];
}>;

export const getChannel = (
  payload: AxiosRequestConfig<GetChannelRequestType>,
): AxiosPromise<GetChannelResponseType> => apis.get(staff_channel, payload);

// get country
export type GetCountryRequestType = Partial<{}>;

export type GetCountryResponseType = {
  country: string;
  currency: string;
};
export const getCountry = (
  payload: AxiosRequestConfig<GetCountryRequestType>,
): AxiosPromise<GetCountryResponseType[]> => apis.get(get_country, payload);

// get purpose
export type GetPurposeRequestType = Partial<{
  headers: { Authorization: string };
  product_id: number;
}>;
export type GetPurposeResponseType = {
  name: string;
  id: number;
};
export const getPurpose = (
  payload: AxiosRequestConfig<GetPurposeRequestType>,
): AxiosPromise<GetPurposeResponseType[]> => apis.get(staff_purpose, payload);

// post deal calc

export type PostDealCalcRequestType = Partial<{
  headers: { Authorization: string };
  purpose_id: number;
  customers_id: number;
  currency: string;
  country: string;
  amount: number;
  type: string;
  created_by: string;
  tcs_type: number;
  discount: number;
  addon_rates: number;
  mail: string;
  service_partner_id: string | number;
  partner_margin: string;
  partner_walkin_margin: string;
  fx_charges: string;
}>;

export type PostDealCalcResponseType = {
  payable_amount: string;
  base_amount: string;
  chain: {
    l1: string;
    l2: string;
    l3: string;
    branch: string;
  };
  customer_rate: string;
  tcs: string;
  gst: string;

  commission: {
    l1: number;
    l2: number;
    l3: number;
  };

  cumulative_commission: {
    l1: number;
    l2: number;
    l3: number;
  };

  cumulative_commission_without_addon: {
    l1: number;
    l2: number;
    l3: number;
  };

  profit: {
    l1: string;
    l2: string;
    l3: string;
  };

  tt_charges: number;
  foreign_bank_charges: number;
  tt_charges_with_gst: number;
  foreign_bank_charges_with_gst: number;
  discloser: string;
  settlement_rates: string;
  service_partner: string;
  service_partner_mode: string;
};

export const postDealCalc = (
  payload: AxiosRequestConfig<PostDealCalcRequestType>,
): AxiosPromise<PostDealCalcResponseType> =>
  apis.post(staff_deal_calc, payload);

export const postDealCalcNew = (
  payload: AxiosRequestConfig<PostDealCalcRequestType>,
): AxiosPromise<PostDealCalcResponseType> =>
  apis.post(staff_deal_calc_new, payload);

// post create deal
export type PostCreateDealRequestType = Partial<{
  headers: { Authorization: string };
  purpose_id: number;
  customers_id: number;
  currency: string;
  country: string;
  amount: number;
  type: string;
  created_by: string;
  tcs_type: number;
  discount: number;
  addon_rates: number;
  mail: string;
  walkin_order: boolean;
  partner_margin: string;
  partner_walkin_margin: string;
}>;

export type PostCreatDealResponseType = {
  discloser: string;
  tcs: string;
  gst: number;
  total_charges: number;
  tt_charges: number;
  l1_profit: string;
  l2_profit: string;
  l3_profit: string;
  customer_effective_amount: string;
  payable_amount: string;
  foreign_bank_charges: string;
  eforex_rates: string;
  ibr_rates: string;
  l1_absolute_commission_rates: number;
  l2_absolute_commission_rates: number;
  l3_absolute_commission_rates: number;
  customer_rates: string;
  deals: {
    id: number;
    deal_id: string;
  };
  payment: string;
};

export const postCreateDeal = (
  payload: AxiosRequestConfig<PostCreateDealRequestType>,
): AxiosPromise<PostCreatDealResponseType> => apis.post(staff_deal, payload);

// get service partners supporting transaction
export type GetServicePartnersRequestType = Partial<{
  headers: { Authorization: string };
  purpose_id: string;
  currency: string;
}>;
export type GetServicePartnerseResponseType = {
  id: string;
  display_name: string;
  mode: string;
  tt_charges: string | number;
  foreign_bank_charges: string | number;
  preference: string | number;
  _service_partner_commission_of_service_partner: {
    does_remittance: boolean;
    settlement_rate: string | number;
    purpose_code: string;
  };
};
export const getServicePartners = (
  payload: AxiosRequestConfig<GetServicePartnersRequestType>,
): AxiosPromise<GetServicePartnerseResponseType[]> =>
  apis.get(service_partners, payload);
