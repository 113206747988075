import React, { useRef } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import Footer1 from "components/Footer1";
import { Spinner } from "components";
import Cancel from "models/Cancel";
import { createForexQuotationSteps, formatIndianNumber } from "../../utils";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import Step1 from "pages/CreateForexQuotation/step1";
import Step2 from "pages/CreateForexQuotation/step2";
import Step3 from "pages/CreateForexQuotation/step3";
import Step4 from "pages/CreateForexQuotation/step4";
import { useNavigate } from "react-router-dom";
import {
  setName,
  setPassportNumber,
  setEmail,
  setMobileNumber,
  setProducts,
  setShowError,
  setCharges,
  setAction,
  setGrandTotal,
  setPdfurl,
} from "../../redux/features/CreateForexQuotation";
import { toast, Toaster } from "sonner";
import "react-toastify/dist/ReactToastify.css";
import { Button, Img, Input, SelectBox, Text } from "components";
import Print from "pages/CreateForexQuotation/print";
import backsvg from "../../assets/images/icon_back.svg";
import pdfsvg from "../../assets/images/icon_pdf.svg";
import imgsvg from "../../assets/images/icon_img.svg";
import mailsvg from "../../assets/images/icon_mail.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  GetInfoRequestType,
  GetPDFURLRequestType,
  getInfo,
  getPDFURL,
} from "service/apis/CreateForexQuotation";
import { AxiosRequestConfig } from "axios";
import Navbar from "components/Navbar/Index";
import Stepper from "components/Stepper/newstepper";

const CreateForexQuotation: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [confirmCancel, setConfirmCancel] = React.useState<boolean>(false);
  const [pdfData, setPdfData] = React.useState<any>({});
  const [partnerName, setPartnerName] = React.useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const childRef = useRef(null);

  const {
    name,
    product_type,
    passport_number,
    mobile_number,
    email,
    showError,
    action,
    products,
    charges,
    totalGST,
    grandTotal,
    pdf_url,
  } = useAppSelector((state) => state.CreateForexQuotation);

  React.useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetInfoRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    getInfo(payload)
      .then((res) => {
        setPartnerName(res?.data?.name);
      })
      .catch((err) => { });
  };

  async function handleNext() {
    if (createForexQuotationSteps?.length === currentStep) {
      return;
    }
    if (currentStep === 1) {
      if (name === "" || product_type == "") {
        dispatch(setShowError(true));
        toast.error("Please fill all required fields");
      } else {
        dispatch(setShowError(false));
        setCurrentStep(currentStep + 1);
      }
    } else if (currentStep === 2) {
      if (products.length < 1) {
        toast.error("Please Add Product");
      } else {
        try {
          // await checkProductProperties();
          setCurrentStep(currentStep + 1);
          getPdfUrlFunction();
        } catch (err) {
          toast.error(err.message);
        }
      }
    } else if (currentStep === 3) {
      if (charges.length < 1) {
        toast.error("Please Add Charges");
      } else {
        try {
          // await checkChargesProperties();
          setCurrentStep(currentStep + 1);
          getPdfUrlFunction();
        } catch (err) {
          toast.error(err.message);
        }
      }
    } else if (currentStep === 4) {
      navigate("/");
    }
  }

  const handlePrevious = () => {
    if (currentStep - 1 < 1) {
      navigate("/");
    }
    setCurrentStep(currentStep - 1);
  };

  const handleCancel = () => {
    dispatch(setName(""));
    dispatch(setPassportNumber(""));
    dispatch(setEmail(""));
    dispatch(setMobileNumber(""));
    dispatch(setPdfurl(""));
    dispatch(setShowError(false));
    dispatch(setProducts([]));
    dispatch(setCharges([]));
    dispatch(setAction(""));
    dispatch(setGrandTotal(0));
    navigate("/");
  };

  const getPdfUrlFunction = () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const grand_total = products.reduce(
      (sum, obj) => sum + Number(obj.total),
      0
    );
    const total_charges = products.reduce(
      (sum, obj) => sum + Number(obj.charges) * (obj.gst ? 1 : 1.18),
      0
    );
    const gst_total = products.reduce(
      (sum, obj) =>
        sum +
        Number(
          obj.gst
            ? (Number(obj.charges) / 1.18) * 0.18
            : Number(Number(obj.charges) * 0.18)
        ),
      0
    );
    const service_charges = products.reduce(
      (sum, obj) => sum + Number(obj.charges) / (obj.gst ? 1.18 : 1),
      0
    );

    const product_total = products.reduce(
      (sum, obj) => sum + Number(obj.qty) * Number(obj.rate),
      0
    );

    const productsData = products.map((item) => {
      return {
        product_name: item.product,
        product_currency: item.currency,
        product_quantity: Number(item.qty),
        product_action: product_type,
        product_rate: Number(item.rate)?.toFixed(2),
        product_gst: 0,
        product_service_charge: Number(item.charges) * (item.gst ? 1 : 1.18),
        product_total: Number(item.total)?.toFixed(2),
      };
    });

    const todayDate = new Date();
    // Calculate the date after 7 days
    const today = new Date(todayDate);
    today.setDate(today.getDate());

    const laterDate = new Date(todayDate);
    laterDate.setDate(today.getDate() + 2);

    // Format the date in "dd/mm/yy" format
    const day = String(today.getDate()).padStart(2, "0");
    const laterDay = String(laterDate.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const laterMonth = String(laterDate.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear()).slice(-2);
    const laterYear = String(laterDate.getFullYear()).slice(-2);

    const formattedDate = `${day}-${month}-${year}`;
    const endDate = `${laterDay}-${laterMonth}-${laterYear}`;

    const updatedProducts = products.map((item) => {
      return {
        product_name: item.product,
        product_qty: item.currency + " " + formatIndianNumber(item.qty),
        product_rate: Number(item.rate),
        product_gst: 0,
        product_service_charge: Number(item.charges) * (item.gst ? 1 : 1.18),
        product_total: formatIndianNumber(
          (Number(item.qty) * Number(item.rate))?.toFixed(4)
        ),
      };
    });

    const payload: AxiosRequestConfig<GetPDFURLRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        customer_name: name,
        action: product_type,
        email: email,
        passport: passport_number,
        phone_number: mobile_number,
        created_by: partnerName,
        products: productsData,
        data: {
          product_type: product_type,
          customer_name: name,
          customer_email: email,
          customer_passport: passport_number,
          customer_phone_number: mobile_number,
          sub_total: formatIndianNumber(product_total?.toFixed(2)),
          gst_total: formatIndianNumber(
            (Number(gst_total) + Number(totalGST))?.toFixed(2)
          ),
          service_charges_total: formatIndianNumber(
            service_charges?.toFixed(2)
          ),
          grand_total: formatIndianNumber(Number(grandTotal)?.toFixed(0)),
          commission: formatIndianNumber(
            (Number(grandTotal) * 0.02)?.toFixed(2)
          ),
          commission_date: endDate,
          products: updatedProducts,
          date: formattedDate,
          name: partnerName,
          total: total_charges,
          service_charges: service_charges,
          gst: total_charges - service_charges,
          subTotal: product_total,
        },
      },
    };

    setLoading(true);
    getPDFURL(payload)
      .then((res) => {
        setLoading(false);
        dispatch(setPdfurl(res?.data));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const setChildRef = () => {
    const child = childRef.current;
    child.id = "print";
  };

  function checkProductProperties() {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < products.length; i++) {
        const currentObject = products[i];

        if (
          currentObject.id === null ||
          currentObject.id === "" ||
          currentObject.product === null ||
          currentObject.product === "" ||
          currentObject.qty === null ||
          currentObject.qty === "" ||
          currentObject.rate === null ||
          currentObject.rate === "" ||
          currentObject.currency === null ||
          currentObject.currency === ""
        ) {
          reject(new Error("Missing Value in Products Table"));
          return;
        }
      }

      resolve("Successful"); // No missing property values found in any object
    });
  }

  function checkChargesProperties() {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < charges.length; i++) {
        const currentObject = charges[i];

        if (
          currentObject.id === null ||
          currentObject.id === "" ||
          currentObject.type === null ||
          currentObject.type === "" ||
          currentObject.amount === null ||
          currentObject.amount === "" ||
          currentObject.gst === null
        ) {
          reject(new Error("Missing Value in Charges Table"));
          return;
        }
      }
      resolve("Successful");
    });
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step4 handleCancel={handleCancel} downloadPDFfromUrl={downloadPDFfromUrl} />;
      default:
        return null;
    }
  };

  const base64toBlob = (data: string) => {
    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  const downloadPDFfromUrl = async () => {
    try {
      const response = await fetch(pdf_url);
      const blob = await response.blob();

      // const blob = base64toBlob(pdf_url);
      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Forex Quotation.pdf";

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // // Clean up: remove the link
      document.body.removeChild(link);
    } catch (error) {
      // console.error('Error downloading PDF:', error);
    }
  };

  const downloadImage = () => {
    const contentToCapture = document.getElementById("print");

    if (contentToCapture != null) {
      contentToCapture.style.width = "1300px"; // Set your preferred width
      contentToCapture.style.height = "auto";

      // Use html2canvas to capture the content and draw it on the canvas
      html2canvas(contentToCapture, {
        width: 1300,
        scale: 1,
        scrollY: -window.scrollY,
      }).then(function (canvas) {
        // Convert the canvas to an image
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "forex_quotation.png";
        link.click();
      });
    } else {
      return;
    }
  };

  return (
    <>
      {loading && <Spinner />}

      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title="Are You Sure You Want To Cancel This Forex Quotation?"
            setConfirmCancel={setConfirmCancel}
            handleCancel={handleCancel}
          />{" "}
        </div>
      )}

      <div className="flex w-full hidden sm:block bg-[#ffffff] px-[12px] py-[10px]">
        <div className="flex flex-row gap-[15px]">
          <div
            className="flex items-center justify-center"
            onClick={handlePrevious}
          >
            <Img
              src={backsvg}
              width={12}
              height={12}
              alt="Back"
              className="object-cover"
            />
          </div>
          <div className="flex flex-row w-full h-full flex-1">
            <div className="flex flex-col gap-[5px] w-[80%] h-full">
              <Text
                className="text-black-900_01 w-auto text-[16px]"
                size="txtRubikRomanRegular20"
              >
                Quotation
              </Text>
              <div className="flex flex-row gap-[12px] items-center">
                <span className="bg-[#165FE3] text-[#ffffff] px-[2px] py-[1px] rounded-sm text-sm text-center">
                  <Text
                    className="text-[#ffffff] w-auto text-[12px]"
                    size="txtRubikRomanRegular12"
                  >
                    {currentStep}/3
                  </Text>
                </span>
                <Text
                  className="text-blue-700_01 w-auto text-[12px]"
                  size="txtInterRegular12"
                >
                  {createForexQuotationSteps[currentStep - 1].name}
                </Text>
              </div>
            </div>
            <div className="flex flex-col pt-[7px] gap-[4px] justify-end items-end flex-1 w-full h-full">
              <Text
                className="text-blue-700_01 w-auto text-[16px]"
                size="txtInterMedium16Blue70001"
              >
                {formatIndianNumber(Number(grandTotal).toFixed(0))}
              </Text>
              <Text
                className="text-blue_gray_900_60 w-auto text-[8px]"
                size="txtInterMedium16Black900"
              >
                Grand Total
              </Text>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 bg-gray-100 flex flex-col h-screen font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="flex md:flex-col flex-col gap-4 md:gap-5 items-start justify-start w-full">
          <Navbar>
            <div className=" w-full flex justify-between">
              <div className=" flex gap-3 items-center">
                <Img
                  src="images/sidebar_img/remit.svg"
                  className="h-5"
                  alt=""
                />
                <div className="gap-2 flex flex-col">
                  <span className="text-sm font-semibold">
                    Forex Quotation
                  </span>
                  <span className="text-xs font-normal text-[#999999]">
                    Create the forex quotation
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="gap-2.5 flex items-center">
                  <Img src="images/overview/wrench.svg" alt="" />
                  <span className="text-sm font-semibold text-blue-700">
                    Help
                  </span>
                </div>
                <AlertDialog>
                  <AlertDialogTrigger>
                    <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                      Cancel
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                      <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                          This action cannot be undone. This will permanently
                          delete your progress and remove the actions preformed.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                          className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                          asChild
                        >
                          <button>Close</button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                          className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                          asChild
                        >
                          <button className="" onClick={handleCancel}>
                            Continue
                          </button>
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogPortal>
                </AlertDialog>
              </div>
            </div>
          </Navbar>
          <div className="gap-5 w-full flex h-full flex-1 flex-grow">
            <div className=" bg-white-A700 p-5 rounded-xl flex flex-col gap-5 w-full md:min-h-[calc(100vh-100px)]">
              <Stepper
                steps={createForexQuotationSteps.map((val) => val.name)}
                currentStep={currentStep - 1}
              />
              {renderStep()}
              {currentStep !== 3 && (
                <div className="flex gap-3">
                  {currentStep !== 1 && (
                    <button
                      type="button"
                      onClick={handlePrevious}
                      className={`px-4 py-[11px] w-fit rounded-lg border border-blue-700 text-blue-700 ${true ? "" : "opacity-40"}`}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={handleNext}
                    className={`px-4 py-[11px] w-fit rounded-lg bg-blue-700 text-white-A700 ${true ? "" : "opacity-40"}`}
                  >
                    {currentStep === createForexQuotationSteps.length - 1
                      ? "Submit"
                      : "Next"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>        
      </div>
      <Toaster theme="dark" position='top-right' />
    </>
  );
};

export default CreateForexQuotation;
