import { apis } from "../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const CUSTOMER_REGISTRATION_API_URLS = {
  POST_CUSTOMER_REGISTRATION_Step1: `${BASE_URL}:NcXLAC78/customers-step-1`,
  POST_CUSTOMER_REGISTRATION_Step1VerifyOTP: `${BASE_URL}:NcXLAC78/customers-verify-otp`,
  POST_CUSTOMER_REGISTRATION_Step2: `${BASE_URL}:NcXLAC78/customers-step-2`,
  POST_PAN_VERIFICATION_STEP_2_0: `${BASE_URL}:NcXLAC78/customers-step-2_0`,
  POST_CUSTOMER_REGISTRATION_Step3: `${BASE_URL}:NcXLAC78/customers-step-3-without-verification`,
  POST_CUSTOMER_REGISTRATION_Step3VerifyOTP: `${BASE_URL}:NcXLAC78/customers-verify-aadhar-otp`,
  POST_CUSTOMER_REGISTRATION_Step4: `${BASE_URL}:NcXLAC78/customers-step-4`,
} as const;

// Customer Registration Step 1 API
export type PostCustomerRegistrationStep1RequestType = Partial<{
  email: string;
  phone_number: string;
  passport_no: string;
  headers: { Authorization: string };
}>;
export type PostCustomerRegistrationStep1ResponseType = {
  id: string;
};
export const postCustomerRegistrationStep1 = (
  payload: AxiosRequestConfig<PostCustomerRegistrationStep1RequestType>,
): AxiosPromise<PostCustomerRegistrationStep1ResponseType> =>
  apis.post(
    CUSTOMER_REGISTRATION_API_URLS.POST_CUSTOMER_REGISTRATION_Step1,
    payload,
  );

// Customer Registration Step 1 verify OTP
export type PostCustomerRegistrationStep1VerifyOTPRequestType = Partial<{
  customers_id: string | number;
  otp: string;
  type: string;
  headers: { Authorization: string };
}>;
export type PostCustomerRegistrationStep1VerifyOTPResponseType = Partial<{}>;
export const postCustomerRegistrationStep1VerifyOTP = (
  payload: AxiosRequestConfig<PostCustomerRegistrationStep1VerifyOTPRequestType>,
): AxiosPromise<PostCustomerRegistrationStep1VerifyOTPResponseType> =>
  apis.post(
    CUSTOMER_REGISTRATION_API_URLS.POST_CUSTOMER_REGISTRATION_Step1VerifyOTP,
    payload,
  );

// Customer Registration Step 2 API
export type PostCustomerRegistrationStep2RequestType = Partial<{
  customers_id: string | number;
  pan_no: string;
  pan_card: string;
  headers: { Authorization: string };
}>;
export type PostCustomerRegistrationStep2ResponseType = Partial<{}>;
export const postCustomerRegistrationStep2 = (
  payload: AxiosRequestConfig<PostCustomerRegistrationStep2RequestType>,
): AxiosPromise<PostCustomerRegistrationStep2ResponseType> =>
  apis.post(
    CUSTOMER_REGISTRATION_API_URLS.POST_CUSTOMER_REGISTRATION_Step2,
    payload,
  );

export const POSTPanVerify = (
  payload: AxiosRequestConfig<PostCustomerRegistrationStep2RequestType>,
): AxiosPromise<PostCustomerRegistrationStep2ResponseType> =>
  apis.post(
    CUSTOMER_REGISTRATION_API_URLS.POST_PAN_VERIFICATION_STEP_2_0,
    payload,
  );

// Customer Registration Step 3 API
export type PostCustomerRegistrationStep3RequestType = Partial<{
  customers_id: string | number;
  aadhar_number: string;
  aadhar_front: string;
  aadhar_back: string;
  headers: { Authorization: string };
}>;

export type PostCustomerRegistrationStep3ResponseType = {
  data: {
    requestId: string;
  };
};

export const postCustomerRegistrationStep3 = (
  payload: AxiosRequestConfig<PostCustomerRegistrationStep3RequestType>,
): AxiosPromise<PostCustomerRegistrationStep3ResponseType> =>
  apis.post(
    CUSTOMER_REGISTRATION_API_URLS.POST_CUSTOMER_REGISTRATION_Step3,
    payload,
  );

// Customer Registration Step 3 verify OTP
export type PostCustomerRegistrationStep3VerifyOTPRequestType = Partial<{
  customers_id: string | number;
  otp: string;
  request_id: string;
  headers: { Authorization: string };
}>;

export type PostCustomerRegistrationStep3VerifyOTPResponseType = Partial<{}>;
export const postCustomerRegistrationStep3VerifyOTP = (
  payload: AxiosRequestConfig<PostCustomerRegistrationStep3VerifyOTPRequestType>,
): AxiosPromise<PostCustomerRegistrationStep3VerifyOTPResponseType> =>
  apis.post(
    CUSTOMER_REGISTRATION_API_URLS.POST_CUSTOMER_REGISTRATION_Step3VerifyOTP,
    payload,
  );

// Customer Registration Step 4 API

export type PostCustomerRegistrationStep4RequestType = Partial<{
  customers_id: string | number;
  bank_account_no: string;
  bank_name: string;
  ifsc_code: string;
  cancel_cheque: string;
  headers: { Authorization: string };
}>;

export type PostCustomerRegistrationStep4ResponseType = {
  id: string;
  status: string;
};

export const postCustomerRegistrationStep4 = (
  payload: AxiosRequestConfig<PostCustomerRegistrationStep4RequestType>,
): AxiosPromise<PostCustomerRegistrationStep4ResponseType> =>
  apis.post(
    CUSTOMER_REGISTRATION_API_URLS.POST_CUSTOMER_REGISTRATION_Step4,
    payload,
  );
