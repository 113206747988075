import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useCallback, useEffect, useState } from "react";
import { addPaymentDetails, setIsOtherLrsDeclare } from "redux/features/Redirect Portal";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Img } from "components";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { setConstantValue } from "typescript";
import { set } from "lodash";

const defaultValues = {
    date_of_transaction: "",
    amount_transferred: "",
    proof_of_transaction: undefined,
    proof_of_transaction_binary: "",
}

const formSchema = z.object({
    date_of_transaction: z.string().min(1, "Date of transaction is required"),
    amount_transferred: z.string().min(1, "Amount transferred is required"),
    proof_of_transaction: z
        .instanceof(File)
        .nullable()
        .refine((file) => file !== null, "Proof of transaction is required"),
    proof_of_transaction_binary: z.string(),
});

type FormSchema = z.infer<typeof formSchema>;

export default function PreviousTransaction() {
    const { is_other_lrs_declare, pan_no, payment_details,pan_found,lrs_amount } = useAppSelector(state => state.CreateRedirectPortal)
    const [isOpen, setIsOpen] = useState(false)
    const [submitType, setSubmitType] = useState<"add" | "edit">("add")
    const [editingItem, setEditingItem] = useState<{id: string, amount: string, date: string, document: string } | null>(null)
    const { register, reset, formState: { errors, isValid }, getValues, handleSubmit, setValue } = useForm<FormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            ...defaultValues,
            proof_of_transaction: undefined,
        },
        mode: "onChange"
    })
    const dispatch = useAppDispatch()


    const onSubmit = (data: FormSchema) => {
        if(submitType === "add") {
            console.log(data);
            dispatch(addPaymentDetails([...payment_details,{ id: (payment_details.length + 1).toString(), amount: data.amount_transferred, date: data.date_of_transaction, document: data.proof_of_transaction.name, document_binary: data.proof_of_transaction_binary }]))
            reset(defaultValues);
            setIsOpen(false);
        }
        else {
            const updatedPaymentDetails = payment_details.map((payment) => {
                if(payment.id === editingItem?.id) {
                    return {
                        id: payment.id,
                        amount: data.amount_transferred,
                        date: data.date_of_transaction,
                        document: data.proof_of_transaction.name,
                        document_binary: data.proof_of_transaction_binary
                    }
                }
                return payment
            })
            dispatch(addPaymentDetails(updatedPaymentDetails))
            reset(defaultValues);
            setIsOpen(false);
        }
    };

    const handleAddDeclaration = handleSubmit(onSubmit)

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            reset({
                ...getValues(),
                proof_of_transaction: file,
            });
            const reader = new FileReader();
            reader.onload = () => {
                const binaryStr = reader.result;
                if (typeof binaryStr === "string") {
                    reset({
                        ...getValues(),
                        proof_of_transaction_binary: binaryStr,
                    });
                }
            };
            reader.readAsDataURL(file);
        }
    }

    const handleEditing = (item:{id: string, amount: string, date: string, document: string }) => {  
        setSubmitType("edit")
        setEditingItem(item)      
        setValue("date_of_transaction", item.date);
        setValue("amount_transferred", item.amount);
        setIsOpen(true)
    }

    return (
        <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-5">
                <div className="py-2 px-4 border text-sm font-normal border-blue-700 rounded-lg w-fit">
                    Related to <span className="text-blue-700 text-base font-medium">{pan_no ? pan_no : "EKYFG67IK"}</span> in current FY.
                </div>
                {
                    pan_found &&
                    <div className="flex gap-2 items-center ">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.39844 0.59375C6.03251 0.59375 4.69726 0.998794 3.56153 1.75766C2.42581 2.51653 1.54062 3.59514 1.0179 4.85709C0.49518 6.11904 0.358413 7.50766 0.624892 8.84734C0.891371 10.187 1.54913 11.4176 2.51498 12.3835C3.48084 13.3493 4.71142 14.0071 6.0511 14.2735C7.39078 14.54 8.7794 14.4033 10.0413 13.8805C11.3033 13.3578 12.3819 12.4726 13.1408 11.3369C13.8996 10.2012 14.3047 8.86593 14.3047 7.5C14.3028 5.66894 13.5745 3.91343 12.2798 2.61868C10.985 1.32393 9.2295 0.595684 7.39844 0.59375ZM7.13282 3.78125C7.29042 3.78125 7.44449 3.82799 7.57554 3.91555C7.70658 4.00311 7.80872 4.12756 7.86903 4.27317C7.92935 4.41878 7.94513 4.57901 7.91438 4.73359C7.88363 4.88817 7.80774 5.03016 7.69629 5.1416C7.58485 5.25305 7.44286 5.32894 7.28828 5.35969C7.1337 5.39044 6.97347 5.37466 6.82786 5.31434C6.68225 5.25403 6.5578 5.15189 6.47024 5.02084C6.38268 4.8898 6.33594 4.73573 6.33594 4.57812C6.33594 4.36678 6.4199 4.16409 6.56934 4.01465C6.71878 3.86521 6.92147 3.78125 7.13282 3.78125ZM7.92969 11.2188C7.6479 11.2188 7.37765 11.1068 7.17839 10.9076C6.97913 10.7083 6.86719 10.438 6.86719 10.1562V7.5C6.72629 7.5 6.59117 7.44403 6.49154 7.3444C6.39191 7.24477 6.33594 7.10965 6.33594 6.96875C6.33594 6.82785 6.39191 6.69273 6.49154 6.5931C6.59117 6.49347 6.72629 6.4375 6.86719 6.4375C7.14898 6.4375 7.41923 6.54944 7.61849 6.7487C7.81775 6.94796 7.92969 7.21821 7.92969 7.5V10.1562C8.07059 10.1562 8.20571 10.2122 8.30534 10.3118C8.40497 10.4115 8.46094 10.5466 8.46094 10.6875C8.46094 10.8284 8.40497 10.9635 8.30534 11.0632C8.20571 11.1628 8.07059 11.2188 7.92969 11.2188Z" fill="#165FE3" />
                        </svg>
                        <small>We have found LRS Transactions of</small>
                        <small>INR</small>
                        <small>{lrs_amount}</small>
                    </div>
                }
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start">
                <p className="text-sm font-medium text-black-label">Are there any other LRS Transactions that you want to declare ? </p>
                <RadioGroup
                    className="flex"
                    defaultValue="no"
                    value={is_other_lrs_declare}
                    onValueChange={useCallback((value: "yes" | "no") => {
                        if(value === "no") {
                            dispatch(addPaymentDetails([]))
                        }
                        dispatch(setIsOtherLrsDeclare(value))
                    }, [])}
                >
                    <div
                        className={`${is_other_lrs_declare === "yes" ? " border-blue-700" : ""} flex items-center w-full rounded-lg border p-3 space-x-2`}
                    >
                        <RadioGroupItem
                            value="yes"
                            id="yes"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                        />
                        <label className=" text-sm font-medium tracking-[-0.01em]" htmlFor="yes">Yes</label>
                    </div>
                    <div
                        className={`${is_other_lrs_declare === "no" ? "border-blue-700" : ""} flex flex-nowrap w-full items-center rounded-lg border p-3 space-x-2`}
                    >
                        <RadioGroupItem value="no" id="no" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2" />
                        <label className=" text-sm font-medium tracking-[-0.01em]" htmlFor="no">No</label>
                    </div>
                </RadioGroup>
            </div>
            <div className="flex flex-col gap-8">
                <div className="py-2 px-3 bg-black-900 rounded-lg flex justify-between w-full">
                    <div className="flex gap-2">
                        <p className="text-white-A700 ">Total Previous Transactions</p>
                    </div>
                    <div className="flex gap-3 items-center">
                        <span className="font-medium text-[18px] text-white-A700 leading-[14px]">
                            333
                        </span>
                        <p className="py-[3px] text-sm leading-4 font-medium bg-white-A700_99 px-2 text-center rounded-md">
                            INR
                        </p>
                    </div>
                </div>
                {
                    is_other_lrs_declare === "yes" &&
                    <div className="flex w-full flex-col gap-4 rounded-xl border p-3">
                        <div className="flex justify-between items-center">
                            <h3>LRS transactions in current FY</h3>
                            <AlertDialog open={isOpen} onOpenChange={e => {
                                if(!e) {
                                    setEditingItem(null)
                                }
                                setIsOpen(e)
                                setSubmitType("add")
                            }}>
                                <AlertDialogTrigger>
                                    <button className="text-white-A700 bg-blue-700 rounded-lg py-2.5 px-4">Add Transaction</button>
                                </AlertDialogTrigger>
                                <AlertDialogPortal>
                                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                                        <AlertDialogHeader className="flex text-left gap-10 py-6 px-10 ">
                                            <AlertDialogTitle className="font-bold gap-2 flex flex-col text-xl leading-7 tracking-[2%] text-black-label">
                                                Add Payment
                                                <small className="text-gray-500 text-xs">
                                                    Fill the required details
                                                </small>
                                            </AlertDialogTitle>
                                            <AlertDialogDescription className="sm:flex sm:flex-col grid gap-8 grid-cols-2">
                                                <div className="flex gap-2 flex-shrink flex-col w-full">
                                                    <label
                                                        htmlFor="date_of_transaction"
                                                        className=" text-sm font-medium text-[#2D3648]"
                                                    >
                                                        Date of Transaction
                                                    </label>
                                                    <Input
                                                        type="date"
                                                        id="date_of_transaction"
                                                        {...register("date_of_transaction", {
                                                            required: "This field is required",
                                                        })}
                                                    />
                                                    {errors.date_of_transaction && (
                                                        <p className="text-red-500 text-sm">{errors.date_of_transaction.message}</p>
                                                    )}
                                                </div>
                                                <div className="flex gap-2 flex-col w-full">
                                                    <label
                                                        htmlFor="amount_transferred"
                                                        className=" text-sm font-medium text-[#2D3648]"
                                                    >
                                                        Amount Transferred
                                                    </label>
                                                    <Input
                                                        type="number"
                                                        id="amount_transferred"
                                                        {...register("amount_transferred", {
                                                            required: "This field is required",
                                                        })}
                                                        placeholder="USD"
                                                    />
                                                    {errors.amount_transferred && (
                                                        <p className="text-red-500 text-sm">{errors.amount_transferred.message}</p>
                                                    )}
                                                </div>
                                                <div className="flex gap-2 flex-1 flex-grow flex-col w-full">
                                                    <h3 className=" text-sm font-medium text-[#2D3648]">
                                                        Upload Proof of Transaction
                                                    </h3>
                                                    <div
                                                        className="py-1.5 px-3.5 justify-between rounded-md flex bg-[#F2F7FF]"
                                                    >
                                                        <div className="flex gap-3 items-center overflow-hidden">
                                                            <Img src="/images/remit/file.svg" alt="" />
                                                            <p className="truncate">{getValues().proof_of_transaction?.name || "No file choosen"}</p>
                                                        </div>
                                                        <label
                                                            htmlFor="proof_of_transaction"
                                                            className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                                                        >
                                                            {getValues("proof_of_transaction")?.name ? "Re-Upload" : "Upload"}
                                                        </label>
                                                        <input
                                                            {...register("proof_of_transaction", {
                                                                required: "This field is required",
                                                            })}
                                                            onChange={handleFileChange}
                                                            type="file"
                                                            id="proof_of_transaction"
                                                            className="hidden"
                                                            accept="image/*"
                                                        />
                                                        {errors.proof_of_transaction && (
                                                            <p className="text-red-500 text-sm">{errors.proof_of_transaction.message}</p>
                                                        )}
                                                    </div>
                                                    <i className="font-normal text-xs text-[#999999]">For e.g - SWIFT Copy, Invoice</i>
                                                </div>
                                            </AlertDialogDescription>
                                            <small className="text-[#666666] text-sm font-normal">Please ensure to add each transaction individually.</small>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                                            <AlertDialogCancel
                                                className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"

                                            >
                                                <button onClick={() => reset(defaultValues)}>Cancel</button>
                                            </AlertDialogCancel>
                                            <AlertDialogAction
                                                asChild
                                                className={`rounded-lg border-blue-700 py-[8px] px-4 bg-blue-700 border text-white-A700 ${isValid ? "" : "opacity-50"}`}
                                            >
                                                <button
                                                    type="button"
                                                    disabled={!isValid}
                                                    onClick={handleAddDeclaration}
                                                >
                                                    Continue
                                                </button>
                                            </AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogPortal>
                            </AlertDialog>
                        </div>
                        {
                            payment_details.length > 0 ?
                                payment_details.map((payment) => (
                                    <div key={payment.id} className="flex w-full flex-col gap-4 py-5 px-3 rounded-xl bg-[#F9F9F9E5] items-center">
                                        <div className="flex justify-between w-full items-center">
                                            <p className="font-medium text-sm text-[#666666]">Date : {payment.date}</p>
                                            <button onClick={()=>handleEditing(payment)} type='submit'  className="font-medium text-sm text-[#666666]">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.64039 8.65252C3.67541 8.56499 3.72784 8.48543 3.79452 8.41879L9.6015 2.61179C10.4173 1.79607 11.7398 1.79607 12.5555 2.6118C13.3712 3.42753 13.3712 4.75009 12.5555 5.56582L6.74852 11.3728C6.68188 11.4395 6.60232 11.4919 6.5148 11.5269L2.47834 13.1415C2.19415 13.2552 1.91213 12.9732 2.02581 12.689L3.64039 8.65252Z" stroke="#666666" strokeWidth="0.852196" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11.829 6.28996L8.875 3.33594" stroke="#666666" strokeWidth="0.852196" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex justify-between w-full items-center">
                                            <p className="font-medium text-sm text-[#666666]">Amount Transferred :</p>
                                            <p className="font-medium text-sm text-[#666666]">{payment.amount}</p>
                                        </div>
                                        <div className="flex justify-between w-full items-center">
                                            <p className="font-medium text-sm text-[#666666]">Proof of transaction :</p>
                                            <p className="font-medium text-sm text-[#666666]">{payment.document}</p>
                                        </div>
                                    </div>
                                ))
                                :
                                <div className="flex gap-2 items-center rounded-lg justify-center h-28 bg-[#FAFAFA]">
                                    <svg
                                        width="15"
                                        height="14"
                                        viewBox="0 0 15 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.25782 0C5.89189 0 4.55664 0.405044 3.42091 1.16391C2.28518 1.92278 1.39999 3.00139 0.877273 4.26334C0.354555 5.52529 0.217788 6.91391 0.484267 8.25359C0.750746 9.59327 1.4085 10.8238 2.37436 11.7897C3.34022 12.7556 4.57079 13.4133 5.91047 13.6798C7.25015 13.9463 8.63877 13.8095 9.90072 13.2868C11.1627 12.7641 12.2413 11.8789 13.0002 10.7432C13.759 9.60743 14.1641 8.27218 14.1641 6.90625C14.1621 5.07519 13.4339 3.31968 12.1391 2.02493C10.8444 0.730175 9.08887 0.00193363 7.25782 0ZM6.99219 3.1875C7.1498 3.1875 7.30386 3.23424 7.43491 3.3218C7.56596 3.40936 7.66809 3.53381 7.72841 3.67942C7.78872 3.82503 7.8045 3.98526 7.77375 4.13984C7.74301 4.29442 7.66711 4.43641 7.55567 4.54785C7.44422 4.6593 7.30223 4.73519 7.14765 4.76594C6.99307 4.79669 6.83285 4.78091 6.68724 4.72059C6.54163 4.66028 6.41717 4.55814 6.32961 4.42709C6.24205 4.29605 6.19532 4.14198 6.19532 3.98438C6.19532 3.77303 6.27927 3.57034 6.42871 3.4209C6.57816 3.27146 6.78085 3.1875 6.99219 3.1875ZM7.78907 10.625C7.50727 10.625 7.23702 10.5131 7.03776 10.3138C6.83851 10.1145 6.72657 9.84429 6.72657 9.5625V6.90625C6.58567 6.90625 6.45054 6.85028 6.35091 6.75065C6.25129 6.65102 6.19532 6.5159 6.19532 6.375C6.19532 6.2341 6.25129 6.09898 6.35091 5.99935C6.45054 5.89972 6.58567 5.84375 6.72657 5.84375C7.00836 5.84375 7.27861 5.95569 7.47787 6.15495C7.67712 6.35421 7.78907 6.62446 7.78907 6.90625V9.5625C7.92996 9.5625 8.06509 9.61847 8.16472 9.7181C8.26435 9.81773 8.32032 9.95285 8.32032 10.0938C8.32032 10.2346 8.26435 10.3698 8.16472 10.4694C8.06509 10.569 7.92996 10.625 7.78907 10.625Z"
                                            fill="#666666"
                                        />
                                    </svg>
                                    <small>Please ensure to add each transaction individually.</small>
                                </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}