import { useLocation, useParams } from "react-router-dom";
import {
  OpportunityContactType,
  OpportunityInteractionType,
} from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { opportunity_status_options } from "common/filters";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import OpportunitiesSheet from "./Sheet";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { resetState } from "redux/features/CreateOpportunity";
import { AxiosRequestConfig } from "axios";
import {
  getCompanyById,
  GetCompanyByIdReqType,
  getContactDetailById,
  getOpportunityByCompany,
  GetOpportunityByCompanyReqType,
  getOpportunityById,
  GetOpportunityByIdReqType,
  postCorporateInteractions,
  PostCorporateInteractionsReqType,
  PutUpdateCompanyDetails,
  PutUpdateCompanyDetailsReqType,
  putUpdateInteractionStatus,
  PutUpdateInteractionStatusReqType,
  putUpdateOpportunityStatus,
  PutUpdateOpportunityStatusReqType,
} from "service/apis/Opportunities";
import ReactSelect, { SingleValue } from "react-select";
import { Option } from "./types";
import { Spinner } from "components";
import { toast, Toaster } from "sonner";
import { XIcon } from "lucide-react";
import EditForm from "./editform";
import { Form, FormProvider, useForm } from "react-hook-form";
import { pipeline } from "stream";
import { getRelationShipManager } from "service/apis/PartnerRegistrationapi";
import { useSelectState } from "./utils/useSelect";
import { initOppType } from "./utils/featureRule";

const columns: ColumnDef<OpportunityContactType>[] = [
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.original.uniqueId}</div>,
    enableHiding: false,
    filterFn: (row, id, value) => {
      const rowValue = String(row.getValue(id));
      const searchValue = String(value);
      return rowValue.includes(searchValue);
    },
  },
  {
    accessorKey: "contactPerson",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Contact Person" />
    ),
    cell: ({ row }) => {
      return <p className=" capitalize">{row.getValue("contactPerson")}</p>;
    },
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
    enableSorting: false,
  },
  {
    accessorKey: "product",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Product" />
    ),
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
    enableSorting: false,
  },
  {
    accessorKey: "assignedTo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Assigned To" />
    ),
    cell: ({ row }) => {
      const name =
        row.getValue("assignedTo") ?? localStorage.getItem("owner_name");
      return <p className="capitalize">{name}</p>;
    },
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
    enableSorting: false,
  },
  {
    accessorKey: "createDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Create Date " />
    ),
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
  },
  {
    accessorKey: "source",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Source " />
    ),
    cell: ({ row }) => {
      return <p className=" capitalize">{row.getValue("source")}</p>;
    },
    filterFn: (row, id, value) => {
      return String(row.getValue(id))
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
    enableSorting: false,
  },
  {
    accessorKey: "opportunityContactStatus",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = opportunity_status_options.find(
        (status) => status.value === row.getValue("opportunityContactStatus"),
      );

      if (!status) {
        return null;
      }

      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
        >
          {status.label}
        </p>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: false,
  },
];
export default function OpportunityDetails() {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [opportunityList, setOpportunityList] = useState<
    OpportunityContactType[]
  >([]);
  const [isRowDetailsOpen, setIsRowDetailsOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] =
    useState<OpportunityContactType | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyDetails] = useState<any>([]);

  const methods = useForm({
    defaultValues: companyData,
  });
  console.log(companyData);
  const { pipelineType } = useAppSelector((state) => state.CreateOpportunity);

  useEffect(() => {
    methods.reset(companyData);
  }, [companyData]);

  const handleRowClick = (row: OpportunityContactType) => {
    setSelectedRowData(row);
    setIsRowDetailsOpen(true);
  };

  const handleDialogOpen = (open: boolean) => {
    setDialogOpen(open);
  };

  const dispatch = useAppDispatch();
  const handleSheetOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      dispatch(resetState());
    }
  };

  const handleUpdateCompany = async (value: any) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    let payload: AxiosRequestConfig<PutUpdateCompanyDetailsReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    if (pipelineType === "corporate") {
      payload.data = {
        id: id as string,
        company_name: value?.company_name,
        email: value?.company_email,
        contactPersonType: value?.contact_person_type?.value,
        contactName: value?.contact_person_name,
        contactNumber: value?.contact_person_phone,
        pipelineType: "coorporate",
      };
    }
    if (pipelineType === "partner") {
      payload.data = {
        id: id as string,
        contactName: value?.entity_name,
        contactEmail: value?.company_email,
        typeOfPartner: value?.entity_type?.value,
        pipelineType: "partner",
      };
    }
    if (pipelineType === "retail") {
      payload.data = {
        id: id as string,
        contactName: value?.contact_name,
        contactNumber: value?.contact_number,
        contactEmail: value?.contact_email,
        address: value?.contact_address,
        pipelineType: "retail",
        contactAddress: [
          ...companyData?.contactAddress,
          value?.contact_address,
        ],
      };
    }

    try {
      const res = (await PutUpdateCompanyDetails(payload)) as any;
      if (res.status === 200) {
        toast.success("Company details updated successfully");
        handleDialogOpen(false);
        await getCompanyDetails();
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update company details");
      setLoading(false);
    }
  };

  const getCompanyDetails = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetCompanyByIdReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        id,
      },
    };
    try {
      let response;
      if (!id) return;
      if (pipelineType === "corporate") {
        response = (await getCompanyById(payload)) as any;
      } else {
        response = (await getContactDetailById(payload)) as any;
      }
      setCompanyDetails(response?.data);
      console.log(response?.data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const getOpportunityList = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    let payload: AxiosRequestConfig<GetOpportunityByCompanyReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        contactID: id,
      },
    };
    if (pipelineType === "corporate") {
      payload = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          companyID: id,
        },
      };
    }
    try {
      const response = (await getOpportunityByCompany(payload)) as any;
      if (response?.data?.length === 0) {
        return;
      }
      setOpportunityList(() => {
        return response?.data?.map((item: any) => ({
          id: item?.id,
          uniqueId: item?.unique_id,
          contactPerson: item?._contacts?.[0]?.contactName,
          product: item?._product?.name,
          assignedTo: item?._staff?.fname,
          createDate: new Date(item?.created_at).toLocaleDateString(),
          source: item?.source,
          opportunityContactStatus: item?.isOpen === "true" ? "open" : "close",
          contactNumber: item?._contacts?.[0]?.contactNumber,
        }));
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, [isOpen, id]);

  useEffect(() => {
    getOpportunityList();
  }, [isOpen, id]);

  return (
    <>
      {loading && <Spinner />}
      <div className="flex h-full gap-10 py-5 sm:py-2 sm:px-0 px-6 w-full flex-col overflow-scroll font-inter font-thin">
        <div className=" flex w-full justify-between items-center py-2.5 border-b">
          <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Contact details
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              Details of{" "}
              <span className=" capitalize">
                {companyData?.company_name
                  ? companyData?.company_name
                  : companyData?.contactName}
              </span>
            </p>
          </div>
        </div>
        <div className="flex gap-5 w-full sm:flex-col">
          <div className="bg-[#fafafa] p-5 rounded-xl w-full">
            <div className="flex flex-col gap-5 w-full">
              <div className="flex gap-10 w-full items-center ">
                <div className="flex items-center flex-1 justify-between w-full">
                  {pipelineType === "partner" || pipelineType === "retail" ? (
                    <>
                      <div className=" text-[#666666] text-base">Name</div>
                      <div className=" font-medium text-black-label capitalize text-base leading-4">
                        {companyData?.contactName}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-[#666666] text-base">
                        Company Name
                      </div>
                      <div className="font-medium text-black-label capitalize text-base leading-4">
                        {companyData?.company_name}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <AlertDialog open={dialogOpen}>
                    <AlertDialogTrigger
                      onClick={() => handleDialogOpen(!dialogOpen)}
                    >
                      <div className=" cursor-pointer ">
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_1689_36824)">
                            <path
                              d="M3.83594 4.82812H3.0026C2.56058 4.82813 2.13665 5.00372 1.82409 5.31628C1.51153 5.62884 1.33594 6.05276 1.33594 6.49479V13.9948C1.33594 14.4368 1.51153 14.8607 1.82409 15.1733C2.13665 15.4859 2.56058 15.6615 3.0026 15.6615H10.5026C10.9446 15.6615 11.3686 15.4859 11.6811 15.1733C11.9937 14.8607 12.1693 14.4368 12.1693 13.9948V13.1615"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.3333 3.16676L13.8333 5.66676M14.9875 4.48759C15.3157 4.15938 15.5001 3.71424 15.5001 3.25009C15.5001 2.78594 15.3157 2.34079 14.9875 2.01259C14.6593 1.68438 14.2142 1.5 13.75 1.5C13.2858 1.5 12.8407 1.68438 12.5125 2.01259L5.5 9.00009V11.5001H8L14.9875 4.48759Z"
                              stroke="#165FE3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1689_36824">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="translate(0 0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </AlertDialogTrigger>
                    <AlertDialogPortal>
                      <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                      <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                        <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
                          <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
                            Edit {pipelineType} Contact Details
                          </AlertDialogTitle>
                          <AlertDialogDescription className="font-medium p-0 text-sm  text-[#7E7E7E] ">
                            Fill the required details
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <FormProvider {...methods}>
                          <div className="h-[500px] overflow-auto w-full">
                            <EditForm />
                          </div>
                        </FormProvider>
                        <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                          <AlertDialogCancel
                            className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                            asChild
                          >
                            <button
                              onClick={() => {
                                methods.reset();
                                methods.clearErrors();
                                setDialogOpen(false);
                              }}
                            >
                              Cancel
                            </button>
                          </AlertDialogCancel>
                          <AlertDialogAction
                            className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                            asChild
                          >
                            <button
                              className=""
                              onClick={methods.handleSubmit(
                                handleUpdateCompany,
                              )}
                            >
                              Submit
                            </button>
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogPortal>
                  </AlertDialog>
                </div>
              </div>
              <div className="flex gap-10 w-full items-center justify-between ">
                <div className="text-[#666666] text-base">Email</div>
                {pipelineType === "partner" || pipelineType === "retail" ? (
                  <div className=" font-medium text-black-label leading-4">
                    {companyData?.contactEmail}
                  </div>
                ) : (
                  <div className=" font-medium text-black-label leading-4">
                    {companyData?.email}
                  </div>
                )}
              </div>
              <div className="flex gap-10 w-full items-center justify-between ">
                { pipelineType === "retail" && (
                  <>
                    <div className="text-[#666666] text-base">Address</div>
                    <div className=" font-medium text-black-label leading-4">
                      {companyData?.contactAddress?.[0]
                        ? companyData?.contactAddress?.[0]
                        : "N/A"}
                    </div>
                  </>
                )}
              </div>
              {pipelineType === "partner" && (
                <>
                  <div className="flex gap-10 w-full items-center justify-between ">
                    <div className="text-[#666666] text-base">Type</div>
                    <div className=" font-normal capitalize text-base leading-4">
                      {companyData?.typeOfPartner
                        ? companyData?.typeOfPartner
                        : "N/A"}
                    </div>
                  </div>
                  <div className="flex gap-10 w-full items-center justify-between ">
                    <div className="text-[#666666] text-base">
                      Contact Number
                    </div>
                    <div className=" font-medium text-black-label leading-4">
                      {companyData?.contactNumber}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <div className="bg-[#fafafa] p-5 flex gap-3 rounded-xl w-full">
                        <div className="w-full flex flex-col gap-3">
                            <div className="p-3 w-full items-center justify-between border-b border-blue-700 flex">
                                <div className="">Total Orders: 0</div>
                                <div className="text-xs text-blue-700">View Orders</div>
                            </div>
                            <div className="flex gap-5 w-full justify-between">
                                <div className="border w-full rounded-lg flex-col bg-[#F1F6FFB2] border-blue-700 p-2.5 justify-between flex">
                                    <h2 className="font-medium text-[32px] leading-[44px] text-black-label">N/A</h2>
                                    <p className=" font-medium text-sm text-[#666666]">Lifetime Order Value</p>
                                </div>
                                <div className="border w-full rounded-lg flex-col bg-[#EA2C2C0D] border-blue-700 p-2.5 justify-between flex">
                                    <h2 className="font-medium text-black-label"><span className="">N/A</span> Cancelation Rate</h2>
                                    <p className=" font-medium text-sm text-[#666666]">N/A Orders Cancelled</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <div className="bg-[#fafafa] p-5 flex gap-3 w-2/4 rounded-xl">
            <div className="w-full flex flex-col gap-3">
              <div className="p-3 w-full items-center justify-between border-b border-blue-700 flex">
                <div className="flex justify-between w-full">
                  Opportunities stats
                  <p className="text-xs text-fuchsia-900 bg-fuchsia-200 w-fit h-fit py-0.5 px-1 rounded-[4px]">
                    Total:{opportunityList?.length}
                  </p>
                </div>
              </div>
              <div className="flex gap-5 w-full justify-between">
                <div className="border w-full rounded-lg flex-col bg-[#E1FFE226]  p-2.5 justify-between flex">
                  <div className="flex w-full justify-between">
                    <h2 className="font-medium text-[32px] leading-[44px] text-black-label">
                      {
                        opportunityList?.filter(
                          (el) => el.opportunityContactStatus === "open",
                        ).length
                      }
                    </h2>
                    <p className="text-xs text-green-800 bg-green-200 h-fit p-0.5 rounded-[4px]">
                      Open
                    </p>
                  </div>
                  <p className=" font-medium text-sm text-[#666666]">
                    Opportunities
                  </p>
                </div>
                <div className="border w-full rounded-lg flex-col bg-[#EA2C2C0D]  p-2.5 justify-between flex">
                  <div className="flex w-full justify-between">
                    <h2 className="font-medium text-[32px] leading-[44px] text-black-label">
                      {
                        opportunityList?.filter(
                          (el) => el.opportunityContactStatus === "close",
                        ).length
                      }
                    </h2>
                    <p className="text-xs text-red-800 bg-red-200 h-fit p-0.5 rounded-[4px]">
                      Close
                    </p>
                  </div>
                  <p className=" font-medium text-sm text-[#666666]">
                    Opportunities
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex w-full justify-between items-center px-6 sm:px-0">
          <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Opportunities
            </h3>
          </div>
          <Sheet open={isOpen} onOpenChange={handleSheetOpenChange}>
            <SheetTrigger>
              <button className="rounded-lg sm:hidden py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                + New Opportunity
              </button>
            </SheetTrigger>
            <SheetContent
              side="right"
              className="bg-white-A700 w-2/5 sm:w-4/5 py-3 px-0 overflow-auto"
            >
              <OpportunitiesSheet
                isOpen={isOpen}
                handleSheetOpen={handleSheetOpenChange}
                company_id={id as string}
              />
            </SheetContent>
          </Sheet>
        </div>
        <div className="flex-1 relative">
          <DataTable
            columns={columns}
            data={opportunityList}
            onRowClick={handleRowClick}
            isToolbarVisible={false}
            isPaginationVisible={opportunityList.length > 10}
            defaultSorting={[{ id: "id", desc: true }]}
          />
          <RowDetailsSheet
            isOpen={isRowDetailsOpen}
            onOpenChange={setIsRowDetailsOpen}
            rowData={selectedRowData}
            getOpportunityList={getOpportunityList}
          />
          <button
            onClick={() => handleSheetOpenChange(true)}
            className="rounded-lg hidden sm:flex py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
          >
            + New Opportunity
          </button>
        </div>
      </div>
      <Toaster theme="dark" position="top-right" />
    </>
  );
}

interface RowDetailsSheetProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  rowData: OpportunityContactType | null;
  getOpportunityList: () => void;
}

const RowDetailsSheet: React.FC<RowDetailsSheetProps> = ({
  isOpen,
  onOpenChange,
  getOpportunityList,
  rowData,
}) => {
  const [typeOptions, setTypeOptions] = useState<Option[]>(initOppType);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [interactionNote, setInteractionNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [opportunityDetails, setOpportunityDetails] = useState<any>({});
  const [interactionList, setInteractionList] = useState<
    OpportunityInteractionType[]
  >([]);
  const [status, setStatus] = useState<any>();
  const { pipelineType } = useAppSelector((state) => state.CreateOpportunity);
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");

  const handleChangeType = (newValue: SingleValue<Option>) => {
    // dispatch(setContactPersonType(newValue?.value??''));
    setSelectedOption(newValue);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setInteractionNote(value);
  };

  const getOpportunity = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetOpportunityByIdReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        id: rowData?.id as string,
      },
    };
    try {
      const response = (await getOpportunityById(payload)) as any;
      if (response?.data?.length === 0) {
        return;
      }
      setOpportunityDetails(() => {
        return {
          id: response?.data?.id,
          contactPerson: response?.data?._coorporate_company?.[0]?.contact_name,
          email: response?.data?._coorporate_company?.[0]?.email,
          product: response?.data?._product?.name,
          assignedTo: response?.data?._staff?.fname,
          createDate: new Date(response?.data?.created_at).toString(),
          isOpportunityOpen:
            response?.data?.isOpen === "true" ? "open" : "close",
          source: response?.data?.source,
          opportunityContactStatus:
            response?.data?.isOpen === "true" ? "open" : "close",
        };
      });
      setNote(() => {
        return response?.data?.interactions_id?.[0]?.[0]?.note;
      });
      setStatus(() => {
        const status = opportunity_status_options.find(
          (status) =>
            status.value ===
            (response?.data?.isOpen === "true" ? "open" : "close"),
        );
        return status;
      });
      setInteractionList(() => {
        return response?.data?.interactions_id?.map((item: any) => ({
          id: item[0]?.id,
          interactionDateandTime: new Date(item[0]?.created_at).toString(),
          interactionSource: item[0]?.source,
          interactionNote: (item[0]?.note as string),
          interactionStatus:
            item[0]?.isOpen === true || item[0]?.isOpen === "true"
              ? "open"
              : "close",
        }));
      });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleUpdateInteractionStatus = async (id: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PutUpdateInteractionStatusReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        id,
        isOpen: "false",
      },
    };
    try {
      const res = (await putUpdateInteractionStatus(payload)) as any;
      if (res.status === 200) {
        toast.success("Interaction status updated successfully");
        await getOpportunity();
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update interaction status");
      setLoading(false);
    }
  };

  const handleUpdateOpportunity = async (reason: string) => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    let payload: AxiosRequestConfig<PutUpdateOpportunityStatusReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    if (reason) {
      payload.data = {
        id: rowData?.id as string,
        reasonToClose: reason,
        isopen: "false",
      };
    }
    if (!reason) {
      payload.data = {
        assignedto_id: relationShipManagerSelectedOption?.value as string,
        id: rowData?.id as string,
      };
    }
    try {
      const res = (await putUpdateOpportunityStatus(payload)) as any;
      if (res.status === 200) {
        toast.success("Opportunity status updated successfully");
        setReason("");
        await getOpportunityList();
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to update opportunity status");
      setLoading(false);
    }
  };

  const handleAddInteraction = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PostCorporateInteractionsReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        opportunity_id: rowData?.id as string,
        remarks: "",
        source: selectedOption?.value as string,
        note: interactionNote,
        subject: "",
      },
    };

    try {
      const res = (await postCorporateInteractions(payload)) as any;
      if (res.status === 200) {
        toast.success("Interaction added successfully");
        setInteractionNote("");
        setSelectedOption(null);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to add interaction");
      setLoading(false);
    }
  };

  const InteractionColumn = useMemo<ColumnDef<OpportunityInteractionType>[]>(
    () => [
      {
        accessorKey: "interactionDateandTime",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Date and Time" />
        ),
        cell: ({ row }) => {
          const date = new Date(
            row.getValue("interactionDateandTime"),
          ).toDateString();
          const time = new Date(row.getValue("interactionDateandTime"))
            .toLocaleTimeString()
            .split(":")
            .slice(0, 2)
            .join(":");
          return (
            <p className=" capitalize">
              {date} {time}
            </p>
          );
        },
        filterFn: (row, id, value) => {
          return String(row.getValue(id))
            .toLowerCase()
            .includes(String(value).toLowerCase());
        },
      },
      {
        accessorKey: "interactionSource",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Source" />
        ),
        cell: ({ row }) => {
          return (
            <p className="capitalize">{row.getValue("interactionSource")}</p>
          );
        },
        filterFn: (row, id, value) => {
          return String(row.getValue(id))
            .toLowerCase()
            .includes(String(value).toLowerCase());
        },
        enableSorting: false,
      },
      {
        accessorKey: "interactionNote",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Note" />
        ),
        filterFn: (row, id, value) => {
          return String(row.getValue(id))
            .toLowerCase()
            .includes(String(value).toLowerCase());
        },
        enableSorting: false,
      },
      {
        accessorKey: "interactionStatus",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Status" />
        ),
        cell: ({ row }) => {
          const status = opportunity_status_options.find(
            (status) => status.value === row.getValue("interactionStatus"),
          );

          if (!status) {
            return null;
          }

          return (
            <DropdownMenu modal>
              <DropdownMenuTrigger disabled={status.label === "Close"}>
                <p
                  style={{
                    backgroundColor: status.bgColor,
                    color: status.textColor,
                  }}
                  className="py-[3px] text-sm leading-4 font-medium flex items-center gap-2.5 px-2 text-center rounded-md"
                >
                  {status.label}
                  <svg
                    width="15"
                    height="7"
                    viewBox="0 0 17 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1689_36934)">
                      <path
                        d="M8.5026 7.38987L2.68796 1.51108C2.6411 1.46268 2.58547 1.42441 2.52428 1.3985C2.46309 1.37258 2.39757 1.35953 2.3315 1.3601C2.26543 1.36067 2.20012 1.37485 2.13936 1.40182C2.07859 1.42879 2.02358 1.46801 1.9775 1.51721C1.93142 1.56641 1.89519 1.62462 1.87091 1.68847C1.84663 1.75232 1.83477 1.82054 1.83603 1.88917C1.83729 1.95781 1.85163 2.02551 1.87824 2.08835C1.90484 2.15119 1.94318 2.20793 1.99103 2.25527L8.15414 8.48575C8.24743 8.58005 8.37245 8.63281 8.5026 8.63281C8.63276 8.63281 8.75778 8.58005 8.85107 8.48575L15.0142 2.25527C15.062 2.20793 15.1004 2.15119 15.127 2.08835C15.1536 2.02551 15.1679 1.95781 15.1692 1.88917C15.1704 1.82054 15.1586 1.75232 15.1343 1.68847C15.11 1.62462 15.0738 1.56641 15.0277 1.51721C14.9816 1.46801 14.9266 1.42879 14.8659 1.40182C14.8051 1.37485 14.7398 1.36067 14.6737 1.3601C14.6076 1.35953 14.5421 1.37258 14.4809 1.3985C14.4197 1.42441 14.3641 1.46268 14.3173 1.51108L8.5026 7.38987Z"
                        fill={status.textColor}
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1689_36934">
                        <rect
                          width="10"
                          height="16"
                          fill="white"
                          transform="translate(0.5 10) rotate(-90)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </p>
              </DropdownMenuTrigger>
              <DropdownMenuContent avoidCollisions align="end" alignOffset={10}>
                <DropdownMenuItem
                  className="py-3 px-4 cursor-pointer hover:bg-[#EFF4FF] bg-white-A700 text-sm font-normal"
                  onClick={() => handleUpdateInteractionStatus(row.original.id)}
                >
                  Close
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
        enableSorting: false,
        filterFn: (row, id, value) => {
          return String(row.getValue(id))
            .toLowerCase()
            .includes(String(value).toLowerCase());
        },
      },
    ],
    [handleUpdateInteractionStatus], // Add any other dependencies here
  );

  const opportunityCloseReason = [
    {
      value: "opportunityWon",
      label: "Opportunity Won",
    },
    {
      value: "notQualified",
      label: "Not Qualified",
    },
    {
      value: "lost",
      label: "Lost",
    },
    {
      value: "noResponse",
      label: "No Response",
    },
  ];

  async function fetchRelationShipManager(): Promise<void | Option[]> {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    const myself = localStorage.getItem("staff_id");
    const owner_name = localStorage.getItem("owner_name");

    try {
      const res = await getRelationShipManager(req);

      const options = res.data.map((obj) => ({
        label: obj?.id === myself ? "Myself" : obj?.fname.toUpperCase(),
        value: obj?.id,
      }));

      const ownerOption = {
        label: `${owner_name} ( Admin )`,
        value: "",
      };
      return [ownerOption, ...options];
    } catch (error) {
      toast.error("Error fetching options staff");
    }
  }

  const {
    options: relationShipManagerOptions,
    selectedOption: relationShipManagerSelectedOption,
    handleChange: relationShipManagerHandleChange,
  } = useSelectState(
    [],
    null,
    () => {},
    () => {},
    fetchRelationShipManager as () => Promise<Option[]>,
  );

  useEffect(() => {
    getOpportunity();
  }, [rowData?.id, loading]);

  if (!rowData) return null;

  return (
    <>
      {loading && <Spinner />}
      <Sheet open={isOpen} onOpenChange={onOpenChange}>
        <SheetContent
          side="right"
          className="bg-white-A700 w-1/2 sm:w-full p-5 sm:p-2 gap-5 flex h-full overflow-auto flex-col"
        >
          <XIcon
            className="top-5 hidden sm:flex right-5 cursor-pointer"
            onClick={() => onOpenChange(false)}
          />
          <div className="p-5 gap-[30px] flex border flex-col rounded-xl border-blue-700 w-full">
            <div className=" pb-2.5 flex justify-between border-b  border-blue-700">
              <div className=" flex gap-2.5 items-center">
                <div className="text-sm font-medium rounded-lg border border-blue-700 p-2">
                  OP-{rowData.id}
                </div>
                <p className="text-sm font-medium">{rowData?.createDate} </p>
              </div>
              <DropdownMenu modal>
                <DropdownMenuTrigger
                  disabled={opportunityDetails?.isOpportunityOpen === "close"}
                >
                  <div className="flex gap-3 items-center h-fit">
                    <div
                      style={{
                        backgroundColor: status?.bgColor,
                        color: status?.textColor,
                      }}
                      className="py-[3px] h-fit flex items-center px-2 gap-2.5 rounded-md"
                    >
                      <button className="text-sm capitalize font-medium">
                        {opportunityDetails?.isOpportunityOpen}
                      </button>
                      <svg
                        className=" bg-transparent"
                        width="15"
                        height="7"
                        viewBox="0 0 17 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1689_36934)">
                          <path
                            d="M8.5026 7.38987L2.68796 1.51108C2.6411 1.46268 2.58547 1.42441 2.52428 1.3985C2.46309 1.37258 2.39757 1.35953 2.3315 1.3601C2.26543 1.36067 2.20012 1.37485 2.13936 1.40182C2.07859 1.42879 2.02358 1.46801 1.9775 1.51721C1.93142 1.56641 1.89519 1.62462 1.87091 1.68847C1.84663 1.75232 1.83477 1.82054 1.83603 1.88917C1.83729 1.95781 1.85163 2.02551 1.87824 2.08835C1.90484 2.15119 1.94318 2.20793 1.99103 2.25527L8.15414 8.48575C8.24743 8.58005 8.37245 8.63281 8.5026 8.63281C8.63276 8.63281 8.75778 8.58005 8.85107 8.48575L15.0142 2.25527C15.062 2.20793 15.1004 2.15119 15.127 2.08835C15.1536 2.02551 15.1679 1.95781 15.1692 1.88917C15.1704 1.82054 15.1586 1.75232 15.1343 1.68847C15.11 1.62462 15.0738 1.56641 15.0277 1.51721C14.9816 1.46801 14.9266 1.42879 14.8659 1.40182C14.8051 1.37485 14.7398 1.36067 14.6737 1.3601C14.6076 1.35953 14.5421 1.37258 14.4809 1.3985C14.4197 1.42441 14.3641 1.46268 14.3173 1.51108L8.5026 7.38987Z"
                            fill={status?.textColor}
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1689_36934">
                            <rect
                              width="10"
                              height="16"
                              fill="white"
                              transform="translate(0.5 10) rotate(-90)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    {/* <p className="p-1 text-sm capitalize font-medium rounded-md border h-min border-black-label py-[3px] flex items-center px-2 gap-2.5">
                                            Won
                                        </p> */}
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="border p-0 rounded-lg border-gray-border-gray-lite"
                  avoidCollisions
                  align="end"
                  alignOffset={10}
                >
                  <DropdownMenuRadioGroup
                    value={reason}
                    onValueChange={(e) => {
                      setReason(e);
                      handleUpdateOpportunity(e);
                    }}
                  >
                    <DropdownMenuLabel className="bg-gray-border-gray-lite rounded-t py-3 px-4 text-base font-medium border-b">
                      Give reason for closing the opportunity.
                    </DropdownMenuLabel>
                    {opportunityCloseReason.map((reason) => (
                      <DropdownMenuRadioItem
                        key={reason.value}
                        value={reason.value}
                        className="py-3 px-4 cursor-pointer hover:bg-[#EFF4FF] bg-white-A700 text-sm font-normal"
                      >
                        {reason.label}
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className="flex gap-5 flex-col w-full">
              <div className="flex justify-between w-full">
                {!(pipelineType === "partner") && (
                  <div className="flex flex-col gap-4 ">
                    <div className="flex gap-2.5">
                      <span className="text-[#666666]">Contact Person</span>
                      <span>{rowData.contactPerson}</span>
                    </div>
                    <div className="flex gap-2.5 ">
                      <span className="text-[#666666]">Contact Number</span>
                      <span>{rowData?.contactNumber}</span>
                    </div>
                  </div>
                )}
                <div
                  className={`flex ${pipelineType === "partner" ? "justify-between w-full" : "flex-col "} gap-4`}
                >
                  <div className="flex gap-2.5">
                    <span className="text-[#666666]">Product</span>
                    <span>{rowData.product}</span>
                  </div>
                  <div className="flex gap-2.5">
                    {pipelineType === "partner" ? (
                      <span className="text-[#666666]">
                        Relationship Manager
                      </span>
                    ) : (
                      <span className="text-[#666666]">Assigned to</span>
                    )}
                    <span>
                      {opportunityDetails?.assignedTo ??
                        localStorage.getItem("owner_name")}
                    </span>
                    <AlertDialog>
                      <AlertDialogTrigger>
                        <div className=" cursor-pointer ">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1689_36824)">
                              <path
                                d="M3.83594 4.82812H3.0026C2.56058 4.82813 2.13665 5.00372 1.82409 5.31628C1.51153 5.62884 1.33594 6.05276 1.33594 6.49479V13.9948C1.33594 14.4368 1.51153 14.8607 1.82409 15.1733C2.13665 15.4859 2.56058 15.6615 3.0026 15.6615H10.5026C10.9446 15.6615 11.3686 15.4859 11.6811 15.1733C11.9937 14.8607 12.1693 14.4368 12.1693 13.9948V13.1615"
                                stroke="#165FE3"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.3333 3.16676L13.8333 5.66676M14.9875 4.48759C15.3157 4.15938 15.5001 3.71424 15.5001 3.25009C15.5001 2.78594 15.3157 2.34079 14.9875 2.01259C14.6593 1.68438 14.2142 1.5 13.75 1.5C13.2858 1.5 12.8407 1.68438 12.5125 2.01259L5.5 9.00009V11.5001H8L14.9875 4.48759Z"
                                stroke="#165FE3"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1689_36824">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </AlertDialogTrigger>
                      <AlertDialogPortal>
                        <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                        <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                          <AlertDialogHeader className="flex text-left border-b  rounded-t py-3 px-10 ">
                            <AlertDialogTitle className="font-bold p-0 text-lg text-black-label">
                              Select the staff to assign
                            </AlertDialogTitle>
                            <AlertDialogDescription></AlertDialogDescription>
                          </AlertDialogHeader>
                          <form className="px-10 flex py-3 flex-col gap-2">
                            <label
                              htmlFor="staff_select"
                              className=" text-sm font-medium "
                            >
                              Choose a staff
                            </label>
                            <ReactSelect
                              menuPlacement="auto"
                              className=""
                              menuPosition="absolute"
                              placeholder="Select the staff"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  zIndex: 0,
                                  borderRadius: ".5rem",
                                  padding: ".4rem",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderColor: state.isFocused
                                    ? "#165FE3"
                                    : "#333333",
                                  boxShadow: "none",
                                  width: "400px",
                                }),
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: 1000,
                                }),
                              }}
                              options={relationShipManagerOptions}
                              onChange={relationShipManagerHandleChange}
                              value={relationShipManagerSelectedOption}
                              name="staff_select"
                              id="staff_select"
                            />
                          </form>
                          <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                            <AlertDialogCancel
                              className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                              asChild
                            >
                              <button>Cancel</button>
                            </AlertDialogCancel>
                            <AlertDialogAction
                              className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                              asChild
                            >
                              <button
                                className=""
                                onClick={() => handleUpdateOpportunity("")}
                              >
                                Submit
                              </button>
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialogPortal>
                    </AlertDialog>
                  </div>
                </div>
              </div>
              <div className=" bg-[#FAFAFA] py-2.5 px-2 rounded-lg text-left">
                <p>Note : {note}</p>
              </div>
            </div>
          </div>
          <div className="flex gap-5 overflow-auto h-full flex-col ">
            <h3>Interactions</h3>
            <DataTable
              columns={InteractionColumn}
              data={interactionList}
              isToolbarVisible={false}
              isPaginationVisible={false}
            />
          </div>
          <div className="flex gap-8 px-5 pt-5 items-end h-full border-t border-gray-300 w-full">
            {opportunityDetails?.isOpportunityOpen === "open" && (
              <div className="flex flex-col gap-5 pb-8 w-full">
                <div className="flex flex-col gap-2.5">
                  <label
                    htmlFor="interaction_note"
                    className="text-sm font-medium"
                  >
                    Add interaction
                  </label>
                  <textarea
                    onChange={handleNoteChange}
                    value={interactionNote}
                    className="rounded-xl border border-gray-border-gray-lite h-24 w-full"
                    name="interaction_note"
                    id="interaction_note"
                    placeholder="write here..."
                  ></textarea>
                </div>
                <div className="flex w-full justify-between gap-5 items-end">
                  <ReactSelect
                    menuPlacement="top"
                    className=""
                    placeholder="Select the channel of communication"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        zIndex: 0,
                        borderRadius: ".5rem",
                        padding: ".4rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderColor: state.isFocused ? "#165FE3" : "#333333",
                        boxShadow: "none",
                        width: "400px",
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        zIndex: 1000,
                      }),
                    }}
                    options={typeOptions}
                    onChange={handleChangeType}
                    value={selectedOption}
                    name="assigned_to"
                    id="assigned_to"
                  />
                  <button
                    onClick={handleAddInteraction}
                    disabled={!interactionNote || !selectedOption?.value}
                    className=" disabled:opacity-40 rounded-lg py-2.5 h-fit px-5 bg-blue-700 text-white-A700 font-semibold text-sm"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};
