import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_auth = `${BASE_URL}:uw_L93nW/staff/register`;
const staff_groups = `${BASE_URL}:Gd8PAVtY`;
const staff_branch = `${BASE_URL}:yyd3i3Ww`;

// Create Staff
export type CreateStaffRequestType = Partial<{
  fname: string;
  email: string;
  phone_number: string;
  password: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pincode: string;
  profile_pic: string;
  role: string;
  date_of_joining: string;
  branch: string;
  employeeID: string;
}>;
export type CreateStaffResponseType = Partial<{}>;

export const createStaff = (
  payload: AxiosRequestConfig<CreateStaffRequestType>
): AxiosPromise<CreateStaffResponseType> => {
  return apis.post(staff_auth, payload);
};

// get Branch
export type GetBranchRequestType = Partial<{
  id: string;
  headers: {
    Authorization: string;
  };
}>;

export type GetBranchResponseType = Partial<{}>;

export const getBranch = (
  payload: AxiosRequestConfig<GetBranchRequestType>
): AxiosPromise<GetBranchResponseType> => {
  {
    // if (!payload.data) return Promise.reject("No data");
    const url = `${staff_branch}/branch`;
    return apis.get(url, payload);
  }
};


// getRole
export type GetRoleRequestType = Partial<{
  headers: {
    Authorization: string;
  };
}>;

export type GetRoleResponseType = Partial<{
  data: {
    id: string;
    name: string;
  }[];
}>;

export const getRole = (
  payload: AxiosRequestConfig<GetRoleRequestType>
): AxiosPromise<GetRoleResponseType> => {
  {
    const url = `${staff_groups}/groups`;
    return apis.get(url, payload);
  }
};
