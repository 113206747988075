import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';

interface Option {
    value: string;
    label: string;
}

export function useSelectState(
    initialOptions: Option[],
    initialSelectedValue: string | null = null,
    onChangeCallback?: (selectedOption: SingleValue<Option>) => void, // Optional callback for onChange
    onInputChangeCallback?: (inputValue: string) => void, // Optional callback for onInputChange
    fetchOptions?: () => Promise<Option[]>,
    trigger?: any
    
) {
    const [options, setOptions] = useState<Option[]>(initialOptions);
    const [inputValue, setInputValue] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<Option | null>(
        initialSelectedValue ? initialOptions.find(opt => opt.value === initialSelectedValue) || null : null
    );
    const [loading, setLoading] = useState<boolean>(Boolean(fetchOptions));
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (fetchOptions) {
          const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
              const fetchedOptions = await fetchOptions();
              setOptions(fetchedOptions);
            } catch (err) {
              setError('Failed to fetch options');
              console.error('Error fetching options:', err);
            } finally {
              setLoading(false);
            }
          };
    
          fetchData();
        }
      }, [trigger]);

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
        if (onInputChangeCallback) {
            onInputChangeCallback(newValue);
        }
    };

    const handleChange = (newValue: SingleValue<Option>) => {
        setSelectedOption(newValue);
        if (onChangeCallback) {
            onChangeCallback(newValue);
        }
    };

    return {
        options,
        selectedOption,
        inputValue,
        handleInputChange,
        handleChange,
        setOptions,
        loading,
        error,
    };
}
