import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Img, Spinner, Text } from "components";
import { useAppDispatch } from "redux/hooks";
import { Capitalize } from "utils";
import {
  setEmail,
  setPhone,
  resetVerifystep,
  setVerifystep,
  setPartnertype,
  setPartnertypeother,
  setStaffId,
  setPanfront,
  setPanfrontBinary,
  setPanNumber,
  setPanType,
  setadhaarFront,
  setadhaarFrontBinary,
  setadhaarBack,
  setadhaarBackBinary,
  setUidNumber,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setPincode,
  setCitylabel,
  setPhotos,
  setGst,
  setGeolocation,
  setPropertyType,
  incrementVerifystep,
  setaddress_proof,
  setOffice_Interior1,
  setPhoto1,
  setphotosBinary,
  setaddressBinary,
} from "../../redux/features/PartnerRegistration";

type FooterProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{}>;

const Step6: React.FC<FooterProps> = () => {
  const ApplicationId = localStorage.getItem("ApplicationId");
  const ApplicatioStatus = localStorage.getItem("ApplicationStatus");
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setLoading(true);
    dispatch(setEmail(""));
    dispatch(setPhone(""));
    dispatch(setPartnertype(""));
    dispatch(setPartnertypeother(""));
    dispatch(setStaffId(0));
    dispatch(setPanfront(""));
    dispatch(setPanfrontBinary(""));
    dispatch(setPanNumber(""));
    dispatch(setPanType(""));
    dispatch(setadhaarFront(""));
    dispatch(setadhaarFrontBinary(""));
    dispatch(setadhaarBack(""));
    dispatch(setadhaarBackBinary(""));
    dispatch(setUidNumber(""));
    dispatch(setAddressLine1(""));
    dispatch(setAddressLine2(""));
    dispatch(setCity(""));
    dispatch(setState(""));
    dispatch(setPincode(""));
    dispatch(setCitylabel(""));
    dispatch(setPhotos(""));
    dispatch(setGst(""));
    dispatch(setGeolocation(""));
    dispatch(setPropertyType("Owned"));
    dispatch(setVerifystep(0));
    dispatch(resetVerifystep());
    dispatch(setaddress_proof(""));
    dispatch(setOffice_Interior1(""));
    dispatch(setPhoto1(""));
    dispatch(
      setphotosBinary({
        photo_1: "",
        photo_2: "",
        photo_3: "",
        photo_4: "",
      }),
    );
    dispatch(setaddressBinary(""));
    setLoading(false);
    navigate("/partners");
  };
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  // React.useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // Programmatically trigger the click event on the "Close" button
  //     if (closeButtonRef.current) {
  //       closeButtonRef.current.click();
  //     }
  //   }, 3000); // 3 seconds
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      {loading && <Spinner />}
      <section className="sm:flex w-full md:h-[85vh] lg:h-[85vh] overflow-y-scroll sm:w-full flex-col gap-8 flex py-3 sm:px-0 px-4">
        <div className=" flex flex-col py-5 px-8 w-1/2 gap-5 border border-white-A700 rounded-xl shadow-deal-success">
          <div className="flex justify-between w-full">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold leading-[22px] text-blue-700">New Partner is Created</h3>
                <p className="text-sm font-normal leading-[22px] text-[#7e7e7e]">
                  We have successfully registered the new partner.
                </p>
              </div>
              <span className="border border-blue-700 rounded-lg py-2 px-4">
                Your Partner ID will be <span className=" text-blue-700">PA-{ApplicationId && ApplicationId}</span>
              </span>
            </div>
            <div className="flex flex-col justify-between">
              <Img src="/images/overview/success.svg" />
              <p className="text-green-parrot">Success</p>
            </div>
          </div>
          {/* <div className=" rounded-lg py-5 items-center justify-between flex gap-2.5">
          <div className="">
            <span>Partner name: </span>
            <span className="font-semibold text-base leading-4"> Name</span>
          </div>
          <div className="">
            <span>Partner Type: </span>
            <span className="font-semibold text-base leading-4"> Name</span>
          </div>
        </div> */}
        </div>
        <button className="py-[11px] px-4 rounded-lg bg-blue-700 text-white-A700 w-fit">
          <Link to="/partners" className="text-white-A700">
            Go Back to Partners
          </Link>
        </button>
      </section>
    </>
  );
};

export default Step6;
