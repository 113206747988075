import { Check, ChevronDown } from "lucide-react"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
    Command,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setAmount, setCurrency, setEduLoanTaken } from "redux/features/Redirect Portal";
import { getCountry } from "service/apis/CreateDealapi";
import { getCurrency } from "service/apis/CreateForexQuotation";

const frameworks = [
    {
        value: "USD",
        label: "USD",
    },
    {
        value: "GBP",
        label: "GBP",
    },
    {
        value: "CAD",
        label: "CAD",
    },
    {
        value: "AUD",
        label: "AUD",
    },
    {
        value: "EUR",
        label: "EUR",
    },
]

export default function Amount() {
    const {eduloan_taken, amount} =  useAppSelector(state => state.CreateRedirectPortal)
    const dispatch = useAppDispatch()
    const dottedBorderWidth = "after:h-28";

    return (
        <form className="max-w-md" >
            <div className="flex flex-col pl-10 gap-8 w-full ">
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                    <div
                        className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                        <img
                            className="relative -left-10 "
                            src="/images/overview/ellipse.svg"
                            alt=""
                        />
                    </div>
                    <label htmlFor="amount" className="text-sm font-medium text-black-label">Enter the amount you want to send.</label>
                    <div className="relative w-full flex rounded-md">
                        <input type="number" placeholder="Enter amount" onChange={(e)=>dispatch(setAmount(e.target.value))} id="amount" name="amount" value={amount} className="border border-black-label rounded-lg rounded-tr-none border-r-0 rounded-br-none p-4 w-full" />
                        <ComboBoxCurrency />
                    </div>
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                    <div
                        className={`flex items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                        <img
                            className="relative -left-10 "
                            src="/images/overview/ellipse.svg"
                            alt=""
                        />
                    </div>
                    <p className="text-sm font-medium text-black-label">Have you taken an education loan  ?</p>
                    <RadioGroup
                        className="flex w-full"
                        defaultValue="no"
                        value={eduloan_taken}
                        onValueChange={useCallback((value : "yes" | "no") => dispatch(setEduLoanTaken(value)),[])}
                    >
                        <div
                            className={`${eduloan_taken === "yes" ? " border-blue-700" : ""} flex items-center w-full rounded-lg border p-3 space-x-2`}
                        >
                            <RadioGroupItem
                                value="yes"
                                id="yes"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                            />
                            <label htmlFor="yes">Yes, We have</label>
                        </div>
                        <div
                            className={`${eduloan_taken === "no" ? "border-blue-700" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
                        >
                            <RadioGroupItem value="no" id="no" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2" />
                            <label htmlFor="no">No, We haven&apos;t</label>
                        </div>
                    </RadioGroup>
                    <i className="font-medium text-xs">TCS charges depend on it</i>
                </div>
            </div>
        </form>
    )
}

const ComboBoxCurrency = () => {
    const [open, setOpen] = useState(false)
    const {currency} = useAppSelector(state => state.CreateRedirectPortal)
    const dispatch = useAppDispatch()
    const [currencyList, setCurrencyList] = useState<{label:string,value:string}[]>([])
    const [value, setValue] = useState("")

    const fetchCurrency =  useCallback(async () => {
        try {
            const res = await getCurrency({})
            setCurrencyList(res.data.map((item) => ({ label: item.currency, value: item.currency })));
        } catch (error) {
            console.error(error)
        }
    },[open])
    return (
        <Popover open={open} onOpenChange={(val)=>{
            setOpen(val)
            if(val) fetchCurrency()
        }}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className=" justify-between p-4 flex border-l-0 h-[58px] border-black-label rounded-tl-none rounded-bl-none"
                >
                    {
                        currency
                            ?
                            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
                                {currency}
                                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </p>
                            :
                            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
                                Select
                                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </p>
                    }
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0 py-2">
                <Command className="p-0">
                    <CommandInput placeholder="Search currency" />
                    <CommandList>
                        <CommandGroup>
                            {currencyList?.map((framework,index) => (
                                <CommandItem
                                    key={index}
                                    value={framework.label}
                                    className="hover:bg-[#EFF4FF] py-3.5 w-full"
                                    onSelect={(currentValue) => {
                                        dispatch(setCurrency(currentValue))
                                        setValue(currentValue === value ? "" : currentValue)
                                        setOpen(false)
                                    }}
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            value === framework.value ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                    {framework.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}