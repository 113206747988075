import Stepper from "components/Stepper/newstepper";
import { useState } from "react";
import { createExistingEducationPaymentSteps } from "utils";
import Amount from "./step1";
import RemitterDetails from "./step2";
import PreviousTransaction from "./step3";
import RecipientDetail from "./step4";
import Documentation from "./step5";
import Review from "./step6";
import { useAppSelector } from "redux/hooks";
import { Skeleton } from "@/components/ui/skeleton";
import { schema } from "../schema";
import { z } from "zod";
import { Toaster } from "sonner";

export default function RedirectExisting() {
    const [currentStep, setCurrentStep] = useState(1);
    const state = useAppSelector(state => state.CreateRedirectPortal);
    const [errors, setErrors] = useState<Record<string, string>>({});

    const handlePrevious = () => {
        setCurrentStep((prev) => prev - 1);
    }


    const buttonState = () => {
        switch (currentStep) {
            case 1:
                if(!state.amount || !state.currency) return true;
                return false;
            case 2:
                if(!state.pan_no) return true;
                return false;
            case 3:
                if(state.is_other_lrs_declare === 'yes'){
                    return !state.payment_details.length;
                }
                return false;
            case 4:
                if(!state.benificiary_nickname || !state.benificiary_name || !state.benificiary_account_no || !state.benificiary_swift || !state.benificiary_bank_name) return true;
                return false;
            case 5:
                if(state.documentList.length < 5) return true;
                return false;
            default:
                return false;
        }
    }

    const validateSteps = (step:number) => {
        const  newErrors: Record<string, string> = {};
        let isValid = false;

        switch (step) {
            case 1:
                return true;
            case 2:
                try {     
                    if( state.pan_found ) {
                        // schema.step2.pick({}).parse({})
                    }
                    else              
                        schema.step2.parse({
                            pan_no: state.pan_no,
                            email: state.email,
                            phone: state.phone,
                        })         
                    isValid = true;    
                } catch (error) {
                    if (error instanceof z.ZodError) {
                        error.errors.forEach((err) => {
                            newErrors[err.path[0]] = err.message;
                        });
                    }                    
                }
                break;  
            case 3:
            case 4:
                return true;
            case 5:
                return true;
            default:
                return false;
        }
        setErrors(newErrors);
        return isValid;
    }

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Amount />;
            case 2:
                return <RemitterDetails errors={errors} />;
            case 3:
                return <PreviousTransaction />;
            case 4:
                return <RecipientDetail />;
            case 5:
                return <Documentation />;
            case 6:
                return <Review />;
            default:
                return null;
        }
    }

    const handleConfirm = () => {
        console.log(state);
    }

    const handleNext = () => {
        if (currentStep === 6) {
            return;
        }
        if (!validateSteps(currentStep)) {
            return;
        }
        setCurrentStep((prev) => prev + 1);
        setErrors({});
    }
    return (
        <main className=" flex gap-5 justify-between h-full">
            <section className="w-full h-full rounded-xl p-3 bg-white-A700">
                <div className="rounded-xl h-full flex flex-col gap-5 w-full px-5 py-2.5">
                    {
                        !(currentStep === 6) &&
                        <Stepper
                            steps={createExistingEducationPaymentSteps.map((val) => val.name)}
                            currentStep={currentStep - 1}
                        />
                    }
                    <div className="flex overflow-auto relative h-full flex-col gap-5 w-full">
                        {renderStep()}
                    </div>
                    {
                        currentStep === 6 ?
                            <div className=" flex w-min gap-5">
                                <button onClick={handlePrevious} className=" w-full text-blue-700 border-blue-700 border flex gap-2.5 py-[11px] px-4 rounded-lg items-center font-semibold text-sm">
                                    Previous
                                </button>
                                <button onClick={handleConfirm} className="bg-blue-700 w-full text-white-A700 flex gap-2.5 py-[11px] px-4 rounded-lg items-center font-semibold text-sm">
                                    Confirm
                                </button>
                            </div> :
                            <div className="flex gap-3">
                                {currentStep !== 1 && (
                                    <button
                                        type="button"
                                        onClick={handlePrevious}
                                        className={`px-4 py-[11px] w-fit rounded-lg border border-blue-700 text-blue-700 ${true ? "" : "opacity-40"}`}
                                    >
                                        Previous
                                    </button>
                                )}
                                <button
                                    type="button"
                                    disabled={buttonState()}
                                    onClick={handleNext}
                                    className="px-4 py-[11px] w-fit rounded-lg bg-blue-700 text-white-A700 disabled:opacity-40"
                                >
                                    {currentStep === createExistingEducationPaymentSteps.length
                                        ? "Submit"
                                        : "Next"}
                                </button>
                            </div>
                    }
                </div>
            </section>
            <section className="w-5/12 rounded-xl p-3 bg-white-A700">
                <h3 className="pl-3 font-semibold text-base">Calculations</h3>
                <div className="pt-5 pb-10 px-5 border-b-8 rounded-xl bg-custom-calc-gradient border-blue-700 gap-10">
                    <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                        <div className="flex flex-col gap-4 w-full">
                            <div className="flex justify-between w-full">
                                <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                                    Recipient gets
                                </span>
                                {true ? (
                                    <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                                ) : (
                                    <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">

                                    </span>
                                )}
                            </div>
                            <div className="w-full border-[.3px]" />
                            <div className="flex flex-col w-full gap-4">
                                <div className="flex justify-between w-full">
                                    <span className="text-sm leading-[17px] indent-3.5 font-normal">
                                        Total Charges
                                    </span>
                                    {true ? (
                                        <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                                    ) : (
                                        <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">

                                        </span>
                                    )}
                                </div>
                                <div className="ml-4 border-l border-gray-300 flex flex-col gap-4 mt-2">
                                    <div className="flex justify-between w-full mb-2">
                                        <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                                            GST on FX conversion
                                        </span>
                                        {true ? (
                                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                                        ) : (
                                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">

                                            </span>
                                        )}
                                    </div>
                                    <div className="flex justify-between w-full mb-2">
                                        <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                                            Foreign Bank charges{" "}
                                            <span className="text-xs text-gray-500">
                                                (incl. GST)
                                            </span>
                                        </span>
                                        {true ? (
                                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                                        ) : (
                                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                                            </span>
                                        )}
                                    </div>
                                    <div className="flex justify-between w-full">
                                        <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                                            TT charges{" "}
                                            <span className="text-xs text-gray-500">
                                                (incl. GST)
                                            </span>
                                        </span>
                                        {true ? (
                                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                                        ) : (
                                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">

                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                            <span className="text-xs leading-[14.5px] indent-3.5 font-semibold">
                                Total estimation
                            </span>
                            {true ? (
                                <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                            ) : (
                                <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Toaster theme="dark" position='top-right' />
        </main>
    )
}   