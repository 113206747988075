import React, { useEffect } from "react";
import {
  Button,
  Img,
  Input,
  ReactTable,
  SelectBox,
  Spinner,
  Text,
} from "../../components";
import successAnimation from "../../assets/gifs/gif_success.json";
import Lottie from "react-lottie";
import { useParams, useNavigate } from "react-router-dom";
import { Capitalize } from "../../utils/index";
const statusOptionsList = [
  { label: "Active", value: "active" },
  { label: "In-active", value: "inactive" },
];

const bankStatusOptionsList = [
  { label: "Active", value: "active" },
  { label: "In-active", value: "inactive" },
  { label: "Un-verified", value: "unverified" },
  { label: "In-complete", value: "incomplete" },
];

import editpng from "../../assets/images/icon _edit_.png";

import {
  GetPartnerDetailsRequestType,
  getPartnerDetails,
  PostApprovalCommisionRateRequestType,
  postApprovalCommisionRate,
  getCommisionRate,
  GetCommisionRateRequestType,
  BranchDetailsType,
  CommissionRateTableRowType,
  PutDeactivateRequestType,
  putDeactivate,
  PutActivateRequestType,
  putActivate,
  ProductRowType,
  DeleteBankAccountRequestType,
  deleteBankAccount,
  ToggleCheckListRequestType,
  toggleCheckList,
} from "service/apis/PartnerDetails";
import "react-toastify/dist/ReactToastify.css";
import { AxiosRequestConfig } from "axios";
import { createColumnHelper } from "@tanstack/react-table";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { setResumeForm } from "../../redux/features/PartnerRegistration";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import EnableGIC from "models/PartnerDetails/EnableGIC";
import Confirm from "models/Confirm";
import ProductActivation from "models/PartnerDetails/ProductActivation";
import AddBankAccountStep from "models/PartnerDetails/AddBankAccountStep";
import BankAccountVerification from "models/PartnerDetails/BankAccountVerification";
import deleteSvg2 from "../../assets/images/icon_delete.svg";

type checkListType = Partial<{
  task: string;
  value: boolean;
  can_user_tick: boolean;
}>;

type statetype = Partial<{
  id: string | number;
  name: string;
  phone: string;
  email: string;
  status?: string;
  pan_type: string;
  type: string;
  pan: {
    url: string;
  };
  aadhar_front: {
    url: string;
  };
  address_proof: {
    url: string;
  };
  masked_pan_no: string;
  masked_aadhar_no: string;
  _realtionship_manager_channels: any;

  _onboarding_checklist: {
    id: string;
    checklist: checkListType[];
  };
  _branch: any;
  _channel_commission: any;
  approval_status: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pincode: string;
  property_type: string;
  office_photos?: { url: string }[];
  photos?: { url: string }[];
}>;
type commisionratedata = Partial<{
  purpose_id: string | number;
  margin: string;
  propose_margin: string;
  purpose: string;
}>;

export type BankDetailsRowType = {
  id: string | number;
  status: string;
  bank_name: string;
  account_number: string;
  ifsc_code: string;
  primary_account: boolean;
};

const PartnerDetailsPage: React.FC = () => {
  const { id } = useParams();
  const [state, setState] = React.useState<statetype>({});
  const [CommissionRateTableRow, setCommissionRateTableRow] = React.useState<
    CommissionRateTableRowType[]
  >([]);

  const [checkList, setCheckList] = React.useState<checkListType[]>([]);
  const [bankAccounts, setBankAccounts] = React.useState<BankDetailsRowType[]>(
    [],
  );
  const [editBankRow, setEditBankRow] = React.useState<string>("");
  const [showimg, setShowimg] = React.useState(false);
  const [imgsrc, setImgsrc] = React.useState("");
  const [isCheckListUpdated, setCheckListUpdated] =
    React.useState<boolean>(false);
  const [commisionratedata, setCommisionratedata] = React.useState<
    commisionratedata[]
  >([]);
  const [updatecommisionratedata, setUpdatecommisionratedata] = React.useState<
    commisionratedata[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [updatestatus, setUpdatestatus] = React.useState("");
  const [productOptionsList, setProductOptionsList] = React.useState<
    ProductRowType[]
  >([]);
  const [product, setProduct] = React.useState<number>(0);
  const [productStatus, setProductStatus] = React.useState<string>("");

  const [enableGIC, setEnableGIC] = React.useState<boolean>(false);

  const [branchdetails, setBranchdetails] = React.useState<BranchDetailsType[]>(
    [],
  );
  const [editproposemargin, setEditproposemargin] = React.useState<
    string | number
  >("");
  const [showGICModal, setShowGICModal] = React.useState<boolean>(false);

  const [showAddProduct, setShowAddProduct] = React.useState<boolean>(false);

  const [showAddAccountModal, setShowAddAccountModal] =
    React.useState<boolean>(false);

  const [showConfirmation, setShowConfirmation] =
    React.useState<boolean>(false);
  const [verifyBankAccount, setVerifyBankAccount] = React.useState<
    string | number
  >("");
  const [showOnboardingChecklist, setShowOnboardingChecklist] =
    React.useState(true);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setResumeForm(false));
    PartnerDetails();
  }, []);

  React.useEffect(() => {
    if (
      product != 0 &&
      productStatus != "" &&
      CommissionRateTableRow &&
      CommissionRateTableRow.length > 0
    ) {
      if (productStatus !== "approved") {
        fetchCommisionRates();
      } else {
        setCommisionratedata(
          state?._channel_commission
            ?.filter((e: any) => e.product_id === product)
            ?.map((e: any) => {
              return {
                purpose_id: e?.purpose_id,
                margin: e?.charges,
                purpose: e?._purpose?.name,
              };
            }),
        );
      }
    }
  }, [CommissionRateTableRow, product, productStatus]);

  // Fetching Commision Rates

  const fetchCommisionRates = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<GetCommisionRateRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        product_id: product,
      },
    };
    setLoading(true);
    getCommisionRate(req)
      .then((res) => {
        if (res.data instanceof Array) {
          const data = res.data
            .map((e: any) => {
              const findone: CommissionRateTableRowType | undefined =
                CommissionRateTableRow.find(
                  (a: commisionratedata) => a.purpose_id === e.purpose_id,
                );
              if (!findone)
                return {
                  purpose_id: "",
                  margin: "",
                  propose_margin: "",
                  purpose: "",
                };
              return {
                purpose_id: findone.id,
                margin: e.charges,
                propose_margin: findone.proposed_charges,
                purpose: e._purpose_mapper.name,
              };
            })
            .filter((e: any) => e.purpose_id !== "");
          setCommisionratedata(data);

          const proposedData = res.data
            .map((e: any) => {
              const findone: CommissionRateTableRowType | undefined =
                CommissionRateTableRow.find(
                  (a: commisionratedata) => a.purpose_id === e.purpose_id,
                );
              if (!findone)
                return {
                  purpose_id: "",
                  margin: "",
                  propose_margin: "",
                  purpose: "",
                };
              return {
                commision_rates_id: findone.id,
                charges: findone.proposed_charges,
              };
            })
            .filter((e: any) => e.purpose_id !== "");
          setUpdatecommisionratedata(proposedData);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // Fetching Partner Details
  const PartnerDetails = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<GetPartnerDetailsRequestType> = {
      data: {
        channels_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    getPartnerDetails(req)
      .then((res) => {
        if (res.data._branch) {
          setBranchdetails(res.data?._branch);
        }
        setState(res.data);

        setCheckList(res?.data?._onboarding_checklist?.checklist ?? []);
        const isCompleted = res?.data?.checklist_completed === true;
        setShowOnboardingChecklist(!isCompleted);

        console.log("state", showOnboardingChecklist);
        if (res.data.status) {
          setUpdatestatus(res.data.status);
        }
        if (res.data._channel_commission) {
          setCommissionRateTableRow(res.data._channel_commission);
        }

        if (res.data._bank_account_of_channels) {
          setBankAccounts(res.data._bank_account_of_channels);
        }

        if (res.data.products) {
          setProductOptionsList(
            res.data.products?.map((e: any) => {
              return {
                label: e?._product?.name,
                value: e?.product_id,
                status: e?.status,
              };
            }),
          );

          const findGIC = res.data.products?.find(
            (e: any) => e.product_id === 8,
          );

          if (!findGIC) {
            setEnableGIC(true);
          }
          setProduct(res.data.products[0].product_id);
          setProductStatus(res.data.products[0].status);
        }

        setCommisionratedata(
          state?._channel_commission?.map((e: any) => {
            return {
              purpose_id: e?.purpose_id,
              margin: e?.charges,
              purpose: e?._purpose?.name,
            };
          }),
        );
      })
      .catch((err) => {});
  };

  // Commission rate actions buttons
  const postapprovalcommision = (data: any): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostApprovalCommisionRateRequestType> = {
      data: {
        child_channel: id,
        commision_rates: data,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    postApprovalCommisionRate(req)
      .then((res) => {
        setLoading(false);
        PartnerDetails();
        toast.success("Commission Rate Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const deactivate = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    setLoading(true);

    const req: AxiosRequestConfig<PutDeactivateRequestType> = {
      data: {
        channels_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    putDeactivate(req)
      .then(() => {
        toast.success("Partner Deactivated Successfully");
        PartnerDetails();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const activate = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    setLoading(true);
    const req: AxiosRequestConfig<PutActivateRequestType> = {
      data: {
        channels_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    putActivate(req)
      .then(() => {
        toast.success("Partner Activated Successfully");
        PartnerDetails();
        setLoading(false);
      })
      .catch((err) => {
        setUpdatestatus("inactive");
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const resumeRegistration = (): void | null => {
    dispatch(setResumeForm(true));
    localStorage.setItem("child_channel_id", id);
    navigate(`/partnerregistration`);
  };

  const handleonChangeStatus = (e: string) => {
    if (e === "inactive") {
      deactivate();
    }
    if (e === "active") {
      activate();
    }
  };

  const CommisionTableColumn: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<CommissionRateTableRowType>();
    const columns = [
      tableColumnHelper.accessor("purpose", {
        header: () => (
          <Text className="font-semibold sm:px-5 h-[60px] w-[250px]  px-[30px] flex items-center justify-left text-blue_gray-900_01 text-sm">
            Purpose
          </Text>
        ),
        cell: (props) => (
          <Text className="font-medium sm:px-5 h-[60px] w-[250px] px-[30px]  flex items-center justify-left text-blue_gray-900_01 text-sm">
            {props.getValue()}
          </Text>
        ),
      }),
      tableColumnHelper.accessor("margin", {
        header: () => (
          <Text className="font-semibold sm:px-5 h-[60px] w-[250px] flex items-center justify-center text-blue_gray-900_01 text-sm">
            Margin
          </Text>
        ),
        cell: (props) => (
          <Text className="font-medium sm:px-5 h-[60px] w-[250px] flex items-center justify-center text-blue_gray-900_01 text-sm">
            {props.getValue()} {`${product === 8 ? "(CAD)" : ""}`}
          </Text>
        ),
      }),
    ];
    // Check approval_status before adding the action buttons
    if (productStatus && productStatus !== "approved") {
      columns.push(
        tableColumnHelper.accessor("propose_margin", {
          header: () => (
            <Text className="font-semibold sm:px-5 h-[60px] w-[250px] flex items-center justify-center text-blue_gray-900_01 text-sm">
              Proposed Margin
            </Text>
          ),
          cell: (props) =>
            editproposemargin === props.row.original.purpose_id ? (
              <input
                type="text"
                className="font-medium sm:px-5 h-[60px] w-[250px] flex items-center justify-center text-blue_gray-900_01 text-sm"
                value={props?.getValue()}
                onChange={(e) => {
                  const data = commisionratedata.map((item: any) => {
                    if (item.purpose_id === props.row.original.purpose_id) {
                      return {
                        ...item,
                        propose_margin:
                          e.target.value !== "" ? e.target.value : "",
                      };
                    }
                    return item;
                  });
                  setCommisionratedata(data);

                  let data1: any = updatecommisionratedata.filter(
                    (item: any) =>
                      item.commision_rates_id !== props.row.original.purpose_id,
                  );
                  data1.push({
                    commision_rates_id: props.row.original.purpose_id,
                    charges: e.target.value !== "" ? e.target.value : "",
                  });
                  setUpdatecommisionratedata(data1);
                }}
              />
            ) : (
              <Text className="font-medium sm:px-5 h-[60px] w-[250px] flex items-center justify-center text-blue_gray-900_01 text-sm">
                {props?.getValue()}
              </Text>
            ),
        }),
        tableColumnHelper.accessor("purpose_id", {
          header: () => (
            <Text className="font-semibold sm:px-5 h-[60px] w-[250px] flex items-center justify-center text-blue_gray-900_01 text-sm">
              {/* Action */}
            </Text>
          ),
          cell: (props) => (
            <div className="font-semibold sm:px-5 h-[60px] w-[250px] flex items-center justify-center text-blue_gray-900_01 text-sm">
              <div className="flex justify-between w-[180px] h-[31px]">
                <Button
                  onClick={() => {
                    if (editproposemargin === props.row.original.purpose_id) {
                      setEditproposemargin("");
                    } else {
                      setEditproposemargin(props.row.original.purpose_id);
                    }
                  }}
                >
                  <Img src={editpng} alt="edit" />
                </Button>
              </div>
            </div>
          ),
        }),
      );
    }
    return columns;
  }, [productStatus, product, editproposemargin]);

  const handleonclickonbranch = (id: string | number) => {
    navigate(`/branchdetails${id}`);
  };
  const BranchDetailColumn: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<BranchDetailsType>();
    const columns = [
      tableColumnHelper.accessor("id", {
        header: () => (
          <Text className="font-semibold sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm">
            Branch ID
          </Text>
        ),
        cell: (props) => (
          <Text
            onClick={() => handleonclickonbranch(props.row.original.id)}
            className="cursor-pointer font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm"
          >
            {props.getValue()}
          </Text>
        ),
      }),

      tableColumnHelper.accessor("name", {
        header: () => (
          <Text className="font-semibold sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm">
            Branch Name
          </Text>
        ),
        cell: (props) => (
          <Text
            onClick={() => handleonclickonbranch(props.row.original.id)}
            className="cursor-pointer font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm"
          >
            {props.getValue()}
          </Text>
        ),
      }),
      tableColumnHelper.accessor("address_line_1", {
        header: () => (
          <Text className="font-semibold sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm">
            Branch Location
          </Text>
        ),
        cell: (props) => (
          <>
            {props.getValue() === "" ? (
              <Text
                onClick={() => handleonclickonbranch(props.row.original.id)}
                className="cursor-pointer font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-[#3FB73D] text-sm"
              >
                In-Progress
              </Text>
            ) : (
              <Text
                onClick={() => handleonclickonbranch(props.row.original.id)}
                className="font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm"
              >
                {props.getValue()}
              </Text>
            )}
          </>
        ),
      }),
      tableColumnHelper.accessor("city", {
        header: () => (
          <Text className="font-semibold sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm">
            City
          </Text>
        ),
        cell: (props) => (
          <>
            {props.getValue() === "" ? (
              <Text
                onClick={() => handleonclickonbranch(props.row.original.id)}
                className="font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-[#3FB73D] text-sm"
              >
                In-Progress
              </Text>
            ) : (
              <Text
                onClick={() => handleonclickonbranch(props.row.original.id)}
                className="cursor-pointer font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm"
              >
                {props.getValue()}
              </Text>
            )}
          </>
        ),
      }),
      tableColumnHelper.accessor("gst_number", {
        header: () => (
          <Text className="font-semibold sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm">
            GST Number
          </Text>
        ),
        cell: (props) => (
          <>
            {props.getValue() === "" ? (
              <Text
                onClick={() => handleonclickonbranch(props.row.original.id)}
                className="cursor-pointer font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm"
              >
                N.A.
              </Text>
            ) : (
              <Text
                onClick={() => handleonclickonbranch(props.row.original.id)}
                className="cursor-pointer font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm"
              >
                {props.getValue()}
              </Text>
            )}
          </>
        ),
      }),
      tableColumnHelper.accessor("status", {
        header: () => (
          <Text className="font-semibold sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm">
            Status
          </Text>
        ),
        cell: (props) => (
          <>
            <div className="font-medium sm:px-5 h-[60px] w-[150px] flex items-center justify-center text-blue_gray-900_01 text-sm">
              <button
                onClick={() => handleonclickonbranch(props.row.original.id)}
                className={`cursor-pointer bg-white-A700 p-[10px] ${
                  props.getValue() === "active"
                    ? "border border-[#3FB73D] text-[#3FB73D] border-solid"
                    : "border border-gray-900 text-blue_gray-900_01 border-solid"
                } cursor-pointer font-semibold mb-1.5 min-w-[69px] mt-4 py-1 rounded text-center text-xs`}
              >
                {" "}
                {props?.getValue()?.toUpperCase()}
              </button>
            </div>
          </>
        ),
      }),
    ];
    return columns;
  }, [branchdetails]);

  const status = state?.status;

  let partnerbgColor;
  switch (status) {
    case "active":
      partnerbgColor = "bg-[#008000]";
      break;
    case "inactive":
      partnerbgColor = "bg-[#FFA500]";
      break;
  }

  const removeBankAccount = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<DeleteBankAccountRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        account_id: editBankRow,
      },
    };
    setLoading(true);
    deleteBankAccount(req)
      .then((res) => {
        PartnerDetails();
        setEditBankRow("");
        setLoading(false);
        toast.success("Bank Account Removed Successfully");
      })
      .catch((err) => {
        setEditBankRow("");
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  let partner_contract_css;
  switch (state?.zoho_contract_status) {
    case "RequestExpired":
      partner_contract_css = "border-red-400 text-red-400";
      break;
    case "RequestSubmitted":
      partner_contract_css = "border-red-400 text-white-A700 bg-red-400"; // Red
      break;
    case "RequestCompleted":
      partner_contract_css = "border-colors2 text-colors2"; // Light Green
      break;
    case "RequestRejected":
      partner_contract_css = "border-red-400 text-red-400"; // Light Green
      break;
    default:
      partner_contract_css = "border-red-400 text-red-400"; // Red
  }

  const toggleCheckBox = (checkListItem: checkListType) => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    setLoading(true);
    const req: AxiosRequestConfig<ToggleCheckListRequestType> = {
      data: {
        onboarding_checklist_id: state?._onboarding_checklist?.id ?? 0,
        task_obj: {
          task: checkListItem.task ?? "",
          value: !checkListItem.value ?? false,
        },
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    toggleCheckList(req)
      .then((res) => {
        console.log(res?.data);
        setLoading(false);
        setCheckList(res?.data?.checklist?.checklist ?? []);
        const isCompleted = res?.data?.completed === true;
        setShowOnboardingChecklist(!isCompleted);
        if (isCompleted) {
          toast.success(
            <div className="flex items-center">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: successAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={50}
                width={50}
              />
              <span className="ml-2">
                Partner Checklist is updated successfully
              </span>
            </div>,
            {
              icon: false,
            },
          );
        } else {
          toast.success("Checklist updated successfully!");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleCheckBoxClick = (checkListItem: checkListType) => {
    if (checkListItem.can_user_tick) {
      toggleCheckBox(checkListItem);
    } else {
      toast.error("Task cannot be marked manually");
    }

    // const updatedCheckList=checkList.map((checkList)=>{
    //   return checkList==checkListItem?
    //   {
    //     ...checkListItem,value:!checkListItem.value
    //   }:checkList
    // })??[];

    // setCheckListUpdated(!(JSON.stringify(updatedCheckList)==JSON.stringify(state._onboarding_checklist?.checklist)))
    // setCheckList(updatedCheckList);
  };

  return (
    <>
      {showConfirmation && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <Confirm
            setShowConfirmation={setShowConfirmation}
            handleContinue={removeBankAccount}
            title={"Are you absolutely sure?"}
            message={`This action cannot be undone. Without a linked bank account, ${
              state?.name ? Capitalize(state?.name) : ""
            } will not be ready to receive commission payouts.`}
          />
        </div>
      )}

      {showGICModal && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <EnableGIC
            setShowGICModal={setShowGICModal}
            channel_id={state?.id}
            PartnerDetails={PartnerDetails}
            setEnableGIC={setEnableGIC}
          />
        </div>
      )}

      {showAddProduct && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="bg-white-A700 fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <ProductActivation
            partner_id={state?.id}
            setShowAddProduct={setShowAddProduct}
            productOptionsList={productOptionsList}
            PartnerDetails={PartnerDetails}
          />
          ;
        </div>
      )}

      {showAddAccountModal && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <AddBankAccountStep
            partnerId={state?.id}
            account_no={verifyBankAccount}
            setShowAddAccountModal={setShowAddAccountModal}
            setVerifyBankAccount={setVerifyBankAccount}
            PartnerDetails={PartnerDetails}
          />
        </div>
      )}

      {verifyBankAccount !== "" && !showAddAccountModal && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <BankAccountVerification
            verifyBankAccount={verifyBankAccount}
            setVerifyBankAccount={setVerifyBankAccount}
            PartnerDetails={PartnerDetails}
          />
        </div>
      )}

      {showimg && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <div className="relative bg-white-A700">
            <Img
              src={imgsrc}
              className="h-[300px] w-[500px] sm:w-[200px] sm:h-[200px]"
              alt="Loading...."
            />
            <div
              className="absolute top-[0] right-[-100px] "
              onClick={() => setShowimg(!showimg)}
            >
              <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
            </div>
          </div>
        </div>
      )}
      {/* </ReactModal> */}
      {loading && <Spinner />}
      <div className="bg-gray-100 flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 flex-col font-inter gap-5 items-start justify-start md:px-5 px-9 py-[58px] w-full">
          <div className="flex flex-col items-start justify-start w-full">
            <Text className="text-black-900 text-xl w-auto">
              Channel Partner Details
            </Text>
          </div>
          <div className="bg-white-A700 flex flex-col font-inter gap-2.5 items-start justify-center md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
            <div className="flex md:flex-col flex-row gap-2.5 w-full">
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text className="font-rubik text-2xl md:text-[22px]  sm:text-xl w-auto pb-[10px]">
                  {state?.name && Capitalize(state?.name)}
                </Text>
                <Text className="font-inter text-[#797D8C] text-sm w-auto">
                  Partner ID:{" "}
                  <span className="text-[#000000]">{state?.id}</span>
                </Text>
                <div className="flex text-[#797D8C]  flex-row font-inter gap-2.5 items-start justify-start w-full">
                  <Text className="flex-1  text-sm w-auto">
                    Phone:
                    <span className="text-[#000000]">
                      {" "}
                      {state?.phone != "" ? state.phone : "N/A"}
                    </span>
                  </Text>
                  <Text className="flex-1  text-sm w-auto">
                    Email ID:{" "}
                    <span className="text-[#000000]">
                      {" "}
                      {state?.email ? state?.email : "N/A"}
                    </span>
                  </Text>
                </div>
                <div className="flex flex-row font-inter gap-2.5 items-start justify-start w-full">
                  <Text className="flex-1 text-[#797D8C] text-sm w-auto">
                    Relationship Manager:{" "}
                    <span
                      className="text-[#000000] cursor-pointer"
                      onClick={() =>
                        state?._realtionship_manager_channels?.id &&
                        state?._realtionship_manager_channels?.id != 0 &&
                        navigate(
                          `/staffdetails${state?._realtionship_manager_channels?.id}`,
                        )
                      }
                    >
                      {" "}
                      {state?._realtionship_manager_channels?.fname ??
                        "N/A"}{" "}
                    </span>
                  </Text>
                </div>
                <div className="flex flex-row font-inter gap-2.5 items-start justify-start w-full">
                  <Text className="flex-1 text-[#797D8C] text-sm w-auto">
                    Partner Contract:{" "}
                    {
                      <span
                        className={`${partner_contract_css} cursor-pointer border-[1px] p-[4px] rounded-md`}
                      >
                        {" "}
                        {state?.zoho_contract_status === "RequestSubmitted"
                          ? "Sent For Signing"
                          : state?.zoho_contract_status === "RequestRejected"
                            ? "Rejected"
                            : state?.zoho_contract_status === "RequestCompleted"
                              ? "Completed"
                              : state?.zoho_contract_status === "RequestExpired"
                                ? "Expired"
                                : "Pending"}{" "}
                      </span>
                    }
                  </Text>
                </div>
              </div>
              <div className="flex flex-row gap-[15px] items-center justify-end mb-[102px] md:mt-0 mt-[0.46px] w-auto">
                <div className="flex flex-col items-center justify-end w-auto">
                  {" "}
                  {updatestatus !== "incomplete" &&
                  updatestatus !== "pending" &&
                  updatestatus !== "" ? (
                    <SelectBox
                      className="bg-white-A700 border border-gray-200_01 border-solid font-medium p-1.5  rounded-lg text-[13px] text-blue_gray-700 text-left pl-[20px] w-[130px]"
                      placeholderClassName="text-blue_gray-700 text-[12px] w-[130px]"
                      isSearchable={true}
                      isMulti={false}
                      value={updatestatus}
                      onChange={(e) => {
                        setUpdatestatus(e);
                        handleonChangeStatus(e);
                      }}
                      indicator={
                        <>
                          <div
                            className={`${partnerbgColor} absolute top-[40%] left-[-10px] h-1.5 rounded-[50%] w-1.5`}
                          ></div>
                          <svg
                            width="22"
                            height="23"
                            viewBox="0 0 22 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              id="Background"
                              cx="11"
                              cy="11.75"
                              r="11"
                              fill="#2B2B2B"
                              fillOpacity="0.1"
                            />
                            <image
                              href="/images/img_arrowdown.svg"
                              x="0"
                              y="0"
                              width="22"
                              height="23"
                            />
                          </svg>
                        </>
                      }
                      options={statusOptionsList}
                      name="status"
                    />
                  ) : (
                    <Button
                      onClick={resumeRegistration}
                      className="bg-blue-700 cursor-pointer h-auto py-2.5 rounded-md shadow-bs2  text-sm text-center text-white-A700 w-auto h-[40px] px-[10px]"
                    >
                      Resume Registration
                    </Button>
                  )}
                </div>
                <Button className="hidden cursor-pointer font-inter  w-auto h-[40px] px-[10px] py-1.5 rounded-md border-[2px] text-[14px] border-[#EAEAEA] text-base text-center text-[#252424]">
                  Reset Password
                </Button>
              </div>
            </div>
          </div>

          {state?._onboarding_checklist && showOnboardingChecklist && (
            <div className="flex md:flex-col flex-row gap-5 w-full">
              <div className="bg-white-A700 flex flex-1 flex-col gap-[20px] h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
                <div className="flex flex-row w-full border-b border-gray-200 border-solid pb-2">
                  <Text
                    className="font-bold sm:pr-5 pr-[35px] pt-[3px] text-gray_900_01 text-sm uppercase w-full"
                    size="txtInterBold14"
                  >
                    OnBoarding Checklist
                  </Text>
                </div>
                <div className="flex flex-col w-full justify-start gap-[10px]">
                  {checkList.map((checkListItem) => {
                    return (
                      <div className="flex flex-row w-full justify-start items-center pt-[5px] gap-[8px]">
                        <input
                          className="text-[#000000] accent-black bg-gray-100 border-[#000000] hover:border-[#000000] focus:border-[#000000] border border-[2px] "
                          checked={checkListItem.value}
                          onClick={() => handleCheckBoxClick(checkListItem)}
                          type="checkbox"
                        />
                        <Text
                          className="text-gray_900_01 text-sm w-full"
                          size="txtInterRegular14"
                        >
                          {checkListItem.task}
                        </Text>
                      </div>
                    );
                  })}
                  {isCheckListUpdated && (
                    <Button className="mt-[10px] cursor-pointer bg-blue-700 text-white-A700 font-inter w-full max-w-[100px] px-[20px] py-1.5 rounded-md border-[2px] text-[14px] border-[#EAEAEA] text-base text-center">
                      Save
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="flex md:flex-col flex-row gap-5 w-full">
            <div className="bg-white-A700 flex flex-1 flex-col gap-[20px] h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <div className="flex flex-row w-full border-b border-gray-200 border-solid pb-2">
                <Text
                  className="font-bold sm:pr-5 pr-[35px] pt-[3px] text-gray_900_01 text-sm uppercase w-full"
                  size="txtInterBold14"
                >
                  Bank account Details
                </Text>
                <Button
                  className="cursor-pointer bg-blue-700 text-white-A700 font-inter w-full max-w-[181px] px-[20px] py-1.5 rounded-md border-[2px] text-[14px] border-[#EAEAEA] text-base text-center"
                  onClick={() => {
                    setVerifyBankAccount("");
                    setShowAddAccountModal(true);
                  }}
                >
                  Link Bank Account
                </Button>
              </div>

              {bankAccounts.length > 0 &&
                bankAccounts.map((bank) => {
                  let bankbgColor;
                  switch (bank.status) {
                    case "active":
                      bankbgColor = "bg-[#008000]";
                      break;
                    case "inactive":
                      bankbgColor = "bg-[#FFA500]";
                      break;
                    case "unverified":
                      bankbgColor = "bg-[#FFA500]";
                      break;
                    case "incomplete":
                      bankbgColor = "bg-[#008000]";
                      break;
                  }
                  return (
                    <div className="border border-gray-200 border-solid min-h-[150px] rounded-lg w-full px-[20px] py-[20px] gap-[10px]">
                      <div className="flex flex-row w-full items-center gap-[12px] pb-[12px]">
                        <div className="flex flex-row w-full items-center justify-start gap-[12px]">
                          <Text
                            className="font-inter font-medium text-gray_900_01 text-[14px] w-auto"
                            size="txtInterSemiBold14"
                          >
                            {bank.bank_name}
                          </Text>
                          {bank.primary_account && (
                            <Text
                              className="font-inter font-medium text-[#165FE3] text-[12px] w-auto"
                              size="txtInterRegular12"
                            >
                              {"Primary Bank"}
                            </Text>
                          )}
                        </div>
                        <div className="flex flex-row w-full items-center justify-end gap-[20px]">
                          <SelectBox
                            className="bg-white-A700 border border-gray-200_01 border-solid font-medium p-1.5  rounded-lg text-[13px] text-blue_gray-700 text-left pl-[20px] w-[110px]"
                            placeholderClassName="text-blue_gray-700 text-[12px] w-[130px]"
                            isSearchable={true}
                            isMulti={false}
                            value={bank.status}
                            isDisabled={true}
                            onChange={(e) => {}}
                            indicator={
                              <>
                                <div
                                  className={`${bankbgColor} absolute top-[30%] left-[-10px] h-1.5 rounded-[50%] w-1.5`}
                                ></div>
                              </>
                            }
                            options={bankStatusOptionsList}
                            name="status"
                          />
                          <Button
                            className="relative cursor-pointer w-[30px] text-center font-inter px-[12px] py-[6px] rounded-md text-base text-center text-blue_gray_700"
                            onClick={() => {
                              if (editBankRow === bank.id) {
                                setEditBankRow("");
                              } else {
                                setEditBankRow(bank.id);
                              }
                            }}
                          >
                            <Img
                              className="text-end"
                              src="/images/img_ellipses.svg"
                              alt="Group"
                            />
                          </Button>
                          {editBankRow.toString() === bank.id.toString() && (
                            <div className="options-dropdown absolute z-10">
                              <ul className=" absolute top-[0px] w-[105px] top-[20px] left-[-30px] bg-[#FFFFFF] border border-solid border-gray_200_02">
                                <li
                                  className="flex flex-row justify-between items-center border-t border-solid border-gray_200_02 px-[12px] py-[6px] hover:bg-[#F4F7FF] text-[#3E3E3E] h-[32px] cursor-pointer"
                                  onClick={() => {
                                    setShowConfirmation(true);
                                    // setDeleteRow(item.id)
                                  }}
                                >
                                  <Text
                                    className="font-inter font-medium text-[#EE7373] text-[14px] w-auto"
                                    size="txtInterRegular16"
                                  >
                                    Remove
                                  </Text>
                                  <Img
                                    className=""
                                    width={9}
                                    height={9}
                                    src={deleteSvg2}
                                    alt="delete"
                                  />
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="relative flex flex-col w-full gap-[10px]">
                        <div
                          className={`${
                            bank.status === "incomplete" ||
                            bank.status === "unverified"
                              ? "opacity-20 "
                              : ""
                          } relative flex flex-col w-full gap-[10px]`}
                        >
                          <div className="flex flex-row w-full">
                            <div className="flex w-full justify-start items-center">
                              <Text
                                className="font-inter font-regular text-gray_900_01 text-[14px] w-auto"
                                size="txtInterRegular14"
                              >
                                Bank Account number
                              </Text>
                            </div>
                            <div className="flex w-full justify-end items-center">
                              <Text
                                className="font-inter font-medium text-[#165FE3] text-[14px] w-auto"
                                size="txtInterRegular14"
                              >
                                {bank.account_number}
                              </Text>
                            </div>
                          </div>
                          <div className="flex flex-row w-full">
                            <div className="flex w-full justify-start items-center">
                              <Text
                                className="font-inter font-regular text-gray_900_01 text-[14px] w-auto"
                                size="txtInterRegular14"
                              >
                                IFSC Code:
                              </Text>
                            </div>
                            <div className="flex w-full justify-end items-center">
                              <Text
                                className="font-inter font-medium text-[#165FE3] text-[14px] w-auto"
                                size="txtInterRegular14"
                              >
                                {bank.ifsc_code}
                              </Text>
                            </div>
                          </div>
                        </div>
                        {(bank.status === "unverified" ||
                          bank.status === "incomplete") && (
                          <div className="absolute top-[40%] left-[30%] flex flex-row sm:top-[5%] sm:left-[5%] sm:flex-col w-full justify-start gap-[10px] items-center">
                            <div className="h-full bg-[#F0F6FF] px-[6px] py-[6px] rounded-[6px]">
                              <Img
                                className="h-[18.52px] w-[18.52px] text-center"
                                src="/images/img_tick.svg"
                                alt="frame5724"
                              />
                            </div>
                            <Text
                              className="font-inter font-[400] font-regular text-[#111827] text-[14px] w-auto"
                              size="txtInterRegular16"
                            >
                              Verify your Bank Account
                            </Text>
                            {bank.status === "unverified" && (
                              <Text
                                className="cursor-pointer font-inter font-[400] font-regular text-[#165FE3] text-[14px] w-auto"
                                size="txtInterRegular16"
                                onClick={() => setVerifyBankAccount(bank.id)}
                              >
                                Click Here
                              </Text>
                            )}
                            {bank.status === "incomplete" && (
                              <Text
                                className="cursor-pointer font-inter font-[400] font-regular text-[#165FE3] text-[12px] w-auto"
                                size="txtInterRegular12"
                                onClick={() => {
                                  setVerifyBankAccount(bank.id);
                                  setShowAddAccountModal(true);
                                }}
                              >
                                Reupload the document
                              </Text>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              {bankAccounts.length == 0 && (
                <div className="border border-gray-200 border-solid min-h-[118px] rounded-lg w-full px-[20px] py-[20px] gap-[10px]">
                  <div className="flex flex-col justify-center items-center gap-[10px]">
                    <div className="flex bg-[#F0F6FF] w-[44px] h-[44px] justify-center items-center rounded-[4px]">
                      <Img
                        className="h-[32px] w-[32px] text-center"
                        src="/images/img_link.svg"
                        alt="frame5724"
                      />
                    </div>
                    <div className="flex pl-[30px] justify-center items-center">
                      <Text
                        className="font-bold text-[14px] sm:pr-5 pr-[35px] pt-[3px] text-gray_900_01 text-sm w-full"
                        size="txtInterRegular14"
                      >
                        No Bank Account is Linked
                      </Text>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex md:flex-col flex-row gap-5 w-full  text-[#797D8C] ">
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px]  text-sm uppercase w-full">
                Type of Partner
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                <Text className=" text-sm w-auto">Organisation Type:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {state?.pan_type === ""
                    ? "N.A."
                    : state?.pan_type &&
                      state?.pan_type?.slice(0, 1)?.toUpperCase() +
                        state?.pan_type?.slice(1)}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className=" text-sm w-auto">Partner Trade:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {state?.type}
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px]  text-sm uppercase w-full">
                Id & Address Proof
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                <Text className=" text-sm w-auto">PAN Card:</Text>
                <Text
                  className="font-medium text-blue-700 text-sm w-auto cursor-pointer"
                  onClick={() => {
                    if (state?.pan == null) {
                      toast.error("PAN Card not uploaded");
                    } else {
                      setImgsrc(state?.pan?.url);
                      setShowimg(true);
                    }
                  }}
                >
                  {state?.masked_pan_no == ""
                    ? "Not Uploaded"
                    : state?.masked_pan_no}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className=" text-sm w-auto">Aadhar card:</Text>
                <Text
                  className="font-medium text-blue-700 text-sm w-auto cursor-pointer"
                  onClick={() => {
                    if (state?.aadhar_front == null) {
                      toast.error("Aadhar Card not uploaded");
                    } else {
                      setImgsrc(state?.aadhar_front?.url);
                      setShowimg(true);
                    }
                  }}
                >
                  *** **** {state?.masked_aadhar_no}
                </Text>
              </div>
            </div>
          </div>
          <div className="bg-white-A700 flex flex-col font-inter gap-2.5 items-start justify-center md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
            <div className="flex flex-row w-full h-[36px]">
              <Text className="font-rubik text-md md:text-[22px]  sm:text-xl font-bold uppercase w-full pb-[10px]">
                Products
              </Text>
              <div className="flex flex-row items-center gap-[10px] justify-end w-auto">
                {productOptionsList && productOptionsList.length > 0 && (
                  <SelectBox
                    className="bg-white-A700 border border-gray-200_01 border-solid font-medium p-1.5 rounded-lg text-[13px] text-blue_gray-700 text-left pl-[20px] w-[160px]"
                    placeholderClassName="text-blue_gray-700 text-[12px] w-[130px]"
                    isSearchable={true}
                    isMulti={false}
                    value={product}
                    onChange={(e) => {
                      setProduct(e);
                      setProductStatus(
                        productOptionsList.find((item: any) => item.value === e)
                          ?.status,
                      );
                    }}
                    indicator={
                      <>
                        <div
                          className={`${partnerbgColor} absolute top-[40%] left-[-10px] h-1.5 rounded-[50%] w-1.5`}
                        ></div>
                        <svg
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            id="Background"
                            cx="11"
                            cy="11.75"
                            r="11"
                            fill="#2B2B2B"
                            fillOpacity="0.1"
                          />
                          <image
                            href="/images/img_arrowdown.svg"
                            x="0"
                            y="0"
                            width="22"
                            height="23"
                          />
                        </svg>
                      </>
                    }
                    options={productOptionsList}
                    name="status"
                  />
                )}

                <Button
                  className="cursor-pointer bg-blue-700 text-white-A700 font-inter w-[150px] h-auto px-[20px] py-1.5 rounded-md border-[2px] text-[14px] border-[#EAEAEA] text-base text-center"
                  onClick={() => {
                    setShowAddProduct(true);
                  }}
                >
                  + Add Product
                </Button>
              </div>
            </div>
            {commisionratedata && commisionratedata.length > 0 && (
              <>
                <Text className="text-[#797D8C] border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px]  text-sm uppercase w-full">
                  Commission Rate
                </Text>
                <div className="flex flex-col overflow-auto bg-white-A700 border border-indigo-50_01 border-solid font-rubik rounded w-full">
                  <ReactTable
                    columns={CommisionTableColumn}
                    data={commisionratedata}
                    headerClass="bg-[#E7E7ED]"
                  />
                </div>
              </>
            )}
            {productStatus && productStatus !== "approved" && (
              <div className="flex flex-row justify-end overflow-auto bg-white-A700  font-rubik rounded w-full">
                <Button
                  onClick={() => postapprovalcommision(updatecommisionratedata)}
                  className="float-right bg-blue-700 cursor-pointer font-inter w-[193px] ml-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
                >
                  Approved Margin
                </Button>
              </div>
            )}
          </div>
          <div className="flex md:flex-col flex-row font-inter gap-5 items-start justify-start w-full h-[60vh] text-[#797D8C] ">
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 h-full items-start justify-start  md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px]  text-sm uppercase w-full">
                Office Address
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between py-[3px] w-full">
                <Text className=" text-sm w-auto">Address Line 1:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {state?.address_line_1}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full py-[3px]">
                <Text className=" text-sm w-auto">Address Line 2:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {state?.address_line_2}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full  py-[3px]">
                <Text className=" text-sm w-auto">City:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {state?.city}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full  py-[3px]">
                <Text className=" text-sm w-auto">State:</Text>
                <Text className="font-medium text-[#000000]  text-sm w-auto">
                  {state?.state}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full  py-[3px]">
                <Text className=" text-sm w-auto">Pincode:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {state?.pincode}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full  py-[3px]">
                <Text className=" text-sm w-auto">Property Type</Text>
                <Text
                  className="font-medium text-blue-700 text-sm w-auto cursor-pointer"
                  onClick={() => {
                    if (state?.address_proof) {
                      setImgsrc(state?.address_proof?.url);
                    }
                    setShowimg(true);
                  }}
                >
                  {state?.property_type}
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 sm:min-h-[80px] h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px]  text-sm uppercase w-full">
                Office Images
              </Text>
              {/* <Img
                className="h-72 w-full"
                src="/images/img_frame5724.svg"
                alt="frame5724"
              /> */}
              <div className="flex flex-row justify-between">
                {(state.photos === undefined ||
                  state?.photos === null ||
                  state?.photos?.length === 0) && (
                  <div className="text-[#000000]">Not Available</div>
                )}
              </div>
              <div className="flex flex-row justify-between ">
                <div className="flex flex-row gap-2.5">
                  {state.photos && state.photos.length > 0 && (
                    <Img
                      className="h-72 w-[70%]"
                      src={state.photos[0].url}
                      alt="Image 1"
                    />
                  )}
                  <div className="h-72 w-[30%] flex flex-col justify-start gap-2.5">
                    {state.photos && state.photos.length > 1 && (
                      <Img
                        className="h-24 w-full"
                        src={state.photos[1].url}
                        alt="Image 1"
                        onClick={() => {
                          if (state.photos && state.photos.length > 1) {
                            setImgsrc(state.photos[1].url);
                          }
                          setShowimg(true);
                        }}
                      />
                    )}
                    {state.photos && state.photos.length > 2 && (
                      <Img
                        className="h-24 w-full"
                        src={state.photos[2].url}
                        onClick={() => {
                          if (state.photos && state.photos.length > 2) {
                            setImgsrc(state.photos[2].url);
                          }
                          setShowimg(true);
                        }}
                        alt="Image 2"
                      />
                    )}
                    {state.photos && state.photos.length > 3 && (
                      <Img
                        className="h-24 w-full"
                        src={state.photos[3].url}
                        alt="Image 3"
                        onClick={() => {
                          if (state.photos && state.photos.length > 3) {
                            setImgsrc(state.photos[3].url);
                          }
                          setShowimg(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-start w-full">
            <div className="flex w-full justify-start">
              <Text className="text-black-900 text-xl w-auto">
                Partner Branch Details
              </Text>
            </div>
            <div className="flex w-full justify-end">
              <Text className="text-black-900 text-xl w-auto">
                <Button
                  className="cursor-pointer bg-blue-700 text-white-A700 font-inter w-[150px] h-auto px-[20px] py-1.5 rounded-md border-[2px] text-[14px] border-[#EAEAEA] text-base text-center"
                  onClick={() => {
                    navigate(`/branchregistration${state?.id}`);
                  }}
                >
                  Add Branch
                </Button>
              </Text>
            </div>
          </div>
          {state._branch?.length === 0 ? (
            <div className="flex flex-col items-start justify-start w-full">
              <Text className="bg-[#FFFFFF] w-full  pb-2 sm:pr-5 px-[13px] pt-[3px]  text-sm uppercas">
                No Branches Found
              </Text>
            </div>
          ) : (
            <>
              <div className="flex flex-col overflow-auto bg-white-A700 border border-indigo-50_01 border-solid font-rubik rounded w-full">
                <ReactTable columns={BranchDetailColumn} data={branchdetails} />
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PartnerDetailsPage;
