import Axios, { AxiosRequestConfig } from "axios";
import { constrainedMemory } from "process";

import Cookies from "universal-cookie";

 const cookies = new Cookies();
 export default cookies;


export const getTokenFromCookies = () => {
  return cookies.get('authToken'); // Adjust this based on your cookie setup
};

const defaultAxios = Axios.create({
  headers: { "Content-type": "application/json" },
});

defaultAxios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

defaultAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export function apiClient(
  method: string,
  url: string,
  options: AxiosRequestConfig<any> = {},
) {
  const { data = {}, headers = {}, params = {}, ...rest } = options;
  return defaultAxios({
    url,
    data,
    method,
    params,
    headers,
    ...rest,
  });
}

export const apis = {
  get: (url: string, args: AxiosRequestConfig<any>) =>
    apiClient("get", url, args),
  post: (url: string, args: AxiosRequestConfig<any>) =>
    apiClient("post", url, args),
  put: (url: string, args: AxiosRequestConfig<any>) =>
    apiClient("put", url, args),
  patch: (url: string, args: AxiosRequestConfig<any>) =>
    apiClient("patch", url, args),
  delete: (url: string, args: AxiosRequestConfig<any>) =>
    apiClient("delete", url, args),
};
