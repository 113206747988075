import React from "react";
import { Input, Text } from "components";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Spinner } from "../../components";
import {
  setName,
  setProductType,
  setPassportNumber,
  setEmail,
  setMobileNumber,
  setGrandTotal,
} from "../../redux/features/CreateForexQuotation";

import { useAppSelector, useAppDispatch } from "redux/hooks";

const Step1: React.FC = () => {

  const [loading, setLoading] = React.useState<boolean>(false);

  const { name, product_type, passport_number, email, mobile_number, showError, products, totalGST } = useAppSelector(
    (state) => state.CreateForexQuotation
  );

  React.useEffect(() => {
    reCalculateGrandTotal();
  }, [product_type])

  const dispatch = useAppDispatch();

  //recalculate
  const reCalculateGrandTotal = () => {
    const taxable_amount = Number(
      products.reduce((sum, obj) => sum + Number(obj.qty) * Number(obj.rate), 0)
    );
    const total_amount = Number(
      products.reduce((sum, obj) => sum + Number(obj.total), 0)
    );

    if (product_type == "Encashment") {
      dispatch(
        setGrandTotal(
          Number(taxable_amount - (total_amount - taxable_amount) - Number(totalGST)
          )
        ));
    }
    else {
      dispatch(
        setGrandTotal(
          Number(total_amount) + Number(totalGST)
        )
      );
    }
  }



  return (
    <>
      {loading && <Spinner />}
      <div className="sm:rounded sm:px-[12px] sm:py-[24px] sm:gap-[20px] max-w-sm flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start pl-10 md:px-5 w-[53%] md:w-full">
        <div className="flex flex-col gap-5 items-start justify-start w-full sm:w-full">
          <div className="flex relative flex-col font-inter gap-2.5 items-start justify-start w-full">
          <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text
              className="text-sm font-medium leading-[17px]"
            >
              FX
            </Text>
            <div className="flex flex-row gap-10 sm:gap-5 h-auto md:h-auto items-center justify-start">
              <div className="flex flex-row gap-2 items-center justify-start w-auto">
                <RadioGroup
                  className="flex"
                  onValueChange={(e) => dispatch(setProductType(e))}
                >
                  <div
                    className={`${product_type==="Encashment"?" border-blue-700":""} flex items-center rounded-lg border p-3 space-x-2`}
                  >
                    <RadioGroupItem
                      value="Encashment"
                      id="Encashment"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                    />
                    <label htmlFor="Encashment">Encashment</label>
                  </div>
                  <div
                    className={`${product_type==="Sale"?"border-blue-700":""} flex items-center rounded-lg border p-3 space-x-2`}
                  >
                    <RadioGroupItem value="Sale" id="Sale" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2" />
                    <label htmlFor="Sale">Sale</label>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>
          <div className="flex relative flex-col font-inter gap-2.5 items-start justify-start w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text
              className="text-sm font-medium leading-[17px]"
            >
              What is Customers name ?
            </Text>
            <Input
              name="name"
              value={name}
              onChange={(e: string) => dispatch(setName(e))}
              placeholder="Enter your Traveller Name"
              errors={
                name == "" && showError ? ["Please Enter Traveller Name"] : []
              }
              className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
              type="text"
              disabled={false}
            ></Input>
          </div>

          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text
              className="text-sm font-medium leading-[17px]"
            >
              What is Passport number (optional) ?
            </Text>
            <Input
              name="passportNo"
              value={passport_number}
              onChange={(e: string) => dispatch(setPassportNumber(e))}
              placeholder="Enter Passport Number"
              className="font-inter py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
            ></Input>
          </div>

          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-24 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text
              className="text-sm font-medium leading-[17px]"
            >
              What is Email ID (optional) ?
            </Text>
            <Input
              name="email"
              type="email"
              value={email}
              onChange={(e: string) => dispatch(setEmail(e))}
              placeholder="Enter Passport Number"
              className="font-inter py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
            ></Input>
          </div>

          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text
              className="text-sm font-medium leading-[17px]"
            >
              What is Mobile number (optional) ?
            </Text>
            <Input
              name="mobileNo"
              type="number"
              value={mobile_number}
              onChange={(e: string) => dispatch(setMobileNumber(e))}
              placeholder="Enter Mobile Number"
              className="font-inter py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
            ></Input>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
