import { z } from "zod";

// Define validation schemas for each step
export const step1Schema = z.object({
  company_name: z.string().min(1, "Company name is required"),
  company_email: z.string().email("Invalid email address"),
  contact_person_name: z.string().min(1, "Contact person name is required"),
  contact_person_phone: z.string().regex(/^\d{10}$/, "Invalid phone number"),
  contact_person_type: z.string().optional(),
});

export const step1SchemaExisting = z.object({
    contact_person_name: z.string().min(1, "Contact person name is required"),
    contact_person_phone: z.string().regex(/^\d{10}$/, "Invalid phone number"),
    contact_person_type: z.string().optional(),
  });
