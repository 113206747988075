import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import BasicDetails from "./basicdetails";
import Navbar from "components/Navbar/Index";
import { Img, Spinner } from "components";
import { useMultistepForm } from "./useMultistepForm";
import Stepper from "components/Stepper/newstepper";
import RemittanceDetails from "./remittancedetails";
import { FormProvider, useForm } from "react-hook-form";
import LRS from "./LRS";
import Documentation from "./documentation";
import Payment from "./payment";
import { useEffect, useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import {
  postDealCalc,
  PostDealCalcResponseType,
} from "service/apis/CreateDealapi";
import { useNavigate } from "react-router-dom";
import {
  postcreateOrderNew,
  POSTCreateOrderRequestType,
} from "service/apis/CreateOrderapi";
import { AxiosRequestConfig } from "axios";
import { Toaster } from "sonner";

export interface IFormInputMap {
  customSettlementRate: string;
  purpose: string;
  country: string;
  amount: number;
  charges: string;
  rate?: number;
  name?: string;
  servicePartner?: string;
}

const CreateRemit = () => {
  const methods = useForm({
    defaultValues: {},
  });
  const [calculationData, setCalculationData] =
    useState<PostDealCalcResponseType>();
  const [isCalsLoading, setIsCalsLoading] = useState<boolean>(false);
  const [isStepValid, setIsStepValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState<number>(0);
  const [orderDetails, setOrderDetails] = useState<any>();
  const channel_id = localStorage.getItem("channel_id") as string;

  const navigate = useNavigate();

  async function fetchOrderCalculation(formData: any) {
    setIsCalsLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        purpose_id: Number(formData.purpose_id),
        currency: formData.currency,
        amount: Number(formData.amount),
        type: "beneficiary_receives",
        created_by: channel_id,
        service_partner_id: Number(formData.servicePartners[0]?.value),
        tcs_type: 1,
        discount: 0,
        addon_rates:
          parseFloat(formData.customer_rate) - parseFloat(rate.toFixed(4)),
        country: formData.country,
        fx_charges: formData.charges,
        partner_margin: "",
        partner_walkin_margin: "",
      },
    };
    try {
      const res = await postDealCalc(payload);
      setCalculationData(res.data); // Update the state with the response
      setRate(
        parseFloat(res?.data?.settlement_rates) +
          (res?.data?.cumulative_commission_without_addon.l3 > 0
            ? res?.data?.cumulative_commission_without_addon.l3
            : res?.data?.cumulative_commission_without_addon.l2 > 0
              ? res?.data?.cumulative_commission_without_addon.l2
              : res?.data?.cumulative_commission_without_addon.l1),
      );
      setIsCalsLoading(false);
    } catch (error) {
      setIsCalsLoading(false);
      console.log(error);
    } finally {
      setIsCalsLoading(false);
    }
  }

  const getValues = methods.getValues as any;

  const validateStep = (step: number): boolean => {
    switch (step) {
      case 1:
        if (
          !getValues().purpose_id ||
          !getValues().currency ||
          !getValues().amount ||
          !getValues().charges
        )
          return true;
        return false;
      case 2:
        if (!getValues('customer_details')?.id) return true;
        return false;
      case 4:
        if (
          !(getValues()?.documents_SUPPORT?.length === getValues().requiredDocs)
        )
          return true;
        return false;
      case 5:
        if (!getValues().payment_documents?.length) return true;
        return false;
      default:
        return false;
    }
  };

  const steps = [
    {
      key: "Basic Details",
      component: <BasicDetails fetchOrderCalculation={fetchOrderCalculation} />,
      stepTitle: "Basic Details",
    },
    {
      key: "Remittance Details",
      component: <RemittanceDetails />,
      stepTitle: "Remittance Details",
    },
    {
      key: "LRS Details",
      component: <LRS />,
      stepTitle: "LRS Details",
    },
    {
      key: "Documentation",
      component: <Documentation />,
      stepTitle: "Documentation",
    },
    {
      key: "Patment Details",
      component: <Payment />,
      stepTitle: "Payment Details",
    },
  ];
  const {
    step,
    currentStepIndex,
    stepTitles,
    isLastStep,
    next,
    prev,
    isFirstStep,
  } = useMultistepForm(steps);

  const onSubmit = async (data: any) => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    if (isLastStep) {
      setLoading(true);
      const orderForm = new FormData();
      orderForm.append("purpose_id", data.purpose_id);
      orderForm.append("amount", data.amount);
      orderForm.append("amount_inr", "");
      orderForm.append("currency", data.currency);
      orderForm.append("customers_id", data.customer_id);
      orderForm.append("country", data.country);
      orderForm.append("type", "beneficiary_receives");
      orderForm.append("created_by", channel_id);
      orderForm.append("discount", "");
      orderForm.append("booking_id", "");
      orderForm.append("universal_order_id", "");
      orderForm.append("add_on_rates", "0");
      orderForm.append("beneficiary_name", data.beneficiary_name ?? "");
      orderForm.append("beneficiary_addr_line_1", data.beneficiary_addr_line_1 ?? "");
      orderForm.append("beneficiary_addr_line_2", data.beneficiary_addr_line_2 ?? "");
      orderForm.append("beneficiary_city", data.beneficiary_city ?? "");
      orderForm.append("beneficiary_state", data.beneficiary_state?? "");
      orderForm.append("beneficiary_country", data.beneficiary_country ?? "");
      orderForm.append("beneficiary_zip", data.beneficiary_zip ?? "");
      orderForm.append("beneficiary_account_number", data.beneficiary_account_number?? "");
      orderForm.append("beneficiary_bank_name", data.beneficiary_bank_name ?? "");
      orderForm.append("beneficiary_bank_addr", data.beneficiary_bank_addr?? "");
      orderForm.append("beneficiary_bank_country", data.beneficiary_bank_country ?? "");
      orderForm.append("beneficiary_swift_code", data.beneficiary_swift_code ?? "");
      orderForm.append("beneficiary_routing_number", data.beneficiary_routing_number ?? "");
      orderForm.append("beneficiary_transit_code", data.beneficiary_transit_code ?? "");
      orderForm.append("beneficiary_bsbcode", data.beneficiary_bsbcode ?? "");
      orderForm.append("beneficiary_short_code", "");
      orderForm.append("beneficiary_national_id", data.beneficiary_national_id ?? "");
      orderForm.append("beneficiary_iban_no", data.beneficiary_iban_no ?? "");
      orderForm.append("beneficiary_correspondant_bank_name", "");
      orderForm.append("beneficiary_correspondant_bank_no", "");
      orderForm.append("walkin_order", "true");
      orderForm.append("tcs_type", "1");
      orderForm.append("discount", "0");
      orderForm.append("fx_charges", data.charges);
      orderForm.append("partner_margin", "");
      orderForm.append("partner_walkin_margin", "");
      orderForm.append(
        "l1_commission",
        JSON.stringify(calculationData?.cumulative_commission?.l1),
      );
      orderForm.append(
        "l2_commission",
        JSON.stringify(calculationData?.cumulative_commission?.l2),
      );
      orderForm.append(
        "l3_commission",
        JSON.stringify(calculationData?.cumulative_commission?.l3),
      );

      const docArray = data?.documents_SUPPORT?.map((doc: any) => {
        return {
          file: doc.path,
          filename: doc.name,
        };
      });
      // orderForm.append("supporting_documents2",JSON.stringify(docArray))
      // orderForm.append("payment_documents",JSON.stringify(data?.payment_documents))
      docArray?.forEach((doc: any) => {
        orderForm.append("supporting_documents2", JSON.stringify(doc));
      });
      console.log(data?.payment_documents);
      data?.payment_documents?.forEach((doc: any) => {
        orderForm.append("payment_documents", JSON.stringify(doc));
      });

      const payload: AxiosRequestConfig<POSTCreateOrderRequestType> = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: orderForm as any,
      };

      try {
        // console.log(data?.payment_documents)
        const res = await postcreateOrderNew(payload);
        setOrderDetails(res.data);
        setLoading(false);
        navigate("/remit/ordersuccess", {
          state: res.data.result,
          replace: true,
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      next();
    }
  };

  const handleCancel = () => {
    methods.reset();
    navigate("/remit");
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="p-4 h-screen font-inter flex gap-2.5 flex-col font-thin">
        <Navbar>
          <div className=" w-full flex justify-between">
            <div className=" flex gap-3 items-center">
              <Img src="images/sidebar_img/remit.svg" className="h-5" alt="" />
              <div className="gap-2 flex flex-col">
                <span className="text-sm font-semibold">
                  Create Gift / Maintenance Order
                </span>
                <span className="text-xs font-normal text-[#999999]">
                  Create an Order for Gift and Maintenance
                </span>
              </div>
            </div>
            <div className="flex gap-3">
              <div className="gap-2.5 flex items-center">
                <Img src="images/overview/wrench.svg" alt="" />
                <span className="text-sm font-semibold text-blue-700">
                  Help
                </span>
              </div>
              <AlertDialog>
                <AlertDialogTrigger>
                  <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                    Cancel Order
                  </button>
                </AlertDialogTrigger>
                <AlertDialogPortal>
                  <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                  <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                    <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                      <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                        Are you absolutely sure?
                      </AlertDialogTitle>
                      <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                        This action cannot be undone. This will permanently
                        delete your progress and remove the actions preformed.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                      <AlertDialogCancel
                        className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                        asChild
                      >
                        <button>Close</button>
                      </AlertDialogCancel>
                      <AlertDialogAction
                        className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                        asChild
                      >
                        <button className="" onClick={handleCancel}>
                          Continue
                        </button>
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogPortal>
              </AlertDialog>
            </div>
          </div>
        </Navbar>
        <div className="w-full h-full gap-2.5 flex sm:flex-col overflow-hidden flex-1">
          {/* Details */}
          <section className="w-4/6 sm:w-full bg-white-A700 p-5 flex gap-8 flex-col rounded-xl">
            <div className="">
              <Stepper steps={stepTitles} currentStep={currentStepIndex} />
            </div>
            <div className="flex-1 overflow-auto pr-5 relative">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  {step}
                  <div className="flex gap-3">
                    {!isFirstStep && (
                      <button
                        type="button"
                        onClick={prev}
                        className={`px-4 py-[11px] w-fit rounded-lg border border-blue-700 text-blue-700 ${true ? "" : "opacity-40"}`}
                      >
                        Previous
                      </button>
                    )}
                    <button
                      type='submit'
                      disabled={validateStep(currentStepIndex + 1)}
                      className={`px-4 py-[11px] w-fit rounded-lg bg-blue-700 text-white-A700 ${!validateStep(currentStepIndex + 1) ? "" : "opacity-40"}`}
                    >
                      {isLastStep ? "Submit" : "Next"}
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </section>
          {/* calculations */}
          <section className="flex-1 bg-white-A700 px-3 py-5 rounded-xl gap-4 flex flex-col">
            <p className="text-lg leading-4 font-semibold">Calculations</p>
            <div className="pt-5 pb-10 px-5 border-b-8 rounded-xl bg-custom-calc-gradient border-blue-700 gap-10">
              <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                <div className="flex flex-col gap-4 w-full">
                  <div className="flex justify-between w-full">
                    <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                      Customer Rate
                    </span>
                    {isCalsLoading ? (
                      <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                    ) : (
                      <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                        {calculationData?.customer_rate
                          ? parseFloat(
                              calculationData?.customer_rate as string,
                            )?.toFixed(4)
                          : "0.00"}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between w-full">
                    <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                      Recipient gets
                    </span>
                    {isCalsLoading ? (
                      <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                    ) : (
                      <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                        {calculationData?.base_amount
                          ? parseFloat(
                              calculationData?.base_amount as string,
                            )?.toFixed(2)
                          : "0.00"}
                      </span>
                    )}
                  </div>
                  <div className="w-full border-[.3px]" />
                  <div className="flex flex-col w-full gap-4">
                    <div className="flex justify-between w-full">
                      <span className="text-sm leading-[17px] indent-3.5 font-normal">
                        Total Charges
                      </span>
                      {isCalsLoading ? (
                        <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                      ) : (
                        <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                          {calculationData?.payable_amount
                            ? (
                                Number(calculationData?.payable_amount) -
                                Number(calculationData?.base_amount)
                              ).toFixed(2)
                            : "0.00"}
                        </span>
                      )}
                    </div>
                    <div className="ml-4 border-l border-gray-300 flex flex-col gap-4 mt-2">
                      <div className="flex justify-between w-full mb-2">
                        <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                          GST on FX conversion
                        </span>
                        {isCalsLoading ? (
                          <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                        ) : (
                          <span className="text-xs font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                            {calculationData?.gst
                              ? parseFloat(
                                  calculationData?.gst as string,
                                )?.toFixed(2)
                              : "0.00"}
                          </span>
                        )}
                      </div>
                      <div className="flex justify-between w-full mb-2">
                        <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                          Foreign Bank charges{" "}
                          <span className="text-xs text-gray-500">
                            (incl. GST)
                          </span>
                        </span>
                        {isCalsLoading ? (
                          <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                        ) : (
                          <span className="text-xs font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                            {calculationData?.foreign_bank_charges
                              ? calculationData?.foreign_bank_charges?.toFixed(
                                  2,
                                )
                              : "0.00"}
                          </span>
                        )}
                      </div>
                      <div className="flex justify-between w-full">
                        <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                          TT charges{" "}
                          <span className="text-xs text-gray-500">
                            (incl. GST)
                          </span>
                        </span>
                        {isCalsLoading ? (
                          <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                        ) : (
                          <span className="text-xs font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                            {calculationData?.tt_charges
                              ? calculationData?.tt_charges?.toFixed(2)
                              : "0.00"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                  <span className="text-xs leading-[14.5px] indent-3.5 font-semibold">
                    Total estimation
                  </span>
                  {isCalsLoading ? (
                    <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                  ) : (
                    <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                      {calculationData?.payable_amount
                        ? parseFloat(
                            calculationData?.payable_amount as string,
                          )?.toFixed(2)
                        : "0.00"}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
    // skipcq: JS-0415
  );
};

export default CreateRemit;
