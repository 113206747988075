import React from 'react';
import { Button, Img, FileUpload, Input, Spinner, Text } from "components";
import {
    BankAccountVerifyTranferRequestType,
    bankAccountVerifyTranfer
  } from "service/apis/PartnerDetails";
import { toast } from "react-toastify";
import { AxiosRequestConfig } from 'axios';

type Props = {
  verifyBankAccount:string;
  setVerifyBankAccount: (value: string) => void;
  PartnerDetails:() => void | null;
};

const AddBankAccountStep: React.FC<Props> = ({ 
    verifyBankAccount,
    setVerifyBankAccount,
    PartnerDetails
  }) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [amount, setAmount] = React.useState<string|number>("");

    const verifyTransfer=():void|null=>{

        const auth: string | null = localStorage.getItem("authToken");
        if (auth === null) return null;
        const authToken = JSON.parse(auth);
    
        const req: AxiosRequestConfig<BankAccountVerifyTranferRequestType> = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            account_id: verifyBankAccount,
            amount: amount,
          },
        };
        setLoading(true);
        bankAccountVerifyTranfer(req)
          .then((res) => {
            setVerifyBankAccount("");
            PartnerDetails();
            setLoading(false);
            toast.success("Congratulations , Your Bank Account is linked and verified successfully");
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err?.response?.data?.message);
          });
        
    }

    return(
        <>
        {loading && <Spinner />}
  
        <div className="rounded-[10px] gap-[40px] relative flex flex-col gap-[20px] items-center bg-white-A700 h-[355px] w-[535px] overflow-y-auto sm:w-[300px] px-[31px] py-[33px] gap-[10px] sm:px-[10px] sm:py-[40px]">
            <div className='flex flex-row justify-start w-full'>

            <Img
                  className="animate-spin w[40px] h-[39px] fill-current "
                  src="images/img_verifyLoading.svg"
                  alt="cell"
                />

            </div>
            <div className='flex flex-col w-full justify-start gap-[20px]'>
                <div className='flex flex-col w-full justify-start gap-[8px]'>
                <Text
                className='font-[20px] text-[#000000] opacity-75 font-[600] font-Inter w-full sm:text-xl w-auto text-start'
                size="txtInterSemiBold20"
                >
                Verification under-process
                </Text>
                <Text
                className='text-[#575757] font-[16px] font-[400] font-Inter w-full sm:text-xl w-auto text-start'
                size="txtInterRegular16"
                >
                {`Your account has been verified by crediting a small amount (<Rs. 2).`}
                </Text></div>
                <div className="hidden flex flex-row w-full justify-start items-start gap-[8px]">
                <Img
                  className="mt-[5px] w[16px] h-[16px] w-[16px] text-[#7E7E7E] fill-current "
                  src="images/img_info.svg"
                  alt="cell"
                />

                <Text
                className='font-[12px] text-[#A2A2A2] font-[400] font-Rubik w-full sm:text-xl w-auto text-start'
                size="txtRubikRomanRegular20">
                  Amount will be refunded as soon you are verified Thank you
                </Text>
              </div>
              <div className='flex flex-col w-full justify-start gap-[5px]'>
              <Text
                  className="text-[#404040] text-[15px] leading-[25px]"
                  size="txtRubikRomanRegular20"
                >
                  Amount 
                </Text>
                <Input
                  name="input_One"
                  value={amount}
                  onChange={(e: string) => {
                    setAmount(e);
                  }}
                  type="text"
                  placeholder="Enter Amount"
                  className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                  wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                ></Input>
              </div>
              <div className='flex flex-row w-full justify-end gap-[40px]'>
              <Text
            className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
            size="txtInterRegular16Black9007f"
            onClick={()=>setVerifyBankAccount("")}
          >
            Cancel
          </Text>
          <Button
            className="bg-blue-700 cursor-pointer font-inter min-w-[193px] sm:min-w-[64px] sm:text-[16px] ml-10 sm:ml-[12px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
            onClick={() => verifyTransfer()}
          >
            Verify
          </Button>
              </div>
                
            </div>
            </div>
        </>
    )

  }




export default AddBankAccountStep;