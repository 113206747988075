import { useAppSelector } from "redux/hooks"

export default function Review () {
    const {phone,email, remitter_name, pan_document, benificiary_account_no, amount, benificiary_swift, benificiary_bank_name,benificiary_country,benificiary_name} = useAppSelector(state => state.CreateRedirectPortal)
    return (
        <div className="w-full gap-8 flex flex-col">
            <div className="flex gap-2 flex-col w-full">
                <p className="font-medium text-base text-[#666666]">Personal Details</p>
                <div style={{"boxShadow":"1px 1px 7px 0px rgba(34, 82, 167, 0.1)"}} className="p-5 border gap-10 flex rounded-xl w-full ">
                    <div className="w-full flex justify-between">
                        <p className="font-normal text-sm text-[#666666]">Remitter:</p>
                        <p className=" font-medium text-sm text-black-label">{remitter_name}</p>
                    </div>
                    <div className="w-full flex flex-col gap-5">
                        <div className="flex justify-between">
                            <p className="font-normal text-sm text-[#666666]">Phone:</p>
                            <p className=" font-medium text-sm text-black-label">{phone}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="font-normal text-sm text-[#666666]">Email:</p>
                            <p className=" font-medium text-sm text-black-label">{email}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-2 flex-col w-full">
                <p className="font-medium text-base text-[#666666]">Transaction Detail</p>
                <div style={{boxShadow:"1px 1px 7px 0px #2252A71A"}} className="p-5 border bg-[#F3F8FF] flex rounded-xl justify-between gap-48 w-full">
                    <div className="w-full">
                        <div className="w-full flex flex-col gap-5">
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">University Name:</p>
                                <p className=" font-medium text-sm text-black-label">{phone}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Country:</p>
                                <p className=" font-medium text-sm text-black-label">{email}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Account Number:</p>
                                <p className=" font-medium text-sm text-black-label">{benificiary_account_no}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Swift Code:</p>
                                <p className=" font-medium text-sm text-black-label">{benificiary_swift}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Amount:</p>
                                <p className=" font-medium text-sm text-black-label">{amount}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="w-full flex flex-col gap-5">
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Aadhaar Card :</p>
                                <p className=" font-medium text-sm text-black-label flex items-center gap-2">
                                    Uploaded 
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 0.5C4.541 0.5 0.5 4.541 0.5 9.5C0.5 14.459 4.541 18.5 9.5 18.5C14.459 18.5 18.5 14.459 18.5 9.5C18.5 4.541 14.459 0.5 9.5 0.5ZM13.802 7.43L8.699 12.533C8.573 12.659 8.402 12.731 8.222 12.731C8.042 12.731 7.871 12.659 7.745 12.533L5.198 9.986C4.937 9.725 4.937 9.293 5.198 9.032C5.459 8.771 5.891 8.771 6.152 9.032L8.222 11.102L12.848 6.476C13.109 6.215 13.541 6.215 13.802 6.476C14.063 6.737 14.063 7.16 13.802 7.43Z" fill="#3FB73D" />
                                    </svg>
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Pan Card :</p>
                                {
                                    pan_document?.name ? (
                                        <p className=" font-medium text-sm text-black-label flex items-center gap-2">
                                            Uploaded
                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.5 0.5C4.541 0.5 0.5 4.541 0.5 9.5C0.5 14.459 4.541 18.5 9.5 18.5C14.459 18.5 18.5 14.459 18.5 9.5C18.5 4.541 14.459 0.5 9.5 0.5ZM13.802 7.43L8.699 12.533C8.573 12.659 8.402 12.731 8.222 12.731C8.042 12.731 7.871 12.659 7.745 12.533L5.198 9.986C4.937 9.725 4.937 9.293 5.198 9.032C5.459 8.771 5.891 8.771 6.152 9.032L8.222 11.102L12.848 6.476C13.109 6.215 13.541 6.215 13.802 6.476C14.063 6.737 14.063 7.16 13.802 7.43Z" fill="#3FB73D" />
                                            </svg>
                                        </p>
                                    ) : (
                                        <p className=" font-medium text-sm text-black-label flex items-center gap-2">
                                            Not Uploaded ❌
                                        </p>
                                    )
                                }
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Passport Front :</p>
                                <p className=" font-medium text-sm text-black-label flex items-center gap-2">
                                    Uploaded 
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 0.5C4.541 0.5 0.5 4.541 0.5 9.5C0.5 14.459 4.541 18.5 9.5 18.5C14.459 18.5 18.5 14.459 18.5 9.5C18.5 4.541 14.459 0.5 9.5 0.5ZM13.802 7.43L8.699 12.533C8.573 12.659 8.402 12.731 8.222 12.731C8.042 12.731 7.871 12.659 7.745 12.533L5.198 9.986C4.937 9.725 4.937 9.293 5.198 9.032C5.459 8.771 5.891 8.771 6.152 9.032L8.222 11.102L12.848 6.476C13.109 6.215 13.541 6.215 13.802 6.476C14.063 6.737 14.063 7.16 13.802 7.43Z" fill="#3FB73D" />
                                    </svg>
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Passport Back :</p>
                                <p className=" font-medium text-sm text-black-label flex items-center gap-2">
                                    Uploaded 
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 0.5C4.541 0.5 0.5 4.541 0.5 9.5C0.5 14.459 4.541 18.5 9.5 18.5C14.459 18.5 18.5 14.459 18.5 9.5C18.5 4.541 14.459 0.5 9.5 0.5ZM13.802 7.43L8.699 12.533C8.573 12.659 8.402 12.731 8.222 12.731C8.042 12.731 7.871 12.659 7.745 12.533L5.198 9.986C4.937 9.725 4.937 9.293 5.198 9.032C5.459 8.771 5.891 8.771 6.152 9.032L8.222 11.102L12.848 6.476C13.109 6.215 13.541 6.215 13.802 6.476C14.063 6.737 14.063 7.16 13.802 7.43Z" fill="#3FB73D" />
                                    </svg>
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Offer Letter :</p>
                                <p className=" font-medium text-sm text-black-label flex items-center gap-2">
                                    Uploaded 
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 0.5C4.541 0.5 0.5 4.541 0.5 9.5C0.5 14.459 4.541 18.5 9.5 18.5C14.459 18.5 18.5 14.459 18.5 9.5C18.5 4.541 14.459 0.5 9.5 0.5ZM13.802 7.43L8.699 12.533C8.573 12.659 8.402 12.731 8.222 12.731C8.042 12.731 7.871 12.659 7.745 12.533L5.198 9.986C4.937 9.725 4.937 9.293 5.198 9.032C5.459 8.771 5.891 8.771 6.152 9.032L8.222 11.102L12.848 6.476C13.109 6.215 13.541 6.215 13.802 6.476C14.063 6.737 14.063 7.16 13.802 7.43Z" fill="#3FB73D" />
                                    </svg>
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className="font-normal text-sm text-[#666666]">Instruction Letter :</p>
                                <p className=" font-medium text-sm text-black-label flex items-center gap-2">
                                    Uploaded 
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 0.5C4.541 0.5 0.5 4.541 0.5 9.5C0.5 14.459 4.541 18.5 9.5 18.5C14.459 18.5 18.5 14.459 18.5 9.5C18.5 4.541 14.459 0.5 9.5 0.5ZM13.802 7.43L8.699 12.533C8.573 12.659 8.402 12.731 8.222 12.731C8.042 12.731 7.871 12.659 7.745 12.533L5.198 9.986C4.937 9.725 4.937 9.293 5.198 9.032C5.459 8.771 5.891 8.771 6.152 9.032L8.222 11.102L12.848 6.476C13.109 6.215 13.541 6.215 13.802 6.476C14.063 6.737 14.063 7.16 13.802 7.43Z" fill="#3FB73D" />
                                    </svg>
                                </p>
                            </div>
                        </div>
                    </div>                    
                </div>          
            </div>            
        </div>
    )
}