import React from "react";
import { Button, Img, Input, Spinner, Text } from "components";
import ProductActivationStep1 from "./ProductActivationStep1";
import ProductActivationStep2 from "./ProductActivationStep2";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  ProductRowType,
} from "service/apis/PartnerDetails";

type Props = {
  partner_id:string;
  setShowAddProduct: (value: boolean) => void;
  productOptionsList: ProductRowType[];
  PartnerDetails:() => void | null;
};

const ProductActivation: React.FC<Props> = ({
  partner_id,
  setShowAddProduct,
  productOptionsList,
  PartnerDetails,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [productId, setProductId] = React.useState<string>("");
  const [approvalNeeded, setApprovalNeeded] = React.useState<string>("");
  const [defaultSubmit, setDefaultSubmit] = React.useState<boolean>(false);

  const handleNext = (): void | null => {
    if (currentStep == 1) {
      if (productId == "") {
        toast.error("Please select a Product");
      } else {
        setErrorMessage("");
        setCurrentStep(currentStep + 1);
      }
    } else if (currentStep == 2) {
      setDefaultSubmit(true);
    }
  };

  const handlePrevious = (): void | null => {
    setDefaultSubmit(false);
    setCurrentStep(currentStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ProductActivationStep1
            partner_id={partner_id}
            setErrorMessage={setErrorMessage}
            productOptionsList={productOptionsList}
            productId={productId}
            setProductId={setProductId}
            setShowAddProduct={setShowAddProduct}
          />
        );
      case 2:
        return (
          <ProductActivationStep2
            defaultSubmit={defaultSubmit}
            partner_id={partner_id}
            product_id={productId}
            approvalNeeded={approvalNeeded}
            setApprovalNeeded={setApprovalNeeded}
            PartnerDetails={PartnerDetails}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {loading && <Spinner />}

      <div className="rounded-[10px] relative flex flex-col gap-[20px] items-center bg-white-A700 py-[20px] h-[500px] w-[70%] overflow-y-auto sm:w-[300px] w-[550px] pb-[10px] px-[31px] py-[40px] sm:px-[10px] sm:py-[40px]">
        <Text className="w-full font-rubik text-xl md:text-[22px] pt-[20px] sm:text-xl w-auto text-start text[#000000] font-semibold">
          Product Activation
        </Text>

        {errorMessage != "" && (
          <div className="flex flex-row items-start gap-[5px] w-full">
            <Img
              className="flex h-[16px] w-[16px]"
              src="images/img_errorinfo.svg"
              alt="cell"
            />
            <Text className="w-full font-rubik text-sm text-[#FF6767] w-auto text-start">
              {errorMessage}
            </Text>
          </div>
        )}

        <div className="w-full">{renderStep()}</div>

        <div className="mt-auto flex flex-row bg-white-A700 bottom-[0] flex gap-[33px] h-[73px] md:h-auto items-center justify-end md:px-5 px-[31px] sticky w-full">
          <Text
            className="common-pointer text-base text-black-900_7f text-center w-auto"
            size="txtInterRegular16Black9007f"
            onClick={() => setShowAddProduct(false)}
          >
            Cancel
          </Text>
          {currentStep > 1 && (
            <Text
              className="common-pointer ml-[55px] text-base text-black-900_7f text-center w-auto"
              size="txtInterMedium16Black9007f"
              onClick={() => {
                handlePrevious();
              }}
              // style={{ pointerEvents: pointerEventsStyle }}
            >
              Previous
            </Text>
          )}
          {approvalNeeded !== "" ? (
            <Button
              className="bg-blue-700 cursor-pointer font-inter min-w-[193px] ml-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
              onClick={() => {
                PartnerDetails();
                setShowAddProduct(false)
              }}
            >
              Finish
            </Button>
          ) : (
            <Button
              disabled={currentStep === 3}
              className="bg-blue-700 cursor-pointer font-inter min-w-[193px] ml-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </div>

        {/* {currentStep !== 7 && (
          <Footer1
            className="bg-white-A700 bottom-[0] flex gap-[33px] h-[73px] md:h-auto items-center justify-end md:px-5 px-[83px] py-[27px] sticky w-full"
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            handleCancel={() => setShowAddProduct(false)}
            currentStep={currentStep}
            steps={productActivationSteps}
          />
        )} */}
      </div>

      <ToastContainer />
    </>
  );
};

export default ProductActivation;
