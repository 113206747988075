import { Option } from "../types";

export interface ITabList {
    value: string;
    label: string;
    enabled: boolean;
}

export const tablist: ITabList[] = [
    {
        value: "retail",
        label: 'Retail',
        enabled: true
    },
    {
        value: "corporate",
        label: 'Corporate',
        enabled: true
    },
    {
        value: "partner",
        label: 'Partner',
        enabled: true
    }
];

export const entityTypes = [
    { value: "firm", label: "Firm" },
    { value: "freelancer", label: "Freelancer" },
];

export const contactPersonTypes = [
    { value: "employee", label: "Employee" },
    { value: "coordinator", label: "Coordinator" }
];

export const campaignOptions = [
    { value: "", label: "--" },
    { value: "socialMedia", label: "Social Media" },
    { value: "email", label: "Email" },
    { value:"google", label: "Google" }
];

export const refferedOptions = [
    { value: "none", label: "Not referred" },
    { value: "partner", label: "Partner Referral" },
    { value: "staff", label: "Employee Referral" },
];


export interface FieldVisibility {
    company_name: boolean;
    company_email: boolean;
    contact_person_name: boolean;
    contact_person_phone: boolean;
    contact_person_type: boolean;
    customer_contact_name: boolean;
    customer_contact_email: boolean;
    customer_contact_phone: boolean;
    customer_contact_address: boolean;
    entityName: boolean;
    entityEmail: boolean;
    entityType: boolean;
    entityNumber: boolean;
    campaign: boolean;
    referralSource: boolean;
}

export const pipelineFeatureRules: Record<string, Partial<FieldVisibility>> = {
    corporate: {
        company_name: true,
        company_email: true,
        contact_person_name: true,
        contact_person_phone: true,
        contact_person_type: true,
        customer_contact_name: false,
        customer_contact_email: false,
        customer_contact_phone: false,
        customer_contact_address: false,
        entityName: false,
        entityEmail: false,
        entityType: false,
        entityNumber: false,        
    },
    retail: {
        company_name: false,
        company_email: false,
        contact_person_name: false,
        contact_person_phone: false,
        contact_person_type: false,
        customer_contact_name: true,
        customer_contact_email: true,
        customer_contact_phone: true,
        customer_contact_address: true,
        entityName: false,
        entityEmail: false,
        entityType: false,
        entityNumber: false, 
        campaign: true,
        referralSource: true,       
    },
    partner:{
        company_name: false,
        company_email: false,
        contact_person_name: false,
        contact_person_phone: false,
        contact_person_type: false,
        customer_contact_name: false,
        customer_contact_email: false,
        customer_contact_phone: false,
        customer_contact_address: false,
        entityName: true,
        entityEmail: true,
        entityType: true,
        entityNumber: true,
    }
};

const retailFields = {
    "customer_contact_number": {
        label: "Contact Number",
        type: "text",
        required: true,
        placeholder: "Enter Contact Number",
    },
    "customer_contact_name": {
        label: "Contact Name",
        type: "text",
        required: true,
        placeholder: "Enter Contact Name",
    },
    "customer_contact_address": {
        label: "Contact Address",
        type: "text",
        required: true,
        placeholder: "Enter Contact Address",
    },
    "customer_contact_email": {
        label: "Contact Email",
        type: "email",
        required: true,
        placeholder: "Enter Contact Email",
    },
}

const coorporateFields = {
    "company_name": {
        label: "Company Name",
        type: "text",
        required: true,
        placeholder: "Enter Company Name",
    },
    "company_email": {
        label: "Company Email",
        type: "email",
        required: true,
        placeholder: "Enter Company Email",
    },
    "contact_person_name": {
        label: "Contact Person Name",
        type: "text",
        required: true,
        placeholder: "Enter Contact Person Name",
    },
    "contact_person_phone": {
        label: "Contact Person Phone",
        type: "text",
        required: true,
        placeholder: "Enter Contact Person Phone",
    },
    "contact_person_type": {
        label: "Contact Person Type",
        type: "text",
        required: true,
        placeholder: "Enter Contact Person Type",
    },
}

const partnerFields = {
    "entityName": {
        label: "Entity Name",
        type: "text",
        required: true,
        placeholder: "Enter Entity Name",
    },
    "entityEmail": {
        label: "Entity Email",
        type: "email",
        required: true,
        placeholder: "Enter Entity Email",
    },
    "entityType": {
        label: "Entity Type",
        type: "text",
        required: true,
        placeholder: "Enter Entity Type",
    },
    "entityNumber": {
        label: "Entity Number",
        type: "text",
        required: true,
        placeholder: "Enter Entity Number",
    },
}

export const searchPlaceholderText: Record<string, string> = {
    retail : "Search customer with customer ID, name and number",
    corporate : "Search contact with company name or email",
    partner : "Search entity with name",
};

export const initOppType: Option[] = [
    { value: "callOutbound", label: "Outbound - Call" },
    { value: "callInbound", label: "Inbound - Call" },
    { value: "emailOutbound", label: "Outbound - Email" },
    { value: "emailInbound", label: "Inbound - Email" },
    { value: "meeting", label: "In Person Meeting" },
    { value: "visit", label: "Visit" },
    { value: "chatOutbound", label: "Outbound - Chat" },
    { value: "chatInbound", label: "Inbound - Chat" },    
    {value: "socialMedia", label: "Social Media"},
    {value: "website", label: "Website"},
];