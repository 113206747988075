import {
  ArrowDown,
  ArrowRight,
  ArrowUp,
  Bug,
  CheckCircle2,
  Circle,
  HelpCircle,
  MailWarning,
  PackagePlus,
  ScrollText,
  Timer,
  XCircle,
} from "lucide-react";
import { FaExclamation } from "react-icons/fa";

export const deal_utilization_options = [
  { value: "unutilized", label: "Unutilized" },
  { value: "utilized", label: "Utilized" },
]

export const opportunity_status_options = [
  {
    value: "open",
    label: "Open",
    icon: FaExclamation,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "close",
    label: "Close",
    icon: CheckCircle2,
    textColor: "#C8831B",
    bgColor: "#FFF0D9",
  }
];

export const opportunity_contact_status_options = [
  {
    value: "open",
    label: "Open",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "close",
    label: "Close",
    icon: CheckCircle2,
    textColor: "#C8831B",
    bgColor: "#FFF0D9",
  }
];

export const deal_status_options = [
  {
    value: "unconfirmed",
    label: "Unconfirmed",
    icon: FaExclamation,
    textColor: "#f43f5e",
    bgColor: "#fecdd3",
  },
  {
    value: "confirmed",
    label: "Confirmed",
    icon: CheckCircle2,
    textColor: "#9747FF",
    bgColor: "#DABDFF",
  },
  {
    value: "hold",
    label: "Hold",
    icon: MailWarning,
    bgColor: "#D2D2D2",
    textColor: "#232323",
  },
  {
    value: "cancelled",
    label: "Cancelled",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
  {
    value: "fulfilled",
    label: "Fulfilled",
    icon: CheckCircle2,
    textColor: "#22c55e",
    bgColor: "#86efac",
  },
];

export const customer_status_options = [
  {
    value: "active",
    label: "Active",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "pending",
    label: "Pending",
    icon: XCircle,
    textColor: "#333333",
    bgColor: "#E8E8E8",
  },
  {
    value: "blocked",
    label: "Blocked",
    icon: MailWarning,
    bgColor: "#D2D2D2",
    textColor: "#232323",
  },
];
export const partner_status_options = [
  {
    value: "active",
    label: "Active",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "inactive",
    label: "In-active",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
  {
    value: "incomplete",
    label: "Incomplete",
    icon: MailWarning,
    bgColor: "#FFF0D9",
    textColor: "#F4960A",
  },
  {
    value: "pending",
    label: "Pending",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
];
export const kyc_status_options = [
  {
    value: "active",
    label: "Full KYC",
    icon: CheckCircle2,
    textColor: "#22c55e",
    bgColor: "#86efac",
  },
  {
    value: "expired",
    label: "Expired",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
];

export const finance_status_options = [
  {
    value: "initiated",
    label: "Initiated",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "completed",
    label: "Completed",
    icon: CheckCircle2,
    textColor: "#22c55e",
    bgColor: "#86efac",
  },
];

export const finance_payout_status_options = [
  {
    value: "initiated",
    label: "Initiated",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "draft",
    label: "Draft",
    icon: CheckCircle2,
    textColor: "#22c55e",
    bgColor: "#86efac",
  },
];

export const order_status_options = [
  {
    value: "processing",
    label: "Processing",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "pre processing",
    label: "Pre Processing",
    icon: FaExclamation,
    textColor: "#333333",
    bgColor: "#ECECEC",
  },
  {
    value: "cancelled",
    label: "Cancelled",
    icon: XCircle,
    textColor: "#EA2C2C",
    bgColor: "#FFCECE",
  },
  {
    value: "fulfilled",
    label: "Fulfilled",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
];

export const payment_status_options = [
  {
    value: "received",
    label: "Received",
    icon: FaExclamation,
    textColor: "#5850EC",
    bgColor: "#CDDBFE",
  },
  {
    value: "pending",
    label: "Pending",
    icon: XCircle,
    textColor: "#d97706",
    bgColor: "#fde68a",
  },
  {
    value: "partially received",
    label: "Partially Received",
    icon: CheckCircle2,
    textColor: "#15803d",
    bgColor: "#86efac",
  },
];

export const status_options = [
  {
    value: "backlog",
    label: "Backlog",
    icon: HelpCircle,
  },
  {
    value: "todo",
    label: "Todo",
    icon: Circle,
  },
  {
    value: "in-progress",
    label: "In Progress",
    icon: Timer,
  },
  {
    value: "done",
    label: "Done",
    icon: CheckCircle2,
  },
  {
    value: "canceled",
    label: "Canceled",
    icon: XCircle,
  },
];

export const label_options = [
  {
    value: "bug",
    label: "Bug",
    icon: Bug,
  },
  {
    value: "feature",
    label: "Feature",
    icon: PackagePlus,
  },
  {
    value: "documentation",
    label: "Documentation",
    icon: ScrollText,
  },
];

export const priority_options = [
  {
    value: "low",
    label: "Low",
    icon: ArrowDown,
  },
  {
    value: "medium",
    label: "Medium",
    icon: ArrowRight,
  },
  {
    value: "high",
    label: "High",
    icon: ArrowUp,
  },
];

export const staff_status_options = [
  {
    value: "active",
    label: "Active",
    icon: CheckCircle2,
    textColor: "#23AB28",
    bgColor: "#E1FFE2",
  },
  {
    value: "inactive",
    label: "Inactive",
    icon: XCircle,
    textColor: "#F4C80A",
    bgColor: "#F7C90533",
  },
];