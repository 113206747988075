import React from "react";
import { Button, Img, List, Spinner, Text, Switch } from "components";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { toast } from "sonner";
import ChannelPartnerRegFiveHeader from "components/ChannelPartnerRegFiveHeader";
import ChannelPartnerRegFiveRowprimarytext from "components/ChannelPartnerRegFiveRowprimarytext";
import ChannelPartnerProducts from "components/ChannelPartnerProducts";
import {
  getCommisionRatesStep5,
  postUpdateCommisionRatesStep5,
  getAllProducts,
  GetAllProductsRequestType,
} from "service/apis/PartnerRegistrationapi";

import {
  setEnabledProducts,
  setDummy,
  setName,
} from "redux/features/PartnerRegistration";

type FooterProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    disableButton: boolean;
  }>;

const Step5: React.FC<FooterProps> = ({ setCurrentStep }) => {
  const [commisionrate, setCommisionrate] = React.useState<any>([]);
  const [Update, setUpdate] = React.useState<boolean>(false);
  const [UpdateCommisionRate, setUpdateCommisionRate] = React.useState<any>([]);
  const [OldCommisionRate, setOldCommisionRate] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editbutton, setEditbutton] = React.useState<boolean>(false);
  const [products, setProducts] = React.useState<any>();
  const [selectedProduct, setSelectedProduct] = React.useState<any>([]);

  const dispatch = useAppDispatch();

  const enabledProducts = useAppSelector(
    (state) => state.PartnerRegistration.enabledProducts,
  );

  React.useEffect(() => {
    dispatch(setEnabledProducts(selectedProduct));
  }, [selectedProduct]);

  React.useEffect(() => {
    fetchProducts();
    fetchCommisionRatesStep5();
  }, []);

  function fetchCommisionRatesStep5(): void | null {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    getCommisionRatesStep5(req)
      .then((res) => {
        setCommisionrate(res.data);
      })
      .catch(() => {});
  }

  const fetchProducts = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    getAllProducts(req)
      .then((res) => {
        setProducts(res.data);
      })
      .catch(() => {});
  };

  const updatedCommisions = (): void | null => {
    const updatedOldCommision = OldCommisionRate.map((obj1: any) => {
      const correspondingObj2 = UpdateCommisionRate.find(
        (obj2: any) => obj2.purpose_id === obj1.purpose_id,
      );

      // If commission exists and has a lower charge, update the charge in oldCommission
      if (correspondingObj2 && correspondingObj2.charges <= obj1.charges) {
        return { ...obj1, charges: correspondingObj2.charges };
      }

      return obj1; // No update needed
    });

    const updatedNewCommision = UpdateCommisionRate.filter(
      (obj1: any) =>
        !updatedOldCommision.some(
          (obj2: any) =>
            obj1.purpose_id === obj2.purpose_id &&
            obj1.charges === obj2.charges,
        ),
    );

    const approvalNeeded = updatedOldCommision.filter((obj1: any) =>
      updatedNewCommision.some((obj2: any) => obj1.id === obj2.id),
    );
    const finalApprovalNeeded = approvalNeeded.map((obj1: any) => {
      const correspondingObj2 = updatedNewCommision.find(
        (obj2: any) => obj2.purpose_id === obj1.purpose_id,
      );

      if (correspondingObj2) {
        return { ...obj1, charges: correspondingObj2.charges };
      }

      return obj1; // No update needed
    });
    const approvalNotNeeded = updatedOldCommision.filter(
      (obj1: any) =>
        !updatedNewCommision.some((obj2: any) => obj1.id === obj2.id),
    );

    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const channel_id = localStorage.getItem("child_channel_id");
    if (channel_id === null) return null;

    const req = {
      data: {
        child_channel: channel_id,
        accept_default: false,
        unapproved_commision_rates: finalApprovalNeeded,
        approved_commision_rates: approvalNotNeeded,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);

    postUpdateCommisionRatesStep5(req)
      .then((res) => {
        setLoading(false);
        if (res.data.channel) {
          localStorage.setItem("ApplicationId", res.data.channel.id);
          localStorage.setItem(
            "ApplicationStatus",
            res.data.channel.approval_status,
          );
        }
        if (setCurrentStep) {
          setCurrentStep((prev) => prev + 1);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message || "Something went wrong");
      });
  };

  function updateCommision(): void | null {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const channel_id = localStorage.getItem("child_channel_id");
    if (channel_id === null) return null;

    let nonUpdatedRates = commisionrate.filter(
      (e: any) =>
        !UpdateCommisionRate.some((a: any) => a.purpose_id === e.purpose_id),
    );

    nonUpdatedRates = nonUpdatedRates.map((e: any) => {
      return {
        purpose_id: e.purpose_id,
        charges: e.charges,
      };
    });

    let newUpdateCommisionRate = [...nonUpdatedRates, ...UpdateCommisionRate];

    if (UpdateCommisionRate.length === 0) {
      newUpdateCommisionRate = [];
    }

    const req = {
      data: {
        child_channel: channel_id,
        accept_default: newUpdateCommisionRate.length === 0,
        commision_rates: newUpdateCommisionRate,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);

    postUpdateCommisionRatesStep5(req)
      .then((res) => {
        setLoading(false);
        if (res.data.channel) {
          localStorage.setItem("ApplicationId", res.data.channel.id);
          localStorage.setItem(
            "ApplicationStatus",
            res.data.channel.approval_status,
          );
        }
        if (setCurrentStep) {
          setCurrentStep((prev) => prev + 1);
        }
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message || "Something went wrong");
      });
  }

  const handleonClick = () => {
    updateCommision();
  };

  return (
    <div className="flex">
      {loading && <Spinner />}
      <div className=" flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start md:max-w-[] md:min-w-full md:px-5 w-full md:w-full">
        <div className="flex flex-col gap-[15px] items-start justify-start w-full">
          <div className="flex w-full justify-end gap-[10px]">
            {OldCommisionRate.length > 0 && (
              <Button
                className="bg-blue-700 cursor-pointer font-inter min-w-[100px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
                onClick={updatedCommisions}
              >
                Save
              </Button>
            )}
          </div>
          <h3 className=" font-semibold text-lg leading-4 pb-8">
            Set Commissions
          </h3>
          <div className="relative flex flex-col justify-end w-full h-full gap-5">
            <p className=" font-medium text-sm leading-4">
              Select the commission for
            </p>
            {products?.map((item: any, key: number) => {
              return (
                <div
                  key={key}
                  className="flex w-full border bg-gray-button-gray-lite border-black-label p-2.5 rounded-md gap-5 h-full items-center relative"
                >
                  <ChannelPartnerProducts
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    OldCommisionRate={OldCommisionRate}
                    setOldCommisionRate={setOldCommisionRate}
                    UpdateCommisionRate={UpdateCommisionRate}
                    setUpdateCommisionRate={setUpdateCommisionRate}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step5;
