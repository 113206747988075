import { apis, getTokenFromCookies } from "../service";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";
export const GROUPS = "https://xxl6-bbr3-kxzs.m2.xano.io/api:Gd8PAVtY";
export const SITE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";
const staff_order = `${BASE_URL}:M4Re3bq8/orders`;
const staff_deal = `${BASE_URL}:6JkkZBcl/deals`;
export const GetGroups = "https://xxl6-bbr3-kxzs.m2.xano.io/api:Gd8PAVtY";
export const GetBranches = "https://xxl6-bbr3-kxzs.m2.xano.io/api:yyd3i3Ww";

const API_URLS = {
  POST_STAFF_LOGIN: `${BASE_URL}:uw_L93nW/staff/v2-login`,
  FORGOT_PASSWORD: `${BASE_URL}:uw_L93nW/forgot-password`,
  GET_GROUPS: `${GROUPS}/groups`,
  POST_RESET_PASSWORD: `${BASE_URL}:uw_L93nW/auth/reset-password`,
  GROUPS: `${BASE_URL}Gd8PAVtY/groups`,
  STAFF_STAFF: `${BASE_URL}:gfz8um1p/staff`,
  STAFF_STAFF_POST: `${BASE_URL}:uw_L93nW`,
  STAFF_CHANNEL: `${BASE_URL}:MhhkaM6y/channels`,
  STAFF_CUSTOMER: `${BASE_URL}:NcXLAC78/customers`,
  STAFF_AUTH: `${BASE_URL}:uw_L93nW`,
} as const;

// Login API
export type PostStaffLoginRequestType = {
  data: {
    email: string;
    password: string;
  };
};

export type PostStaffLoginResponseType = Partial<{
  authToken?: {
    auth_token: string;
    channel_id?: number | undefined;
    level?: string | undefined;
    staff_id?: number | undefined;
  };
}>;

export const postStaffLogin = (payload: AxiosRequestConfig): AxiosPromise =>
  apis.post(API_URLS.POST_STAFF_LOGIN, payload);

// Forgot Password API

export type ForgotPasswordRequestType = {
  mail: string;
  role: string;
};

export type ForgotPasswordResponseType = Partial<{}>;

export const forgotPassword = (payload: AxiosRequestConfig): AxiosPromise =>
  apis.post(API_URLS.FORGOT_PASSWORD, payload);

// export const GetGroups = (payload: AxiosRequestConfig): AxiosPromise => apis.get(API_URLS.GET_GROUPS, payload);

export type GetListRequestParams = Partial<{
  q: string;
  page: number;
  per_page: number;
  type: string;
  status: string;
  role: string;
  kyc_status: string;
  city: string;
  order_by: string;
}>;
export type GetListResponse = {
  curPage: number;
  items: any;
  pageTotal: number;
  itemsTotal: number;
};

export const GetList = (
  url: string,
  payload: GetListRequestParams,
): AxiosPromise =>
  // apis.get(`${url}?q=${payload.q}&page=${payload.page}&per_page=${payload.per_page}`, { headers: { Authorization: getTokenFromCookies() } });
  apis.get(
    `${url}?q=${payload.q}&page=${payload.page}&per_page=${payload.per_page}&type=${payload.type}&status=${payload.status}&role=${payload.role}&kyc_status=${payload.kyc_status}&city=${payload.city}&order_by=desc`,
    {
      headers: {
        Authorization: getTokenFromCookies(),
      },
    },
  );

export const GetStaff = (payload: GetListRequestParams): AxiosPromise =>
  GetList(API_URLS.STAFF_STAFF, payload);
export const GetChannel = (payload: GetListRequestParams): AxiosPromise =>
  GetList(API_URLS.STAFF_CHANNEL, payload);
// export const GetCustomer = (payload: GetListRequestParams): AxiosPromise => GetList(API_URLS.STAFF_CUSTOMER, payload);
export type GetCustomerRequesrType = Partial<{
  headers: {
    Authorization: string;
  };
  params: {
    q: string;
    page: number;
    per_page: number;
    kyc_status: string;
    city: string;
    status: string;
  };
}>;
export type GetCustomerResponseType = {};

export const GetCustomer = (
  payload: AxiosRequestConfig<GetCustomerRequesrType>,
): AxiosPromise => apis.get(API_URLS.STAFF_CUSTOMER, payload);

// get channel table
export type GetChannelRequestType = Partial<{
  headers: {
    Authorization: string;
  };
  params: {
    q: string;
    page: number;
    per_page: number;
    primary_location: string;
    type: string;
    status: string;
    order_by: string;
  };
}>;
export type GetChannelResponseType = {};

export const getChannel = (
  payload: AxiosRequestConfig<GetChannelRequestType>,
): AxiosPromise =>
  apis.get(API_URLS.STAFF_CHANNEL, {
    ...payload,
    headers: {
      Authorization: getTokenFromCookies(),
    },
  });

export const postCustomer = (payload: AxiosRequestConfig): AxiosPromise =>
  apis.post(API_URLS.STAFF_CUSTOMER, payload);

// Reset Password API(First Time Login)
export type PostResetPasswordRequestType = {
  email: string;
  password: string;
  is_owner: boolean;
  new_password: string | undefined;
};

export const postResetPassword = (
  payload: AxiosRequestConfig<PostResetPasswordRequestType>,
) => apis.post(API_URLS.POST_RESET_PASSWORD, payload);

// rest password using link
export type PostResetPasswordLinkRequestType = {
  token: string;
  new_password: string;
};

export type PostResetPasswordLinkResponseType = Partial<{}>;
export const postResetPasswordLink = (
  payload: AxiosRequestConfig<PostResetPasswordLinkRequestType>,
) => {
  const url = `${API_URLS.STAFF_AUTH}/owner/forget-verify`;
  return apis.post(url, payload);
};

// Get Groups API
export type GetGroupsRequest = Partial<{
  Authorization: string;
}>;
export type GetGroupsResponse = Partial<{
  id: string;
  created_at: string;
  channel_id: string;
  name: string;
  description: string;
}>;

export const postStaff = (payload: AxiosRequestConfig): AxiosPromise =>
  apis.post(API_URLS.STAFF_STAFF_POST, {
    ...payload,
    headers: {
      Authorization: getTokenFromCookies(),
    },
  });

// get order
export type GetOrderRequestType = Partial<{
  headers: {
    Authorization: string;
  };
  page: number;
  per_page: number;
  service_partner_id: string;
  status: string;
  payment_status: string;
}>;
export type GetOrderResponseType = Partial<{}>;

export const getOrder = (
  payload: AxiosRequestConfig<GetOrderRequestType>,
): AxiosPromise => apis.get(staff_order, payload);

// get deal
export type GetDealRequestType = Partial<{
  headers: {
    Authorization: string;
  };
  page: number;
  per_page: number;
  service_partner_id: string;
  status: string;
  utilization_status: string;
  payment_status: string;
}>;
export type GetDealResponseType = Partial<{}>;

export const getDeal = (
  payload: AxiosRequestConfig<GetOrderRequestType>,
): AxiosPromise => apis.get(staff_deal, payload);

// get city
