import { Option } from "pages/Opportunities/types";
import { useState } from "react";
import ReactSelect, { SingleValue } from "react-select";
import { setMediumOfContact, setProductList } from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { initOppType } from "../utils/featureRule";

interface ContactDetailsProps {
    errors: Record<string, string>;
}

export default function ExistingInteractionDetail({errors}: ContactDetailsProps) {
    const [typeOptions, setTypeOptions] = useState<Option[]>(initOppType);
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const dottedBorderWidth = "after:h-32";
    const dispatch = useAppDispatch();
    const { forex_note, remit_note, medium_of_contact, product_list,contact_person_name } = useAppSelector((state) => state.CreateOpportunity);
    const handleChangeType = (
        newValue: SingleValue<Option>,
    ) => {
        // dispatch(setContactPersonType(newValue?.value??''));
        setSelectedOption(newValue);
        dispatch(setMediumOfContact(newValue?.value ?? ''));
    };

    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>, id: string) => {
        const value = e.target.value;
        const updatedProductList = product_list.map(product => {
            if (product.product_id === id) {
                return { ...product, notes: value,remarks:"" };
            }
            return product;
        });
        dispatch(setProductList(updatedProductList));
    }

    return (
        <form className="flex flex-col gap-10" onSubmit={() => { }} >
            <div className="flex flex-col pl-10 gap-8 w-full max-w-md ">
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                    <div
                        className={`flex items-center top-0 flex-col after:right-10  ${dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                        <img
                            className="relative -left-10 "
                            src="/images/overview/ellipse.svg"
                            alt=""
                        />
                    </div>
                    <label htmlFor="contact_medium" className="text-sm">How did {contact_person_name? contact_person_name : "they"} contact you?</label>
                    <ReactSelect
                        className="w-full"
                        placeholder='Select the channel of communication'
                        menuPlacement='bottom'
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                zIndex: 0,
                                borderRadius: ".5rem",
                                padding: ".6rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderColor: state.isFocused ? "#165FE3" : "#333333",
                                boxShadow: "none",
                            }),
                            menu: (provided, state) => ({
                                ...provided,
                                zIndex: 1000,
                            }),
                        }}
                        options={typeOptions}
                        onChange={handleChangeType}
                        value={medium_of_contact ? { label: typeOptions.find(el => el.value === medium_of_contact)?.label as string, value: medium_of_contact } : selectedOption}
                        name="contact_medium"
                        id="contact_medium"
                    />
                </div>
                {
                    product_list.map((product, index) => (
                        <div key={product.product_id} className="flex relative flex-col gap-3 items-start justify-start w-full">
                            <div
                                className={`flex items-center top-0 flex-col after:right-10  ${product_list.length - 1 === index ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                            >
                                <img
                                    className="relative -left-10 "
                                    src="/images/overview/ellipse.svg"
                                    alt=""
                                />
                            </div>
                            <label htmlFor={product.subject} className="text-sm">Note for {product.subject}</label>
                            <textarea placeholder="Write note here...." onChange={e => handleNoteChange(e, product.product_id)} id={product.subject} value={product.notes} className="border h-36 border-black-label rounded-lg p-4 w-full" />
                        </div>
                    ))
                }
            </div>
        </form>
    )
}