import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useCallback } from "react";
import { setEmail, setIsStudent, setLastKycDate, setLrsAmount, setPanBinary, setPanDocument, setPanFound, setPanNo, setPhone, setRelationWithStudent, setRemitterEmail, setRemitterName, setRemitterPhone } from "redux/features/Redirect Portal";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Img } from "components";
import { useDebounce } from "pages/CreateDeal/newdeal";

const relationOptions: {label:string, value: string}[] = [
    {
        value: "parent",
        label: "I am the Parent of the student.",
    },
    {
        value: "guardian",
        label: "I am the Guardian of the student.",
    },
    {
        value: "spouse",
        label: "I am the Spouse or Partner of the student.",        
    },
    {
        value: "sibling",
        label: "I am the Sibling of the student.",
    }
]

interface RemitterDetailsProps {
    errors: Record<string, string>;
}

const fakePanData: { [key: string]: { name: string; phone: string; email: string; last_kyc: string; lrs_amount:string } } = {
    "1234567890": {
        name : "jojn doe",
        phone : "1234567890",
        email : "jhondoe@a.com",
        last_kyc : "12/12/2021",
        lrs_amount : "500000"
    },
    "1212121212": {
        name : "jojn doe2",
        phone : "1234567891",
        email : "jhondoe@a.ceom",
        last_kyc : "12/12/2023" ,
        lrs_amount : "2300000"
    },    
    "1231231231": {
        name : "jojn doefmn",
        phone : "123456789110",
        email : "jhondoe@a.cwwom",
        last_kyc : "12/12/3021" ,
        lrs_amount : "100000"
    },
    "1234123412": {
        name : "jojn doe3",
        phone : "1234567893",
        email : "",
        last_kyc : "12/12/2025",
        lrs_amount : "760000"
    },
}

const queryPanData = (pan_no: string) => {
    return new Promise<
    {
        name: string;
        phone: string;
        email: string;
        last_kyc: string;
        lrs_amount: string;
    }
    >((resolve, reject) => {
        setTimeout(() => {
            if (!fakePanData[pan_no]) {
                reject(new Error("PAN not found"));
            }
            resolve(fakePanData[pan_no]);
        }, 200);
    });
}

export default function RemitterDetails({errors}:RemitterDetailsProps) {
    const { is_student,pan_found, pan_no, pan_binary, pan_document,relation_with_student, email,phone,remitter_email,remitter_name,remitter_phone,last_kyc_date } = useAppSelector(state => state.CreateRedirectPortal)
    const dispatch = useAppDispatch()
    const dottedBorderWidth = "after:h-28 after:border-blue-700";

    const fetchPanData = async () => {        
        if (pan_no.length < 10) {
            if (pan_no.length < 10 && remitter_name) {
                dispatch(setPanFound(false));
                dispatch(setRemitterName(""));
                dispatch(setRemitterEmail(""));
                dispatch(setRemitterPhone(""));
                dispatch(setLastKycDate(""));
                return;
            }
            return;
        }
        try {
            const data = await queryPanData(pan_no);
            if (data?.name) {
                dispatch(setPanFound(true));
                dispatch(setRemitterName(data.name));
                dispatch(setRemitterEmail(data.email));
                dispatch(setRemitterPhone(data.phone));
                dispatch(setLastKycDate(data.last_kyc));
                dispatch(setLrsAmount(data.lrs_amount));
            }
        } catch (error) {
            dispatch(setPanFound(false));
        }
    }

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            dispatch(setPanDocument(file));
            const reader = new FileReader();

            reader.onload = () => {
                dispatch(setPanBinary(reader.result as string));
            };

            reader.readAsDataURL(file);
        }
    }, []);

    useDebounce(fetchPanData , 1000, [pan_no])
    return (
        <form className="w-full flex gap-5 justify-between" >
            <div className="flex flex-col pl-10 gap-8 ">
                <div className="flex relative w-[508px] flex-col gap-3 items-start justify-start">
                    <div
                        className={`flex items-center top-0  flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                        <img
                            className="relative -left-10 "
                            src="/images/overview/ellipse.svg"
                            alt=""
                        />
                    </div>
                    <p className="text-sm font-medium text-black-label">Are you student or not ?</p>
                    <RadioGroup
                        className="flex w-full"
                        defaultValue="no"
                        value={is_student}
                        onValueChange={useCallback((value: "yes" | "no") => dispatch(setIsStudent(value)), [])}
                    >
                        <div
                            className={`${is_student === "yes" ? " border-blue-700" : ""} flex items-center w-full rounded-lg border p-3 space-x-2`}
                        >
                            <RadioGroupItem
                                value="yes"
                                id="yes"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                            />
                            <label className=" text-sm font-medium tracking-[-0.01em]" htmlFor="yes">Yes, I am the student</label>
                        </div>
                        <div
                            className={`${is_student === "no" ? "border-blue-700" : ""} flex flex-nowrap w-full items-center rounded-lg border p-3 space-x-2`}
                        >
                            <RadioGroupItem value="no" id="no" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2" />
                            <label className=" text-sm font-medium tracking-[-0.01em]" htmlFor="no">No, I am related to the student</label>
                        </div>
                    </RadioGroup>
                </div>
                {
                    !(is_student === "yes") &&
                    <div className="flex max-w-md relative flex-col gap-3 items-start justify-start w-full">
                        <div
                            className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                            <img
                                className="relative -left-10 "
                                src="/images/overview/ellipse.svg"
                                alt=""
                            />
                        </div>
                        <p className="text-sm font-medium text-black-label">Select your relationship with the student</p>
                        <div className="relative w-full flex rounded-md">
                            <Select value={relation_with_student} onValueChange={value=> dispatch(setRelationWithStudent(value))}>
                                <SelectTrigger className="py-4 px-[15px] h-auto rounded-lg">
                                    <SelectValue placeholder="Select Relation with student" />
                                </SelectTrigger>
                                <SelectContent>
                                    {
                                        relationOptions.map((option) => (
                                            <SelectItem className="hover:bg-[#EFF4FF] py-3.5 w-full" key={option.value} value={option.value}>
                                                {option.label}
                                            </SelectItem>
                                        ))
                                    }
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                }
                <div className="flex max-w-md relative flex-col gap-3 items-start justify-start w-full">
                    <div
                        className={`flex items-center top-0 flex-col after:right-10  ${pan_found ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                        <img
                            className="relative -left-10 "
                            src="/images/overview/ellipse.svg"
                            alt=""
                        />
                    </div>
                    <label htmlFor="pan" className="text-sm font-medium text-black-label">Enter the PAN number.</label>
                    <input type="text" placeholder="Enter PAN number" onChange={(e) => dispatch(setPanNo(e.target.value))} id="pan" name="pan" value={pan_no} className="border border-black-label rounded-lg p-4 w-full" />
                    {
                        errors.pan_no && <small className="text-red-500">{errors.pan_no}</small>
                    }
                    {pan_found && <p className="">PAN Found ✅</p>}
                </div>
                {
                    !pan_found &&
                    <div className="flex max-w-md relative flex-col gap-2.5 items-start justify-center w-full">
                        <div
                            className={`flex items-center top-0 flex-col after:right-10 absolute ${false ? "after:h-0 " : dottedBorderWidth}  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                            <img
                                className="relative -left-10 "
                                src={`images/overview/ellipse.svg`}
                                alt=""
                            />
                        </div>
                        <p
                            className="text-sm font-medium"
                        >
                            Upload your PAN
                        </p>
                        <div
                            className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${pan_binary && "bg-[#F2F7FF]"}`}
                        >
                            <div className="flex gap-3 items-center overflow-hidden">
                                <Img src="/images/remit/file.svg" alt="" />
                                <p className="truncate">
                                    {pan_document?.name ? pan_document?.name : "No file choosen"}
                                </p>
                            </div>
                            <label
                                htmlFor="pan_document"
                                className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                            >
                                {pan_binary ? "Re-upload" : "Upload"}
                            </label>
                            <input
                                name="pan_document"
                                type="file"
                                id="pan_document"
                                className="hidden"
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </div>
                    </div>
                }
                {
                    !pan_found &&
                    <div className="flex max-w-md relative flex-col gap-3 items-start justify-start w-full">
                        <div
                            className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                            <img
                                className="relative -left-10 "
                                src="/images/overview/ellipse.svg"
                                alt=""
                            />
                        </div>
                        <label htmlFor="email" className="text-sm font-medium text-black-label">What is your Email ID  ?</label>
                        <input type="email" placeholder="Enter email id" onChange={(e) => dispatch(setEmail(e.target.value))} id="email" name="email" value={email} className="border border-black-label rounded-lg p-4 w-full" />
                        {
                            errors.email && <small className="text-red-500">{errors.email}</small>
                        }
                    </div>
                }
                {
                    !pan_found &&
                    <div className="flex max-w-md relative flex-col gap-3 items-start justify-start w-full">
                        <div
                            className={`flex items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                            <img
                                className="relative -left-10 "
                                src="/images/overview/ellipse.svg"
                                alt=""
                            />
                        </div>
                        <label htmlFor="phone" className="text-sm font-medium text-black-label">What is your Phone number ?</label>
                        <input type="number" placeholder="Enter your phone Number" onChange={(e) => dispatch(setPhone(e.target.value))} id="phone" name="phone" value={phone} className="border border-black-label rounded-lg p-4 w-full" />
                        {
                            errors.phone && <small className="text-red-500">{errors.phone}</small>
                        }
                    </div>
                }
            </div>
            {
                !(pan_no.length < 10) &&
                <div className="flex gap-5 h-fit flex-col rounded-xl bg-[#FBFCFF] py-8 px-10">
                    <div className="flex flex-col gap-5 border-b pb-3">
                        {
                            pan_found ?
                            <p className="font-medium text-base text-blue-700">We already have your details with us </p>
                            :
                            <p className="font-medium text-base text-blue-700">This remitter is not registered here.</p>
                        }
                        {
                            !pan_found ?
                                <div className="flex items-center gap-2">
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.5 14.4C4.972 14.4 2.1 11.528 2.1 8C2.1 4.472 4.972 1.6 8.5 1.6C12.028 1.6 14.9 4.472 14.9 8C14.9 11.528 12.028 14.4 8.5 14.4ZM8.5 0C4.076 0 0.5 3.576 0.5 8C0.5 12.424 4.076 16 8.5 16C12.924 16 16.5 12.424 16.5 8C16.5 3.576 12.924 0 8.5 0ZM10.572 4.8L8.5 6.872L6.428 4.8L5.3 5.928L7.372 8L5.3 10.072L6.428 11.2L8.5 9.128L10.572 11.2L11.7 10.072L9.628 8L11.7 5.928L10.572 4.8Z" fill="#DD3A3A" />
                                    </svg>
                                    <small>PAN card doesn’t exists</small>
                                </div>
                                :
                                <div className="flex items-center gap-2">
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 0C4.541 0 0.5 4.041 0.5 9C0.5 13.959 4.541 18 9.5 18C14.459 18 18.5 13.959 18.5 9C18.5 4.041 14.459 0 9.5 0ZM13.802 6.93L8.699 12.033C8.573 12.159 8.402 12.231 8.222 12.231C8.042 12.231 7.871 12.159 7.745 12.033L5.198 9.486C4.937 9.225 4.937 8.793 5.198 8.532C5.459 8.271 5.891 8.271 6.152 8.532L8.222 10.602L12.848 5.976C13.109 5.715 13.541 5.715 13.802 5.976C14.063 6.237 14.063 6.66 13.802 6.93Z" fill="#3FB73D" />
                                    </svg>
                                    <small>You are a MoneeFlo Customer</small>
                                </div>

                        }
                    </div>
                    {
                        pan_found &&
                        <div className="flex flex-col gap-5">
                            <div className="flex w-full justify-between">
                                <p className=" text-[#2D3648] font-medium text-base">Remitter Name</p>
                                <p className="text-[#5E5E5E] font-normal text-sm">{remitter_name}</p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className=" text-[#2D3648] font-medium text-base">Phone</p>
                                <p className="text-[#5E5E5E] font-normal text-sm">{remitter_phone}</p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className=" text-[#2D3648] font-medium text-base">Email</p>
                                <p className="text-[#5E5E5E] font-normal text-sm">{remitter_email}</p>
                            </div>
                            <div className="flex w-full justify-between">
                                <p className=" text-[#2D3648] font-medium text-base">Last KYC updated</p>
                                <p className="text-[#5E5E5E] font-normal text-sm">{last_kyc_date}</p>
                            </div>
                        </div>
                    }
                </div>
            }
        </form>
    )
}