import React, { useState } from "react";

import { Button, FileUpload, Img, Input, Text, SelectBox } from "components";
import { toast } from "sonner";
import Pandummy from "assets/images/Pandummy.png";
import {
  postPanVerification,
  PostPanVerificationRequestType,
} from "service/apis/PartnerRegistrationapi";
import { Spinner } from "components";
import { AxiosRequestConfig } from "axios";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setPanfront,
  setPanNumber,
  setPanType,
  setPanfrontBinary,
  incrementVerifystep,
} from "../../redux/features/PartnerRegistration";
import Panpopup from "models/Panpopup";
import { set } from "lodash";

type FooterProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<{
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    disableButton: boolean;
  }>;

function validatePAN(pan: string) {
  const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
  return pan.match(panPattern) !== null;
}

const pantypeotions = [
  { label: "Individual", value: "individualPan" },
  { label: "Business", value: "businessPan" },
];

const Step2: React.FC<FooterProps> = ({ setCurrentStep, disableButton }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [showpanpopup, setShowpanpopup] = React.useState<boolean>(false);
  const [verifypandetails, setVerifypandetails] = React.useState<any>({
    masked_pan_no: "",
    name: "",
  });
  const dispatch = useAppDispatch();
  const PanNumber = useAppSelector(
    (state) => state.PartnerRegistration.PanNumber,
  );
  const { currverifystep } = useAppSelector(
    (state) => state.PartnerRegistration,
  );
  const PanType = useAppSelector((state) => state.PartnerRegistration.PanType);
  const Panfront = useAppSelector(
    (state) => state.PartnerRegistration.Panfront,
  );
  const PanfrontBinary = useAppSelector(
    (state) => state.PartnerRegistration.PanfrontBinary,
  );

  React.useEffect(() => {
    if (Panfront instanceof File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          dispatch(setPanfrontBinary(event.target.result.toString()));
        }
      };
      reader.readAsDataURL(Panfront);
    }
  }, [Panfront]);

  function verifyPAN(): void {
    // get channel id from localstorage
    const channel_id = localStorage.getItem("child_channel_id");
    if (channel_id === null) return;

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PostPanVerificationRequestType> = {
      data: {
        channels_id: channel_id,
        pan_number: PanNumber,
        pan_front: PanfrontBinary,
        pan_type: PanType,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    setLoading(true);
    postPanVerification(req)
      .then((res) => {
        setLoading(false);
        if (currverifystep === 1) {
          dispatch(incrementVerifystep());
        }
        setShowpanpopup(true);
        setVerifypandetails({
          masked_pan_no: res.data?.masked_pan_no,
          name: res.data?.name,
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error("Invalid PAN");
      });
  }
  const handleonClick = () => {
    verifyPAN();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file); // Update the file name state
      const reader = new FileReader();

      reader.onload = () => {
        dispatch(setPanfrontBinary(reader.result as string));
      };

      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="flex  gap-5">
      {loading && <Spinner />}
      {showpanpopup && setCurrentStep && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <Panpopup
            setCurrentStep={setCurrentStep}
            setShowpanpopup={setShowpanpopup}
            verifypandetails={verifypandetails}
          />
        </div>
      )}
      <div className=" border-solid pl-10 flex md:flex-1 flex-col h-full  md:h-auto items-start justify-start  md:px-5 w-[53%] md:w-full">
        <div className="flex flex-col gap-8 items-start justify-start w-2/3 sm:w-full">
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              Add new partner’s PAN card
            </Text>
            <div
              className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${file?.name && "bg-[#F2F7FF]"}`}
            >
              <div className="flex gap-3 items-center overflow-hidden">
                <Img src="/images/remit/file.svg" alt="" />
                <p className="truncate">
                  {file?.name ? file?.name : "No file choosen"}
                </p>
              </div>
              <label
                htmlFor="fileupload"
                className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
              >
                {file?.name ? "Re-upload" : "Upload"}
              </label>
              <input
                name="fileupload"
                type="file"
                id="fileupload"
                className="hidden"
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
          </div>
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              Enter PAN card
            </Text>
            <Input
              value={PanNumber}
              onChange={(e: string) => dispatch(setPanNumber(e))}
              name="price"
              placeholder="Enter 10-digit PAN card number"
              className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
            ></Input>
            {!validatePAN(PanNumber) && PanNumber !== "" && (
              <Text
                className="text-[12px] text-red-500 tracking-[0.15px] w-auto"
                size="txtRubikRomanBold15Black900bf"
              >
                Invalid PAN
              </Text>
            )}
          </div>
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0 "} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">PAN type</Text>
            <div className="flex flex-row font-inter gap-2 items-center justify-start w-full">
              <SelectBox
                className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                value={PanType}
                onChange={(e) => dispatch(setPanType(e))}
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                isMulti={false}
                name="inputbox"
                options={pantypeotions}
                isSearchable={false}
                placeholder="Select PAN type"
              />
            </div>
          </div>
          {(PanNumber !== "" || Panfront) && PanType !== "" && (
            <div className="flex flex-row w-full justify-end">
              <Button
                className="bg-blue-700 cursor-pointer font-inter min-w-[193px] ml-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
                onClick={handleonClick}
              >
                Verify
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="border-solid flex sm:flex-1 flex-col md:h-auto md:hidden items-start justify-start md:px-5 sm:w-full">
        <div className="w-full flex flex-col gap-2">
          <h3 className="bg-[#F5F9FF] text-blue-700 py-3 px-4 rounded-md">
            Verify the details
          </h3>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              PAN name is authorized as per document
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              Only the front of the Pan Card should be visible
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              The image uploaded is completely in the frame
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              Make sure that your details are clearly visible
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
