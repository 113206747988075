import { Img } from "components";
import { convertFileToBase64 } from "pages/CreateRemit/documentation";
import { useCallback } from "react";
import { FileData, setDocumentList, setDocumentListWithoutBinary } from "redux/features/Redirect Portal";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const fakeDocMap = [
    {
        id: 1,
        doc_name: "Proof of Payment",
    },
    {
        id: 2,
        doc_name: "Proof of Identity",
    },
    {
        id: 3,
        doc_name: "Proof of Address",
    },
    {
        id: 4,
        doc_name: "Proof of Enrollment",
    },
    {
        id: 5,
        doc_name: "Proof of Previous Transaction",
    },
]

export default function Documentation() {
    const { documentList, documentListWithoutBinary } = useAppSelector(state => state.CreateRedirectPortal)
    const dispatch = useAppDispatch()
    
    const getUploadedDocumentsCount = useCallback(() => {
        return Object.values(documentListWithoutBinary).filter(doc => doc).length;
    }, [documentListWithoutBinary]);

    const handleFileChange = useCallback(
        (fieldName: string, id: number) =>
            async (event: React.ChangeEvent<HTMLInputElement>) => {
                console.log(documentList)
                const file = event.target.files?.[0];
                if (file) {
                    dispatch(setDocumentListWithoutBinary({...documentListWithoutBinary, [fieldName]: file }));

                    const base64String = await convertFileToBase64(file);

                    // Create the file data object
                    const fileData: FileData = {
                        access: "public",
                        path: base64String,
                        name: id,
                        type: file.type,
                        size: file.size,
                        mime: file.type,
                        meta: {},
                    };

                    const existingDocIndex = documentList.findIndex(doc => doc.name === id);

                    if (existingDocIndex !== -1) {
                        const updatedDocumentList = [...documentList];
                        updatedDocumentList[existingDocIndex] = fileData;
                        dispatch(setDocumentList(updatedDocumentList));
                    } else {
                        dispatch(setDocumentList([...documentList, fileData]));
                    }
                }
            },
        [ documentList, documentListWithoutBinary, dispatch],
    );

    return (
        <div className="flex gap-8 flex-col pb-8">
            <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                    <div className="">
                        <Img src="/images/remit/documentation.svg" alt="" />
                    </div>
                    <div className="text-blue-700">Upload Documents</div>
                </div>
                <small className=""><span className="text-blue-700 text-sm font-medium">{getUploadedDocumentsCount()}/{fakeDocMap.length}</span> Uploaded</small>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                {fakeDocMap.map(({ id, doc_name }) => (
                    <div key={id} className="flex gap-2 flex-col w-full">
                        <h3>{doc_name}</h3>
                        <div
                            className={`py-1.5 px-3.5 justify-between border rounded-md flex ${documentListWithoutBinary[doc_name]?.name && "bg-[#F2F7FF]"}`}
                        >
                            <div className="flex gap-3 items-center overflow-hidden">
                                <Img src="/images/remit/file.svg" alt="" />
                                <p className="truncate text-[#999999] text-sm font-normal">
                                    {documentListWithoutBinary[doc_name]?.name ? documentListWithoutBinary[doc_name]?.name : "No file choosen"}
                                </p>
                            </div>
                            <label
                                htmlFor={doc_name}
                                className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                            >
                                {documentListWithoutBinary[doc_name]?.name ? "Re-upload" : "Upload"}
                            </label>
                            <input
                                name={doc_name}
                                type="file"
                                id={doc_name}
                                className="hidden"
                                accept="image/*"
                                onChange={handleFileChange(doc_name, id)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}