import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const get_opportunities = `${BASE_URL}:rBHQT4XV/get_opportunity`;
const search_contacts = `${BASE_URL}:rBHQT4XV/contacts`;
const create_opp_new_contact = `${BASE_URL}:rBHQT4XV/coorperate_opportunities_newCompany`;
const get_product_list = `${BASE_URL}:rBHQT4XV/product`;
const post_new_contact = `${BASE_URL}:rBHQT4XV/opportunities_new_v2`;
const get_staff_list = `${BASE_URL}:rBHQT4XV/staff`;
const get_comapany = `${BASE_URL}:rBHQT4XV/query_contacts_company`;
const get_opportunity_list_by_comapany = `${BASE_URL}:rBHQT4XV/opportunity_by_id`;
const post_existing_opportunity = `${BASE_URL}:rBHQT4XV/opportunities_existing_v2`;
const patch_update_comapny_details = `${BASE_URL}:rBHQT4XV/coorporate_company/:id`;
const get_opportunities_by_opp_id_with_interactions = `${BASE_URL}:rBHQT4XV/opportunity/:id`;
const patch_update_ooportunity_status = `${BASE_URL}:rBHQT4XV/opportunity/:id`;
const add_coorporate_interactions = `${BASE_URL}:rBHQT4XV/corporate_interactions`;
const get_comapany_by_id = `${BASE_URL}:rBHQT4XV/coorporate_company/:id`;
const get_contacts_referral = `${BASE_URL}:rBHQT4XV/contacts_for_refferal`;
const get_contacts_detail_by_id = `${BASE_URL}:rBHQT4XV/contacts/:id`;
const patch_updata_interaction_status = `${BASE_URL}:rBHQT4XV/corporate_interactions/:id`;
const get_branche_by_id = "https://xxl6-bbr3-kxzs.m2.xano.io/api:yyd3i3Ww/branch_by_Id";  

export type GetOpportunityReqType = Partial<{
    headers: {
        Authorization: string;
    };
    page: number;
    searchQuery: string;
    per_page: number;
    offset: number;
    pipelineType: string;
}>;
export type GetOpportunityResponseType = Partial<{}>;

export const getOpportunities = (
    payload: AxiosRequestConfig<GetOpportunityReqType>,
): AxiosPromise<GetOpportunityResponseType> => {
    return apis.get(get_opportunities, payload);
};

export type GetContactReqType = Partial<{
    headers: {
        Authorization: string;
    };
    comapnyId: string | number;
    number: string;
}>;
export type GetContactResponseType = Partial<{
    id: number;
    created_at: string;
    contactName: string;
    contactNumber: string;
    contactPersonType: string;
    contactEmail: string;
    contactAddress: string;
}>[];
export const getContacts = (
    payload: AxiosRequestConfig<GetContactReqType>,
): AxiosPromise<GetContactResponseType> => {
    return apis.get(search_contacts, payload);
};

export type CreateOpportunityReqType = Partial<{
    headers: {
        Authorization: string;
    };
    companyName: string;
    companyEmail: string;
    companyContact: string;
    companyAddress: string;
    companyType: string;
}>;

export type CreateOpportunityResponseType = Partial<{}>;

export const postCreateOpportunitynewContact = (
    payload: AxiosRequestConfig<CreateOpportunityReqType>,
): AxiosPromise<CreateOpportunityResponseType> => {
    return apis.post(create_opp_new_contact, payload);
}

export type GetProductsReqType = Partial<{
    headers: {
        Authorization: string;
    };
}>;

export type GetProductsResponseType = Partial<{
    id: number;
    name: string;
    created_at: string;
    details:{}
}>[];

export const getProducts = (
    payload: AxiosRequestConfig<GetProductsReqType>,
): AxiosPromise<GetProductsResponseType> => {
    return apis.get(get_product_list, payload);
}

export type CreateContactReqType = Partial<{
    headers: {
        Authorization: string;
    };
}>;

export type CreateContactResponseType = Partial<{}>;

export const postCreateContact = (
    payload: AxiosRequestConfig<CreateContactReqType>,
): AxiosPromise<CreateContactResponseType> => {
    return apis.post(post_new_contact, payload);
}

export type GetStaffReqType = Partial<{
    headers: {
        Authorization: string;
    };
}>;
export type GetStaffResponseType = Partial<{

}>[];

export const getStaff = (
    payload: AxiosRequestConfig<GetStaffReqType>,
): AxiosPromise<GetStaffResponseType> => {
    return apis.get(get_staff_list, payload);
}

export type GetCompanyReqType = Partial<{
    headers: {
        Authorization: string;
    };
    query: string;
    page: number;
    per_page: number;
    pipelineType: string;
}>;
export type GetCompanyResponseType = Partial<{

}>[];

export const getCompany = (
    payload: AxiosRequestConfig<GetCompanyReqType>,
): AxiosPromise<GetCompanyResponseType> => {
    return apis.get(get_comapany, payload);
}

export type GetOpportunityByCompanyReqType = Partial<{
    headers: {
        Authorization: string;
    };
    companyID: number;
    contactID: number;
}>;

export type GetOpportunityByCompanyResponseType = Partial<{}>;

export const getOpportunityByCompany = (
    payload: AxiosRequestConfig<GetOpportunityByCompanyReqType>,
): AxiosPromise<GetOpportunityByCompanyResponseType> => {
    return apis.get(get_opportunity_list_by_comapany, payload);
}

export type PostExistingOpportunityReqType = Partial<{
    headers: {
        Authorization: string;
    };       
}>;

export type PostExistingOpportunityResponseType = Partial<{}>;

export const postExistingOpportunity = (
    payload: AxiosRequestConfig<PostExistingOpportunityReqType>,
): AxiosPromise<PostExistingOpportunityResponseType> => {
    return apis.post(post_existing_opportunity, payload);
}

export type PutUpdateCompanyDetailsReqType = Partial<{
    headers: {
        Authorization: string;        
    };    
    id: string | number;
    company_name: string;
    email: string;
    pipelineType: string;
    address: string;
    phone: string;
    contactName: string;
    contactNumber: string;
    contactEmail: string;
    contactPersonType: string;
    typeOfPartner: string;
    contactAddress: string[];    
}>;

export type PutUpdateCompanyDetailsResponseType = Partial<{}>;

export const PutUpdateCompanyDetails = (
    payload: AxiosRequestConfig<PutUpdateCompanyDetailsReqType>,
): AxiosPromise<PutUpdateCompanyDetailsResponseType> => {
    const id = payload?.data?.id;
    const url = patch_update_comapny_details.replace(":id", id as string);
    return apis.patch(url, payload);
}

export type PostCorporateInteractionsReqType = Partial<{
    headers: {
        Authorization: string;
    };
    remarks: string,
    contact: number,
    source: string,
    subject: string,
    note: string,
    opportunity_id: string | number,
}>;

export type PostCorporateInteractionsResponseType = Partial<{}>;

export const postCorporateInteractions = (
    payload: AxiosRequestConfig<PostCorporateInteractionsReqType>,
): AxiosPromise<PostCorporateInteractionsResponseType> => {
    return apis.post(add_coorporate_interactions, payload);
}

export type GetOpportunityByIdReqType = Partial<{
    headers: {
        Authorization: string;
    };
    id: string | number;
}>;

export type GetOpportunityByIdResponseType = Partial<{}>;

export const getOpportunityById = (
    payload: AxiosRequestConfig<GetOpportunityByIdReqType>,
): AxiosPromise<GetOpportunityByIdResponseType> => {
    const id = payload?.data?.id;
    const url = get_opportunities_by_opp_id_with_interactions.replace(":id", id as string);
    return apis.get(url, payload);
}


export type PutUpdateOpportunityStatusReqType = Partial<{
    headers: {
        Authorization: string;
    };
    id: string | number;
    isopen: string;
    reasonToClose: string;
    assignedto_id: string | number;
}>;

export type PutUpdateOpportunityStatusResponseType = Partial<{}>;

export const putUpdateOpportunityStatus = (
    payload: AxiosRequestConfig<PutUpdateOpportunityStatusReqType>,
): AxiosPromise<PutUpdateOpportunityStatusResponseType> => {
    const id = payload?.data?.id;
    const url = patch_update_ooportunity_status.replace(":id", id as string);
    return apis.patch(url, payload);
}

export type GetCompanyByIdReqType = Partial<{
    headers: {
        Authorization: string;
    };
    id: string | number;
}>;

export type GetCompanyByIdResponseType = Partial<{}>;

export const getCompanyById = (
    payload: AxiosRequestConfig<GetCompanyByIdReqType>,
): AxiosPromise<GetCompanyByIdResponseType> => {
    const id = payload?.data?.id;
    const url = get_comapany_by_id.replace(":id", id as string);
    return apis.get(url, payload);
}

export type GetContactsForReferralReqType = Partial<{
    headers: {
        Authorization: string;
    };
    typeOfContact: "partner" | "staff";
}>;

export type GetContactsForReferralResponseType = Partial<{}>;

export const getContactsForReferral = (
    payload: AxiosRequestConfig<GetContactsForReferralReqType>,
): AxiosPromise<GetContactsForReferralResponseType> => {
    return apis.get(get_contacts_referral, payload);
}

export type GetContactDetailByIdReqType = Partial<{
    headers: {
        Authorization: string;
    };
    id: string | number;
}>;

export type GetContactDetailByIdResponseType = Partial<{}>;

export const getContactDetailById = (
    payload: AxiosRequestConfig<GetContactDetailByIdReqType>,
): AxiosPromise<GetContactDetailByIdResponseType> => {
    const id = payload?.data?.id;
    const url = get_contacts_detail_by_id.replace(":id", id as string);
    return apis.get(url, payload);
}

export type PutUpdateInteractionStatusReqType = Partial<{
    headers: {
        Authorization: string;
    };
    id: string | number;
    isOpen: string;
}>;

export type PutUpdateInteractionStatusResponseType = Partial<{}>;

export const putUpdateInteractionStatus = (
    payload: AxiosRequestConfig<PutUpdateInteractionStatusReqType>,
): AxiosPromise<PutUpdateInteractionStatusResponseType> => {
    const id = payload?.data?.id;
    const url = patch_updata_interaction_status.replace(":id", id as string);
    return apis.patch(url, payload);
}

export type GetBranchByIdReqType = Partial<{
    headers: {
        Authorization: string;
    };
    Chaneel_id: string | number;
}>;

export type GetBranchByIdResponseType = Partial<{}>;

export const getBranchById = (
    payload: AxiosRequestConfig<GetBranchByIdReqType>,
): AxiosPromise<GetBranchByIdResponseType> => {
    return apis.get(get_branche_by_id, payload);
}