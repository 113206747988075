import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  Img,
  Input,
  ReactTable,
  Spinner,
  Text,
} from "../../components";
import "../../styles/styles.css";
import { AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie";
import { Capitalize } from "utils";
const cookies = new Cookies();
import { GetChannelRequestType, getChannel } from "../../service/api";
import { statusOptions, typeOptions, statusClass } from "../../utils";
import { RxCross2 } from "react-icons/rx";
import { setResumeForm } from "../../redux/features/PartnerRegistration";
import { useAppDispatch } from "redux/hooks";
import { DataTable } from "components/Table/DataTable";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { PartnersType } from "@/lib/validations/schema";
import { partner_status_options } from "common/filters";

type TableRowType = {
  id: string;
  name: string;
  address_line_1: string;
  _realtionship_manager_channels: {
    fname: string;
  };
  relationshil_manager: string;
  type: string;
  status: string;
};

const NewPartnersPage: React.FC = () => {
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPageOptions = [5, 10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const nevigate = useNavigate();

  const dispatch = useAppDispatch();

  //   useEffect(() => {
  //     const level = localStorage.getItem("level");
  //     dispatch(setResumeForm(false));
  //     if (level === "3") {
  //       navigate("/");
  //     }
  //     fetchResults(query, currentPage, perPage, type, status);
  //   }, [query, currentPage, perPage, type, status]);

  const fetchResults = () =>
    // searchTerm: string,
    // page: number | string,
    // perPage: string | number,
    // type: string,
    // status: string,
    {
      setLoading(true);

      // get auth token from localstorage
      const auth: string | null = localStorage.getItem("authToken");
      if (auth === null) return null;
      const authToken = JSON.parse(auth);

      const payload: AxiosRequestConfig<GetChannelRequestType> = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        //   params: {
        //     q: searchTerm.length > 0 ? `${searchTerm}:*` : searchTerm,
        //     page: page,
        //     per_page: perPage,
        //     type: type,
        //     status: status,
        //   },
      };
      getChannel(payload)
        .then((response) => {
          setLoading(false);
          setResults(
            response?.data?.items?.map(
              (
                result: Partial<{
                  id: string | number;
                  name: string;
                  address_line_1: string;
                  _realtionship_manager_channels: {
                    fname: string;
                  };
                  relationshil_manager: string;
                  type: string;
                  status: string;
                }>,
              ) => {
                return {
                  id: result.id,
                  partner: result.name,
                  primaryLocation: result.address_line_1,
                  relationshipManager:
                    result?._realtionship_manager_channels?.fname,
                  type: result.type,
                  partnerStatus: result.status,
                };
              },
            ) ?? [],
          );
          // if (currentPage > response?.data?.pageTotal) {
          //   setCurrentPage(response?.data?.pageTotal);
          // } else if (currentPage === 0 && response?.data?.pageTotal > 0) {
          //   setCurrentPage(1);
          // }
          // setTotalPages(response?.data?.pageTotal);
        })
        .catch(() => {
          setLoading(false);
          // console.error("Error fetching results:", error);
        });
    };
  const handleSearch = (e: React.HTMLAttributeAnchorTarget) => {
    setQuery(e);
    setCurrentPage(1);
  };

  const handlePerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(parseInt(e.target.value));
  };

  const handlePageChange = (direction: string) => {
    if (direction === "next") {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "prev") {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const selectStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value);
  };
  const selectType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value);
  };

  // Get the current results to display on the current page
  const startIndex = (currentPage - 1) * perPage;

  function handleCellClick(value: string | number) {
    // Perform actions when the cell is clicked, using the value if needed
    nevigate(`/partnerdetails${value}`);
  }

  const columns: ColumnDef<PartnersType>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Customer ID" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
      enableHiding: false,
      filterFn: (row, id, value) => {
        const rowValue = String(row.getValue(id));
        const searchValue = String(value);
        return rowValue.includes(searchValue);
      },
    },
    {
      accessorKey: "partner",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Partner" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "primaryLocation",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Primary Location" />
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      enableSorting: false,
    },
    {
      accessorKey: "relationshipManager",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Relationship Manager" />
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      enableSorting: false,
    },
    {
      accessorKey: "type",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Type" />
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      enableSorting: false,
    },
    {
      id: "partnerStatus",
      accessorKey: "partnerStatus",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        const status = partner_status_options.find(
          (status) => status.value === row.getValue("partnerStatus"),
        );

        if (!status) {
          return null;
        }

        return (
          <p
            style={{ backgroundColor: status.bgColor, color: status.textColor }}
            className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
          >
            {status.label}
          </p>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: false,
    },
  ];

  useEffect(() => {
    fetchResults();
  }, []);

  const navigate = useNavigate();
  const handleAction = (rowData: any) => {
    // Your action logic here, e.g.:
    navigate(`/partnerdetails/${rowData.id}`);
  };

  return (
    <div className="flex h-full gap-5 py-5 px-6 w-full flex-col bg-white-A700 rounded-xl overflow-hidden">
      <div className=" flex w-full justify-between items-center">
        <div className=" flex flex-col gap-2">
          <h3 className=" font-semibold leading-4 text-lg text-black-label">
            Partners
          </h3>
          <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
            List of all partners
          </p>
        </div>
        <Link to={"/partnerregistration"}>
          <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
            Register Partner
          </button>
        </Link>
      </div>
      <div className="flex-1 relative overflow-auto">
        {loading && <Spinner />}
        <DataTable
          onRowClick={handleAction}
          data={results} 
          columns={columns}
          searchData={{
            placeholder: "Search by ID, customer name",
            columns: ["id", "partner","relationshipManager"],
          }}
        />
      </div>
    </div>
  );
};

export default NewPartnersPage;
