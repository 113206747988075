import React from "react";
import { Img, List, SelectBox, Text } from "components";
import { useParams, useNavigate } from "react-router-dom";
import { AxiosRequestConfig } from "axios";
import { Button } from "components";
import {
  GetStaffCustomerRequestType,
  getStaffCustomer,
  putCustomerStatus,
  PutCustomerStatusRequestType,
} from "service/apis/CustomerDetailsapi";
import { Capitalize } from "utils";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setResumeRegistration } from "redux/features/CustomerRegistration";
import { useAppSelector, useAppDispatch } from "redux/hooks";
const statusOptionsList = [
  { label: "Pending", value: "pending" },
  { label: "Active", value: "active" },
  { label: "Conditional Active", value: "conditional active" },
  { label: "Blocked ", value: "blocked" },
  { label: "Archieved", value: "archieved" },
];
type CustomerDetailsType = {
  id: number;
  full_name: string;
  phone_number: string;
  email: string;
  last_kyc: string;
  status: string;
  pan_no: string;
  pan_soft_copy: {
    url: string;
  };
  aadhaar_soft_copy_front: {
    url: string;
  };
  aadhaar_no: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pincode: string;
  bank_account_number: string;
  bank_name: string;
  ifsc_code: string;
};
const CustomerDetailsPage: React.FC = () => {
  const { id } = useParams();
  const [customerDetails, setCustomerDetails] =
    React.useState<CustomerDetailsType>({
      id: 0,
      full_name: "",
      phone_number: "",
      email: "",
      last_kyc: "",
      status: "",
      pan_no: "",
      pan_soft_copy: {
        url: "",
      },
      aadhaar_soft_copy_front: {
        url: "",
      },
      aadhaar_no: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      pincode: "",
      bank_account_number: "",
      bank_name: "",
      ifsc_code: "",
    });
  const [updatestatus, setUpdatestatus] = React.useState("");
  const [showimg, setShowimg] = React.useState(false);
  const [imgsrc, setImgsrc] = React.useState("");

  React.useEffect(() => {
    fetchPartnerDetails();
  }, []);

  React.useEffect(() => {
    if (updatestatus !== "") {
      updateCustomerStatus();
    }
  }, [updatestatus]);

  const fetchPartnerDetails = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetStaffCustomerRequestType> = {
      data: {
        customers_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    getStaffCustomer(payload)
      .then((res) => {
        setCustomerDetails(res.data);
      })
      .catch((err) => {});
  };

  const updateCustomerStatus = (): null | void => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PutCustomerStatusRequestType> = {
      data: {
        customers_id: id,
        status: updatestatus,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    putCustomerStatus(payload)
      .then((res) => {
        toast.success("Status Updated Successfully");
        fetchPartnerDetails();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wron");
      });
  };

  const resumeRegistration = (): void | null => {
    dispatch(setResumeRegistration(true));
    localStorage.setItem("customer", id);
    navigate(`/partnerregistration`);
  };

  let customerbg = "";
  const status = customerDetails?.status;
  switch (status) {
    case "pending":
      customerbg = "bg-[#f7cb73]";
      break;
    case "active":
      customerbg = "bg-[#008000]";
      break;
    case "conditional active":
      customerbg = "bg-[#008000]";
      break;
    case "blocked":
      customerbg = "bg-[#FF0000]";
      break;
    case "archieved":
      customerbg = "bg-[#808080]";
      break;
  }
  return (
    <>
      {showimg && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Img src={imgsrc} className="h-[300px] w-[400px]" alt="Loading...." />
          <div
            className="relative top-[-150px] left-[10px]"
            onClick={() => setShowimg(!showimg)}
          >
            <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
          </div>
        </div>
      )}
      <div className="bg-gray-100 flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 flex-col font-inter gap-5 items-start justify-start md:px-5 px-9 py-[58px] w-full">
          <div className="flex flex-col items-start justify-between w-full">
            <Text className="text-black-900 text-xl w-auto">
              Customer Details
            </Text>
          </div>
          <div className="bg-white-A700 text-[#797D8C] flex md:flex-col flex-row font-inter gap-2.5 items-start justify-center md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
            <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
              <Text className="font-inter text-2xl md:text-[22px] text-gray-900_01 sm:text-xl w-auto">
                {customerDetails?.full_name &&
                  Capitalize(customerDetails?.full_name)}
              </Text>
              <Text className="font-inter text-gray-600_04 text-sm w-auto">
                <span className="text-gray-600_04 text-left font-normal">
                  Customer ID:
                </span>
                <span className="text-black-900 text-left font-normal">
                  {customerDetails?.id}{" "}
                </span>
              </Text>
              <div className="flex flex-col font-inter items-start justify-start w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Last KYC:{" "}
                  <span className="text-black-900 text-left font-normal">
                    {customerDetails?.last_kyc
                      ? customerDetails?.last_kyc
                      : "Not Available"}
                  </span>
                </Text>
              </div>
            </div>
            <div className="flex flex-row gap-2.5 items-center justify-end w-auto">
              <div className="flex flex-row gap-[5px] items-center justify-end w-auto">
                {/* {customerDetails?.status=="pending"?<Button
                      onClick={resumeRegistration}
                      className="bg-blue-700 cursor-pointer h-auto py-2.5 rounded-md shadow-bs2  text-sm text-center text-white-A700 w-auto h-[40px] px-[10px]"
                    >
                      Resume Registration
                    </Button>: */}

                <SelectBox
                  className="bg-white-A700 border border-gray-200_01 border-solid font-medium p-1.5  rounded-lg text-[13px] text-blue_gray-700 text-left pl-[20px] w-[130px]"
                  placeholderClassName="text-blue_gray-700 text-[12px] w-[130px]"
                  // isSearchable={true}
                  placeholder="Select Status"
                  isMulti={false}
                  value={customerDetails?.status}
                  onChange={(e) => {
                    setUpdatestatus(e);
                  }}
                  indicator={
                    <>
                      <div
                        className={`${customerbg} absolute top-[40%] left-[-10px] h-1.5 rounded-[50%] w-1.5`}
                      ></div>
                      <svg
                        width="22"
                        height="23"
                        viewBox="0 0 22 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          id="Background"
                          cx="11"
                          cy="11.75"
                          r="11"
                          fill="#2B2B2B"
                          fillOpacity="0.1"
                        />
                        <image
                          href="/images/img_arrowdown.svg"
                          x="0"
                          y="0"
                          width="22"
                          height="23"
                        />
                      </svg>
                    </>
                  }
                  options={statusOptionsList}
                  name="status"
                />
              </div>
            </div>
          </div>
          <List
            className="sm:flex-col flex-row text-[#797D8C]  font-inter gap-5 grid md:grid-cols-1 grid-cols-2 justify-start w-full"
            orientation="horizontal"
          >
            <div className="bg-white-A700  flex flex-1 flex-col gap-2.5 h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_04 text-sm uppercase w-full">
                Contact Information
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Phone number:{" "}
                </Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {customerDetails?.phone_number}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Email Id:
                </Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {customerDetails?.email}
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_04 text-sm uppercase w-full">
                Id & Address Proof
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  PAN Card:
                </Text>
                <Text
                  onClick={() => {
                    if (customerDetails?.pan_soft_copy === null) {
                      toast.error("No Pan Card Found");
                    } else {
                      setImgsrc(customerDetails?.pan_soft_copy?.url);
                      setShowimg(true);
                    }
                  }}
                  className="text-gray-900_01 text-sm w-auto"
                >
                  {customerDetails?.pan_no?.toUpperCase()}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Aadhar card:
                </Text>
                <Text
                  onClick={() => {
                    if (customerDetails?.aadhaar_soft_copy_front === null) {
                      toast.error("No Aadhaar Card Found");
                    } else {
                      setImgsrc(customerDetails?.aadhaar_soft_copy_front?.url);
                      setShowimg(true);
                    }
                  }}
                  className="text-gray-900_01 text-sm w-auto"
                >
                  ********{customerDetails?.aadhaar_no}
                </Text>
              </div>
            </div>
          </List>
          <List
            className="sm:flex-col flex-row text-[#797D8C] font-inter gap-5 grid md:grid-cols-1 grid-cols-2 justify-start w-full"
            orientation="horizontal"
          >
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_04 text-sm uppercase w-full">
                Address
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Address Line 1:
                </Text>
                <Text className="font-medium text-gray-900_01 text-sm w-auto">
                  {customerDetails?.address_line_1?.charAt(0)?.toUpperCase() +
                    customerDetails?.address_line_1?.slice(1)}{" "}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Address Line 2:
                </Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {customerDetails?.address_line_2?.charAt(0)?.toUpperCase() +
                    customerDetails?.address_line_2?.slice(1)}{" "}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">City:</Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {customerDetails?.city?.charAt(0)?.toUpperCase() +
                    customerDetails?.city?.slice(1)}{" "}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">State:</Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {customerDetails?.state?.charAt(0)?.toUpperCase() +
                    customerDetails?.state?.slice(1)}{" "}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Pincode:
                </Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {customerDetails?.pincode}
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 h-full items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_04 text-sm uppercase w-full">
                Bank Account
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Account Number:
                </Text>
                <Text className="font-medium text-gray-900_01 text-sm w-auto">
                  {customerDetails?.bank_account_number === ""
                    ? "Not Available"
                    : customerDetails?.bank_account_number}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  Bank Name:
                </Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {customerDetails?.bank_name === ""
                    ? "Not Available"
                    : customerDetails?.bank_name}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">
                  IFSC Code:
                </Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {customerDetails?.ifsc_code === ""
                    ? "Not Available"
                    : customerDetails?.ifsc_code}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className="text-gray-600_04 text-sm w-auto">Status:</Text>
                <Text className="text-gray-900_01 text-sm w-auto">
                  {/* {customerDetails?.status?.charAt(0).toUpperCase() +
                    customerDetails?.status?.slice(1).toLowerCase()} */}
                </Text>
              </div>
            </div>
          </List>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CustomerDetailsPage;
