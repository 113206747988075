import React from "react";

import { Text, Input } from "components";

type ChannelPartnerRegFiveRowprimarytextProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "primarytexttwo" | "primarytexttwo1" | "primarytexttwo2"
> &
  Partial<{
    primarytexttwo: string;
    primarytexttwo1: string | number;
    primarytexttwo2: string;
    id: number;
    isUpdate: boolean;
    purpose_id: number;
    updateCommisionRate: any;
    setUpdateCommisionRate?: React.Dispatch<any> | undefined;
  }>;

const ChannelPartnerRegFiveRowprimarytext: React.FC<
  ChannelPartnerRegFiveRowprimarytextProps
> = (props) => {
  const [form, setForm] = React.useState<any>({
    purpose_id: props.purpose_id,
    charges: props.primarytexttwo1,
    id:props.id
  });
  React.useEffect(() => {
    if (props.isUpdate) {
      // Filter out the old form data with the same purpose_id
      const updatedRates = props.updateCommisionRate.filter(
        (e: any) => e.purpose_id !== props.purpose_id
      );

      // Add the new form data to the updatedRates array
      if (parseFloat(form.charges) !== props.primarytexttwo1) {
        updatedRates.push({ ...form, charges: parseFloat(form.charges) });
      }
      
      // Update the state with the updatedRates
      if (props.setUpdateCommisionRate) {
        props.setUpdateCommisionRate(updatedRates);
      }
    }
  }, [props.isUpdate, form.charges]);

  return (
    <>
      <div className={props.className}>
        <div className="flex flex-1 flex-col h-16  items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="text-blue_gray-900_01 text-sm w-full"
                size="txtInterRegular14Bluegray90001"
              >
                {props?.primarytexttwo}
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col h-16  items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="text-blue_gray-900_01 text-sm w-full"
                size="txtInterRegular14Bluegray90001"
              >
                {props?.primarytexttwo1}
              </Text>
            </div>
          </div>
        </div>
        {props?.isUpdate && (
          <div className="flex flex-1 flex-col h-16  items-start justify-start w-full">
            <div className="flex flex-col h-full items-center justify-center px-3 w-full">
              <div className="flex flex-col items-start justify-start w-full">
                {props?.isUpdate && (
                  <>
                    <Input
                      name="price"
                      type="number"
                      value={form.charges}
                      onChange={(e: any) => setForm({ ...form, charges: e })}
                      placeholder="12"
                      className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-black-900_99 text-left w-full"
                      wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                    ></Input>
                  </>
                )}
                {!props?.isUpdate && (
                  <Text
                    className="text-blue_gray-900_01 text-sm w-full"
                    size="txtInterRegular14Bluegray90001"
                  >
                    {form.charges}
                  </Text>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

ChannelPartnerRegFiveRowprimarytext.defaultProps = {
  primarytexttwo: "2",
  primarytexttwo1: "2P",
  primarytexttwo2: "2",
};

export default ChannelPartnerRegFiveRowprimarytext;
