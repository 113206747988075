import {  Controller, useFormContext } from "react-hook-form";
import ReactSelect from "react-select";
import { useAppSelector } from "redux/hooks";
import { contactPersonTypes, entityTypes } from "./utils/featureRule";
import { Option } from "./types";

export default function EditForm() {
    const { pipelineType } = useAppSelector(state => state.CreateOpportunity);
    const {control,formState: { errors }, getValues} = useFormContext();

    const defaultValues = getValues();
    const dottedBorderWidth = Object.keys(errors).length ?"after:h-40 after:border-blue-700": "after:h-32 after:border-blue-700";

    const renderField = (name:string, label:string, type = "text", options: Option[]|null = null,isLast:boolean=false,defaultValue?: any, rules?: any, isOptional: boolean = false) => (
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div className={`flex items-center top-0 flex-col after:right-10 ${isLast ?'after:h-0':dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}>
                <img className="relative -left-10" src="/images/overview/ellipse.svg" alt="" />
            </div>
            <label htmlFor={name} className="text-sm font-medium text-black-label">{label}</label>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules || {
                    ...(isOptional ? {} : { required: "This field is required" }),
                    validate: (value) => {
                        if (type === "email" && value) {
                            return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) || "Invalid email address";
                        }
                        if (type === "number" && value) {
                            return /^\d{10}$/.test(value) || "Phone number must be 10 digits";
                        }
                        return true;
                    }
                }}  
                // skipcq: JS-0417
                render={({ field }) => (
                    options ? (
                        <ReactSelect
                            {...field}
                            options={options}
                            defaultValue={options.find(option => option?.value === defaultValue)}
                            className="w-full"
                            menuPlacement='top'
                            placeholder={`Select ${label}`}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    zIndex: 100,
                                    borderRadius: ".5rem",
                                    padding: ".6rem",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderColor: state.isFocused ? "#165FE3" : errors[name] ? "red" : "#333333",
                                    boxShadow: "none",
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 1000,
                                }),
                            }}
                            isClearable
                            isSearchable
                        />
                    ) : (
                        <input
                            {...field}
                            type={type}
                            placeholder={`Enter ${label}`}
                            defaultValue={defaultValue}
                            className={`border ${errors[name] ? 'border-red-500' : 'border-black-label'} rounded-lg p-4 w-full`}
                        />
                    )
                )}
            />
            {errors[name] && <span className="text-red-500 text-sm">{errors[name].message as string}</span>}
        </div>
    );

    switch (pipelineType) {
        case 'retail':
            return (
                <form className="max-w-md py-8 pl-9">
                    <div className="flex flex-col pl-10 gap-10 w-full">
                        {renderField("contact_number", "What is contact's phone number?", "tel",null,false,defaultValues?.contactNumber,{
                            required: "This field is required"
                        },false)}
                        {renderField("contact_name", "What is the contact name?", "",null,false,defaultValues?.contactName,{
                            required: "This field is required" 
                        },false)}
                        {renderField("contact_email", "What is your email ID?", "email",null,false,defaultValues?.contactEmail,{},true)}
                        {renderField("contact_address", "What is your address?", "textarea",null,true,defaultValues?.contactAddress?.[0],{},true)}
                    </div>
                </form>
            );
        case 'corporate':
            return (
                <form className="max-w-md py-8 pl-9 ">
                    <div className="flex flex-col pl-10 gap-10 w-full">
                        {renderField("company_name", "What is the name of the company?", "",null,false,defaultValues?.company_name)}
                        {renderField("company_email", "What is the email ID of the company?", "email",null,false,defaultValues?.email)}
                        {renderField("contact_person_type", "What is the type of the contact person?", "", contactPersonTypes,false,defaultValues?.contactPersonType)}
                        {renderField("contact_person_name", "What is the name of contact person (Employee)?", "",null,false,defaultValues?.contacts_id?.[0]?.[0]?.contactName)}
                        {renderField("contact_person_phone", "What is the contact number of the person?", "number",null,true,defaultValues?.contacts_id?.[0]?.[0]?.contactNumber)}
                    </div>
                </form>
            );
        case 'partner':
            return (
                <form className="max-w-md py-8 pl-9">
                    <div className="flex flex-col pl-10 gap-10 w-full">
                        {renderField("entity_name", "What is the Name of Entity?", "",null,false,defaultValues?.contactName)}
                        {renderField("entity_type", "What is the type of the entity?", "", entityTypes,false,defaultValues?.typeOfPartner)}
                        {renderField("company_email", "What is the email ID of the company?", "email",null,true,defaultValues?.contactEmail)}
                    </div>
                </form>
            );
        default:
            return null;
    }
}