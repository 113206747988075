import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CURRENCY, NUMBER, RADIO, SELELCT, TEXT } from "common/constants";
import { Img } from "components";
import { IFormInput } from "components/Form/form";
import { CurrencyInput, IField, RadioInput } from "components/Input/Inputs";
import { Search } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import {
  addressFieldData,
  BeneficiaryAddressType,
  BeneficiaryOtherDetailsType,
  BeneficiaryType,
  otherFieldData,
} from "./data";
import {
  getCustomer,
  GetCustomerRequestType,
  GetCustomerResponseType,
} from "service/apis/CreateOrderapi";
import { AxiosRequestConfig } from "axios";
import { useDebounce } from "pages/CreateDeal/newdeal";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ca } from "date-fns/locale";
const formConfig = [
  {
    label: "What is your name ?",
    name: "beneficiary_name",
    type: TEXT,
  },
  {
    label: "What is the your Bank Account number ?",
    name: "beneficiary_account_number",
    type: NUMBER,
  },
  {
    label: "Enter the valid Swift Code",
    name: "beneficiary_swift_code",
    type: TEXT,
  },
  {
    label: "What is the your Bank Name  ?",
    name: "beneficiary_bank_name",
    type: TEXT,
  },
  {
    label: "What is the your Bank Address  ?",
    name: "beneficiary_bank_addr",
    type: TEXT,
  },
  {
    label: "What is the your Bank Country  ?",
    name: "beneficiary_bank_country",
    type: TEXT,
  },
];

const RemittanceDetails = () => {
  const [panFound, setPanFound] = useState(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [customerDetails, setCustomerDetails] =
    useState<GetCustomerResponseType>();
  const [formData, setFormData] = useState(formConfig);
  const { watch, control, register, setValue,getValues } = useFormContext();

  const navigate = useNavigate();

  const fetchBankWithSwiftCode = async (swiftCode: string) => {
    try {      
      const res = await fetch(`https://api.api-ninjas.com/v1/swiftcode?swift=${swiftCode}`,{
        method:'GET',
        headers:{
          'X-API-Key': `${import.meta.env.VITE_AUTH_TOKEN}`
        }
      })
      const data = await res.json();

      setFormData(prevFormData => prevFormData.map(field => {
        if (field.name === 'beneficiary_bank_name') {
          return { ...field, defaultValue: data?.bank_name || '' };
        }
        if (field.name === 'beneficiary_bank_country') {
          return { ...field, defaultValue: data?.bank_country || '' };
        }
        if (field.name === 'beneficiary_bank_city') {
          return { ...field, defaultValue: data?.city || '' };
        }
        return field;
      }));

      setValue('beneficiary_bank_name',data?.bank_name);
      setValue('beneficiary_bank_country',data?.bank_country);
      setValue('beneficiary_bank_city',data?.city);
      // console.log(data);
    } catch (error) {
      console.log(error);
      // toast.error(error?.response?.data?.message);      
    }
  }
  useEffect(()=>{
    if(getValues('beneficiary_swift_code')){
      fetchBankWithSwiftCode(getValues('beneficiary_swift_code'))
    }
  },[getValues('beneficiary_swift_code')])

  const watchAllFields = watch();

  const isFieldDisabledBeneficiary = (index: number) =>{
    switch(formData[index].name){
      case 'beneficiary_account_number':
        return !watchAllFields.beneficiary_name;
      case 'beneficiary_swift_code':
        return !watchAllFields.beneficiary_account_number;
      case 'beneficiary_bank_name':
        return !watchAllFields.beneficiary_swift_code;
      case 'beneficiary_bank_addr':
        return !watchAllFields.beneficiary_bank_name;
      case 'beneficiary_bank_country':
        return !watchAllFields.beneficiary_bank_addr;
      default:
        return false
    }
  }

  const isFieldDisabledAddress = (index: number) => {
    switch (addressFieldData[index].name) {
      case "beneficiary_addr_line_2":
        return !watchAllFields.beneficiary_addr_line_1;
      case "beneficiary_zip":
        return !watchAllFields.beneficiary_addr_line_2;
      case "beneficiary_country":
        return !watchAllFields.beneficiary_zip;
      case "beneficiary_state":
        return !watchAllFields.beneficiary_country;
      case "beneficiary_city":
        return !watchAllFields.beneficiary_state;
      default:
        return 
    }
  }

  const isFieldDisabledOther = (index: number) => {
    switch (otherFieldData[index].name) {
      case "beneficiary_transit_code":
        return !watchAllFields.beneficiary_routing_number;
      case "beneficiary_bsbcode":
        return !watchAllFields.beneficiary_transit_code;
      case "beneficiary_national_id":
        return !watchAllFields.beneficiary_bsbcode;
      case "beneficiary_iban_no":
        return !watchAllFields.beneficiary_national_id;      
      default:
        return
    }
  }

  const renderFormField = (
    data: IFormInput,
    field: IField<BeneficiaryType>["field"],
    isDisabled: boolean,
  ) => {
    if (isDisabled) return null;
    switch (data.type) {
      case SELELCT:
        return (
          <Select onValueChange={field.onChange} value={field.value as string}>
            <FormControl className="border border-gray-border-gray-lite m-0 p-4">
              <SelectTrigger className="h-12 max-w-[340px]">
                <SelectValue placeholder={data.placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="rounded-lg bg-white-A700">
              <SelectGroup>
                <SelectLabel className="text-blue-700 p-4 bg-blue_gray-lite-bg">
                  {data.placeholder}
                </SelectLabel>
                {data.options?.map((option) => (
                  <SelectItem
                    className="py-4"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        );

      case RADIO:
        return (
          <RadioInput<BeneficiaryType>
            defaultValue=""
            options={data.options ?? []}
            field={field}
          />
        );

      case CURRENCY:
        return (
          <CurrencyInput<BeneficiaryType>
            currencyType={data.currency as string}
            currencySymbol={data.currencySymbol ?? ""}
            field={field}
          />
        );

      case NUMBER:
        return (
          <Input
            type="number"
            {...field}
            value={field.value as number}
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          />
        );
      default:
        return (
          <Input
            type="text"
            {...field}
            value={field.value as string || data.defaultValue || ''}
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          />
        );
    }
  };
  const handleSearch = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetCustomerRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        pan_no: searchInput,
      },
    };
    try {
      if (searchInput.length < 10) return;
      const res = await getCustomer(payload);
      setPanFound(true);
      setValue("pan", true);
      setValue("pan_no", searchInput);
      console.log(res.data);
      setCustomerDetails(res.data);
      setValue('customer_details',res.data);
      setValue("customer_id", res.data.id);
      setValue("customer_name", res.data.full_name);
    } catch (error) {
      setPanFound(false);
      toast.error(error?.response?.data?.message);
      setValue("pan", false);
      setValue("pan_no", "");
      setValue('customer_details',{})
      setCustomerDetails({
        full_name: "",
        email: "",
        phone_number: "",
        address_line_1: "",
        id: "",
      });
    }
  };
  console.log(getValues('customer_details'));
  useDebounce(handleSearch, 500, [searchInput]);
  return (
    <div className="flex gap-8 flex-col pb-8">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          <div className="">
            <Img src="/images/remit/remit-details.svg" alt="" />
          </div>
          <div className="">Remitter Details</div>
        </div>
        <small className="">Scroll down for beneficiary details</small>
      </div>
      <div className="">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <div className="relative flex items-center w-[300px] flex-grow ">
              <Input
                placeholder="Search Customer with PAN ID"
                className="py-3 pl-10 pr-4 flex gap-3.5 rounded-[30px] w-full max-w-4xl "
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value.toUpperCase())}
              />
              <Search className="absolute left-2 h-3.5" />
            </div>
          </div>
          {!panFound && (
            <button
              type="button"
              className="px-4 py-[11px] w-a rounded-lg bg-blue-700 text-white-A700 border"
              onClick={() => navigate("/customerregistration")}
            >
              Add Customer
            </button>
          )}
        </div>
      </div>
      {getValues('pan') ? (
        <div className="flex flex-col gap-3">
          <div className="py-2 px-4 rounded-3xl bg-[#F0F6FF] w-fit text-blue-700">
            PAN NO. {getValues('customer_details')?.pan_no}
          </div>
          <div className="bg-[#FBFCFF] rounded-xl py-8 px-10 flex">
            <div className="flex flex-col gap-5">
              <div className="flex gap-5">
                <h3>Remitter Name:</h3>
                <small>{getValues('customer_details')?.id}</small>
              </div>
              <div className="flex gap-5">
                <h3>Email:</h3>
                <small>{getValues('customer_details')?.email}</small>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex gap-5">
                <h3>Phone:</h3>
                <small>{getValues('customer_details')?.phone_number}</small>
              </div>
              <div className="flex gap-5">
                <h3>Address:</h3>
                <small className=" w-min">
                  {getValues('customer_details')?.address_line_1}
                </small>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex gap-2 items-center rounded-lg justify-center p-5 h-20 bg-[#FAFAFA]">
          <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.25782 0C5.89189 0 4.55664 0.405044 3.42091 1.16391C2.28518 1.92278 1.39999 3.00139 0.877273 4.26334C0.354555 5.52529 0.217788 6.91391 0.484267 8.25359C0.750746 9.59327 1.4085 10.8238 2.37436 11.7897C3.34022 12.7556 4.57079 13.4133 5.91047 13.6798C7.25015 13.9463 8.63877 13.8095 9.90072 13.2868C11.1627 12.7641 12.2413 11.8789 13.0002 10.7432C13.759 9.60743 14.1641 8.27218 14.1641 6.90625C14.1621 5.07519 13.4339 3.31968 12.1391 2.02493C10.8444 0.730175 9.08887 0.00193363 7.25782 0ZM6.99219 3.1875C7.1498 3.1875 7.30386 3.23424 7.43491 3.3218C7.56596 3.40936 7.66809 3.53381 7.72841 3.67942C7.78872 3.82503 7.8045 3.98526 7.77375 4.13984C7.74301 4.29442 7.66711 4.43641 7.55567 4.54785C7.44422 4.6593 7.30223 4.73519 7.14765 4.76594C6.99307 4.79669 6.83285 4.78091 6.68724 4.72059C6.54163 4.66028 6.41717 4.55814 6.32961 4.42709C6.24205 4.29605 6.19532 4.14198 6.19532 3.98438C6.19532 3.77303 6.27927 3.57034 6.42871 3.4209C6.57816 3.27146 6.78085 3.1875 6.99219 3.1875ZM7.78907 10.625C7.50727 10.625 7.23702 10.5131 7.03776 10.3138C6.83851 10.1145 6.72657 9.84429 6.72657 9.5625V6.90625C6.58567 6.90625 6.45054 6.85028 6.35091 6.75065C6.25129 6.65102 6.19532 6.5159 6.19532 6.375C6.19532 6.2341 6.25129 6.09898 6.35091 5.99935C6.45054 5.89972 6.58567 5.84375 6.72657 5.84375C7.00836 5.84375 7.27861 5.95569 7.47787 6.15495C7.67712 6.35421 7.78907 6.62446 7.78907 6.90625V9.5625C7.92996 9.5625 8.06509 9.61847 8.16472 9.7181C8.26435 9.81773 8.32032 9.95285 8.32032 10.0938C8.32032 10.2346 8.26435 10.3698 8.16472 10.4694C8.06509 10.569 7.92996 10.625 7.78907 10.625Z"
              fill="#666666"
            />
          </svg>
          <small>Search Customers to see the details here</small>
        </div>
      )}
      {showRegistrationForm && (
        <div className="flex gap-5 flex-col">
          <small>Register a new customer directly</small>
          <div className="border rounded-[20px] py-5 px-5 flex gap-10 flex-col">
            <div className="flex flex-col gap-3">
              <small>Add New Customer’s Aadhaar document</small>
              <form className=" flex flex-col gap-3 ">
                <div className="border items-center flex w-fit py-1.5 px-5 rounded-md border-[#DCDEE2]">
                  <label
                    htmlFor="aadhaar"
                    className="flex gap-2 w-full items-center"
                  >
                    <Img src="/images/remit/file.svg" alt="" />
                    Upload the Document
                  </label>
                  <input
                    {...register("aadhaar")}
                    type="file"
                    name="aadhaar"
                    id="aadhaar"
                    className=" file:hidden "
                  />
                  <button
                    type="button"
                    className="px-4 ml-12 py-2 rounded-lg text-blue-700 border border-blue-700"
                  >
                    upload
                  </button>
                </div>
                <div className="py-3 px-4 flex flex-col gap-6">
                  <div className="flex gap-5 items-center">
                    <input
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600  border-[#666666] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Are the details provided in English?
                    </label>
                  </div>
                  <div className="flex gap-5 items-center">
                    <input
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600  border-[#666666] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Is the signature in English?
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className="flex flex-col gap-3">
              <small>Add New Customer’s PAN document</small>
              <form className=" flex flex-col gap-3 ">
                <div className="border items-center flex w-fit py-1.5 px-5 rounded-md border-[#DCDEE2]">
                  <label
                    htmlFor="pan"
                    className="flex gap-2 w-full items-center"
                  >
                    <Img src="/images/remit/file.svg" alt="" />
                    Upload the Document
                  </label>
                  <input
                    {...register("pan")}
                    type="file"
                    name="pan"
                    id="pan"
                    className=" file:hidden "
                  />
                  <button
                    type="button"
                    className="px-4 ml-12 py-2 rounded-lg text-blue-700 border border-blue-700"
                  >
                    upload
                  </button>
                </div>
                <div className="py-3 px-4 flex flex-col gap-6">
                  <div className="flex gap-5 items-center">
                    <input
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600  border-[#666666] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Are the details provided in English?
                    </label>
                  </div>
                  <div className="flex gap-5 items-center">
                    <input
                      type="checkbox"
                      value=""
                      className="w-4 h-4 text-blue-600  border-[#666666] focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="checkbox"
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Is the signature in English?
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className="flex flex-col gap-3">
              <div className="py-3 px-4 bg-[#F5F9FF] text-blue-700 rounded-lg">
                Verify the details{" "}
              </div>
              <small className="py-2 px-4 flex items-center gap-5 bg-[#F9FAFB] rounded-lg">
                <Img src="/images/login/green-tick.svg" />
                PAN name is authorized as per document
              </small>
              <small className="py-2 px-4 flex items-center gap-5 bg-[#F9FAFB] rounded-lg">
                <Img src="/images/login/green-tick.svg" />
                Date of Birth authorized
              </small>
            </div>
          </div>
        </div>
      )}
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <div className="">
                  <Img src="/images/remit/beni.svg" alt="" />
                </div>
                <div className="">Add Beneficiary Details (Optional)</div>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="pb-8 flex flex-col gap-10 pl-10">
              {formData.map((data, index) => (
                <div key={data.name} className="flex justify-between">
                  <div
                    className={`flex items-center flex-col after:right-10 ${index === formData.length - 1 && "after:border-none"}  ${isFieldDisabledBeneficiary(index) ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10"
                      src={`${isFieldDisabledBeneficiary(index) ? "images/overview/ellipse-gray.svg" : "images/overview/ellipse.svg"}`}
                      alt=""
                    />
                  </div>
                  <Controller
                    name={data.name as keyof BeneficiaryType}
                    control={control}
                    defaultValue={data.defaultValue || ""}
                    // skipcq: JS-0417
                    render={({ field }) => (
                      <FormItem className="w-full flex flex-col gap-3">
                        <FormLabel
                          className={`${isFieldDisabledBeneficiary(index) ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
                        >
                          {data.label}
                        </FormLabel>
                        {renderFormField(
                          data,
                          field,
                          isFieldDisabledBeneficiary(index),
                        )}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <div className="">
                  <Img src="/images/remit/beni.svg" alt="" />
                </div>
                <div className="">Address Details (optional)</div>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="pb-8 flex flex-col gap-10 pl-10">
              {addressFieldData.map((data, index) => (
                <div key={data.name} className="flex justify-between w-full">
                  <div
                    className={`flex items-center flex-col after:right-10 ${index === addressFieldData.length - 1 && "after:border-none"}  ${isFieldDisabledAddress(index) ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10"
                      src={`${isFieldDisabledAddress(index) ? "images/overview/ellipse-gray.svg" : "images/overview/ellipse.svg"}`}
                      alt=""
                    />
                  </div>
                  <Controller
                    name={data.name as keyof BeneficiaryAddressType}
                    control={control}
                    defaultValue=""
                    // skipcq: JS-0417
                    render={({ field }) => (
                      <FormItem className="w-full flex flex-col gap-3">
                        <FormLabel
                          className={`${isFieldDisabledAddress(index) ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
                        >
                          {data.label}
                        </FormLabel>
                        {renderFormField(
                          data,
                          field,
                          isFieldDisabledAddress(index),
                        )}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <div className="">
                  <Img src="/images/remit/beni.svg" alt="" />
                </div>
                <div className="">Other Details (optional)</div>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="pb-8 flex flex-col gap-10 pl-10">
              {otherFieldData.map((data, index) => (
                <div key={data.name} className="flex justify-between w-full">
                  <div
                    className={`flex items-center flex-col after:right-10 ${index === otherFieldData.length - 1 && "after:border-none"}  ${isFieldDisabledOther(index) ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10"
                      src={`${isFieldDisabledOther(index) ? "images/overview/ellipse-gray.svg" : "images/overview/ellipse.svg"}`}
                      alt=""
                    />
                  </div>
                  <Controller
                    name={data.name as keyof BeneficiaryOtherDetailsType}
                    control={control}
                    defaultValue=""
                    // skipcq: JS-0417
                    render={({ field }) => (
                      <FormItem className="w-full flex flex-col gap-3">
                        <FormLabel
                          className={`${isFieldDisabledOther(index) ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
                        >
                          {data.label}
                        </FormLabel>
                        {renderFormField(
                          data,
                          field,
                          isFieldDisabledOther(index),
                        )}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default RemittanceDetails;
