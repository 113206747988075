import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import { ProtectedRoute } from "components";
import DefaultLayout from "layouts/DefaultLayout";
import Loader from "common/loader";
import ContactPage from "pages/Contacts";
import CreateRemittanceQuotation from "pages/CreateRemittanceQuotation";
import CreateForexQuotation from "pages/CreateForexQuotation";
import DashboardPage from "pages/Dashboard";
import CustomersPage from "pages/Customers";
import ApprovalsPage from "pages/Approvals";
import RoleandgroupsPage from "pages/Accessandroles";
import FinancePage from "pages/Finance";
import Remit from "pages/Remit";
import DealsPage from "pages/Deals";
import OrdersPage from "pages/Orders/neworders";
import CreateRemit from "pages/CreateRemit/Index";
import CreateDeal from "pages/CreateDeal/newdeal";
import DealDetailsPage from "pages/DealDetails";
import Overview from "components/Remit/overview";
import CreateNewDeal from "pages/CreateDeal/newdeal";
import LogoutPage from "pages/Logout";
import DealSuccess from "pages/CreateDeal/dealsuccess";
import CreateOrder from "pages/CreateOrder";
import TaskPage from "pages/Deals/newdealpage";
import NewDealDetailsPage from "pages/DealDetails/newdealdetails";
import OrderDetailsPage from "pages/OrderDetails";
import NewOrderDetailsPage from "pages/OrderDetails/neworderdetails";
import PartnersPage from "pages/Partners";
import NewCustomersPage from "pages/Customers/newcustomer";
import NewPartnersPage from "pages/Partners/newpartners";
import CustomerRegistration from "pages/CustomerRegistration";
import PartnerDetailsPage from "pages/PartnerDetails";
import ConfirmCollection from "pages/ConfirmCollection";
import CustomerDetailsPage from "pages/CustomerDetails";
import PartnerRegistration from "pages/PartnerRegistration";
import NewFinancePage from "pages/Finance/newfinance";
import CreatePayout from "pages/CreatePayout";
import CreateCollection from "pages/CreateCollection";
import EntryForm from "pages/EntryForm";
import Ordersuccess from "pages/CreateRemit/ordersuccess";
import CreateStaffPage from "pages/CreateStaff";
import StaffPage from "pages/Staff";
import StaffdetailsPage from "pages/StaffDetails";
import Opportunities from "pages/Opportunities";
import OpportunitiesOverview from "pages/Opportunities/overview";
import CreateOpportunity from "pages/Opportunities/search";
import CreateContact from "pages/Create Opportunities";
import OpportunityDetails from "pages/Opportunities/opportunitydetails";
import LoginPage from "pages/Login";
import RedirectPortal from "pages/Redirect Portal";
import Existing from "pages/Redirect Portal/existing";
import RedirectExisting from "pages/Redirect Portal/existing";
import OrderSuccess from "pages/Redirect Portal/ordersuccess";
import Payment from "pages/Redirect Portal/payment";
import HomeLayout from "pages/HomeLayout";
const BranchRegistration = lazy(() => import("pages/BranchRegistration"));
const Reports = lazy(() => import("pages/Reports"));
const Settings = lazy(() => import("pages/Settings"));
const SetPassword = lazy(() => import("pages/SetPassword"));
const ForgetPassword = lazy(() => import("pages/ForgetPassword"));
const Company = lazy(() => import("pages/Company"));
const Createregion = lazy(() => import("pages/CreateRegion"));
const TestPage = lazy(() => import("pages/Test")); //testing page
const BranchDetails = lazy(() => import("pages/BranchDetails"));
const mydefaultmargin = lazy(() => import("pages/mydefaultmargin"));
const mymargins = lazy(() => import("pages/MyMargin"));
const Region = lazy(() => import("pages/Region"));
const GicOrders = lazy(() => import("pages/Gic-Orders"));
const ConfirmPayout = lazy(() => import("pages/ConfirmPayout"));
const RetailInteraction = lazy(() => import("pages/CreateRetailInteraction"));
const CorporateInteraction = lazy(
  () => import("pages/CreateCompanyInteraction"),
);

const ProjectRoutes = () => {
  // const authtoken = localStorage.getItem("authToken");

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          {/* AUTH */}

          <Route path="/" element={<LoginPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route
            path="/logout"
            element={<ProtectedRoute element={LogoutPage} />}
          />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route
            path="/setpassword"
            element={<ProtectedRoute element={SetPassword} />}
          />
          <Route
            path="/createRemit"
            element={<ProtectedRoute element={CreateRemit} />}
          />
          <Route
            path="/createnewdeal"
            element={<ProtectedRoute element={CreateNewDeal} />}
          />

          {/* DefaultLayout */}
          <Route path="/" element={<DefaultLayout />}>            
            <Route
              path="/company"
              element={<ProtectedRoute element={Company} />}
            />
            <Route
              path="/createregion"
              element={<ProtectedRoute element={Createregion} />}
            />            
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={DashboardPage} />}
            />
            <Route path="/opportunities" element={<ProtectedRoute element={Opportunities} />}>
              <Route index element={<ProtectedRoute element={OpportunitiesOverview} />} />
              <Route path="addopportunity" element={<ProtectedRoute element={CreateOpportunity} />} />
              <Route path="createcontact" element={<ProtectedRoute element={CreateContact} />} />
              <Route path="opportunitydetails/:id" element={<ProtectedRoute element={OpportunityDetails} />} />
            </Route>
            <Route path="/remit" element={<ProtectedRoute element={Remit} />}>
              <Route
                path="orders"
                element={<ProtectedRoute element={OrdersPage} />}
              />
              <Route
                path="olddeals"
                element={<ProtectedRoute element={DealsPage} />}
              />
              <Route
                path="deals"
                element={<ProtectedRoute element={TaskPage} />}
              />
              <Route
                path="dealdetails/:id"
                element={<ProtectedRoute element={NewDealDetailsPage} />}
              />
              <Route
                path="dealsuccess"
                element={<ProtectedRoute element={DealSuccess} />}
              />
              <Route
                path="ordersuccess"
                element={<ProtectedRoute element={Ordersuccess} />}
              />
              <Route index element={<ProtectedRoute element={Overview} />} />
              <Route
                path="orderdetails/:id"
                element={<ProtectedRoute element={NewOrderDetailsPage} />}
              />
            </Route>            
            <Route
              path="/entry-form"
              element={<ProtectedRoute element={EntryForm} />}
            />
            <Route
              path="/dealdetails2:id"
              element={<ProtectedRoute element={DealDetailsPage} />}
            />

            <Route path="/" element={<ProtectedRoute element={HomeLayout} />}>
              <Route path="/staff" element={<ProtectedRoute element={StaffPage} />} />
              <Route
                path="/customers"
                element={<ProtectedRoute element={NewCustomersPage} />}
              />
              <Route
                path="/finance"
                element={<ProtectedRoute element={NewFinancePage} />}
              />
              <Route
                path="/accessandroles"
                element={<ProtectedRoute element={RoleandgroupsPage} />}
              />
              <Route
                path="/approvals"
                element={<ProtectedRoute element={ApprovalsPage} />}
              />
              <Route
                path="/partners"
                element={<ProtectedRoute element={NewPartnersPage} />}
              />
            </Route>

            <Route
              path="/reports"
              element={<ProtectedRoute element={Reports} />}
            />
            <Route
              path="/settings"
              element={<ProtectedRoute element={Settings} />}
            />
            <Route
              path="/branchregistration"
              element={<ProtectedRoute element={BranchRegistration} />}
            />
            <Route
              path="/branchregistration:id"
              element={<ProtectedRoute element={BranchRegistration} />}
            />
            <Route
              path="/customerdetails/:id"
              element={<ProtectedRoute element={CustomerDetailsPage} />}
            />
            <Route
              path="/staffdetails:id"
              element={<ProtectedRoute element={StaffdetailsPage} />}
            />
            <Route
              path="/mydefaultmargin"
              element={<ProtectedRoute element={mydefaultmargin} />}
            />
            <Route
              path="/partnerdetails/:id"
              element={<ProtectedRoute element={PartnerDetailsPage} />}
            />
            <Route
              path="/branchdetails:id"
              element={<ProtectedRoute element={BranchDetails} />}
            />
            <Route
              path="/regions"
              element={<ProtectedRoute element={Region} />}
            />
            <Route
              path="/gicorders"
              element={<ProtectedRoute element={GicOrders} />}
            />
            <Route
              path="/mymargins"
              element={<ProtectedRoute element={mymargins} />}
            />
            <Route
              path="/createcollection"
              element={<ProtectedRoute element={CreateCollection} />}
            />
            <Route
              path="/confirmcollection:id"
              element={<ProtectedRoute element={ConfirmCollection} />}
            />
            <Route
              path="/createpayout"
              element={<ProtectedRoute element={CreatePayout} />}
            />
            <Route
              path="/confirmpayout:id"
              element={<ProtectedRoute element={ConfirmPayout} />}
            />

            <Route
              path="/contacts"
              element={<ProtectedRoute element={ContactPage} />}
            />
            <Route
              path="/retailinteraction"
              element={<ProtectedRoute element={RetailInteraction} />}
            />
            <Route
              path="/corporateinteraction"
              element={<ProtectedRoute element={CorporateInteraction} />}
            />
          </Route>

          <Route path="/redirect" element={<ProtectedRoute element={RedirectPortal}/>}>
            <Route index element={<RedirectExisting />} />
            <Route path="ordersuccess" element={<OrderSuccess />} />
            <Route path="payment" element={<Payment />} />
          </Route>
          <Route
            path="/createRemittanceQuotation"
            element={<ProtectedRoute element={CreateRemittanceQuotation} />}
          />
          <Route
            path="/createstaff"
            element={<ProtectedRoute element={CreateStaffPage} />}
          />
          <Route
            path="/partnerregistration"
            element={<ProtectedRoute element={PartnerRegistration} />}
          />
          <Route
            path="/customerregistration"
            element={<ProtectedRoute element={CustomerRegistration} />}
          />
          <Route
            path="/createorder"
            element={<ProtectedRoute element={CreateOrder} />}
          />
          <Route
            path="/createdeal"
            element={<ProtectedRoute element={CreateDeal} />}
          />

          <Route
            path="/createforexquotation"
            element={<ProtectedRoute element={CreateForexQuotation} />}
          />

          {/* DELETE LATER */}

          <Route path="/lv-dashboard" element={<Home />} />

          {/* 404 Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
};
export default ProjectRoutes;
